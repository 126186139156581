<template>
  <div class="currency-switcher px-4 py-3">
    <div class="currency-switcher__title mb-2">
      {{ $t('currency') }}
    </div>
    <CyButtonToggle
      v-model="value"
      :items="$static.options"
      active-theme="secondary"/>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CyButtonToggle from '@/components/CyButtonToggle.vue'

export default {
  name: 'CyCcmSidebarCurrencySwitcher',
  components: {
    CyButtonToggle,
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      queryBody: (state) => state.queryBody,
    }),
    $static: () => ({
      options: [
        {
          key: 'USD',
          value: 'USD',
          text: '$ - USD',
        },
        {
          key: 'EUR',
          value: 'EUR',
          text: '€ - EUR',
        },
        {
          key: 'GBP',
          value: 'GBP',
          text: '£ - GBP',
        },
        {
          key: 'CNY',
          value: 'CNY',
          text: '¥ - CNY',
        },
      ],
    }),
    value: {
      get () {
        return _.get(this.queryBody, 'currency', 'EUR')
      },
      set (value) {
        this.SET_QUERY_FILTER({ key: 'currency', value })
        this.$emit('input')
      },
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
  },
  i18n: {
    messages: {
      en: {
        currency: 'Currency',
      },
      es: {
        currency: 'Divisa',
      },
      fr: {
        currency: 'Devise',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .currency-switcher {
    border: 1px solid cy-get-color("grey");
    border-radius: 4px;
    background-color: cy-get-color("white");

    &__title {
      color: cy-get-color("grey", "dark-2");
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;
    }

    ::v-deep .cy-btn-toggle__wrapper {
      display: flex;
      width: 100%;

      > .cy-btn {
        display: flex;
        flex-grow: 1;
      }
    }
  }
</style>

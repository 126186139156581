<template>
  <div>
    <h2 class="mb-5">
      Group-by filter
    </h2>

    <CyCcmSidebarGroupByFilter/>
  </div>
</template>

<script>
import CyCcmSidebarGroupByFilter from '@/components/CyCcmSidebarGroupByFilter.vue'

export default {
  name: 'CyCcmSidebarGroupByFilterSandbox',
  components: {
    CyCcmSidebarGroupByFilter,
  },
}
</script>

<template>
  <v-expansion-panel :class="['radio-filter', { 'single-filter': isSingleFilter }]">
    <v-expansion-panel-header
      v-if="!isSingleFilter"
      class="px-4 d-flex flex-wrap">
      <template #default>
        <span class="radio-filter__title text-truncate">
          {{ title }}
        </span>
        <div
          v-if="!_.isEmpty(selected)"
          class="radio-filter__selected mt-3">
          <CyTag
            small
            icon-after="close"
            class="radio-filter__selected-tag"
            @click-icon-after="clear">
            <span class="text-truncate">{{ selected }}</span>
          </CyTag>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :class="isSingleFilter ? 'elevation-0' : 'elevation-4'">
      <div class="radio-filter__selected--empty px-4">
        <CyTag
          v-if="!isSingleFilter && _.isEmpty(selected)"
          variant="default"
          class="mb-3"
          small>
          {{ $t('noAppliedFilter') }}
        </CyTag>
      </div>
      <v-list class="radio-filter__options cy-scrollbars py-0 px-4">
        <v-radio-group
          v-model="selected"
          @change="isSingleFilter && $emit('input')">
          <v-radio
            v-for="{ label, value } in _.sortBy(options, 'value')"
            :key="value"
            :label="label"
            :value="value"
            color="secondary">
            {{ label || value }}
          </v-radio>
        </v-radio-group>
      </v-list>
      <div
        v-if="!isSingleFilter"
        class="radio-filter__actions pa-2">
        <CyButton
          variant="tertiary"
          theme="grey"
          sm
          @click="clear">
          {{ $t('forms.btnClear') }}
        </CyButton>
        <CyButton
          sm
          @click="$emit('input')">
          {{ $t('applyFilters') }}
        </CyButton>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'CyCcmSidebarRadioFilter',
  props: {
    filterId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    isSingleFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      queryBody: (state) => state.queryBody,
    }),
    selected: {
      get () {
        return _.get(this.queryBody, this.filterId, null)
      },
      set (value) {
        this.SET_QUERY_FILTER({ key: this.filterId, value })
      },
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
    clear () {
      this.selected = null
      this.$emit('input')
    },
  },
  i18n: {
    messages: {
      en: {
        applyFilters: 'Apply filters',
        noAppliedFilter: 'No filter applied',
        noAvailableFilters: 'No available filters',
      },
      es: {
        applyFilters: 'Aplicar filtros',
        noAppliedFilter: 'No se aplicó ningún filtro',
        noAvailableFilters: 'No hay filtros disponibles',
      },
      fr: {
        applyFilters: 'Appliquer les filtres',
        noAppliedFilter: 'Aucun filtre appliqué',
        noAvailableFilters: 'Aucun filtre disponible',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.radio-filter {
  border-radius: 4px;

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }

  ::v-deep .v-expansion-panel-header {
    font-size: $font-size-default;

    &__icon {
      .v-icon {
        margin-right: -4px;
        font-size: 30px;
      }
    }

    &--active {
      min-height: 0;

      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 0;
        left: 0;
        height: 6px;
        background: cy-get-color("white");
      }
    }
  }

  ::v-deep .v-expansion-panel-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    border-radius: 0 0 #{$menu-border-radius} #{$menu-border-radius};
    background-color: cy-get-color("white");

    &.expand-transition-leave-active {
      z-index: 1;
    }
  }

  &__options {
    max-height: 260px;
    border-top: 1px solid cy-get-color("grey");
    border-bottom: 1px solid cy-get-color("grey");
    border-radius: 0 0 4px 4px;

    ::v-deep .v-item--active::before {
      opacity: 0;
    }

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 120px;
      border-top: 1px solid cy-get-color("grey");
      border-bottom: 1px solid cy-get-color("grey");
    }
  }

  &.single-filter {
    ::v-deep .v-expansion-panel-content {
      position: relative;
    }

    .radio-filter__options {
      border-bottom: none;
    }
  }

  .v-input--radio-group {
    margin-top: 12px;

    .v-radio {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .v-messages {
      display: none;
    }
  }

  &__title {
    width: calc(100% - 26px);
    line-height: 1.5;
  }

  &__back-btn {
    width: 36px;
  }

  &__selected {
    display: flex;
    justify-content: space-between;
    order: 1;
    width: 100%;

    &-tag.tag {
      display: grid;
      grid-template-columns: 1fr 24px;
    }

    &--empty {
      order: 1;
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<template>
  <div>
    <CyDetails
      :item-id="catalogRepositoryCanonical"
      :on-save="onSave"
      :on-cancel="onCancel"
      :on-delete="$toggle.showDeleteDialog"
      :can-cancel="canCancel"
      :can-save="canSave"
      :loading="isLoading"
      :saving="isSaving"
      :deleting="isDeleting"
      :is-owner="isOwner"
      :contained="false"
      :is-read-only="!canUpdateCatalogRepository"
      not-contained>
      <template slot="details_form">
        <CyAlert
          theme="error"
          :content="[...refreshErrors, ...errors]"/>
        <v-row
          align="start"
          no-gutters>
          <v-col>
            <h2 class="h5">
              {{ $t('general') }}
            </h2>
          </v-col>
          <v-col
            class="ml-4"
            cols="5">
            <v-text-field
              v-model="$v.formData.name.$model"
              :label="$t('forms.fieldName')"
              :error-messages="nameErrors"
              :disabled="!canUpdateCatalogRepository"
              required
              class="required-field"
              data-cy="name-input"
              @blur="$v.formData.name.$touch()"/>
            <CySelectOwner
              v-model="$v.formData.owner.$model"
              show-on-creation
              :readonly="!canUpdateCatalogRepository"
              :disabled="!canUpdateCatalogRepository"
              :current-owner="formData.owner"/>
          </v-col>
          <v-col/>
        </v-row>

        <v-divider class="my-8"/>

        <v-row
          align="start"
          no-gutters>
          <v-col>
            <h2 class="h5 mb-2">
              {{ $t('stacks.gitRepo') }}
            </h2>
            <p>
              {{ $t('stacks.gitRepoHint') }}
            </p>
          </v-col>
          <v-col
            class="ml-4"
            cols="5">
            <CySearchBox
              v-model="$v.formData.url.$model"
              :error-messages="urlErrors"
              :disabled="isUrlReadOnly"
              :readonly="isUrlReadOnly"
              :hint="hints.urlGithub"
              :persistent-hint="!$isCreationRoute"
              required
              clearable
              class="required-field has-copy-btn mb-3"
              data-cy="url-input">
              <v-icon
                v-if="!$isCreationRoute && canUpdateCatalogRepository"
                slot="append-outer"
                color="darkgrey"
                @click="$toggle.isUrlLocked">
                {{ isUrlLocked ? 'lock' : 'lock_open' }}
              </v-icon>
              <span slot="label">{{ $t('untranslated.URL') }}</span>
              <CyCopyButton
                v-if="!$v.formData.url.$invalid"
                slot="append"
                :copy-value="formData.url"/>
            </CySearchBox>

            <CyCredentials
              v-if="showCredentials"
              v-model="$v.formData.credential_canonical.$model"
              clearable
              :class="['mb-3', {
                'mt-5': !$isCreationRoute,
                'required-field': isPrivate,
              }]"
              :disabled="!canUpdateCatalogRepository"
              :error-messages="credentialCanonicalErrors"
              :hint="isPrivate ? $t('credentialRequiredForBranches') : null"
              :items="filteredCredentials"
              :label="$t('Credential')"
              :required="isPrivate"
              @blur="$v.formData.credential_canonical.$touch()"/>

            <v-row
              v-if="showBranches"
              no-gutters>
              <v-select
                v-model="$v.formData.branch.$model"
                :disabled="!hasBranches || !canUpdateCatalogRepository"
                :items="branches"
                :label="$t('forms.fieldBranch')"
                :hint="hints.fetchBranches"
                persistent-hint
                hide-selected
                required
                class="required-field align-self-start flex-1-1-0"
                data-cy="branch-select">
                <template #selection="{ item }">
                  <div class="black--text">
                    {{ item }}
                  </div>
                </template>

                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>

              <CyTooltip
                right
                transition="slide-x-transition"
                :disabled="!canFetchBranches">
                <template #activator="{ on: refreshTooltip }">
                  <span
                    class="align-self-center ml-5"
                    v-on="refreshTooltip">
                    <CyButton
                      :readonly="!canFetchBranches"
                      :disabled="!canFetchBranches || !canUpdateCatalogRepository"
                      :loading="isFetchingBranches"
                      icon="refresh"
                      variant="secondary"
                      icon-only
                      sm
                      @click="getBranches"/>
                  </span>
                </template>
                <span>
                  {{ isFetchingBranches ? $t('refreshingBranches') : $t('refreshBranches') }}
                </span>
              </CyTooltip>
            </v-row>
          </v-col>
          <v-col/>
        </v-row>

        <v-divider
          v-if="$isCreationRoute"
          class="my-8"/>

        <v-row
          v-if="$isCreationRoute"
          align="start"
          no-gutters>
          <v-col>
            <h2 class="h5 mb-2">
              {{ $t('stacks.visibility.title') }}
            </h2>
            <p>
              {{ $t('stacks.visibility.description') }}
            </p>
          </v-col>
          <v-col
            class="ml-4"
            cols="5">
            <div class="section__label">
              {{ $t('stacks.visibility.label') }}
            </div>
            <CyWizardStackVisibilityList
              v-if="$isCreationRoute"
              class="mb-2"
              :visibility.sync="formData.visibility"/>

            <CyWizardStackMaintainerSelector
              v-if="$isCreationRoute && !_.isEmpty(teams)"
              :teams="teams"
              :selected.sync="formData.team_canonical"/>
          </v-col>
          <v-col/>
        </v-row>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmDeleteHeader')"
      :action-btn-func="onDeleteConfirm"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
      <h3>{{ formData.name }}</h3>
      <p class="url">
        {{ formData.url }}
      </p>
      <p class="ma-0">
        {{ $t('confirmDeleteRepository') }}
      </p>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyCopyButton from '@/components/CyCopyButton.vue'
import CyCredentials from '@/components/CyCredentials.vue'
import CyDetails from '@/components/CyDetails.vue'
import CySearchBox from '@/components/CySearchBox.vue'
import CySelectOwner from '@/components/CySelectOwner.vue'
import CyWizardStackMaintainerSelector from '@/components/CyWizardStackMaintainerSelector.vue'
import CyWizardStackVisibilityList from '@/components/CyWizardStackVisibilityList.vue'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb, checksPass, anyChecksPass } from '@/utils/helpers'
import { required, requiredIf } from 'vuelidate/lib/validators'

export const PUBLIC = 'PUBLIC'
export const PRIVATE = 'PRIVATE'

export default {
  name: 'CyPageCatalogRepositoryForm',
  components: {
    CyCopyButton,
    CyCredentials,
    CyDetails,
    CySearchBox,
    CySelectOwner,
    CyWizardStackMaintainerSelector,
    CyWizardStackVisibilityList,
  },
  breadcrumb () {
    const header = this.$isCreationRoute
      ? this.$t('forms.btnCreate')
      : this.$t('forms.btnEdit')

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.catalogRepositories'),
        name: 'catalogRepositories',
      },
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    const title = {
      newCatalogRepository: this.$t('createCatalog'),
      editCatalogRepository: this.$t('editCatalogRepository', { catalogRepositoryName: this.catalogRepository?.name }),
    }[this.$route.name]

    return { title }
  },
  props: {
    catalogRepositoryCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formData: {
      name: '',
      url: '',
      branch: '',
      credential_canonical: null,
      owner: {},
      visibility: 'local',
      team_canonical: null,
    },
    hasFetchedBranchesFor: {},
    isDeleting: false,
    isFetchingBranches: false,
    isLoading: true,
    isSaving: false,
    isUrlLocked: true,
    showDeleteDialog: false,
  }),
  validations: {
    formData: {
      name: { required },
      url: {
        required,
        isValidGitUrl: (url) => REGEX.GIT.test(url),
      },
      branch: { required },
      credential_canonical: {
        required: requiredIf(function () {
          return this.isPrivate
        }),
        isValidCredential () {
          return !this.hasBranchesErrors
        },
      },
      owner: {},
    },
  },
  computed: {
    ...mapState('organization', {
      hasCredentials: (state) => !_.isEmpty(state.available.credentials),
      teams: (state) => state.available.teams,
    }),
    ...mapState('organization/catalogRepository', {
      branches: (state) => _.$get(state, 'branches', []),
      catalogRepositoryErrors: (state) => state.errors,
      refreshErrors: (state) => state.errors.refresh,
    }),
    ...mapGetters('organization', [
      'getCredentialsByType',
    ]),
    ...mapGetters('organization/catalogRepository', [
      'catalogRepository',
      'hasBranches',
      'hasBranchesErrors',
    ]),
    canUpdateCatalogRepository () {
      const { catalogRepositoryCanonical } = this
      return this.$cycloid.permissions.canDisplay('UpdateServiceCatalogSource', catalogRepositoryCanonical)
    },
    showCredentials () {
      return this.formData.credential_canonical || (this.isPrivate && !this.$v.formData.url.$invalid)
    },
    isPrivate () {
      return _.isEqual(_.$get(this.hasFetchedBranchesFor, this.formData.url), PRIVATE)
    },
    errors () {
      const { branches, catalogRepository } = this.catalogRepositoryErrors
      return _.filter([
        ...branches,
        ...catalogRepository,
      ], ({ code }) => code !== 'Required')
    },
    hasErrors () {
      return !_.isEmpty(this.errors)
    },
    canCancel () {
      return this.$hasDataChanged('formData')
    },
    canSave () {
      return this.$isCreationRoute
        ? !this.$v.formData.$invalid
        : !this.$v.formData.$invalid && this.canCancel
    },
    showBranches () {
      return _.isString(this.hasFetchedBranchesFor[this.formData.url])
    },
    canFetchBranches () {
      const checks = {
        hasNoErrors: !this.hasErrors,
        hasValidURL: !this.$v.formData.url.$invalid,
        hasValidCred: !this.$v.formData.credential_canonical.$invalid,
        notFetchingBranches: !this.isFetchingBranches,
      }
      return checksPass(checks)
    },
    canGetTeams () {
      return this.$cycloid.permissions.canDisplay('GetTeams')
    },
    isUrlReadOnly () {
      return !this.$isCreationRoute && this.isUrlLocked
    },
    hints () {
      const { isUrlReadOnly, isFetchingBranches, hasBranches, isPrivate } = this
      return {
        urlGithub:
          isUrlReadOnly
            ? this.$t('fieldGitHintReadonly')
            : this.$t('fieldGitHint'),
        fetchBranches: (() => {
          if (isFetchingBranches) return this.$t('fetchingBranches')
          if (hasBranches) return this.$t('fetchBranchesSuccess')
          return isPrivate
            ? this.$t('fetchBranchesFieldsMissing.private')
            : this.$t('fetchBranchesFieldsMissing.public')
        })(),
      }
    },
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    urlErrors () {
      const errors = []
      const { $dirty, required, isValidGitUrl } = this.$v.formData.url
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isValidGitUrl) errors.push(this.$t('forms.fieldInvalidGitUrl'))
      return errors
    },
    credentialCanonicalErrors () {
      const errors = []
      const { $dirty } = this.$v.formData.credential_canonical
      if (!$dirty || this.isFetchingBranches) return errors
      if (this.hasBranchesErrors) errors.push(this.$t('fetchBranchesError'))
      return errors
    },
    crCanonical () {
      const { catalogRepositoryCanonical, formData: { name: catalogRepositoryName } } = this
      return this.$isCreationRoute ? this.$getSlug(catalogRepositoryName) : catalogRepositoryCanonical
    },
    filteredCredentials () {
      if (!this.hasCredentials) return []
      const creds = this.formData.url.startsWith('https')
        ? this.getCredentialsByType('basic_auth')
        : this.getCredentialsByType('ssh')
      return _.sortBy(creds, ({ name }) => _.lowerCase(name))
    },
    branchesWatcher () {
      return [
        this.formData.credential_canonical || '',
        this.formData.url || '',
      ]
    },
    isOwner () {
      return this.isEntityOwner(this.formContent)
    },
  },
  watch: {
    branchesWatcher: {
      async handler ([newCred], [oldCred]) {
        if (this.$hasDataChanged('formData.url') && _.isEqual(newCred, oldCred)) this.$set(this.formData, 'credential_canonical', null)
        this.SET_BRANCHES(null)
        await this.getBranches()
      },
      deep: true,
    },
    'formData.branch' () {
      this.CLEAR_CR_ERRORS()
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'credentials' })

    if (this.$isCreationRoute) {
      if (this.canGetTeams) {
        await this.FETCH_AVAILABLE({ keyPath: 'teams' })
      }
    } else {
      await this.GET_CATALOG_REPOSITORY({ catalogRepositoryCanonical: this.crCanonical })
      this.setItemAndFormData()
      await this.getBranches()
    }

    this.$toggle.isLoading(false)
  },
  destroyed () {
    this.RESET_CR_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/catalogRepository', [
      'CREATE_CATALOG_REPOSITORY',
      'DELETE_CATALOG_REPOSITORY',
      'GET_BRANCHES',
      'GET_CATALOG_REPOSITORY',
      'UPDATE_CATALOG_REPOSITORY',
    ]),
    ...mapMutations('organization/catalogRepository', [
      'CLEAR_CR_ERRORS',
      'RESET_CR_STATE',
      'SET_BRANCHES',
    ]),
    async getBranches () {
      if (this.$v.formData.url.$invalid) return

      this.$toggle.isFetchingBranches(true)
      const { credential_canonical: credentialCanonical, url } = this.formData

      if (!_.has(this.hasFetchedBranchesFor, url)) this.$set(this.hasFetchedBranchesFor, url, PUBLIC)
      await this.GET_BRANCHES({ credentialCanonical, url })

      const markAsPrivate = anyChecksPass({
        noBranchesNoCred: !this.hasBranches && _.$isEmpty(credentialCanonical),
        hasBranchesAndCred: this.hasBranches && !_.$isEmpty(credentialCanonical),
      })
      if (markAsPrivate) this.$set(this.hasFetchedBranchesFor, url, PRIVATE)
      if (this.$isCreationRoute && this.branches.length === 1) this.formData.branch = this.branches[0]

      this.$toggle.isFetchingBranches(false)
    },
    onCancel () {
      this.$toggle.isUrlLocked(true)
      this.CLEAR_CR_ERRORS()
      this.$resetData('formData')
      this.$v.$reset()
    },
    async onSave () {
      if (this.isSaving) return

      this.$toggle.isSaving(true)

      const { crCanonical: catalogRepositoryCanonical, $router } = this
      const { team_canonical: teamCanonical, ...rest } = this.formData
      const catalogRepository = {
        ...rest,
        canonical: catalogRepositoryCanonical,
        owner: this.formData?.owner?.username,
        ...(teamCanonical !== null && { team_canonical: teamCanonical }),
      }
      this.$isCreationRoute
        ? await this.CREATE_CATALOG_REPOSITORY({ catalogRepository, $router })
        : await this.UPDATE_CATALOG_REPOSITORY({ catalogRepository })

      this.$toggle.isSaving(false)

      if (this.hasErrors) return

      this.setItemAndFormData()
    },
    async onDeleteConfirm () {
      const { formData: catalogRepository, $router } = this

      this.$toggle.isDeleting(true)
      await this.DELETE_CATALOG_REPOSITORY({ catalogRepository, $router })
      this.$toggle.showDeleteDialog(false)
      this.$toggle.isDeleting(false)
    },
    setItemAndFormData () {
      if (!this.catalogRepository) return

      this.$set(this, 'formData', {
        ..._.cloneDeep(this.catalogRepository),
        branch: _.get(this.catalogRepository, 'branch', 'stacks'),
      })
      this.$setOriginalData()
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.catalogRepository',
        confirmDeleteHeader: 'Delete catalog repository?',
        confirmDeleteRepository: `Please note that this does not delete the associated git repository. If you want to do that as well, you'll have to do it manually afterwards.`,
        confirmDeleteSentence: 'Are you sure that you want to delete this catalog repository?',
        createCatalog: 'Create catalog repository',
        credentialRequiredForBranches: 'This field is required to fetch branch information.',
        editCatalogRepository: 'Editing {catalogRepositoryName}',
        fetchBranchesError: 'Valid credentials must be provided in order to fetch the branches.',
        fetchBranchesFieldsMissing: {
          public: 'Please supply a valid git URL before selecting a branch.',
          private: 'Please supply a valid git URL and credentials before selecting a branch.',
        },
        fetchBranchesSuccess: 'Branches fetched.',
        fetchingBranches: 'Fetching repository branches...',
        fieldGitHint: 'This field should contain a valid Git URL',
        fieldGitHintReadonly: 'This field is readonly because changing it could break something. If you still need to edit it, please click on the lock',
        refreshBranches: 'Refresh branches',
        refreshingBranches: 'Refreshing branches...',
      },
      es: {
        title: '@:routes.catalogRepository',
        confirmDeleteHeader: 'Borrar repositorio del catálogo ?',
        confirmDeleteRepository: 'Ten en cuenta que esto no elimina el repositorio git asociado. Si quiere hacer eso también, tendrás que hacerlo manualmente después.',
        confirmDeleteSentence: 'Estás seguro de querer borrar este repositorio del catálogo?',
        createCatalog: 'Crear repositorio de catálogo',
        credentialRequiredForBranches: 'Este campo es obligatorio para obtener la información de la sucursal.',
        editCatalogRepository: 'Editando {catalogRepositoryName}',
        fetchBranchesError: 'No se pueden recuperar ramas: la URL git o la credencial no son válidas.',
        fetchBranchesFieldsMissing: {
          public: 'Por favor, seleccione una URL git antes de seleccionar una rama.',
          private: 'Por favor, seleccione una URL git y credenciales validas antes de seleccionar una rama.',
        },
        fetchBranchesSuccess: 'Ramas obtenidas.',
        fetchingBranches: 'Obteniendo ramas del repositorio...',
        fieldGitHint: 'Este campo debe contener una URL Git válida',
        fieldGitHintReadonly: 'Este campo es de solo lectura porque cambiarlo podría romper algo. Si aún necesita editarlo, haga clic en el candado',
        refreshBranches: 'Actualizar ramas',
        refreshingBranches: 'Actualizando ramas...',
      },
      fr: {
        title: '@:routes.catalogRepository',
        confirmDeleteHeader: 'Supprimer sources du catalogue ?',
        confirmDeleteRepository: 'Veuillez noter que cela ne supprime pas le dépôt git associé. Si vous souhaitez le faire également, vous devrez le faire manuellement par la suite.',
        confirmDeleteSentence: 'Êtes-vous sûr de vouloir supprimer ces sources du catalogue ?',
        createCatalog: 'Créer sources du catalogue',
        credentialRequiredForBranches: `Ce champ est obligatoire pour récupérer les informations de la branche.`,
        editCatalogRepository: 'Édition du {catalogRepositoryName}',
        fetchBranchesError: `Impossible de récupérer les branches: les informations d'identification ou l'URL du dépot ne sont pas valides.`,
        fetchBranchesFieldsMissing: {
          public: 'Veuillez sélectionner une URL git valide de sélectionner une branche.',
          private: `Veuillez sélectionner une URL git valide et des informations d'identification avant de sélectionner une branche.`,
        },
        fetchBranchesSuccess: 'Des branches sont allées chercher.',
        fetchingBranches: 'Récupération des branches du sources du catalogue...',
        fieldGitHint: 'Ce champ doit contenir une URL Git valide',
        fieldGitHintReadonly: `Ce champ est en lecture seule car le changer pourrait casser quelque chose. Si vous avez besoin de l'éditer, veuillez cliquer sur le cadenas`,
        refreshBranches: 'Actualiser les branches',
        refreshingBranches: 'Actualisation des branches...',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.has-copy-btn ::v-deep .v-label {
  height: auto;
}

.url {
  color: cy-get-color("grey", "dark-2");
}

.v-toolbar__content {
  line-height: 62px;
}

.section__label {
  margin-bottom: 8px;
  color: cy-get-color("grey", "dark-2");
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}

</style>

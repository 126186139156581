<template>
  <div>
    <h2 class="mb-5">
      Resource utilisation card
    </h2>

    <div>
      <CyInputsStorage
        v-model="value"
        label="Memory"
        required/>
    </div>
    <span>
      Value: {{ value }}
    </span>
  </div>
</template>

<script>
import CyInputsStorage from '@/components/CyInputsStorage.vue'

export default {
  name: 'CyInputsStorageSandbox',
  components: {
    CyInputsStorage,
  },
  data: () => ({
    value: 20736,
  }),
}
</script>

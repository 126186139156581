<template>
  <div class="mt-4 mb-8 pr-2">
    <CyAlert
      v-for="(error, env) in _.omitBy(errors, _.isEmpty)"
      :key="`error-${env}`"
      theme="warning"
      :title="$t('errorTitle', { env })"
      :content="error"
      :button-label="$t('retry')"
      @click="getEnvEstimation(env)"/>

    <v-data-table
      :headers="$static.headers"
      :items="itemsByTimeInterval"
      :loading="isFetchingEstimate"
      :loading-text="''"
      :items-per-page="itemsByTimeInterval.length"
      hide-default-footer
      item-key="environment"
      class="pricing-table mt-4">
      <template #top>
        <v-toolbar
          flat
          class="d-flex justify-end">
          <CyButtonToggle
            v-model="timeInterval"
            :items="$static.timeIntervals"
            class="time-interval-toggle"
            mandatory/>
        </v-toolbar>
      </template>
      <template #loading>
        <div class="loader">
          <v-progress-circular
            :size="24"
            color="secondary"
            indeterminate/>
        </div>
      </template>
      <template #item="{ item }">
        <tr>
          <td>
            <span class="font-weight-bold">
              {{ item.environment }}
            </span>
          </td>
          <td>
            <div v-if="item.hasEstimate">
              <span class="estimated font-weight-bold mr-1">
                {{ $tc('terracost.estimated', item.estimated, { nb: item.estimated }) }}
              </span>
              <span class="unestimated">
                {{ $tc('terracost.unestimated', item.unestimated, { nb: item.unestimated }) }}
              </span>
            </div>
            <div v-else>
              <span class="no-estimate">
                {{ $t('noEstimate') }}
              </span>
            </div>
          </td>
          <td class="text-right">
            {{ item.formattedCost }}
          </td>
        </tr>
      </template>
      <template #footer>
        <v-row
          v-if="!isFetchingEstimate && !_.$isEmpty(estimates)"
          no-gutters
          class="pa-4 pricing-table__footer">
          <v-col class="footer__labels">
            <span class="d-flex justify-end font-weight-bold">
              {{ isHourlyInterval ? $t('terracost.hourlyTotal') : $t('terracost.monthlyTotal') }} ({{ currency }})
            </span>
            <span class="d-flex justify-end font-weight-bold mt-1">
              {{ $t('terracost.annualTotal') }} ({{ currency }})
            </span>
          </v-col>
          <v-col class="text-right footer__totals">
            <span class="d-flex justify-end font-weight-bold">
              {{ isHourlyInterval ? totals.hourly : totals.monthly }}
            </span>
            <span class="d-flex justify-end font-weight-bold mt-1">
              {{ totals.annual }}
            </span>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CyButtonToggle from '@/components/CyButtonToggle.vue'

export default {
  name: 'CyTerracostPricingSummary',
  components: {
    CyButtonToggle,
  },
  props: {
    environmentsUsecaseKey: {
      type: Object,
      required: true,
    },
    formsConfig: {
      type: Object,
      default: null,
    },
    stackRef: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    timeInterval: 'monthly',
  }),
  computed: {
    ...mapState('organization/terracost', [
      'currency',
      'errors',
      'estimates',
      'loading',
      'stackFormsValues',
    ]),
    ...mapGetters('organization/terracost', [
      'getAmountWithCurrency',
    ]),
    $static () {
      return {
        headers: [
          {
            text: this.$t('forms.fieldOrgEnv'),
            value: 'environment',
            width: '150px',
          },
          {
            text: this.$t('terracost.resources'),
            value: 'estimated',
          },
          {
            text: this.$t('terracost.cost'),
            value: 'cost',
            align: 'right',
          },
        ],
        timeIntervals: [
          {
            key: 'monthly',
            value: 'monthly',
            theme: 'secondary',
            variant: 'secondary',
            text: this.$t('terracost.monthly'),
          },
          {
            key: 'hourly',
            value: 'hourly',
            theme: 'secondary',
            variant: 'secondary',
            text: this.$t('terracost.hourly'),
          },
        ],
      }
    },
    isFetchingEstimate () {
      return _.some(this.loading)
    },
    isHourlyInterval () {
      return this.timeInterval === 'hourly'
    },
    itemsByTimeInterval () {
      if (this.isFetchingEstimate) return []

      return _.chain(this.estimates)
        .entries()
        .map(([
          environment, modules]) => {
          const estimated = _.sumBy(modules, (data) => data.resource_estimates?.length) || 0
          const unestimated = _.sumBy(modules, (data) => data.skipped_resource_addresses?.length) || 0
          const cost = this.isHourlyInterval
            ? _.sumBy(modules, (data) => Number(data.planned_hourly_cost))
            : _.sumBy(modules, (data) => Number(data.planned_cost))
          const hasEstimate = !!(estimated || unestimated)
          const formattedCost = !_.$isEmpty(cost) ? `${this.getAmountWithCurrency(cost)}` : '--'

          return { cost, formattedCost, environment, estimated, hasEstimate, unestimated }
        })
        .value()
    },
    totals () {
      const getCostSum = (array, key) => _.sumBy(array, (item) => Number(item[key]))
      const annualTotal = _.reduce(this.estimates, (sum, modules) => sum + getCostSum(modules, 'planned_yearly_cost'), 0)
      const hourlyTotal = _.reduce(this.estimates, (sum, modules) => sum + getCostSum(modules, 'planned_hourly_cost'), 0)
      const monthlyTotal = _.reduce(this.estimates, (sum, modules) => sum + getCostSum(modules, 'planned_cost'), 0)

      return {
        annual: _.isNaN(annualTotal) ? '--' : this.getAmountWithCurrency(annualTotal),
        hourly: _.isNaN(hourlyTotal) ? '--' : this.getAmountWithCurrency(hourlyTotal),
        monthly: _.isNaN(monthlyTotal) ? '--' : this.getAmountWithCurrency(monthlyTotal),
      }
    },
  },
  mounted () {
    for (const environmentCanonical of _.keys(this.formsConfig)) {
      if (!this.isEstimationUpToDate(environmentCanonical)) {
        this.getEnvEstimation(environmentCanonical)
      }
    }
  },
  methods: {
    ...mapActions('organization/terracost', [
      'GET_ESTIMATE',
    ]),
    isEstimationUpToDate (environmentCanonical) {
      return _.isEqual(
        this.formsConfig[environmentCanonical],
        this.stackFormsValues[environmentCanonical]?.inputs[0]?.vars,
      )
    },
    getEnvEstimation (environmentCanonical) {
      const { environmentsUsecaseKey, formsConfig, stackRef } = this

      this.GET_ESTIMATE({
        service_catalog_ref: stackRef,
        inputs: [
          {
            environment_canonical: environmentCanonical,
            use_case: environmentsUsecaseKey[environmentCanonical],
            vars: formsConfig[environmentCanonical],
          },
        ],
      })
    },
  },
  i18n: {
    messages: {
      en: {
        errorTitle: 'An error occured while estimating the {env} environment.',
        noEstimate: 'Estimate not available',
        retry: 'Retry',
      },
      es: {
        errorTitle: 'Se produjo un error al estimar el entorno {env}.',
        noEstimate: 'Estimación no disponible',
        retry: 'Intentar otra vez',
      },
      fr: {
        errorTitle: `Une erreur s'est produite lors de l'estimation de l'environnement {env}.`,
        noEstimate: 'Estimation non disponible',
        retry: 'Réessayer',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  %table-layout {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  &-header {
    @extend %table-layout;
  }

  &__wrapper {
    tbody {
      @extend %cy-scrollbars;

      display: block;
      max-height: 260px;
      overflow-y: scroll; // stylelint-disable-line declaration-property-value-disallowed-list

      tr {
        @extend %table-layout;

        td:first-child {
          width: 150px;
          min-width: 150px;
        }
      }
    }
  }
}

::v-deep .cy-alert--warning .cy-alert__button {
  background-color: cy-get-color("warning", "light-2") !important;
  box-shadow: none !important;
  color: cy-get-color("warning", "dark-1") !important;

  &:hover {
    color: cy-get-color("warning") !important;
  }
}

.pricing-table {
  margin-left: 2px;

  // following rules are because it did not look like the mockups with just elevation-1
  border-radius: 4px;
  box-shadow:
    0 1px 5px cy-get-color("primary", $alpha: 0.2),
    0 1px 1px cy-get-color("primary", $alpha: 0.14),
    0 1px 3px cy-get-color("primary", $alpha: 0.12);

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }

  ::v-deep {
    .time-interval-toggle .v-btn__content {
      color: cy-get-color("primary");
    }

    .v-progress-linear {
      display: none;
      height: 0;
    }
  }

  .no-estimate,
  .unestimated {
    color: cy-get-color("grey", "dark-2");
  }

  .v-toolbar {
    border-bottom: 1px solid cy-get-color("grey", "light-2");
  }

  &__footer {
    border-top: 1px solid cy-get-color("grey", "light-2");
    background-color: cy-get-color("grey", "light-4");

    .d-flex:last-child {
      color: cy-get-color("grey", "dark-2");
    }

    .footer {
      &__labels {
        width: auto;
      }

      &__totals {
        width: 120px;
        max-width: 120px;
        padding-right: 7px;
      }
    }
  }
}
</style>

<template>
  <v-card
    v-bind="$attrs"
    outlined
    :class="['stack-card', { clickable: !_.$isEmpty(onClickAction) }]"
    @mouseenter="onCardHover(true)"
    @mouseleave="onCardHover(false)"
    @click.stop="onClickAction ? onClickAction() : null">
    <v-card-text class="stack-card__content">
      <CyStackAvatar
        class="stack-card__avatar"
        size="32"
        :ripple="showUseBtn && _.$isEmpty(stackImportStatus) || isStackImportInProgressOrFailed"
        :stack="service || {}"/>
      <div class="flex-grow-1">
        <div class="d-flex align-center">
          <CyTooltip
            :disabled="stackName.length < 30"
            top>
            <template
              #activator="{ on }">
              <span
                class="stack-card__name"
                v-on="on">
                {{ stackName }}
              </span>
            </template>
            {{ stackName }}
          </CyTooltip>

          <CyTag
            v-if="service && service.blueprint"
            class="ml-2"
            variant="accent"
            icon-before="architecture">
            {{ $t('stacks.blueprint') }}
          </CyTag>

          <CyTooltip
            v-if="stackVisibility"
            :max-width="250"
            top>
            <template #activator="{ on }">
              <CyTag
                class="ml-2"
                :variant="stackVisibilityVariant"
                :icon-before="stackVisibilityIcon"
                v-on="on">
                {{ $t(`stacks.visibility.${stackVisibility}`) }}
              </CyTag>
            </template>
            {{ $t(`stacks.visibility.${stackVisibility}${parentOrgText}Hint`) }}
          </CyTooltip>

          <CyTooltip
            v-if="_.get(service, 'quota_enabled')"
            top>
            <template #activator="{ on }">
              <v-icon
                class="ml-1"
                size="16"
                color="warning"
                v-on="on">
                data_usage
              </v-icon>
            </template>
            {{ $t('quotas.requiresQuota') }}
          </CyTooltip>
        </div>
        <p class="stack-card__description mt-1 mb-3">
          {{ description }}
        </p>
        <div class="stack-card__metadata">
          <CyHeaderMetadata
            v-if="stackBelongsToCurrentOrg && service && !_.isEmpty(service.team)"
            :to="{ name: 'team', params: { teamCanonical: service.team.canonical } }"
            @click.prevent.stop>
            <span class="d-flex align-center">
              <CyAvatar
                :item="service.team"
                sm/>
              {{ service.team.name }}
            </span>
          </CyHeaderMetadata>
          <CyHeaderMetadata
            v-else-if="service && !_.isEmpty(service.team)">
            <span class="d-flex align-center">
              <CyAvatar
                :item="service.team"
                sm/>
              {{ service.team.name }}
            </span>
          </CyHeaderMetadata>
          <CyHeaderMetadata
            v-for="provider of providers"
            :key="`service-${stackRef}-${provider}`"
            class="text__provider-images">
            <CyIconCredential
              size="22"
              :type="provider"
              show-tooltip
              tooltip-direction="top"
              :tooltip-text="_.$get(getProviderExtraInfoByCredType, provider, {}).displayNameFull"/>
          </CyHeaderMetadata>
          <CyHeaderMetadata
            v-if="_.get(service, 'organization_canonical')"
            icon="domain"
            :label="_.get(service, 'organization_canonical')"/>
          <CyHeaderMetadata
            v-if="$showFeature.costEstimation"
            :label="'from XX $/month'"/>
          <CyHeaderMetadata
            v-if="_.get(service, 'quota_enabled')"
            icon="data_usage"
            :label="$t('quotas.requiresQuota')"/>
          <CyTag
            v-if="isStackImportInProgressOrFailed"
            :variant="isStackImport.importing ? 'accent' : 'error'"
            @click="$emit('show-import-progress-modal', service)">
            {{ stackImportStatusText }}
          </CyTag>
        </div>
      </div>

      <div class="stack-card__actions">
        <CyMenu
          v-if="!_.$isEmpty($static.menuItems) && moreBtnVisible && !hideMoreBtn && stackBelongsToCurrentOrg"
          v-model="isMenuOpen"
          left
          offset-y
          :items="$static.menuItems"/>
        <CyButton
          v-if="showUseBtn"
          v-has-rights-to="'CreateProject'"
          class="ml-3"
          sm
          theme="primary"
          variant="secondary"
          data-cy="use-this-stack"
          @click.prevent.stop="$emit('selected', service)">
          {{ $t('forms.btnSelect') }}
        </CyButton>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import { retryInfraImportIntervalTimer } from '@/store/modules/organization/infraImport'
import { VISIBILITIES } from '@/utils/helpers/stacks'

export const defaultServiceImage = '/static/images/stack_default.png'
export const MAX_DESCRIPTION_LENGTH = 120

export default {
  name: 'CyWizardServiceCard',
  components: {
    CyHeaderMetadata,
    CyStackAvatar,
  },
  props: {
    service: {
      type: Object,
      default: () => ({}),
    },
    onClickAction: {
      type: Function,
      default: null,
    },
    showUseBtn: {
      type: Boolean,
      default: false,
    },
    hideMoreBtn: {
      type: Boolean,
      default: false,
    },
    stackBelongsToCurrentOrg: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    moreBtnVisible: false,
    isCardHovered: false,
    isMenuOpen: false,
    animatedDots: '...',
    animatedDotsRefreshIntervalID: null,
    infraImportRefreshIntervalID: null,
  }),
  computed: {
    ...mapGetters('organization/infraImport', [
      'infraImportStatus',
    ]),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
    ]),
    $static () {
      const { canDisplay } = this.$cycloid.permissions
      return {
        menuItems: [
          {
            icon: 'visibility',
            label: `${this.$t('stacks.visibility.changeVisibility')}...`,
            action: () => { this.$emit('show-visibility-modal', this.service) },
            permissionKey: 'UpdateServiceCatalog',
          },
          {
            icon: 'group',
            label: `${this.$t('stacks.maintainer.assignMaintainer')}...`,
            action: () => { this.$emit('show-maintainer-modal', this.service) },
            permissionKey: 'UpdateServiceCatalog',
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, this.service?.canonical)),
        visibilityTags: VISIBILITIES,
      }
    },
    parentOrgText () {
      if (_.isEqual(this.stackVisibility, 'shared') && !this.stackBelongsToCurrentOrg) {
        return 'ByParentOrg'
      }
      return ''
    },
    stackImportStatus () {
      const { service, infraImportStatus, stackRef } = this
      const stackImportStatusFromLogs = infraImportStatus(stackRef)
      const stackImportStatus = _.$get(service, 'import_status', '')
      return stackImportStatusFromLogs || stackImportStatus
    },
    stackImportStatusText () {
      const trailingDots = this.isStackImport.importing ? this.animatedDots : ''
      return `${_.upperFirst(this.$t(this.stackImportStatus))}${trailingDots}`
    },
    isStackImportInProgressOrFailed () {
      const { isStackImport: { failed, importing } } = this
      return failed || importing
    },
    isStackImport () {
      return { [this.stackImportStatus]: true }
    },
    stackVisibility () {
      return this.service?.visibility
    },
    stackVisibilityVariant () {
      return this.$static.visibilityTags[this.stackVisibility]?.variant
    },
    stackVisibilityIcon () {
      return this.$static.visibilityTags[this.stackVisibility]?.icon
    },
    providers () {
      const { keywords = [] } = this.service || {}

      return keywords
        .filter((keyword) => keyword.startsWith('provider:'))
        .sort()
        .map((providerId) => providerId.split(':')[1])
    },
    stackName () {
      return this.service?.name ?? ''
    },
    description () {
      const { description = '' } = this.service || {}

      return description.length < MAX_DESCRIPTION_LENGTH
        ? description
        : description.slice(0, MAX_DESCRIPTION_LENGTH) + '...'
    },
    stackRef () {
      return this.service?.ref
    },
  },
  watch: {
    stackImportStatus: {
      handler (status) {
        window.clearInterval(this.infraImportRefreshIntervalID)
        window.clearInterval(this.animatedDotsRefreshIntervalID)
        if (status === 'importing') {
          const { stackRef } = this
          this.infraImportRefreshIntervalID = window.setInterval(() => this.GET_STACK_INFRA_IMPORT_STATUS({ stackRef }), retryInfraImportIntervalTimer)
          this.animatedDotsRefreshIntervalID = window.setInterval(() => this.animateDots(), 1000)
        }
      },
      immediate: true,
    },
    isCardHovered: 'updateMoreBtnVisible',
    isMenuOpen: 'updateMoreBtnVisible',
  },
  beforeDestroy () {
    window.clearInterval(this.infraImportRefreshIntervalID)
    window.clearInterval(this.animatedDotsRefreshIntervalID)
  },
  methods: {
    ...mapActions('organization/infraImport', [
      'GET_STACK_INFRA_IMPORT_STATUS',
    ]),
    animateDots () {
      this.animatedDots = this.animatedDots.length > 2 ? '' : `${this.animatedDots}.`
    },
    onCardHover (value) {
      this.isCardHovered = value
    },
    updateMoreBtnVisible () {
      this.moreBtnVisible = this.isCardHovered || this.isMenuOpen
    },
  },
  i18n: {
    messages: {
      en: {
        failed: 'import failed',
        private: 'Private',
        public: 'Public',
        updated: 'Updated {0}',
      },
      es: {
        failed: 'importación fallida',
        private: 'Privada',
        public: 'Pública',
        updated: 'Actualizado {0}',
      },
      fr: {
        failed: 'manqué',
        private: 'Privée',
        public: 'Publique',
        updated: 'Mis à jour {0}',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.stack-card {
  cursor: default;

  &.clickable {
    transition: background-color 0.3s ease;

    @extend %clickable;

    &:hover {
      background-color: cy-get-color("primary", "light-5");
    }
  }

  &__content {
    display: flex;
    gap: $spacer-4;
  }

  &__avatar {
    min-width: 0;
  }

  &__name {
    color: cy-get-color("primary");
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: $line-height-sm;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 40px;
  }

  &__metadata {
    display: flex;
    flex-wrap: wrap;
  }

  .cy-avatar {
    border-radius: 4px !important;
  }
}
</style>

<template>
  <ul
    ref="tag-list"
    v-resize-observer="onResize"
    :class="['tag-list', { 'tag-list--contained': contained }, { 'tag-list--expanded': isExpanded }]">
    <template v-for="(tag, index) in sortedTags">
      <li
        v-if="isTagVisible(index)"
        :key="index"
        :class="['tag-list__item', { 'tag-list__item--small': small }]">
        <slot
          name="tag"
          :tag="tag">
          <CyTag
            :label="tag.label"
            :small="small"
            :variant="variant || tag.variant">
            {{ tag.content }}
          </CyTag>
        </slot>
      </li>
    </template>

    <li
      v-if="showToggleButton"
      ref="toggle-btn"
      :class="['tag-list__toggle-btn', 'tag-list__item', { 'tag-list__item--small': small }]">
      <CyTag
        element-type="button"
        :small="small"
        @click.prevent.stop.native="toggle()">
        {{ isExpanded ? $t('showLess') : $t('showMore') }}
      </CyTag>
    </li>
  </ul>
</template>

<script>
import { variants } from '@/global/components/CyTag.vue'

export default {
  name: 'CyTagList',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      validator: (variant) => variants.includes(variant),
      default: 'secondary',
    },
    contained: {
      type: Boolean,
      default: false,
    },
    sortKey: {
      type: String,
      default: 'key',
    },
  },
  data: () => ({
    isExpanded: false,
    isOverflowing: false,
    visibleTagsCount: 0,
    isProcessingTagsWidth: false,
  }),
  computed: {
    showToggleButton () {
      return this.contained && (this.isProcessingTagsWidth || this.isOverflowing)
    },
    sortedTags () {
      return _.sortBy(this.tags, [this.sortKey])
    },
  },
  watch: {
    tags: {
      async handler () {
        await this.processTagsWidth()
      },
      deep: true,
    },
  },
  async mounted () {
    if (!this.contained) return
    await this.processTagsWidth()
  },
  methods: {
    isTagVisible (tagIndex) {
      return !this.contained ||
        (this.isProcessingTagsWidth || this.isExpanded || !this.isOverflowing || tagIndex + 1 < this.visibleTagsCount)
    },
    toggle () {
      this.isExpanded = !this.isExpanded
    },
    getWidthWithMargin (el) {
      const { marginLeft, marginRight } = getComputedStyle(el)
      return el.clientWidth + this.pixelToInt(marginLeft) + this.pixelToInt(marginRight)
    },
    pixelToInt (string) {
      return Number.parseInt(string, 10)
    },
    async processTagsWidth () {
      this.isProcessingTagsWidth = true
      await this.$nextTick()
      const el = this.$refs['tag-list']
      this.isOverflowing = el?.scrollWidth > el?.clientWidth
      this.visibleTagsCount = this.getVisibleTagsCount()
      this.isProcessingTagsWidth = false
    },
    getVisibleTagsCount () {
      if (!this.isOverflowing) return this.tags.length

      const el = this.$refs['tag-list']
      const tagList = [...el.children]
      let tagsWidth = this.getWidthWithMargin(this.$refs['toggle-btn'])
      let visibleTagsCount = 1
      for (const [index, tag] of tagList.entries()) {
        tagsWidth += this.getWidthWithMargin(tag)
        if (tagsWidth > el.clientWidth) {
          visibleTagsCount = Math.max(1, index + 1)
          break
        }
      }

      return visibleTagsCount
    },
    onResize () {
      const el = this.$refs['tag-list']
      this.$emit('tag-list-toggled', { expanded: this.isExpanded, height: el?.clientHeight })
      if (!this.contained || this.isExpanded) return
      this.processTagsWidth()
    },
  },
  i18n: {
    messages: {
      en: {
        showLess: 'show less',
        showMore: 'show more',
      },
      es: {
        showLess: 'ver menos',
        showMore: 'ver más',
      },
      fr: {
        showLess: 'voir moins',
        showMore: 'voir plus',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-list {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  &--contained {
    flex-wrap: nowrap;
    margin-right: 0;
    overflow: hidden;
    white-space: nowrap;
  }

  &--expanded {
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    margin: calc(#{$tag-list-margin} / 2);

    > .tag {
      display: inline-flex;
    }
  }

  &__toggle-btn {
    .tag-list--expanded & {
      width: 100%;
    }

    ::v-deep .tag {
      background-color: cy-get-color("white");
      color: cy-get-color("secondary");
      font-weight: $font-weight-bold;
    }
  }
}
</style>

import appLocales from '.'

const { VUE_APP_DEFAULT_LANG } = process.env

export const locales = Object.keys(appLocales)

export function getLocalesList (chosenLocale = VUE_APP_DEFAULT_LANG) {
  const getName = (locale) => appLocales[chosenLocale][`lang.${locale}.name`]
  const getIcon = (locale) => `/static/images/flags/${locale}_32.png`
  return locales.map((locale) => ({ locale, name: getName(locale), icon: getIcon(locale) }))
}

<template>
  <div>
    <h2 class="mb-5">
      Date range filter
    </h2>

    <CyCcmSidebarDateRangeFilter/>
  </div>
</template>

<script>
import CyCcmSidebarDateRangeFilter from '@/components/CyCcmSidebarDateRangeFilter.vue'

export default {
  name: 'CyCcmSidebarDateRangeFilterSandbox',
  components: {
    CyCcmSidebarDateRangeFilter,
  },
}
</script>

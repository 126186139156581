import Vue from 'vue'
import router from '@/router'
import * as Sentry from '@sentry/vue'

const isDevelop = process.env.NODE_ENV === 'development'

// Sentry configuration
if (!isDevelop) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_URL,
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,
    autoSessionTracking: false,
    // This enables automatic instrumentation (highly recommended),
    // but is not necessary for purely manual usage
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 0.05,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

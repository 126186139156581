import { mapGetters } from 'vuex'

const getters = {
  root: [
    'isAuthenticated',
    'isOrgAdmin',
    'isEntityOwner',
    'isUsingMSP',
    'orgCanonical',
    'orgName',
    'projectName',
    'username',
  ],
  organization: [
    'organization',
  ],
  dev: [
    '$isBetaFeature',
    '$showDevThings',
    '$showFeature',
  ],
}

export const globalMapGetters = Object.values(getters).flat()

export default {
  install (Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters(getters.root),
        ...mapGetters('organization', getters.organization),
        ...mapGetters('dev', getters.dev),
      },
    })
  },
}

<template>
  <div>
    <h2 class="mb-5">
      Granularity selector
    </h2>

    <CyCcmSidebarGranularitySelector/>
  </div>
</template>

<script>
import CyCcmSidebarGranularitySelector from '@/components/CyCcmSidebarGranularitySelector.vue'

export default {
  name: 'CyCcmSidebarGranularitySelectorSandbox',
  components: {
    CyCcmSidebarGranularitySelector,
  },
}
</script>

<template>
  <div>
    <CyDetails
      ref="cy-details"
      :loading="false"
      :hide-delete="$isCreationRoute"
      :on-delete="$toggle.showDeleteModal"
      :can-cancel="canCancel"
      :on-cancel="onCancel"
      :can-save="canSave"
      :on-save="onSave"
      :saving="saving">
      <template slot="details_formFullWidth">
        <CyAlert
          class="mx-5 mt-6"
          theme="error"
          :content="errors"/>

        <v-container
          fluid
          class="form-section px-6 mb-6">
          <v-row align="start">
            <v-col>
              <h3 v-text="$t('generalInformation')"/>
            </v-col>
          </v-row>
          <v-row
            align="start"
            no-gutters>
            <v-col/>
            <v-col
              cols="5"
              class="mt-n5 pl-5">
              <v-text-field
                ref="nameField"
                v-model.trim="$v.formContent.name.$model"
                class="name-field required-field mb-4"
                :label="$t('forms.fieldName')"
                :error-messages="inputErrors.name"
                :loading="!$isCreationRoute && loading"
                required
                @blur="$v.formContent.name.$touch()"/>

              <v-autocomplete
                ref="providerField"
                v-model="$v.formContent.provider.$model"
                class="provider-field required-field"
                :items="[$static.customResource.canonical]"
                :label="$t('provider')"
                disabled
                required>
                <template #selection>
                  <v-icon
                    size="21"
                    class="mr-1">
                    {{ $static.customResource.icon }}
                  </v-icon>
                  {{ $static.customResource.name }}
                </template>
              </v-autocomplete>

              <v-combobox
                ref="labelField"
                v-model.trim="$v.formContent.label.$model"
                class="label-field required-field mb-6"
                :items="_.union(_.map(availableLabels, 'name'), _.filter([$v.formContent.label.$model]))"
                :label="$t('untranslated.label')"
                :hint="$t('formLabelHint')"
                :error-messages="inputErrors.label"
                :loading="loading"
                required
                persistent-hint
                @blur="$v.formContent.label.$touch()">
                <template #selection="{ item }">
                  <CyTag variant="default">
                    {{ item }}
                  </CyTag>
                </template>
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="pb-1">
                      {{ item }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template #no-data>
                  <div
                    v-if="_.isEmpty(availableLabels)"
                    class="no-data px-4 py-1">
                    <div
                      class="no-data__title"
                      v-text="$t('noData.title', { field: _.lowerFirst($t('untranslated.label')) })"/>
                    <span
                      class="no-data__text"
                      v-html="$sanitizeHtml($t('noData.text'))"/>
                  </div>
                  <div
                    v-else
                    class="no-data px-4 py-1">
                    <div
                      class="no-data__title"
                      v-text="$t('notFound.title', { field: _.lowerFirst($t('untranslated.label')) })"/>
                    <span
                      class="no-data__text"
                      v-html="$sanitizeHtml($t('notFound.text'))"/>
                  </div>
                </template>
              </v-combobox>

              <v-combobox
                ref="typeField"
                v-model.trim="$v.formContent.type.$model"
                class="type-field required-field"
                :items="_.union(availableResourceTypes, _.filter([$v.formContent.type.$model]))"
                :label="$t('forms.type')"
                :hint="$t('formTypeHint')"
                :error-messages="inputErrors.type"
                :loading="loading"
                required
                persistent-hint
                item-text="type"
                item-value="type"
                @blur="$v.formContent.type.$touch()">
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="pb-1">
                      {{ item }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template #no-data>
                  <div
                    v-if="_.isEmpty(availableResourceTypes)"
                    class="no-data px-4 py-1">
                    <div
                      class="no-data__title"
                      v-text="$t('noData.title', { field: _.lowerFirst($t('forms.type')) })"/>
                    <span
                      class="no-data__text"
                      v-html="$sanitizeHtml($t('noData.text'))"/>
                  </div>
                  <div
                    v-else
                    class="no-data px-4 py-1">
                    <div
                      class="no-data__title"
                      v-text="$t('notFound.title', { field: _.lowerFirst($t('forms.type')) })"/>
                    <span
                      class="no-data__text"
                      v-html="$sanitizeHtml($t('notFound.text'))"/>
                  </div>
                </template>
              </v-combobox>
            </v-col>
            <v-col/>
          </v-row>
        </v-container>

        <v-divider class="v-divider__forms my-8 mx-6"/>
      </template>

      <template slot="details_formFullWidth">
        <v-container
          fluid
          class="px-6 py-0">
          <v-row
            align="center">
            <v-col>
              <h3 v-text="$t('Resources')"/>
            </v-col>
          </v-row>
          <v-row
            no-gutters>
            <v-col class="mt-4">
              <p v-text="$t('resourcesSectionHint')"/>
            </v-col>
            <v-col
              cols="5"
              class="mt-n6 pl-5">
              <CyInputsStorage
                v-model.number="$v.formContent.memory.$model"
                class="memory-field mb-6"
                :label="$t('quotas.memory')"
                :loading="loading && !$isCreationRoute"
                :error-messages="inputErrors.memory"
                required
                @blur="$v.formContent.memory.$touch()"/>
            </v-col>
            <v-col/>
          </v-row>
          <v-row
            no-gutters>
            <v-col/>
            <v-col
              cols="5"
              class="pl-5">
              <CyInputsStorage
                v-model.number="$v.formContent.storage.$model"
                class="storage-field mb-6"
                :label="$t('quotas.storage')"
                :loading="loading && !$isCreationRoute"
                :error-messages="inputErrors.storage"
                required
                @blur="$v.formContent.storage.$touch()"/>
            </v-col>
            <v-col/>
          </v-row>
          <v-row
            no-gutters>
            <v-col/>
            <v-col
              cols="5"
              class="pl-5">
              <v-text-field
                v-model.number="$v.formContent.cpu.$model"
                type="number"
                class="cpu-field required-field mb-4"
                :label="$t('quotas.cpuCores')"
                :loading="loading && !$isCreationRoute"
                :error-messages="inputErrors.cpu"
                required
                @blur="$v.formContent.cpu.$touch()"/>
            </v-col>
            <v-col/>
          </v-row>
        </v-container>

        <v-divider class="v-divider__forms my-8 mx-6"/>
      </template>

      <template slot="details_formFullWidth">
        <v-container
          fluid
          class="px-6 py-0">
          <v-row
            align="center">
            <v-col>
              <h3 v-text="$t('customAttributes')"/>
            </v-col>
          </v-row>
          <v-row
            no-gutters>
            <v-col class="mt-4">
              <p v-text="$t('customAttributesHint')"/>
            </v-col>
            <v-col
              cols="5"
              class="mt-n10 mb-7 pl-5">
              <CyResourceCustomAttributes
                ref="custom-attributes"
                :attributes.sync="formContent.customAttributes"
                :loading="loading && !$isCreationRoute"/>
            </v-col>
            <v-col/>
          </v-row>
        </v-container>
      </template>
    </CyDetails>

    <CyInventoryResourceDelete
      v-if="showDeleteModal"
      :resource="resource || {}"
      @cancel="$toggle.showDeleteModal(false)"
      @error="onDeleteError"
      @success="onDeleteSuccess"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyDetails from '@/components/CyDetails.vue'
import CyInputsStorage from '@/components/CyInputsStorage.vue'
import CyInventoryResourceDelete from '@/components/CyInventoryResourceDelete.vue'
import CyResourceCustomAttributes from '@/components/CyResourceCustomAttributes.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { required, minValue, integer } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageResourcePoolResource',
  components: {
    CyDetails,
    CyInputsStorage,
    CyInventoryResourceDelete,
    CyResourceCustomAttributes,
  },
  breadcrumb () {
    const { $isCreationRoute, resource } = this
    const header = $isCreationRoute
      ? this.$t('addResource')
      : resource?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.resourcePools'),
        name: 'resourcePools',
      },
      {
        label: this.$t('routes.resourcesSection'),
        name: 'resourcesSection',
      },
    ])
  },
  header () {
    return {
      title: this.$isCreationRoute ? this.$t('createResource') : this.$t('editResource'),
      description: {
        text: this.$t('addResourceHint'),
        link: $docLinks.quotas.resources,
      },
    }
  },
  props: {
    resourceId: {
      type: Number,
      default: null,
    },
  },
  validations: {
    formContent: {
      name: { required },
      provider: { required },
      label: { required },
      type: { required },
      memory: { required, minValue: minValue(0) },
      storage: { required, minValue: minValue(0) },
      cpu: { required, integer, minValue: minValue(0) },
      customAttributes: {
        $each: {
          key: { required },
          value: { required },
        },
      },
    },
  },
  data: () => ({
    loading: false,
    saving: false,
    showDeleteModal: false,
    formContent: {
      name: null,
      provider: 'custom_resources',
      label: null,
      type: null,
      memory: null,
      storage: null,
      cpu: null,
      customAttributes: [],
    },
  }),
  computed: {
    ...mapState('organization', {
      availableLabels: (state) => state.available.labels,
      availableResourceTypes: (state) => state.available.resourceTypes,
    }),
    ...mapState('organization/resource', {
      resource: (state) => state.detail,
      errors: (state) => state.errors.resource,
      deleteErrors: (state) => state.errors.delete,
    }),
    $static () {
      return {
        customResource: {
          name: this.$t('inventory.custom_resources'),
          canonical: 'custom_resources',
          icon: 'extension',
        },
      }
    },
    inputErrors () {
      const infoInputs = ['name', 'label', 'type', 'customAttributes']
      const infoErrors = infoInputs.reduce((acc, inputType) => {
        const errors = []
        const { $dirty, required } = this.$v.formContent[inputType]
        if (!$dirty) return { ...acc, [inputType]: [] }
        if (!required) errors.push(this.$t('forms.fieldRequired'))
        return { ...acc, [inputType]: errors }
      }, {})

      const resourceInputs = ['memory', 'storage', 'cpu']
      const resourceErrors = resourceInputs.reduce((acc, inputType) => {
        const errors = []
        const { $dirty, required, integer, minValue } = this.$v.formContent[inputType]
        if (!$dirty) return { ...acc, [inputType]: [] }
        if (!required) errors.push(this.$t('forms.fieldRequired'))
        if (!minValue) errors.push(this.$t('quotas.positiveOrZero'))
        if (!integer && inputType === 'cpu') errors.push(this.$t('quotas.integer'))
        return { ...acc, [inputType]: errors }
      }, {})

      return { ...infoErrors, ...resourceErrors }
    },
    canCancel () {
      return _.some(_.keys(this.formContent)
        .map((key) => this.$hasDataChanged(`formContent.${key}`)))
    },
    canSave () {
      const { $v, canCancel } = this
      return canCancel && !$v.$invalid
    },
  },
  created () {
    this.setup()
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/resource', [
      'CREATE_RESOURCE',
      'GET_RESOURCE',
      'UPDATE_RESOURCE',
    ]),
    async setup () {
      const { formContent: { provider } } = this
      this.$toggle.loading(true)
      await Promise.all([
        this.FETCH_AVAILABLE({ keyPath: 'labels', extraParams: [provider] }),
        this.FETCH_AVAILABLE({ keyPath: 'resourceTypes', extraParams: [provider] }),
      ])
      if (!this.$isCreationRoute) await this.fetchResourceData()
      this.$toggle.loading(false)
    },
    async fetchResourceData () {
      const { resourceId } = this
      await this.GET_RESOURCE({ resourceId })
      if (!_.isEmpty(this.resourceErrors) || _.isEmpty(this.resource)) return
      const customAttributes = _.toPairs(this.resource.custom_attributes).map(([key, value]) => ({ key, value }))
      this.formContent = { ..._.omit(this.resource, ['custom_attributes']), customAttributes }
      this.$setOriginalData()
    },
    onCancel () {
      this.$resetData('formContent')
      this.$v.$reset()
    },
    onDeleteError () {
      this.SHOW_ALERT({ type: 'error', content: this.deleteErrors })
    },
    onDeleteSuccess () {
      this.$router.push({ name: 'resourcePools' })
    },
    async onSave () {
      this.$toggle.saving(true)

      const { $router, $isCreationRoute, formContent } = this

      const customAttributes = _.fromPairs(formContent.customAttributes?.map((object) => _.values(object)))
      const resource = _.omit({ ...formContent, custom_attributes: customAttributes }, ['customAttributes'])

      $isCreationRoute
        ? await this.CREATE_RESOURCE({ resource, $router })
        : await this.UPDATE_RESOURCE({ resource, $router })

      if (_.isEmpty(this.errors)) this.$setOriginalData()

      this.$toggle.saving(false)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.resource',
        addResource: 'Create @:resource',
        addResourceHint: 'Describe your bare metal servers to be used in resource pools and to apply quotas.',
        editResource: 'Edit @:resource',
        createResource: 'Create @:resource',
        customAttributes: 'Custom attributes',
        customAttributesHint: 'You can use custom attributes to add custom data to a resource. Each attribute consists of a key/value pair.',
        formLabelHint: 'Labels are used in resource pools to target resources that shares a common label.',
        formTypeHint: 'Describe the kind of the resource, e.g. Server or Disk bay.',
        generalInformation: 'General information',
        noData: {
          title: 'No {field} created yet',
          text: 'Press <kbd>Enter</kbd> to confirm this one.',
        },
        notFound: {
          title: 'No {field} matching your criteria',
          text: 'Review your search or press <kbd>Enter</kbd> to confirm this one.',
        },
        resourcesSectionHint: 'Define the total capacity of this resource.',
      },
      es: {
        title: '@:routes.resource',
        addResource: 'Crear @:resource',
        addResourceHint: 'Describa los servidores bare metal que se utilizarán en grupos de recursos y para aplicar quotas.',
        editResource: 'Editar el @:resource',
        createResource: 'Crear @:resource',
        customAttributes: 'Atributos personalizados',
        customAttributesHint: 'Puede usar atributos personalizados para agregar datos personalizados a un recurso. Cada atributo consta de un par clave/valor.',
        formLabelHint: 'Las labels se utilizan en grupos de recursos para dirigirse a recursos que comparten una etiqueta común.',
        formTypeHint: 'Describa el tipo de recurso, por ejemplo, Server o Disk bay.',
        generalInformation: 'Información general',
        noData: {
          title: 'Aún no se creó ningún {field}',
          text: 'Presione <kbd>Enter</kbd> para confirmar el ingresado.',
        },
        notFound: {
          title: 'Ningún {field} que coincida con sus criterios',
          text: 'Revise su búsqueda o presione <kbd>Enter</kbd> para confirmar el ingresado.',
        },
        resourcesSectionHint: 'Defina la capacidad total de este recurso.',
      },
      fr: {
        title: '@:routes.resource',
        addResource: 'Créer une @:resource',
        addResourceHint: 'Décrivez vos serveurs bare metal à utiliser dans les pools de ressources et pour appliquer des quotas.',
        editResource: 'Modifier la @:resource',
        createResource: 'Créer @:resource',
        customAttributes: 'Attributs personnalisés',
        customAttributesHint: 'Vous pouvez utiliser des attributs personnalisés pour ajouter des données personnalisées à une ressource. Chaque attribut consiste en une paire clé/valeur.',
        formLabelHint: 'Les labels sont utilisés dans les pools de ressources pour cibler les ressources partageant un libellé commun.',
        formTypeHint: 'Décrivez le type de ressource, par ex. Serveur ou baie de disque.',
        generalInformation: 'Informations générales',
        noData: {
          title: `Aucun {field} créé pour le moment.`,
          text: 'Appuyez sur <kbd>Entrer</kbd> pour confirmer celui-ci.',
        },
        notFound: {
          title: 'Aucun {field} ne correspond à votre recherche',
          text: 'Modifiez votre recherche ou appuyez sur <kbd>Entrer</kbd> pour confirmer celui-ci.',
        },
        resourcesSectionHint: 'Définissez la capacité totale de cette ressource.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

::v-deep .cy-details {
  &__content {
    padding: 0;
    overflow-y: visible;
  }

  &__section {
    & + & {
      margin-top: 32px;
    }
  }
}

.provider-field .v-icon {
  color: map.get($slate-grey, "light-3");
}

::v-deep .v-divider__forms {
  max-width: 69%;
}

.no-data {
  &__title {
    padding-bottom: 4px;
    font-weight: $font-weight-bolder;
  }

  &__title,
  &__text {
    color: cy-get-color("primary");
  }
}

.input-storage {
  max-width: unset;
}
</style>

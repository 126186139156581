<template>
  <section>
    <h3 class="mb-2">
      {{ $t('manualActionsTitle') }}
    </h3>
    <p>
      {{ $t('manualActionsDescription') }}
      <a
        :href="$docLinks.roles.manualActions"
        class="cy-link"
        target="_blank">
        {{ $t('learnMore') }}
      </a>
    </p>

    <v-card
      :key="value.length"
      class="ma-1">
      <v-card-title
        v-if="!isEmpty"
        class="card-title">
        <span class="card-title__item">{{ $t('actions') }}</span>
        <span class="card-title__item ml-6">{{ $t('Resources') }}</span>
        <span class="card-title__spacer ml-6"/>
      </v-card-title>

      <v-card-text>
        <div
          v-if="isEmpty"
          class="no-actions">
          <span>{{ $t('noManualActionsDefinedYet') }}</span>
        </div>

        <template v-else>
          <CyManualActionsBoxItem
            v-for="(_, index) in manualActions"
            :key="`record-${index}`"
            ref="action"
            v-model="manualActions[index]"
            :highlighted="highlighted === index"
            :disabled="disabled"
            @remove="onRemove(index)"
            @highlight="highlighted = index"/>
          <div
            v-if="isCycloidEmployee && !disabled"
            class="hint">
            <span
              class="hint__item"
              v-html="$sanitizeHtml($t('actionsHint'))"/>
            <span
              class="hint__item ml-6"
              v-html="$sanitizeHtml($t('resourcesHint'))"/>
            <span class="hint__spacer ml-6"/>
          </div>
        </template>
      </v-card-text>

      <v-card-actions>
        <CyButton
          v-if="isCycloidEmployee && !disabled"
          icon="add"
          variant="secondary"
          @click="onAdd">
          <span>{{ $t('addManualAction') }}</span>
        </CyButton>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import CyManualActionsBoxItem from '@/components/CyManualActionsBoxItem.vue'

export default {
  name: 'CyManualActionsBox',
  components: {
    CyManualActionsBoxItem,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    highlighted: null,
  }),
  computed: {
    ...mapGetters('user', ['isCycloidEmployee']),
    manualActions: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      },
    },
    isEmpty () {
      return this.manualActions.length === 0
    },
  },
  methods: {
    onAdd () {
      this.$emit('add:start')
      this.manualActions = [...this.manualActions, {
        actions: [],
        resources: [],
      }]
      this.highlighted = this.manualActions.length
    },
    onRemove (index) {
      this.manualActions = [...this.manualActions.filter((_, i) => (i !== index))]
      this.highlighted = null
    },
  },
  i18n: {
    messages: {
      en: {
        actions: 'Actions',
        actionsHint: 'Press TAB or ENTER to add an action.<br/>e.g. <kbd>organization:project:read</kbd> or <kbd>organization:**</kbd> to give the admin permissions',
        addManualAction: 'Add Manual Action',
        manualActionsDescription: 'Manual actions allow advanced users to use globbing to target multiple actions and/or resources.',
        manualActionsTitle: 'Manual Actions',
        noManualActionsDefinedYet: 'No manual actions defined yet',
        resourcesHint: 'Press TAB or ENTER to add a resource.<br/>e.g. <kbd>organization:&lt;org_canonical&gt;:project:&lt;proj_canonical&gt;</kbd>',
      },
      es: {
        actions: 'Acciones',
        actionsHint: 'Presione TAB o ENTER para agregar una acción.<br/>e.g. <br/><kbd>organization:project:read</kbd> u <kbd>organización:**</kbd> para otorgar permisos de administrador',
        addManualAction: 'Añadir acción manual',
        manualActionsDescription: 'Las acciones manuales permiten a los usuarios avanzados utilizar la función global para apuntar a múltiples acciones y / o recursos.',
        manualActionsTitle: 'Acciones manuales',
        noManualActionsDefinedYet: 'Aún no hay acciones manuales definidas',
        resourcesHint: 'Presione TAB o ENTER para agregar un recurso.<br/>e.g. <kbd>organization:&lt;org_canonical&gt;:project:&lt;proj_canonical&gt;</kbd>',
      },
      fr: {
        actions: 'Actions',
        actionsHint: `Appuyez sur TAB ou ENTER pour ajouter une action.<br/>ex. <kbd>organization:project:read</kbd> ou <kbd>organisation:**</kbd> pour donner les autorisations d'administrateur`,
        addManualAction: 'Ajouter une action manuelle',
        manualActionsDescription: `Les actions manuelles permettent aux utilisateurs avancés d'utiliser le globbing pour cibler plusieurs actions et / ou ressources.`,
        manualActionsTitle: 'Actions manuelles',
        noManualActionsDefinedYet: `Aucune action manuelle n'est encore définie`,
        resourcesHint: 'Appuyez sur TAB ou ENTRÉE pour ajouter une ressource.<br/>ex. <kbd>organization:&lt;org_canonical&gt;:project:&lt;proj_canonical&gt;</kbd>',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  margin-top: 24px;
  padding: 16px;

  &__title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 16px;
    padding: 0;
    color: cy-get-color("grey", "dark-1");
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__actions {
    margin-top: 24px;
    padding: 0;
  }
}

.no-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  background: cy-get-color("primary", "light-5");
  color: cy-get-color("primary", "light-2");
}

.card-title {
  &__item {
    flex-grow: 1;
    width: 50%;
    font-size: $font-size-default;
    font-weight: $font-weight-bolder;
    line-height: $line-height-heading;
  }

  &__spacer {
    flex-grow: 0;
    width: 40px;
  }
}

.hint {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 12px;
  font-size: $font-size-sm;

  &__item {
    flex-grow: 1;
    width: 50%;
  }

  &__spacer {
    flex-grow: 0;
    width: 40px;
  }
}

::v-deep kbd {
  margin: 0;
  padding: 1px 4px;
  background-color: cy-get-color("primary", "light-2") !important;
  box-shadow: none !important;
  line-height: $line-height-default;
}
</style>

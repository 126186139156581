<template>
  <v-card
    :to="{
      name: 'project',
      params: {
        orgCanonical,
        projectCanonical: project.canonical,
      },
    }"
    class="mb-4"
    outlined>
    <v-card-text>
      <v-row
        dense
        no-gutters
        align="center"
        class="flex-nowrap mb-4">
        <v-col
          cols="auto"
          class="pr-3 py-0">
          <v-avatar
            size="24"
            rounded>
            <img
              :src="_.get(project, 'service_catalog.image', '/static/images/stack_default.png')"
              :alt="project.name">
          </v-avatar>
        </v-col>
        <v-col class="text-left py-0 overflow-x-hidden">
          <div class="line-height-sm font-weight-bold text-truncate">
            {{ project.name }}
          </div>
          <div
            class="line-height-md text-truncate">
            {{ project.description }}
          </div>
        </v-col>
      </v-row>
      <div class="mb-2">
        <v-chip class="transparent">
          <CyAvatar
            class="mr-2"
            :item="project.owner"
            xs/>
          {{ displayName(project.owner) }}
        </v-chip>
      </div>
      <div
        v-if="cloudProviders"
        class="mb-2 d-flex flex-wrap">
        <v-chip
          v-for="provider in cloudProviders"
          :key="provider.canonical"
          class="transparent">
          <CyIconCredential
            size="16"
            class="mr-1"
            :type="provider.canonical"/>
          {{ provider.name }}
        </v-chip>
      </div>
      <div>
        <v-chip
          :ripple="false"
          class="transparent"
          label>
          {{ $t('updated') }} {{ $date.$formatTimeAgo(project.updated_at) }}
        </v-chip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { displayName } from '@/utils/helpers'

export default {
  name: 'CyProjectCardSmall',
  props: {
    project: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    cloudProviders () {
      const allProviders = this.project.environments.map((item) => ({
        canonical: item.cloud_provider_canonical,
        name: item.cloud_provider_name,
      }))
      return allProviders.length ? _.uniqBy(allProviders, 'canonical') : null
    },
  },
  methods: {
    displayName,
  },
  i18n: {
    messages: {
      en: {
        updated: 'Updated',
      },
      es: {
        updated: 'Actualizado',
      },
      fr: {
        updated: 'Mis à jour',
      },
    },
  },
}
</script>

<style scoped lang="scss">
.flex-wrap {
  gap: $spacer-2;
}

.v-chip {
  position: relative;
  height: auto;
  padding: 0;
  overflow: visible;
  color: cy-get-color('primary', 'light-2');

  &:hover::before {
    opacity: 0;
  }
}
</style>

<template>
  <component
    ref="autocomplete"
    v-bind="$attrs"
    :value="parsedValue"
    :class="['cy-widget-input', 'cy-inputs-autocomplete', width]"
    :error-messages="getErrors"
    :required="required"
    :items="parsedItems"
    :multiple="multiselectActive"
    @blur="$v.value.$touch()"
    @input="emitValue"
    @update:searchInput="updateSearchInput($event) && $refs.autocomplete.blur()"
    :is="allowFreeText ? 'v-combobox' : 'v-autocomplete'">
    <template
      v-if="loading"
      slot="append">
      <v-progress-circular
        size="22"
        indeterminate
        color="secondary"/>
    </template>

    <template
      v-if="multiselectActive"
      #selection="{ item }">
      <CyTag
        variant="default"
        icon-after="close"
        small
        @click-icon-after="removeValue(item)">
        {{ displayItem(item) }}
      </CyTag>
    </template>
  </component>
</template>

<script>
import { VCombobox, VAutocomplete } from 'vuetify/lib'
import { maxArrayLength, minArrayLength, mustExistInArray, mustExistKeysInMap, generateRuleName, arrayWhenMultiselect } from '@/utils/helpers/validators'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidgetAutocomplete',
  components: {
    VCombobox,
    VAutocomplete,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'is-x-large',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Boolean, Number, Array, Object],
      default: '',
      required: true,
    },
    hasValueMapping: {
      type: Boolean,
      default: false,
    },
    allowFreeText: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
      default: () => {},
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    formsValidations: {
      type: Array,
      default () {
        return []
      },
    },
  },
  validations () {
    if (this.formsValidations) {
      return {
        value: {
          required: requiredIf(function () {
            return this.required
          }),
          validArray: !this.multiselectActive || this.hasValueMapping || arrayWhenMultiselect(this.items),
          ...(this.formsValidations).reduce((validators, validation) => {
            if (validation.array) this.addArrayValidators(validation, validators)
            if (validation.map) this.addMapValidators(validation, validators)
            return validators
          }, {}),
        },
      }
    }
    return {
      value: {
        required: requiredIf(function () {
          return this.required
        }),
        validArray: !this.multiselectActive || this.hasValueMapping || arrayWhenMultiselect(this.items),
      },
    }
  },
  computed: {
    getErrors () {
      // External error messages from props take precedence
      if (!_.$isEmpty(this.errorMessages)) return this.errorMessages

      const errors = []
      const { $dirty, $error, required: $vRequired, validArray: $vValidArray, ...validationChecks } = this.$v.value

      if (!$dirty) return errors
      if (this.required && !$vRequired) errors.push(this.$t('forms.fieldRequired'))
      if ($dirty && !$vValidArray) errors.push(this.$t('forms.multiselectArrayRequired'))
      if ($dirty && $error && this.formsValidations) {
        this.formsValidations.forEach((validation) => {
          this.handleArrayValidation(validation, errors, validationChecks)
          this.handleMapValidation(validation, errors, validationChecks)
        })
      }

      return errors
    },
    parsedItems () {
      return _.map(this.items, this.parseItem)
    },
    parsedValue () {
      if (this.multiselectActive && _.isArray(this.value)) {
        return this.value.map((item) => this.parseItem(item))
      }
      return this.parseItem(this.value)
    },
    multiselectActive () {
      return this.config?.multiselect
    },
  },
  methods: {
    generateRuleName,
    displayItem (item) {
      if (_.isArray(item)) return item.join(', ')
      if (_.isPlainObject(item)) return JSON.stringify(item)
      return item
    },
    emitValue (value) {
      let valueToEmit = value

      // if JSON.parse fails it means we just need to emit
      // value as it is
      if (this.multiselectActive && _.isArray(value)) {
        valueToEmit = value.map((val) => {
          try {
            const parsedValue = JSON.parse(val)
            return _.isObjectLike(parsedValue) ? parsedValue : val
          } catch (e) {
            return val
          }
        })
      } else {
        try {
          const parsedValue = JSON.parse(value)
          if (_.isObjectLike(parsedValue)) valueToEmit = parsedValue
        } catch (e) {}
      }

      this.$emit('input', valueToEmit ?? '')
    },
    parseItem (item) {
      const mappedItem = this.hasValueMapping ? _.get(item, 'label', item) : item
      if (_.isPlainObject(mappedItem)) return JSON.stringify(mappedItem)
      return mappedItem
    },
    removeValue (item) {
      if (_.isArray(this.value)) {
        const updatedValue = this.value.filter((val) => {
          const parsedVal = _.isObject(val) ? JSON.stringify(val) : val
          const parsedItem = _.isObject(item) ? JSON.stringify(item) : item
          return parsedVal !== parsedItem
        })
        this.emitValue(updatedValue)
      } else {
        this.emitValue([])
      }
    },
    updateSearchInput (searchText) {
      // vuetify autocomplete needs 3 backspace hits to actually emit
      // the input event with an empty value, that's why this "hack"
      if (searchText === '') {
        this.$emit('input', '')
        this.$v.$touch()
      }
    },
    addArrayValidators (validation, validators) {
      const {
        min_elems: minElements,
        max_elems: maxElements,
        must_exist_elems: mustExistElements,
      } = validation.array

      if (!_.isUndefined(minElements)) {
        const ruleName = this.generateRuleName('minArrayLength', minElements)
        validators[ruleName] = minArrayLength(minElements)
      }
      if (!_.isUndefined(maxElements)) {
        const ruleName = this.generateRuleName('maxArrayLength', maxElements)
        validators[ruleName] = maxArrayLength(maxElements)
      }
      if (!_.isUndefined(mustExistElements)) {
        const ruleName = this.generateRuleName('mustExistInArray', mustExistElements)
        validators[ruleName] = mustExistInArray(mustExistElements)
      }
    },
    addMapValidators (validation, validators) {
      const { must_exist_keys: mustExistKeys } = validation.map

      if (!_.isUndefined(mustExistKeys)) {
        const ruleName = this.generateRuleName('mustExistKeysInMap', mustExistKeys)
        validators[ruleName] = mustExistKeysInMap(mustExistKeys)
      }
    },
    handleArrayValidation (validation, errors, validationChecks) {
      if (!validation.array) return

      const { min_elems: minElements, max_elems: maxElements, must_exist_elems: mustExistElements } = validation.array

      if (!_.isUndefined(minElements)) {
        const minElemsRuleName = this.generateRuleName('minArrayLength', minElements)

        if (!validationChecks[minElemsRuleName]) {
          errors.push(validation.error_message || this.$tc('forms.arrayMinLength', minElements, { number: minElements }))
        }
      }

      if (!_.isUndefined(maxElements)) {
        const maxElemsRuleName = this.generateRuleName('maxArrayLength', maxElements)

        if (!validationChecks[maxElemsRuleName]) {
          errors.push(validation.error_message || this.$tc('forms.arrayMaxLength', maxElements, { number: maxElements }))
        }
      }

      if (!_.isUndefined(mustExistElements)) {
        const ruleName = this.generateRuleName('mustExistInArray', mustExistElements)

        if (!validationChecks[ruleName]) {
          const defaultErrorMessage = this.$t('forms.mustExistInArray', { elements: _.castArray(mustExistElements).join(', ') })
          errors.push(validation.error_message || defaultErrorMessage)
        }
      }
    },
    handleMapValidation (validation, errors, validationChecks) {
      if (!validation.map) return

      const { must_exist_keys: mustExistKeys } = validation.map
      const ruleName = this.generateRuleName('mustExistKeysInMap', mustExistKeys)

      if (!validationChecks[ruleName]) {
        const defaultErrorMessage = this.$t('forms.mustExistKeysInMap', { keys: _.castArray(mustExistKeys).join(', ') })
        errors.push(validation.error_message || defaultErrorMessage)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$width-large: 228px;
$width-x-large: 440px;
$size-variants: (
  "is-large": $width-large,
  "is-x-large": $width-x-large
);

@mixin media-query-full-width() {
  @media screen and (width <= 745px) {
    width: 100%;
  }
}

::v-deep .v-list-item {
  padding-right: 1rem;
  padding-left: 1rem;
}

.cy-inputs-autocomplete {
  ::v-deep {
    .v-input__control {
      input {
        padding-right: 1rem;
        padding-left: 1rem;
      }

      .v-input__slot {
        height: auto;
      }

      .v-input__append-inner {
        margin-top: 4px;
      }

      .v-select {
        &__selections {
          display: flex;
          gap: 4px;
          justify-content: flex-start;
          margin-top: 0;
          padding: 4px;

          &--comma {
            display: inline-block;
            width: 148px;

            @extend %ellipsis;
          }
        }

        &__slot {
          margin-right: 1rem;
        }
      }
    }
  }

  @each $name, $width in $size-variants {
    &.#{$name} {
      width: $width;

      @include media-query-full-width;

      ::v-deep {
        .v-select__selections {
          width: $width - 50px;

          @include media-query-full-width;

          .v-select__selection--comma {
            width: $width;

            @include media-query-full-width;
          }
        }
      }
    }
  }

  &.v-text-field--full-width {
    width: 100%;
    height: $widget-input-height;

    ::v-deep {
      .v-select__selections {
        .v-select__selection--comma {
          width: auto;
          overflow: visible;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"infra-policies"},[_c('CyAlert',{attrs:{"theme":"error","content":_vm.errors}}),_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"id":"cy-infra-policies-table","fetch-available":{ keyPath: 'infraPolicies' },"bulk":_vm.hasBulkModeEnabled,"headers":_vm.$static.headers,"link-builder":_vm.getLinkTarget,"searchable-fields":_vm.$static.searchableFields,"filters":_vm.$static.filters,"sorts":_vm.$static.sorts,"key-field":"id"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
var selected = ref.selected;
return [(!_vm._.isEmpty(selected))?[_c('CyDevButton',{staticClass:"my-0 mr-2",attrs:{"wrap-dev-text":false},nativeOn:{"click":function($event){return _vm.setMissingOwners(selected)}}},[_vm._v(" Set missing owner ")]),(!_vm._.isEmpty(selected))?_c('CyButton',{attrs:{"theme":"error","icon":"delete"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm.$tc('deleteInfraPolicyBtn', selected.length, { nb: selected.length }))+" ")]):_vm._e()]:_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateInfraPolicy'),expression:"'CreateInfraPolicy'"}],attrs:{"icon":"add","to":{ name: 'newInfraPolicy' }}},[_vm._v(" "+_vm._s(_vm.$t('addInfraPolicyBtn'))+" ")]),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$tc('confirmDeleteTitle', _vm.toDelete.length),"action-btn-func":_vm.onDelete,"action-btn-text":_vm.$tc('deleteInfraPolicyBtn', _vm.toDelete.length, { nb: _vm.toDelete.length }),"cancel-btn-func":_vm.closeDeleteModal,"modal-type":"delete"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('areYouSure', _vm.toDelete.length, { item: _vm.toDelete[0].name }))}})]),_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.toDelete),function(ref){
var canonical = ref.canonical;
var name = ref.name;
var description = ref.description;
var severity = ref.severity;
var enabled = ref.enabled;
return _c('div',{key:canonical},[_c('div',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"8"}},[_c('h3',[_vm._v(" "+_vm._s(name)+" "),_c('v-chip',{class:("infra-policies__item--" + severity),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(severity)+" ")])],1),_c('span',{staticClass:"infra-policies__item-description"},[_vm._v(_vm._s(description))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"disabled":"","input-value":enabled,"label":enabled ? _vm.$t('enabled') : _vm.$t('disabled'),"color":"secondary","hide-details":""}})],1)],1)])}),0)]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var item = ref.props.item;
return [_c('td',[_c('div',{staticClass:"d-flex infra-policies__item mt-4"},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('p',[_c('v-chip',{class:[("infra-policies__item--" + (item.severity)), 'body-2'],attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.severity)+" ")])],1),_c('p',{staticClass:"infra-policies__item-description"},[_vm._v(" "+_vm._s(item.description)+" ")])])]),_c('td',[(_vm.canUpdateOwner(item))?_c('CyFormsAssignOwner',{attrs:{"errors":_vm.errors,"form-content":item,"action-btn-func":_vm.assignNewOwner,"sm":""}}):_c('CyButton',{attrs:{"to":{
            name: 'member',
            params: {
              id: item.owner.id,
              backRouteTo: 'infraPolicies',
            },
          },"disabled":!_vm.canGoToMember(item),"readonly":!_vm.canGoToMember(item),"theme":"grey","variant":"tertiary","sm":"","member-link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('CyMember',{attrs:{"member":item.owner,"simple":"","sm":""}})],1)],1),_c('td',{staticClass:"infra-policies__item-status justify-end"},[_c('v-switch',{attrs:{"input-value":item.enabled,"label":item.enabled ? _vm.$t('enabled') : _vm.$t('disabled'),"disabled":!_vm.$cycloid.permissions.canDisplay('UpdateInfraPolicy', item.canonical),"color":"secondary","hide-details":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleInfraPolicyStatus(item)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div
      v-for="{ title, attrs } of $static.scenarios"
      :key="title"
      class="each-row">
      <h3>{{ title }}</h3>
      <div class="icons">
        <CyIconCredential
          v-for="(type, index) of $static.icons"
          :key="`${title}:${type}`"
          :data-canonical="type"
          v-bind="attrs(type, index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { icons } from '@/global/components/CyIconCredential.vue'

export default {
  name: 'CyIconCredentialSandbox',
  computed: {
    $static: () => ({
      icons: _.keys(icons),
      scenarios: [
        {
          title: 'Default',
          attrs: (type, index) => ({ type }),
        },
        {
          title: 'Has tooltips',
          attrs: (type, index) => ({
            type,
            showTooltip: true,
            tooltipText: _.capitalize(type).replace('_', ' '),
          }),
        },
        {
          title: 'Different size',
          attrs: (type, index) => ({
            type,
            size: 20 + index * 3,
          }),
        },
        {
          title: 'Greyscale',
          attrs: (type, index) => ({
            type,
            greyscale: true,
          }),
        },
      ],
    }),
  },
}
</script>

<style lang="scss" scoped>
.each-row {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 40px;
  }

  h3 {
    margin-bottom: 10px;
  }

  .icons {
    display: flex;
    align-items: flex-start;
  }
}
</style>

import { render, staticRenderFns } from "./CyPageMfaLoginLinkNotice.vue?vue&type=template&id=6a8a33ee&scoped=true"
import script from "./CyPageMfaLoginLinkNotice.vue?vue&type=script&lang=js"
export * from "./CyPageMfaLoginLinkNotice.vue?vue&type=script&lang=js"
import style0 from "./CyPageMfaLoginLinkNotice.vue?vue&type=style&index=0&id=6a8a33ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8a33ee",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./CyPageOrganizations.vue?vue&type=template&id=14764408&scoped=true"
import script from "./CyPageOrganizations.vue?vue&type=script&lang=js"
export * from "./CyPageOrganizations.vue?vue&type=script&lang=js"
import style0 from "./CyPageOrganizations.vue?vue&type=style&index=0&id=14764408&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14764408",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})

import { render, staticRenderFns } from "./CyDataTablePagination.vue?vue&type=template&id=6f4084a4&scoped=true"
import script from "./CyDataTablePagination.vue?vue&type=script&lang=js"
export * from "./CyDataTablePagination.vue?vue&type=script&lang=js"
import style0 from "./CyDataTablePagination.vue?vue&type=style&index=0&id=6f4084a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f4084a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VIcon,VList,VListItem,VListItemGroup,VListItemTitle,VSpacer,VTextField,VToolbar})

import { render, staticRenderFns } from "./CyPageEnvironments.vue?vue&type=template&id=865a7852&scoped=true"
import script from "./CyPageEnvironments.vue?vue&type=script&lang=js"
export * from "./CyPageEnvironments.vue?vue&type=script&lang=js"
import style0 from "./CyPageEnvironments.vue?vue&type=style&index=0&id=865a7852&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "865a7852",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCardText,VCol,VIcon,VRow,VSkeletonLoader,VSlideXReverseTransition})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})

import * as dateFns from 'date-fns' // eslint-disable-line no-restricted-imports
import * as fpDateFns from 'date-fns/fp' // eslint-disable-line no-restricted-imports
import { enGB as en, es, fr } from 'date-fns/locale' // eslint-disable-line no-restricted-imports
import i18n from './i18n'

export const $date = {
  ...dateFns,
  // https://github.com/date-fns/date-fns/issues/1532#issuecomment-599940816
  fp: fpDateFns,
  /** Returns String noting how long ago a timestamp was.
   * Uses i18n.locale to populate the correct locale phrasing.
   *
   * @example [strict=true] '11 hours ago'
   * @example [strict=false]'about 1 year ago'
  */
  $formatTimeAgo (value, { strict = true } = {}) {
    const locale = { en, es, fr }[i18n.locale]
    const date = $date.$getTimestamp(value)
    if (!date) return null
    return strict
      ? i18n.t('time.ago', [dateFns.formatDistanceToNowStrict(date, { locale })])
      : i18n.t('time.ago', [dateFns.formatDistanceToNow(date, { locale })])
  },

  /** Return a diff object with years, months, days, hours, minutes and seconds.
   *
   * @example [start=1714643400] [end=1714643408] { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 8 }
   */
  $diff (start, end) {
    return {
      years: dateFns.differenceInYears(end, start),
      months: dateFns.differenceInMonths(end, start),
      days: dateFns.differenceInDays(end, start),
      hours: dateFns.differenceInHours(end, start) % 24,
      minutes: dateFns.differenceInMinutes(end, start) % 60,
      seconds: dateFns.differenceInSeconds(end, start) % 60,
    }
  },

  /** Returns an array of 12 months in the current locale */
  $months () {
    return Array.from({ length: 12 }, (_, i) => $date.format(new Date(2021, i, 1), 'LLLL', { locale: this.$dateLocale }))
  },

  /** Returns an array of 7 weekdays in the current locale */
  $weekdays () {
    return Array.from({ length: 7 }, (_, i) => $date.format(new Date(2021, 0, i + 1), 'EEEE', { locale: this.$dateLocale }))
  },

  /** Returns a timestamp in ms (13 chars) or s (10 chars).
   *
   * @example [returnSeconds=true]  1650651285      # returns s
   * @example [returnSeconds=false] 1650651285735   # returns ms
   */
  $getTimestamp (value, { returnSeconds = false } = {}) {
    if (_.$isEmpty(value)) return null
    const date = _.$isPrimitive(value) ? new Date(Number(`${value}`.padEnd(13, '0'))) : value
    return Number(dateFns.format(date, returnSeconds ? 't' : 'T'))
  },
}

window.$date = $date

export default {
  install (Vue) {
    Vue.prototype.$date = $date
    Vue.mixin({
      // So these will be available to each template too.
      computed: {
        /* eslint-disable vue/no-unused-properties */
        $date: () => $date,
        $dateLocale: () => ({ en, es, fr }[i18n.locale]), // ? needed for some date-fns that use { locale } option
        /* eslint-enable vue/no-unused-properties */
      },
    })
  },
}

<template>
  <div>
    <h3>
      {{ $tc('customListCount', list.length, { nb: list.length }) }}
      <CyTooltip
        right
        transition="slide-x-transition">
        <template #activator="{ on }">
          <v-icon
            class="clickable"
            v-on="on">
            info_outline
          </v-icon>
        </template>
        {{ $t('youCanUse') }}
        <kbd>shift</kbd> + <kbd>alt</kbd> +
        {{ $t('andArrowKeysToNavigate') }}
      </CyTooltip>
    </h3>
    <div
      v-for="({ key, value }, index) of list"
      :key="index"
      class="pair-key-value">
      <v-text-field
        ref="customListItem_key"
        :value="key"
        :error-messages="_.get(errors, `${index}.key`)"
        class="required-field pair-key-value__key has-copy-btn"
        data-cy="key-input"
        required
        :readonly="!canEdit() || readOnlyAttrs.readonly"
        :disabled="!canEdit() || readOnlyAttrs.readonly"
        @input="setKey(index, $event)"
        @keyup.shift.alt.up="focusInput('up', index, 'key')"
        @keyup.shift.alt.down="focusInput('down', index, 'key')"
        @keyup.shift.alt.right="focusInput('right', index, 'key')">
        <span slot="label">{{ $t('forms.key') }}</span>
        <CyCopyButton
          v-if="path && key"
          slot="append"
          :copy-hint="`${path}.${key}`"
          :copy-value="`${path}.${key}`"/>
      </v-text-field>

      <component
        ref="customListItem_value"
        :append-icon="_.get(hideAsPassword, index, false) ? 'visibility_off' : 'visibility'"
        :type="_.get(hideAsPassword, index, false) ? 'text' : 'password'"
        :value="value"
        :label="$t('forms.value')"
        :error-messages="_.get(errors, `${index}.value`)"
        v-bind="readOnlyAttrs"
        spellcheck="false"
        class="required-field pair-key-value__value"
        data-cy="value-input"
        rows="1"
        auto-grow
        @click:append="$set(hideAsPassword, index, !_.get(hideAsPassword, index, false))"
        @input="setValue(index, $event)"
        @keyup.shift.alt.up="focusInput('up', index, 'value')"
        @keyup.shift.alt.down="focusInput('down', index, 'value')"
        @keyup.shift.alt.left="focusInput('left', index, 'value')"
        :is="_.get(hideAsPassword, index) ? 'v-textarea' : 'v-text-field'"/>

      <CyDeleteButton
        v-if="!isCycloidCredential && canEdit()"
        class="pair-key-value__delete"
        data-cy="delete-pair-button"
        variant="secondary"
        @click="removePair(index)"/>
    </div>

    <div>
      <CyButton
        v-if="!isCycloidCredential && canEdit()"
        icon="add"
        variant="secondary"
        data-cy="add-pair-button"
        @click="addPair()">
        {{ $t('addPair') }}
      </CyButton>
    </div>
  </div>
</template>

<script>
import { VTextarea } from 'vuetify/lib'
import CyCopyButton from '@/components/CyCopyButton.vue'
import { checksPass } from '@/utils/helpers'

/**
 * Used exclusively by CyPageCredential
 *
 * When typeIs.custom only, so customList will be Array of Objects shaped like so: { key, value }
 */
export default {
  name: 'CyCredentialCustomKeyList',
  components: {
    CyCopyButton,
    VTextarea,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    credentialCanonical: {
      type: String,
      default: '',
    },
    customList: {
      type: Array,
      validator: (customList) => customList.every((customCred) => _.$hasAll(customCred, ['key', 'value'])),
      required: true,
    },
    errors: {
      type: [Array, Object],
      validator: (errors) => _.values(errors)
        .every((error) => _.$hasAll(error, ['key', 'value']) &&
        _.every(_.values(error), _.isArray)),
      required: true,
    },
    readOnlyAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  data: ({ customList = [] }) => ({
    hideAsPassword: {},
    list: _.cloneDeep(customList),
  }),
  computed: {
    isCycloidCredential () {
      return ['vault', 'cycloid-worker-keys'].includes(this.credentialCanonical)
    },
  },
  watch: {
    customList (newVal) {
      this.setList(newVal)
    },
  },
  methods: {
    setList (list) {
      if (!Array.isArray(list)) console.error(`[setList]: cannot set list, param was not an Array.`, list)
      else this.list = list
    },
    setKey (index, newKey) {
      this.list[index].key = newKey
      this.updateValidations(index, 'key')
      this.updateList(this.list)
    },
    setValue (index, newValue) {
      this.list[index].value = newValue
      this.updateValidations(index, 'value')
      this.updateList(this.list)
    },
    removePair (selectedIndex) {
      this.updateList(this.list.filter((keyValuePair, index) => index !== selectedIndex))
    },
    async addPair () {
      this.updateList([...this.list, { key: '', value: '' }])
      await this.$nextTick()
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    },
    updateValidations (index, fieldToUpdate) {
      this.$emit('update-validations', index, fieldToUpdate)
    },
    updateList (list) {
      this.$emit('update-list', list)
    },
    focusInput (direction, currentIndex, currentInput) {
      let newIndex = currentIndex
      const isMovingVertically = ['up', 'down'].includes(direction)
      const isGoing = { [direction]: true }
      const cannotMove = (isMovingVertically && !checksPass({
        hasMultipleListItems: this.list.length > 1,
        canAscend: isGoing.down || currentIndex !== 0,
        canDescend: isGoing.up || currentIndex !== _.findLastIndex(this.list),
      })) ||
      (!isMovingVertically && isGoing.left && _.isEqual(currentInput, 'key')) ||
      (!isMovingVertically && isGoing.right && _.isEqual(currentInput, 'value'))

      if (cannotMove) return

      if (isMovingVertically) newIndex = isGoing.up ? currentIndex - 1 : currentIndex + 1

      const refToFocus = {
        up: `customListItem_${currentInput}`,
        down: `customListItem_${currentInput}`,
        left: `customListItem_key`,
        right: `customListItem_value`,
      }[direction]
      if (!_.isEmpty(this.$refs[refToFocus][newIndex])) this.$refs[refToFocus][newIndex].focus()
    },
    canEdit () {
      return this.$isCreationRoute
        ? this.$cycloid.permissions.canDisplay('CreateCredential')
        : this.$cycloid.permissions.canDisplay('UpdateCredential', this.credentialCanonical)
    },
  },
  i18n: {
    messages: {
      en: {
        addPair: 'Add pair',
        andArrowKeysToNavigate: 'the arrow keys to navigate the key/values below',
        customListCount: '1 pair @:forms.fieldKeyValue | {nb} pairs @:forms.fieldKeyValue',
        youCanUse: 'You can use',
      },
      es: {
        addPair: 'Añadir par',
        andArrowKeysToNavigate: 'las teclas de flecha para navegar por la clave / valores a continuación',
        customListCount: '1 par @:forms.fieldKeyValue | {nb} pares @:forms.fieldKeyValue',
        youCanUse: 'Puedes usar',
      },
      fr: {
        addPair: 'Ajouter paire',
        andArrowKeysToNavigate: 'les touches directionnelles pour parcourir les clés/valeurs ci-dessous',
        customListCount: '1 paire @:forms.fieldKeyValue | {nb} paires @:forms.fieldKeyValue',
        youCanUse: 'Vous pouvez utiliser',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .pair-key-value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: capitalize;

    &__key,
    &__value {
      ::v-deep .v-input__control {
        min-height: 34px;
      }
    }

    &__key {
      max-width: 30%;
      margin-right: 10px;
    }

    &__delete {
      align-self: center;
      margin-left: 16px;
    }

    .has-copy-btn {
      ::v-deep {
        .v-label {
          height: auto;
          background: cy-get-color("white");
          cursor: pointer;
          pointer-events: auto;
        }

        input {
          margin-top: 2px;
        }

        textarea {
          margin-top: 8px;
          padding-top: 0;
        }
      }
    }
  }
</style>

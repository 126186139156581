<template>
  <div>
    <h2 class="h5 mb-6 add-baremetal__title">
      {{ $t('untranslated.bareMetal') }}
    </h2>
    <p>
      <i18n path="workers.documentationHelp">
        <a
          :href="$docLinks.workers.onPremHelp"
          class="cy-link"
          target="_blank">{{ $t('workers.documentationHelpLink') }}</a>
      </i18n>
    </p>

    <CyWorkersAddForm
      :scheduler-host="schedulerHost"
      :scheduler-port="schedulerPort"
      :scheduler-public-t-s-a-key="schedulerPublicTSAKey"
      @change="(schedulerProperty) => $emit('update', schedulerProperty)"/>
  </div>
</template>

<script>
import CyWorkersAddForm from '@/components/CyWorkersAddForm.vue'

export default {
  name: 'CyWorkersAddBaremetalStepOne',
  components: {
    CyWorkersAddForm,
  },
  props: {
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.add-baremetal__title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: $font-weight-bolder;
}

::v-deep .doc-tag {
  padding: 4px 8px;
  border-radius: 8px;
  background-color: cy-get-color("grey", "light-2");
}
</style>

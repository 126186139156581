<template>
  <div class="account-summary">
    <h2 class="mb-4 account-summary__title">
      {{ $t('accountSummary') }}
    </h2>
    <div class="account-provider">
      <div class="account-provider__label">
        {{ $t('provider') }}
      </div>
      <div class="account-provider__value">
        <CyIconCredential
          size="24"
          class="mr-2"
          :type="provider.type"/>
        {{ provider.name }}
      </div>
    </div>
    <div class="account-credential">
      <div class="account-credential__label">
        {{ nameLabel }}
      </div>
      <div class="account-credential__value">
        {{ accountCredential.name || accountCredential.canonical }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CyCcmAccountsSummary',
  props: {
    accountCredential: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
      'getProviderExtraInfoByCanonical',
    ]),
    nameLabel () {
      return _.isEmpty(this.accountCredential.name)
        ? this.$t('cloudCostManagement.credentialCanonical')
        : this.$t('cloudCostManagement.credentialName')
    },
    provider () {
      const providerExtraInfoGetter = this.accountCredential.type
        ? this.getProviderExtraInfoByCredType
        : this.getProviderExtraInfoByCanonical
      const providerExtraInfoParam = this.accountCredential.type || this.accountCredential.cloudProviderCanonical

      return {
        name: _.$get(providerExtraInfoGetter, providerExtraInfoParam)?.displayNameFull,
        type: providerExtraInfoParam,
      }
    },
  },
  i18n: {
    messages: {
      en: {
        accountSummary: 'Account summary',
        provider: 'Provider',
      },
      es: {
        accountSummary: 'Resumen de la cuenta',
        provider: 'Proveedor',
      },
      fr: {
        accountSummary: 'Resumé du compte',
        provider: 'Fournisseur',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .account {
    &-summary {
      position: sticky;
      top: 96px;

      &__title {
        font-size: $font-size-lg;
      }
    }

    &-provider,
    &-credential {
      margin-bottom: 16px;

      &__label {
        color: cy-get-color("grey", "dark-2");
        font-size: $font-size-xs;
      }

      &__value {
        display: flex;
        align-items: center;
      }
    }
  }
</style>

<template>
  <CyListWrapper
    class="projects-page"
    entities-key="projects"
    :is-empty="_.isEmpty(storeProjects)"
    :is-loading="!hasLoaded"
    :has-permission="canCreateProject"
    :create-btn-text="$t('createBtn')">
    <template #actions>
      <CyButton
        class="btn-create-new"
        data-cy="create-new-project-btn"
        icon="add"
        :to="{ name: 'projectFromStack' }"
        @click="trackCreateProjectClick">
        {{ $t('createBtn') }}
      </CyButton>
    </template>

    <CyProjectsList
      id="cy-projects-list"
      ref="CyProjectsList"
      @has-loaded="hasLoaded = true"/>
  </CyListWrapper>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CyListWrapper from '@/components/CyListWrapper.vue'
import CyProjectsList from '@/components/CyProjectsList.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { gtmProjectsEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyPageProjects',
  components: {
    CyProjectsList,
    CyListWrapper,
  },
  data: () => ({
    hasLoaded: false,
  }),
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.projects'), [
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    const { $docLinks } = this
    return {
      title: this.$t('routes.projectsSection'),
      description: {
        text: this.$t('description'),
        link: $docLinks.project.deploy,
      },
    }
  },
  computed: {
    ...mapState('organization', {
      storeProjects: (state) => state.available.projects,
    }),
    canCreateProject () {
      return this.$cycloid.permissions.canDisplay('CreateProject')
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'workers' })
  },
  beforeDestroy () {
    this.RESET_ORG_STATE('available.workers')
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('organization', [
      'RESET_ORG_STATE',
    ]),
    trackCreateProjectClick () {
      this.$gtm.trackEvent(gtmProjectsEvents.allProjectsCreateNewProject)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectsSection',
        description: 'Organise your deployments and workflows with projects and environments.',
        createBtn: 'Create a new @:project',
        projectName: '@:Project name',
      },
      es: {
        title: '@:routes.projectsSection',
        description: 'Organice sus despliegues y flujos de trabajo con proyectos y entornos.',
        createBtn: 'Crear un nuevo @:project',
        projectName: 'Nombre del @:project',
      },
      fr: {
        title: '@:routes.projectsSection',
        description: 'Organisez vos déploiements et méthodes de travail avec des projets et des environnements.',
        createBtn: 'Créer un nouveau @:project',
        projectName: 'Nom du @:project',
      },
    },
  },
}
</script>

<template>
  <div>
    <CyDataTableYdApi
      hide-group-selector
      :fetch-available="{ keyPath: 'resourcePools' }"
      :groups="$static.groups"
      :headers="$static.headers"
      :paginate="false"
      :parse-items-array="resourcePoolsParser"
      :searchable-fields="$static.searchableFields"
      :transform-items="(item) => ({ ...item, usage: getUsagePercentage(item) })">
      <template
        #header-title-append="{ header }">
        <div v-if="header === 'capacity'">
          <CyTooltip
            max-width="150px"
            bottom>
            <template #activator="{ on }">
              <div
                class="ml-1"
                v-on="on">
                <v-icon
                  size="12"
                  v-on="on">
                  help_outline
                </v-icon>
              </div>
            </template>
            <span v-text="$t('capacityInfo')"/>
          </CyTooltip>
        </div>
        <div v-else-if="header === 'allocated'">
          <CyTooltip
            max-width="150px"
            bottom>
            <template #activator="{ on }">
              <div
                class="ml-1"
                v-on="on">
                <v-icon
                  size="12"
                  v-on="on">
                  help_outline
                </v-icon>
              </div>
            </template>
            <span v-text="$t('allocatedInfo')"/>
          </CyTooltip>
        </div>
        <div v-else-if="header === 'unallocated'">
          <CyTooltip
            max-width="150px"
            bottom>
            <template #activator="{ on }">
              <div
                class="ml-1"
                v-on="on">
                <v-icon
                  size="12"
                  v-on="on">
                  help_outline
                </v-icon>
              </div>
            </template>
            <span v-text="$t('unallocatedInfo')"/>
          </CyTooltip>
        </div>
      </template>
      <template
        #group-header-title-append="{ props }">
        <v-icon
          v-if="_.some(props.items, ({ usage }) => usage >= 90)"
          color="error"
          size="18"
          class="ml-1">
          error
        </v-icon>
        <v-icon
          v-if="_.some(props.items, ({ usage }) => _.inRange(usage, 70, 90))"
          color="warning"
          size="18"
          class="ml-1">
          warning
        </v-icon>
      </template>
      <template
        #group-header-actions="{ props }">
        <CyMenu
          :items="getRowActionItems( _.head(props.items))"
          bottom
          offset-y
          left>
          <template #activator="{ on }">
            <CyButton
              variant="tertiary"
              theme="primary"
              icon="more_horiz"
              max-width="24"
              max-height="24"
              icon-only
              sm
              data-cy="menu-activator"
              v-on="on"
              @click.stop/>
          </template>
        </CyMenu>
      </template>
      <template
        #table-cmp-body-row="{ props }">
        <td>
          {{ $t(`quotas.${props.item.type}`) }}
        </td>
        <td class="d-flex align-center">
          <v-progress-linear
            :value="props.item.usage"
            :color="getUsageBarColor(props.item)"
            background-color="grey lighten-2"
            height="8"
            rounded/>
          <div class="usage-percentage">
            {{ props.item.usage }}%
          </div>
          <div>
            {{ getFormattedUnits(props.item.used, props.item.type) }}
          </div>
        </td>
        <td>
          {{ getFormattedUnits(props.item.capacity, props.item.type) }}
        </td>
        <td>
          {{ getFormattedUnits(props.item.allocated, props.item.type) }}
        </td>
        <td>
          {{ getFormattedUnits(props.item.unallocated, props.item.type) }}
        </td>
      </template>
      <template #table-cmp-no-data>
        <div
          v-if="noResourcePools"
          class="no-data d-flex flex-column justify-center align-center">
          <h3 class="mb-2">
            {{ $t('noResourcePool') }}
          </h3>
          <i18n
            path="noResourcePoolHint"
            tag="p"
            class="px-6 pb-6 mb-0">
            <template #documentation>
              <br>
              <a
                :href="$docLinks.quotas.resourcePools"
                target="_blank"
                rel="noopener noreferrer"
                class="cy-link"
                v-text="$t('learnMore')"/>
            </template>
          </i18n>
          <CyButton
            v-has-rights-to="'CreateResourcePool'"
            class="create-resource-pool__btn"
            icon="add"
            theme="secondary"
            variant="primary"
            :to="{ name: 'newResourcePool' }">
            {{ $t('quotas.addResourcePool') }}
          </CyButton>
        </div>
        <div
          v-else
          class="no-data d-flex flex-column justify-center align-center">
          <h3 class="mb-2">
            {{ $t('noResourcePoolsFound') }}
          </h3>
          <p>
            {{ $t('tryDifferentFilters') }}
          </p>
        </div>
      </template>
      <template #table-cmp-header-actions>
        <CyButton
          v-has-rights-to="'CreateInventoryResource'"
          class="create-resource__btn"
          icon="add"
          theme="primary"
          variant="secondary"
          :to="{ name: 'newResource' }">
          {{ $t('inventory.addResource') }}
        </CyButton>
        <CyButton
          v-has-rights-to="'CreateResourcePool'"
          class="create-resource-pool__btn ml-4"
          icon="add"
          theme="secondary"
          variant="primary"
          :to="{ name: 'newResourcePool' }">
          {{ $t('quotas.addResourcePool') }}
        </CyButton>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyDataTableYdApi from '@/components/CyDataTableYdApi.vue'
import { getUsagePercentage, getUsageBarColor, getFormattedUnits } from '@/utils/helpers/quotas'

export default {
  name: 'CyResourcePoolListTable',
  components: {
    CyDataTableYdApi,
  },
  computed: {
    ...mapState('organization', {
      fetchInProgress: (state) => state.fetchInProgress.resourcePools,
      resourcePools: (state) => state.available.resourcePools,
    }),
    ...mapState('layout', {
      tableFilters: (state) => state.dataTables?.resourcePools?.filters || [],
    }),
    $static () {
      return {
        headers: [
          {
            text: this.$t('quotas.resourceType'),
            value: 'type',
          },
          {
            text: this.$t('quotas.usage'),
            value: 'usage',
          },
          {
            text: this.$t('quotas.capacity'),
            value: 'capacity',
            width: '12%',
            sortable: false,
          },
          {
            text: this.$t('quotas.allocated'),
            value: 'allocated',
            width: '12%',
            sortable: false,
          },
          {
            text: this.$t('quotas.unallocated'),
            value: 'unallocated',
            width: '30%',
            sortable: false,
          },
        ],
        searchableFields: [
          {
            label: this.$t('ResourcePool'),
            name: 'resource_pool',
          },
        ],
        groups: [
          {
            groupBy: 'resource_pool',
            hideColumn: false,
            isDefault: true,
            hideCount: true,
          },
        ],
      }
    },
    canUpdateResourcePool () {
      return this.$cycloid.permissions.canDisplay('UpdateResourcePool')
    },
    noResourcePools () {
      return _.isEmpty(this.resourcePools) && !this.fetchInProgress && _.isEmpty(this.tableFilters)
    },
  },
  methods: {
    getUsagePercentage,
    getUsageBarColor,
    getFormattedUnits,
    getRowActionItems ({ canonical: resourcePoolCanonical }) {
      return [
        {
          icon: this.canUpdateResourcePool ? 'edit' : 'info',
          label: this.canUpdateResourcePool ? this.$t('forms.btnEdit') : this.$t('Details'),
          action: () => { this.$router.push({ name: 'resourcePool', params: { resourcePoolCanonical } }) },
        },
      ]
    },
    resourcePoolsParser (items) {
      return items.reduce((parsedItems, item) => {
        const { canonical, name } = item
        const resourceResourcePoolItems = ['memory', 'storage', 'cpu'].map((type) => ({
          canonical,
          resource_pool: name,
          type,
          capacity: item[type],
          used: item[`used_${type}`],
          allocated: item[`allocated_${type}`],
          unallocated: item[type] - item[`allocated_${type}`],
        }))
        return [...parsedItems, ...resourceResourcePoolItems]
      }, [])
    },
  },
  i18n: {
    messages: {
      en: {
        allocatedInfo: 'Sum of all quotas allocated for a given resource.',
        capacityInfo: 'Total amount of a given resource.',
        noResourcePoolsFound: 'No @:resourcePool found',
        noResourcePool: 'No @:resourcePool yet',
        noResourcePoolHint: 'Resource pools are groups of resources to assign quotas to. {documentation}.',
        tryDifferentFilters: 'Try different filters',
        unallocatedInfo: 'Amount of resource that has no quotas allocated to it.',
      },
      es: {
        allocatedInfo: 'Suma de todas las quotas asignadas para un recurso determinado.',
        capacityInfo: 'Cantidad total de un recurso dado.',
        noResourcePoolsFound: 'No se encontraron @:resourcePool',
        noResourcePool: 'Aún no hay @:resourcePool',
        noResourcePoolHint: 'Los grupos de recursos son recursos a los que asignar quotas. {documentation}.',
        tryDifferentFilters: 'Prueba diferentes filtros',
        unallocatedInfo: 'Cantidad de recurso que no tiene quotas asignadas.',
      },
      fr: {
        allocatedInfo: 'Somme de tous les quotas alloués pour une ressource donnée.',
        capacityInfo: `Montant total d'une ressource donnée.`,
        noResourcePoolsFound: 'Aucun @:resourcePool trouvé',
        noResourcePool: 'Aucun @:resourcePool pour le moment',
        noResourcePoolHint: 'Les pools de ressources sont des groupes de ressources auxquels peuvent être attribués des quotas. {documentation}.',
        tryDifferentFilters: `Essayez d'autres filtres`,
        unallocatedInfo: `Quantité de ressource qui n'a pas de quotas qui lui sont alloués.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-progress-linear {
  width: 100px;
  margin-right: 16px;
}

.usage-percentage {
  width: 3em;
  margin-right: 24px;
}

.v-tooltip__content {
  width: 150px;
}

.no-data {
  height: 265px;

  h3 {
    color: cy-get-color("primary", "light-2");
  }

  p {
    color: cy-get-color("primary", "light-3");
  }
}
</style>

<template>
  <div class="watch-rule">
    <div class="watch-rule__content">
      <div class="table-header py-3 pl-4 d-flex align-center rounded-tl-lg rounded-tr-lg">
        <CyDataTableSorts
          class="mr-3"
          :sorts="$static.sorts"/>

        <CyDataTableFilters :filters="$static.filters"/>

        <CyButton
          v-has-rights-to="'GetEvents'"
          class="ml-auto mr-6"
          :loading="loading"
          variant="secondary"
          icon="refresh"
          @click="refreshNotifications">
          {{ $t('forms.btnRefresh') }}
        </CyButton>
      </div>

      <CyDataTableTags
        v-if="!_.$isEmpty(activeFilters)"
        :filters="$static.filters"
        class="table-tags"/>

      <div class="watch-rule__table d-flex justify-center">
        <v-progress-circular
          v-if="loading"
          class="mt-4"
          indeterminate
          color="secondary"/>
        <div
          v-else-if="!notifications.length"
          class="watch-rule__empty-state py-8 text-center">
          <div class="h5">
            {{ $t('events.noEventsFound') }}
          </div>
          <div class="text-body mt-2">
            {{ $t('events.tryDifferent') }}
          </div>
        </div>
        <CyEventsListTable
          v-else
          :events="notifications.map((item) => item.event)"
          :loading="loading"
          :active-filters="activeFilters"/>
      </div>
    </div>
    <div class="watch-rule__sidebar">
      <div v-if="isProjectActivityType && project">
        <div class="h6 mb-2">
          {{ $t('Project') }}
        </div>
        <CyProjectCardSmall :project="project"/>
      </div>

      <div class="h6 mb-1">
        {{ $t('watchRules.lastTriggered') }}
      </div>
      <p>
        {{ notifications.length ? $date.$formatTimeAgo(notifications[0].created_at) : '–' }}
      </p>

      <template v-if="watchRuleFilters">
        <div class="h6 mb-2">
          {{ $t('filters') }}
        </div>
        <CyFilterTagsList
          v-model="watchRuleFilters"
          :filter-icons="$static.filterIcons"
          read-only/>
      </template>

      <transition name="fade-transition">
        <CyAlert
          v-if="watchRule.muted"
          :title="$t('watchRules.mutedAlertTitle')"
          :content="$t('watchRules.mutedAlertContent')"
          class="mt-4"/>
      </transition>
    </div>
    <portal
      v-if="!loading"
      to="header-actions">
      <CyButton
        variant="secondary"
        theme="primary"
        :icon="watchRule.muted ? 'volume_up' : 'volume_off'"
        @click="toggleMute">
        {{ watchRule.muted ? $t('watchRules.unmute') : $t('watchRules.mute') }}
      </CyButton>
      <CyButton
        variant="secondary"
        theme="primary"
        icon="edit"
        :to="{
          name: 'userWatchRuleEdit',
          params: {
            watchRuleCanonical: watchRule.canonical,
          },
        }">
        {{ $t('watchRules.edit') }}
      </CyButton>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyDataTableFilters from '@/components/CyDataTableFilters.vue'
import CyDataTableSorts from '@/components/CyDataTableSorts.vue'
import CyDataTableTags from '@/components/CyDataTableTags.vue'
import CyEventsListTable from '@/components/CyEventsListTable.vue'
import CyFilterTagsList from '@/components/CyFilterTagsList.vue'
import CyProjectCardSmall from '@/components/CyProjectCardSmall.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { WATCH_RULE_FILTER_ICONS, WATCH_RULE_FILTERS, WATCH_RULE_SORT_OPTIONS, WATCH_RULE_TYPES } from '@/utils/helpers/watchRules'

export default {
  name: 'CyPageUserWatchRuleDetail',
  components: {
    CyDataTableSorts,
    CyDataTableFilters,
    CyDataTableTags,
    CyEventsListTable,
    CyFilterTagsList,
    CyProjectCardSmall,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.watchRule?.name, [
      {
        label: this.$t('routes.userWatchRules'),
        name: 'userWatchRules',
      },
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  header () {
    return {
      title: this.watchRule?.name,
      createdAt: this.watchRule?.created_at,
      updatedAt: this.watchRule?.updated_at,
      type: this.watchRuleType,
      organization: this.organizations.find((org) => org.name === this.watchRule?.organization_canonical),
      tag: this.watchRule?.muted ? this.$t('watchRules.muted') : null,
      tagIcon: 'volume_off',
    }
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState(['organizations']),
    ...mapState('notifications', {
      notifications: (state) => state.notifications ?? [],
      watchRule: (state) => state.watchRule ?? {},
    }),
    ...mapGetters('organization/project', [
      'project',
    ]),
    ...mapGetters('layout', [
      'getDataTableProps',
      'getDataTableFilters',
    ]),
    $static () {
      return {
        filterIcons: WATCH_RULE_FILTER_ICONS,
        filters: WATCH_RULE_FILTERS,
        sorts: WATCH_RULE_SORT_OPTIONS,
        watchRuleTypes: WATCH_RULE_TYPES,
      }
    },
    isProjectActivityType () {
      return !!this.watchRule?.project_canonical
    },
    watchRuleType () {
      return this.$static.watchRuleTypes.find((type) => type.key === (this.isProjectActivityType ? 'projectActivity' : 'eventTracking'))
    },
    watchRuleFilters () {
      return this.watchRule?.filters?.map(({ entity, operation }) => {
        if (entity === '*' && operation === '*') {
          return [
            `Action.${operation}`,
            `Entity.${entity}`,
          ]
        }
        if (entity === '*') return [`Action.${operation}`]
        if (operation === '*') return [`Entity.${entity}`]
        return []
      }).flat()
    },
    activeFilters () {
      return this.getDataTableFilters(this.$route.name)
    },
  },
  watch: {
    activeFilters: {
      async handler (newFilters, oldFilters) {
        if (!_.isEqual(newFilters, oldFilters)) {
          await this.updateQuery()
        }
      },
      deep: true,
    },
  },
  async created () {
    await this.GET_WATCH_RULE({ canonical: this.$route.params.watchRuleCanonical })
    this.SET_DATA_TABLE_PROPS({
      name: this.$route.name,
      props: {
        ...this.getDataTableProps(this.$route.name),
        ...this.$static.sorts[0],
      },
    })

    this.SET_DATA_TABLE_FILTERS({
      name: this.$route.name,
      filters: this.set7DaysTimeRange(),
    })

    await this.updateQuery()

    if (this.isProjectActivityType) {
      await this.GET_PROJECT({ projectCanonical: this.watchRule.project_canonical })
    }
    this.loading = false
  },
  beforeDestroy () {
    // Make sure stale project data does not linger in the store.
    this.RESET_PROJ_STATE()
  },
  methods: {
    ...mapActions('notifications', [
      'GET_NOTIFICATIONS',
      'GET_WATCH_RULE',
      'UPDATE_WATCH_RULE',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT',
    ]),
    ...mapMutations('organization/project', [
      'RESET_PROJ_STATE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
      'SET_DATA_TABLE_PROPS',
      'UPDATE_FILTERS_TIME_RANGE',
      'RESET_DATA_TABLE_PAGE',
    ]),
    set7DaysTimeRange () {
      const { 'type[in]': types, 'severity[in]': severities, ...rest } = this.$route.query
      const nowTimestamp = Date.now()
      const sevenDaysAgoTimestamp = $date.subDays(nowTimestamp, 7)
      const sevenDaysAgo = $date.format(sevenDaysAgoTimestamp, 'T')
      const currentTime = $date.format(nowTimestamp, 'T')

      return {
        'watch_rule_canonical[eq]': this.watchRule.canonical,
        'timestamp[gt]': sevenDaysAgo,
        'timestamp[lt]': currentTime,
        ...(types ? { 'type[in]': types } : null),
        ...(severities ? { 'severity[in]': severities } : null),
        ...rest,
      }
    },
    async updateQuery () {
      const { ...filters } = this.activeFilters
      const query = { ...filters }
      if (!_.isEqual(query, this.$route.query || {})) this.$router.replace({ query }).catch(() => { /* silenced */ })
      await this.GET_NOTIFICATIONS({ query })
    },
    async refreshNotifications () {
      // Handle this later because 'begin' & 'end' are not supported by the endpoint yet.
      const previousFilters = { ...this.activeFilters }
      this.loading = true
      const updatedCurrentTime = $date.format(Date.now(), 'T')
      this.UPDATE_FILTERS_TIME_RANGE({ name: this.$route.name, time: String(updatedCurrentTime) })
      if (_.isEqual(previousFilters, this.activeFilters)) await this.updateQuery()
      this.RESET_DATA_TABLE_PAGE({ name: this.$route.name })
      this.loading = false
    },
    async toggleMute () {
      await this.UPDATE_WATCH_RULE({
        watchRuleCanonical: this.watchRule.canonical,
        watchRule: {
          ...this.watchRule,
          muted: !this.watchRule.muted,
        },
      })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.userWatchRule',
      },
      es: {
        title: '@:routes.userWatchRule',
      },
      fr: {
        title: '@:routes.userWatchRule',
      },
    },
  },
}
</script>

<style scoped lang="scss">
.watch-rule {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;

  @include breakpoint('xl') {
    grid-template-columns: auto 480px;
  }
}

.watch-rule__content {
  display: flex;
  flex-direction: column;
}

.watch-rule__table {
  position: relative;
  flex: 1;

  .data-scrollbox {
    padding-left: $spacer-8;
  }
}

.watch-rule__empty-state {
  color: cy-get-color('slate-grey', 'light-3');

  .h5 {
    color: cy-get-color('slate-grey', 'light-2');
  }
}

.watch-rule__sidebar {
  padding-block: $spacer-6;
  padding-inline: $spacer-8;
  border-left: solid 1px cy-get-color('grey', 'light-1');
}

.table-header,
.table-tags {
  border-bottom: solid 1px cy-get-color('grey', 'light-1');
}

.table-tags {
  background: none;
}

::v-deep .cy-pagination {
  padding-inline: $spacer-8;
  border-top: solid 1px cy-get-color('grey', 'light-1');

  // Get rid of pseudo border, which exists in list-table.vue for no apparent reason.. ?
  &::before {
    content: none;
  }
}
</style>

<template>
  <div class="tc-external-backend">
    <h3 class="mb-4">
      {{ $t('configureExternalBackend') }}
    </h3>

    <div class="tc-external-backend__fields">
      <i18n
        path="description.entire"
        tag="p"
        class="tc-external-backend__description">
        <template #tfstate-link>
          <a
            :href="$docLinks.terraform.state"
            target="_blank"
            class="cy-link">
            {{ $t('description.tfstateLinkText') }}
          </a>
        </template>
        <template #infraview-link>
          <a
            :href="$docLinks.project.infraView"
            target="_blank"
            class="cy-link">{{ $t('routes.infraView') }}</a>
        </template>
      </i18n>

      <v-expand-transition>
        <div v-if="inputExternalBackend">
          <CyProjectConfigurationDetail
            ref="ProjectConfigurationDetailRef"
            :presets="inputExternalBackend"
            :is-in-infra-import="{ creation: true }"
            create-mode
            @update-can-save="(value) => canSave = value"
            @change="mergeFields">
            <template #description>
              {{ null }}
            </template>
            <template #actions>
              {{ null }}
            </template>
          </CyProjectConfigurationDetail>
        </div>
      </v-expand-transition>
    </div>

    <v-divider class="mb-6 mt-4"/>

    <div class="d-flex justify-end pt-4">
      <CyButton
        variant="secondary"
        @click="$emit('previous')">
        {{ $t('forms.back') }}
      </CyButton>

      <CyButton
        :disabled="!canSave"
        @click.stop="$emit('next', outputExternalBackendWithoutDummyFields)">
        {{ $t('forms.btnContinue') }}
      </CyButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyProjectConfigurationDetail from '@/components/CyProjectConfigurationDetail.vue'

export default {
  name: 'CyInfraImportWizardExternalBackend',
  components: {
    CyProjectConfigurationDetail,
  },
  data: () => ({
    canSave: false,
    inputExternalBackend: null,
    outputExternalBackendWithDummyFields: null,
  }),
  computed: {
    ...mapState('organization/infraImport', {
      mainProviderCanonical: (state) => state.providerCanonical,
      mainProviderConfig: (state) => state.providerConfig,
      storeExternalBackend: (state) => state.externalBackendConfig,
      project: (state) => state.project,
    }),
    outputExternalBackendWithoutDummyFields () {
      return _.omitBy(_.merge({},
        this.outputExternalBackendWithDummyFields,
        _.mapValues(this.necessaryDummyFields, () => null),
      ), _.isNil)
    },
    /* We wanted to treat the page component mostly as a black box and change as little
      in how it works as we can. It's also a good way to avoid a large refactor this way.
      And that component expects these values. E.g. to decide whether everything is
      filled in the forms via vualidate, it checks for the presence of these values.
      And then we are re-using that logic in infra Import to see if the wizard can
      progress safely. (The outputted dummy values that may come out can be discarded
      and need not be persisted by infra Import itself, of course.) */
    necessaryDummyFields () {
      const { environment, canonical } = this.project
      return {
        environment_canonical: environment.canonical,
        project_canonical: canonical,
        purpose: 'remote_tfstate',
      }
    },
  },
  mounted () {
    this.setExternalBackendInput()
  },
  methods: {
    setExternalBackendInput () {
      const { necessaryDummyFields, storeExternalBackend, mainProviderConfig, mainProviderCanonical } = this
      let inputExternalBackend = _.cloneDeep(necessaryDummyFields)

      inputExternalBackend = storeExternalBackend
        ? _.merge({}, inputExternalBackend, storeExternalBackend)
        : _.merge({}, inputExternalBackend, this.getEbInputFieldsByProvider(mainProviderConfig, mainProviderCanonical))

      this.$set(this, 'inputExternalBackend', inputExternalBackend)
    },
    getEbInputFieldsByProvider ({ region = '' }, mainProviderCanonical) {
      const ebProvider = {
        openstack: 'SwiftStorage',
        aws: 'AWSStorage',
        google: 'GCPStorage',
        azurerm: 'AzureStorage',
      }[mainProviderCanonical] || 'AWSStorage'

      return {
        AWSStorage: {
          configuration: {
            engine: 'AWSStorage',
            region,
            bucket: '',
          },
        },
        SwiftStorage: {
          configuration: {
            engine: 'SwiftStorage',
          },
        },
        GCPStorage: {
          configuration: {
            engine: 'GCPStorage',
          },
        },
        AzureStorage: {
          configuration: {
            engine: 'AzureStorage',
          },
        },
      }[ebProvider]
    },
    mergeFields (fields) {
      const mergedFields = _.merge({}, this.necessaryDummyFields, fields)
      this.$set(this, 'outputExternalBackendWithDummyFields', mergedFields)
    },
  },
  i18n: {
    messages: {
      en: {
        configureExternalBackend: 'Configure External Backend',
        description: {
          entire: 'Setup where you want us to save the {tfstate-link} we create for you. It\'s required for the {infraview-link} feature—a centralized graphical representation of your infrastructure.',
          tfstateLinkText: 'Terraform State (.tfstate) file',
        },
        useExternalBackendSwitchLabel: 'Use External Backend',
      },
      es: {
        configureExternalBackend: 'Configurar el Backend Externo',
        description: {
          entire: 'Configure dónde quiere que guardemos el {tfstate-link} que creamos para usted. Es necesario para el funcionamiento {infraview-link}, una representación gráfica centralizada de su infraestructura.',
          tfstateLinkText: 'Archivo Terraform State (.tfstate)',
        },
        useExternalBackendSwitchLabel: 'Usar Backend Externo',
      },
      fr: {
        configureExternalBackend: 'Configurer le Backend Externe',
        description: {
          entire: `Configurez l'endroit où vous voulez que nous enregistrions le {tfstate-link} que nous créons pour vous. Il est nécessaire pour la fonctionnalité {infraview-link}, une représentation graphique centralisée de votre infrastructure.`,
          tfstateLinkText: 'fichier Terraform State (.tfstate)',
        },
        useExternalBackendSwitchLabel: 'Utiliser un Backend Externe',
      },
    },
  },

}
</script>

<style lang="scss" scoped>
.tc-external-backend__fields {
  max-width: 450px;
}

.tc-external-backend__description a {
  white-space: nowrap;
}

::v-deep .cy-details {
  &__content {
    padding: 0;
    overflow: hidden;
  }
}
</style>

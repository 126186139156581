<template>
  <div class="workers-code-editor my-6">
    <CyCodeEditor
      code-lang="sh"
      :show-gutter="false"
      :value="code"/>
    <CyCopyButton
      :copy-hint="$t('workers.generatedCode')"
      :copy-value="code"/>
  </div>
</template>

<script>
import CyCodeEditor from '@/components/CyCodeEditor.vue'
import CyCopyButton from '@/components/CyCopyButton.vue'
import 'brace/mode/sh'

export default {
  name: 'CyWorkersCodeEditor',
  components: {
    CyCopyButton,
    CyCodeEditor,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.workers-code-editor {
  position: relative;
  height: 140px;
  overflow: hidden;
  border-radius: 8px;

  ::v-deep .cy-copy-btn {
    visibility: hidden;
    position: absolute;
    z-index: 40;
    top: 0;
    right: 0;
    margin-top: 16px;
    margin-right: 16px;
    transition: all 0.2s ease-out;
    opacity: 0;
  }

  &:hover ::v-deep .cy-copy-btn {
    visibility: visible;
    opacity: 1;
  }
}
</style>

<template>
  <div>
    <h2 class="mb-5">
      Currency switcher
    </h2>

    <CyCcmSidebarCurrencySwitcher/>
  </div>
</template>

<script>
import CyCcmSidebarCurrencySwitcher from '@/components/CyCcmSidebarCurrencySwitcher.vue'

export default {
  name: 'CyCcmSidebarCurrencySwitcherSandbox',
  components: {
    CyCcmSidebarCurrencySwitcher,
  },
}
</script>

import { render, staticRenderFns } from "./CyBetaTag.vue?vue&type=template&id=37d197d2&scoped=true"
import script from "./CyBetaTag.vue?vue&type=script&lang=js"
export * from "./CyBetaTag.vue?vue&type=script&lang=js"
import style0 from "./CyBetaTag.vue?vue&type=style&index=0&id=37d197d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d197d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VIcon})

<template>
  <div class="resource-type elevation-1 flex-grow-1 my-8">
    <div class="resource-type__header d-flex justify-space-between pa-4 px-4">
      <h3 class="h5 d-flex align-self-center pl-2">
        {{ type }}
      </h3>

      <!-- TODO: FE#4322 enable refreshing with InfraView 3.0 -->
      <div
        v-if="false"
        class="resource-type__refresh-area d-flex align-center">
        <span class="refresh-text mr-2">
          refreshed DD/MM/YY HH:mm
        </span>

        <CyTooltip left>
          <template #activator="{ on }">
            <span v-on="on">
              <CyButton
                icon="refresh"
                icon-only
                theme="secondary"
                variant="tertiary"
                sm/>
            </span>
          </template>
          {{ $t('refreshResourceType', { resourceType: type }) }}
        </CyTooltip>
      </div>
    </div>

    <CyInfraViewListResourceInstance
      v-for="resource of resources"
      :key="resource._canonical"
      :resource="resource"/>
  </div>
</template>

<script>
import CyInfraViewListResourceInstance from '@/components/CyInfraViewListResourceInstance.vue'

export default {
  name: 'CyInfraViewListResourceType',
  components: {
    CyInfraViewListResourceInstance,
  },
  props: {
    resources: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: {
      en: {
        refreshResourceType: 'refresh all the {resourceType}',
      },
      es: {
        refreshResourceType: 'actualizar todas las {resourceType}',
      },
      fr: {
        refreshResourceType: 'actualiser tout le {resourceType}',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-type {
  max-width: 896px;
  margin-right: auto;
  margin-left: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: cy-get-color("white");

  &__refresh-area {
    .refresh-text {
      color: cy-get-color("primary", "light-3");
    }
  }
}
</style>

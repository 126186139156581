<template>
  <div class="sandbox">
    <v-autocomplete
      v-model="selectedComponent"
      :items="componentOptions"
      class="sandbox__options"
      spellcheck="false">
      <template #append-outer>
        <CyTooltip
          right
          transition="slide-x-transition">
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="primary"
              v-on="on">
              info
            </v-icon>
          </template>
          <span>To add a new set of examples, follow the guide in <code>src/sandbox.vue</code></span>
        </CyTooltip>
        <CyTooltip
          :key="selectedComponent"
          right
          transition="slide-x-transition"
          :theme="figmaLinks[selectedComponent] ? 'secondary' : 'warning'">
          <template #activator="{ on }">
            <a
              v-if="figmaLinks[selectedComponent]"
              :href="figmaLinks[selectedComponent]"
              target="_blank"
              v-on="on">
              <img
                :src="figmaIcon"
                class="figma-link clickable"
                alt="figma icon">
            </a>
            <img
              v-else
              :src="figmaIcon"
              class="figma-link figma-link--missing"
              alt="figma icon (missing)"
              v-on="on">
          </template>
          <span>
            <v-icon
              color="white"
              size="14">
              {{ figmaLinks[selectedComponent] ? 'launch' : 'warning' }}
            </v-icon>
            {{ figmaLinks[selectedComponent] ? 'Open mockups in Figma' : 'Figma link is missing' }}
          </span>
        </CyTooltip>
      </template>
    </v-autocomplete>

    <template v-for="componentName of componentOptions">
      <component
        v-if="componentName === selectedComponent"
        :key="componentName"
        :class="`sandbox__${_.kebabCase(componentName)}`"
        :is="componentName"/>
    </template>
  </div>
</template>

<script>
import { CycloidIcons } from '@/assets/icons'
import figmaLinks from '@/sandbox/figmaLinks'
import { constructBreadcrumb } from '@/utils/helpers'

const requireModule = require.context('./examples', false, /^\.\/Cy.+\.vue$/)

const components = requireModule.keys().reduce((_components, fileName) => {
  const component = requireModule(fileName).default
  const componentName = component.name.replace('Sandbox', '')
  return { ..._components, [componentName]: component }
}, {})

const componentOptions = _.keys(components).sort()

/** How to add examples to the sandbox
 *
 * CREATE NEW file under src/sandbox/examples
 *   - Name the file [component.name].vue
 *      e.g. CyButtonSandbox.vue
 *   - Set the component.name to [component.name]Sandbox
 *      e.g name: 'CyButtonSandbox'
 *   - Add your examples in any way you like
 *   - That's it! Components in ./examples are auto imported
 *
 * ---------------------------------------------------------
 *
 * Still unsure?
 * Check out the following for implementation:
 *   - examples/CyButtonSandbox.vue
 *   - examples/CyButtonToggleSandbox.vue
 *   - examples/CyTagSandbox.vue
 *
 * FAQ
 *   - No tests needed, Jest is set to ignore this directory.
 *   - No need to delete these examples once you're finished,
 *     keeping them will aid us when we start using StoryBook.
 *
 */
export default {
  name: 'CyPageSandbox',
  components,
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.sandbox'))
  },
  beforeRouteLeave: (to, _from, next) => {
    if (to.params.orgCanonical) return next()
    return next({ ...to, params: { ...to.params, orgCanonical: 'seraf' } })
  },
  data: () => ({
    selectedComponent: _.cloneDeep(componentOptions[0]),
    componentOptions,
    figmaLinks,
    figmaIcon: CycloidIcons.figma,
  }),
  watch: {
    selectedComponent (selectedComponent) {
      if (this.$router.query?.selectedComponent === selectedComponent) return
      this.$router.replace({ query: { selectedComponent } }).catch(() => { /* silenced */ })
    },
  },
  mounted () {
    const { selectedComponent } = this.$route?.query || {}
    if (selectedComponent) this.selectedComponent = selectedComponent
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.sandbox',
      },
      es: {
        title: '@:routes.sandbox',
      },
      fr: {
        title: '@:routes.sandbox',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.sandbox {
  display: flex;
  flex-flow: column;
  height: 100%;
  background: cy-get-color("background");

  &__options {
    flex-grow: 0;
    width: 350px;

    + div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

.figma-link {
  height: 24px;

  &--missing {
    filter: grayscale(1);
  }
}
</style>

import Vue from 'vue'
import ObjWithPrivateProps from '@/utils/classes/ObjWithPrivateProps'
import { vuexMixin, getKey, parseModules } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'
import ApiKey from './apiKey'
import Appearance from './appearance'
import Billing from './billing'
import CatalogRepository from './catalogRepository'
import CloudCostManagement from './cloudCostManagement'
import ConfigRepository from './configRepository'
import Credential from './credential'
import ExternalBackend from './externalBackend'
import InfraImport from './infraImport'
import InfraPolicy from './infraPolicy'
import Inventory from './inventory'
import Licence from './licence'
import Member from './member'
import Project from './project'
import Quota from './quota'
import Resource from './resource'
import ResourcePool from './resourcePool'
import Role from './role'
import Stack from './stack'
import Team from './team'
import Terracost from './terracost'

export const cycloidDefault = {
  credentials: [
    'cycloid-worker-keys',
    'vault',
  ],
  roles: [
    'organization-admin',
    'organization-member',
  ],
}

const AVAILABLE = {
  apiKeys: [],
  appearances: [],
  catalogRepositories: [],
  childOrgs: [],
  cloudProviders: [],
  configRepositories: [],
  credentials: [],
  environments: [],
  events: [],
  externalBackends: [],
  infraPolicies: [],
  inventoryResources: [],
  invitedMembers: [],
  invites: [],
  labels: [],
  members: [],
  pipelines: [],
  policies: [],
  projects: [],
  quotas: [],
  resourcePools: [],
  resourcesByLabel: [],
  resourceTypes: [],
  roles: [],
  stacks: [],
  teamMembers: [],
  teams: [],
  workers: [],
}

const FILTERS = {
  inventoryResources: {},
}

export const initialState = {
  detail: null,
  available: {
    ...AVAILABLE,
    tags: {},
  },
  errors: {
    ...AVAILABLE,
    organization: [],
    tags: [],
  },
  fetchInProgress: {
    ..._.mapValues(AVAILABLE, () => false),
    organization: false,
    summary: false,
  },
  filters: FILTERS,
  cycloidCredentials: _(cycloidDefault.credentials).map((key) => [key, null]).fromPairs().value(),
  workersFetched: false,
  orgCanonicalToSwitchAfterDelete: null,
  summary: {
    catalogRepositories: 0, // service_catalog_sources
    configRepositories: 0,
    credentials: 0,
    members: 0, // users
    pipelines: 0,
    projects: 0,
    roles: 0,
    stacks: 0, // service_catalogs
    teams: 0,
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  getAvailableByCanonical: (state) => (resource, canonical) => _.find(state.available[resource], ['canonical', canonical]),
  getAvailableById: (state) => (resource, id) => _.find(state.available[resource], ['id', id]),
  getCredentialsByType: (state) => (credentialType) => state.available.credentials.filter(({ type }) => credentialType === type),
  getErrorsByKey: (state) => (key) => {
    if (getKey(key) !== key) {
      console.warn(`[getErrorsByKey]: please change to use the preferred key, as used on the state`, {
        keyPassed: key,
        keyPreferred: getKey(key),
      })
    }
    return state.errors?.[getKey(key)] ?? []
  },
  favoriteProjects: (state) => _.filter(state.available.projects, { favorite: true }),
  hasCredentialsOfType: (state) => (credentialType) => state.available.credentials.some(({ type }) => credentialType === type),
  hasCycloidCredentialOfType: (state) => (canonical) => !_.isEmpty(state.cycloidCredentials?.[canonical]),
  hasErrors: (state) => _.fromPairs(Object.entries(state.errors).map(([key, value]) => [key, _.isEmpty(value)])),
  hasRunningWorkers: (state) => !state.workersFetched || _.some(state.available.workers, ['state', 'running']),
  hasAvailable: (state) => (key) => {
    if (getKey(key) !== key) {
      console.warn(`[getErrorsByKey]: please change to use the preferred key, as used on the state`, {
        keyPassed: key,
        keyPreferred: getKey(key),
      })
    }

    return !_.$isEmpty(state.available[key])
  },
  hasQuotasEnabled: (state) => state.detail?.quotas,
  organization: (state) => state.detail,
  orgCanonical: (state) => _.get(state.detail, 'canonical', ''),
  orgName: (state) => _.get(state.detail, 'name', ''),
  isOrgSelected: (state) => _.has(state.detail, 'canonical'),
  orgMembers: (state, _getters, _rootState, rootGetters) => _
    .chain(state.available.members)
    .filter(['invitation_state', 'accepted'])
    .sortBy([
      (member) => !_.isEqual(member.username, rootGetters.username),
      (member) => member.given_name?.toLowerCase(),
      (member) => member.family_name?.toLowerCase(),
      'username',
    ])
    .value(),
  subscriptionMembersLeftPercentage: ({ detail }) => {
    const { current_members: membersCount = null, members_count: maxMembers = null } = detail?.subscription || {}
    return _.some([membersCount, maxMembers], _.isNull)
      ? null
      : Math.round((maxMembers - membersCount) * 100 / maxMembers)
  },
}

const {
  mutations: { CLEAR_ERRORS, SET_ERRORS, START_FETCH, STOP_FETCH },
  actions: { BULK_DELETE, FETCH_AVAILABLE },
} = vuexMixin(initialState)

export const actions = {
  BULK_DELETE,
  FETCH_AVAILABLE,

  async CHANGE_ORGANIZATION ({ commit, dispatch, rootState, getters }, { nextCanonical }) {
    const refreshTokenError = await dispatch('auth/REFRESH_TOKEN', { query: { organization_canonical: nextCanonical } }, { root: true })

    if (refreshTokenError === 'MFAError') return false
    const token = rootState.auth.jwt
    if (!token) return false

    await dispatch('UPDATE_USER_SESSION', token, { root: true })
    commit('customers/SET_SCOPE_DEPTH', 0, { root: true })
    commit('customers/REMOVE_CUSTOMER_SCOPES', null, { root: true })
    commit('RESET_ORG_STATE', 'errors')
    commit('RESET_ORG_STATE', 'available')
    await dispatch('GET_ORGANIZATION', nextCanonical)
    const { orgName } = getters
    commit('customers/SET_CURRENT_SCOPE', { name: orgName, canonical: nextCanonical, jwt: token }, { root: true })
    return true
  },

  async CREATE_ORGANIZATION ({ dispatch, commit }, { reqPage, $router, organization, parentCanonical = null }) {
    const { data, errors = null } = parentCanonical
      ? await Vue.prototype.$cycloid.ydAPI.createOrgChild(organization, parentCanonical) || {}
      : await Vue.prototype.$cycloid.ydAPI.createOrg(organization) || {}
    if (data) {
      const { canonical: orgCanonical, name: orgName } = data
      await dispatch('FETCH_ORGS', { reqPage }, { root: true })
      await dispatch('CHANGE_ORGANIZATION', { nextCanonical: orgCanonical })
      $router.push({ name: 'orgSettings', params: { orgCanonical } })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.organization.created', { orgName }) }, { root: true })
    }
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async DELETE_ORGANIZATION ({ dispatch, commit, rootState }, { reqPage, $router, organization }) {
    const { canonical: orgCanonical, name: orgName } = organization

    const nextCanonical = rootState.organizations.find((o) => o.canonical !== orgCanonical)?.canonical
    if (nextCanonical) commit('SWITCH_TO_OTHER_ORG_AFTER_DELETE', nextCanonical)

    const { errors = null } = await Vue.prototype.$cycloid.ydAPI.deleteOrg(orgCanonical) || {}
    if (errors) return commit('SET_ERRORS', { key: 'organization', errors })

    await dispatch('FETCH_ORGS', { reqPage }, { root: true })
    if (!_.isEmpty(rootState.organizations)) await dispatch('CHANGE_ORGANIZATION', { nextCanonical: nextCanonical || rootState.organizations[0].canonical })
    else {
      commit('RESET_ORGANIZATION')
      commit('customers/RESET_CUSTOMERS_STATE', null, { root: true })
      commit('organization/licence/RESET_LICENCE_STATE', null, { root: true })
      const { data } = await Vue.prototype.$cycloid.ydAPI.refreshToken(null)
      await dispatch('UPDATE_USER_SESSION', data.token, { root: true })
    }
    $router.push({ name: 'organizations' })
    dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.organization.deleted', { orgName }) }, { root: true })
  },

  async GET_ORGANIZATION ({ commit, rootState, dispatch }, orgCanonical) {
    if (_.isEmpty(orgCanonical)) return console.error('[GET_ORGANIZATION] was not passed the required orgCanonical param')

    commit('CLEAR_ERRORS', 'organization')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getOrg(orgCanonical) || {}
    if (data) {
      commit('SET_ORGANIZATION', data)
      await dispatch('FETCH_APP_APPEARANCE', orgCanonical, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'organization', errors })
  },

  async GET_ORG_SUMMARY ({ commit, rootGetters: { orgCanonical } }) {
    commit('START_FETCH', 'summary')
    commit('RESET_SUMMARY')
    const { data } = await Vue.prototype.$cycloid.ydAPI.getOrgSummary(orgCanonical) || {}
    if (data) commit('SET_SUMMARY', data)
    commit('STOP_FETCH', 'summary')
  },

  async GET_CYCLOID_CREDENTIALS ({ commit, rootGetters: { orgCanonical } }) {
    const successData = []

    const promises = cycloidDefault.credentials.map(async (credentialCanonical) => {
      const { data } = await Vue.prototype.$cycloid.ydAPI.getCycloidCredential(orgCanonical, credentialCanonical) || {}
      if (data) successData.push(data)
    })
    await Promise.allSettled(promises)

    for (const data of successData) commit('SET_CYCLOID_CREDENTIAL', data)
  },

  async INVITE_ORG_MEMBERS ({ commit, dispatch, rootGetters: { orgCanonical, orgName } }, { invitedMembers, $router }) {
    commit('CLEAR_ERRORS', 'invitedMembers')
    const allErrors = []
    let successfullyInvitedEmailCount = 0

    const promises = invitedMembers.map(async (invitedMember) => {
      const { errors } = await Vue.prototype.$cycloid.ydAPI.inviteUserToOrgMember(orgCanonical, invitedMember) || {}
      if (errors) allErrors.push(...errors.map((error) => ({ ...error, details: [...(error.details || []), invitedMember.email] })))
      else successfullyInvitedEmailCount++
    })
    await Promise.allSettled(promises)

    const successMessage = i18n.tc('alerts.success.organization.members.invited', successfullyInvitedEmailCount, { orgName, nb: successfullyInvitedEmailCount })
    if (successfullyInvitedEmailCount > 0) dispatch('alerts/SHOW_ALERT', { type: 'success', content: successMessage }, { root: true })
    if (!_.isEmpty(allErrors)) commit('SET_ERRORS', { key: 'invitedMembers', errors: allErrors })
    else if ($router) $router.push({ name: 'members' })
  },

  async RESEND_INVITES ({ commit, dispatch, rootGetters: { orgCanonical, orgName } }, { invites }) {
    commit('CLEAR_ERRORS', 'invites')
    const allErrors = []
    let successfullyResentCount = 0

    const promises = invites.map(async (invite) => {
      const { errors } = await Vue.prototype.$cycloid.ydAPI.resendInvitation(orgCanonical, invite.id) || {}
      if (errors) allErrors.push(...errors.map((error) => ({ ...error, details: [...(error.details || []), invite.email] })))
      else successfullyResentCount++
    })
    await Promise.allSettled(promises)

    const successMessage = i18n.tc('alerts.success.organization.members.invitesResent', successfullyResentCount, { orgName, nb: successfullyResentCount })
    if (successfullyResentCount > 0) dispatch('alerts/SHOW_ALERT', { type: 'success', content: successMessage }, { root: true })
    if (!_.isEmpty(allErrors)) commit('SET_ERRORS', { key: 'invites', errors: allErrors })
  },

  async UNASSIGN_ORG_MEMBERS ({ commit, dispatch, rootGetters: { orgCanonical, orgName } }, { ids, $router }) {
    commit('CLEAR_ERRORS')
    const allErrors = []
    const successfullyRemovedIds = []

    const promises = ids.map(async (id) => {
      const { errors } = await Vue.prototype.$cycloid.ydAPI.removeOrgMember(orgCanonical, id) || {}
      if (errors) allErrors.push(...errors)
      else successfullyRemovedIds.push(id)
    })
    await Promise.allSettled(promises)

    const idsRemoved = _.$getListFromArray(successfullyRemovedIds, { boldItems: true })
    const successMessage = i18n.tc('alerts.success.organization.members.unassigned', successfullyRemovedIds.length, { orgName, idsRemoved })
    if (!_.isEmpty(successfullyRemovedIds)) dispatch('alerts/SHOW_ALERT', { type: 'success', content: successMessage }, { root: true })
    if (!_.isEmpty(allErrors)) commit('SET_ERRORS', { errors: allErrors })
    else if ($router) $router.push({ name: 'members' })
  },

  async UPDATE_ORGANIZATION ({ dispatch, commit }, { organization }) {
    commit('CLEAR_ERRORS', 'organization')

    const { data, errors = null } = await Vue.prototype.$cycloid.ydAPI.updateOrg(organization) || {}
    if (data) {
      const { name: orgName } = data
      commit('SET_ORGANIZATION', data)
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.organization.updated', { orgName }) }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'organization', errors })
  },
}

export const mutations = {
  CLEAR_ERRORS,
  SET_ERRORS,
  START_FETCH,
  STOP_FETCH,

  SWITCH_TO_OTHER_ORG_AFTER_DELETE (state, nextOrganization) {
    Vue.set(state, 'orgCanonicalToSwitchAfterDelete', nextOrganization)
  },

  RESET_SWITCH_TO_OTHER_ORG_AFTER_DELETE (state) {
    Vue.set(state, 'orgCanonicalToSwitchAfterDelete', null)
  },

  RESTORE_ORGANIZATION (state) {
    if (state.detail) throw new Error('[RESTORE_ORGANIZATION]: Organization MUST NEVER be restored from the local storage when the store contains an organization')

    const organization = sessionStorage.getItem(LSK.ORGANIZATION) || localStorage.getItem(LSK.ORGANIZATION)
    if (organization) Vue.set(state, 'detail', JSON.parse(organization))
  },

  RESET_ORGANIZATION (state, orgsList = []) {
    const organization = orgsList[0] || {}
    if (_.isEmpty(organization)) {
      sessionStorage.removeItem(LSK.ORGANIZATION)
      localStorage.removeItem(LSK.ORGANIZATION)
      Vue.set(state, 'detail', null)
    } else {
      Vue.set(state, 'detail', organization)
      sessionStorage.setItem(LSK.ORGANIZATION, JSON.stringify(organization))
      localStorage.setItem(LSK.ORGANIZATION, JSON.stringify(organization))
    }
  },

  SET_AVAILABLE (state, { key, value }) {
    const available = applySpecificProps(state, key, value)

    if (key === 'workers') Vue.set(state, 'workersFetched', true)

    const sortedAvailable = {
      appearances: _.sortBy(available, ({ canonical }) => canonical !== 'default'),
    }?.[key] ?? available

    Vue.set(state.available, key, sortedAvailable)
  },

  SET_FILTERS (state, { key, value }) {
    Vue.set(state.filters, key, value)
  },

  SET_CYCLOID_CREDENTIAL (state, credential) {
    if (cycloidDefault.credentials.includes(credential.canonical)) Vue.set(state.cycloidCredentials, credential.canonical, credential)
  },

  SET_SUMMARY (state, summary) {
    for (const [key, value] of _.entries(summary)) {
      Vue.set(state.summary, getKey(key), value || 0)
    }
  },

  SET_ORGANIZATION (state, org = {}) {
    const { blocked, ...organization } = org
    if (_.isEmpty(organization)) return
    Vue.set(state, 'detail', organization)
    sessionStorage.setItem(LSK.ORGANIZATION, JSON.stringify(organization))
    localStorage.setItem(LSK.ORGANIZATION, JSON.stringify(organization))
  },

  RESET_ORG_STATE (state, keyPath) {
    if (!!keyPath && !_.has(initialState, keyPath)) return console.error(`[RESET_ORG_STATE] was trying to reset a keyPath that does not exist: 'organization.state.${keyPath}'`)

    if (keyPath) _.set(state, keyPath, _.cloneDeep(_.get(initialState, keyPath)))
    else for (const key in initialState) Vue.set(state, key, _.cloneDeep(initialState)[key])
  },

  RESET_SUMMARY (state) {
    Vue.set(state, 'summary', _.cloneDeep(initialState.summary))
  },
}

/**
 * For some lists sent from the api, we also want to add more info
 * @returns Array|Object
 */
function applySpecificProps (state, propName, items) {
  return _.isArray(items)
    ? {
        credentials: items.map((credential) => new ObjWithPrivateProps(credential, {
          _isDefault: cycloidDefault.credentials.includes(credential.canonical),
        })),
        projects: items.map((project) => new ObjWithPrivateProps(project, {
          _cloudProviders: _(project.environments)
            .map('cloud_provider')
            .filter()
            .uniqBy('canonical')
            .value(),
        })),
        roles: items.map((role) => new ObjWithPrivateProps(role, {
          _isDefault: role.default ?? false,
        })),
        stacks: items.map((stack) => new ObjWithPrivateProps(stack, {
          _catalogRepositoryName: _.get(state.available.catalogRepositories.find(({ canonical }) => canonical === stack.service_catalog_source_canonical), 'name', null),
        })),
      }[propName] || items
    : items
}

export const privateFunctions = {
  applySpecificProps,
}

export {
  AVAILABLE as available,
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,

  modules: parseModules({
    ApiKey,
    Appearance,
    Billing,
    CatalogRepository,
    CloudCostManagement,
    ConfigRepository,
    Credential,
    ExternalBackend,
    InfraImport,
    InfraPolicy,
    Inventory,
    Licence,
    Member,
    Project,
    Quota,
    Resource,
    ResourcePool,
    Role,
    Stack,
    Team,
    Terracost,
  }),
}

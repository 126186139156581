<template>
  <div class="panels">
    <v-card class="panel panel--with-border pa-6">
      <div class="panel__row">
        <div class="plan-info">
          <h4 class="panel__title">
            {{ $t('currentPlan') }}
          </h4>
          <CyTag variant="secondary">
            {{ planName }}
          </CyTag>
        </div>

        <div v-if="isEssential">
          <p class="mt-2 plan-info plan-info--wide">
            {{ $t('noLimitationOn', { planName: subscriptionPlan.name }) }}
          </p>
        </div>

        <div
          v-else
          class="plan-info plan-info--with-padding">
          <CyLicenceProgressBar
            with-description
            with-title/>
        </div>
      </div>

      <CyAlert
        v-if="isEnterprise || trialExpired"
        class="mt-6"
        :title="contactUsInfo.title"
        :content="contactUsInfo.text"
        :button-label="$t('contactUsInfo.btnText')"
        no-bottom-margin
        @click="openContactUsPage"/>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyLicenceProgressBar from '@/components/CyLicenceProgressBar.vue'

export default {
  name: 'CyPlanInfo',
  components: {
    CyLicenceProgressBar,
  },
  computed: {
    ...mapGetters('organization/billing', [
      'isEnterprise',
      'isEssential',
      'planName',
      'subscriptionPlan',
      'trialExpired',
    ]),
    contactUsInfo () {
      const type = this.isEnterprise ? 'maxUsers' : 'trialEnded'
      return {
        title: this.$t(`contactUsInfo.${type}.title`),
        text: this.$t(`contactUsInfo.${type}.text`),
      }
    },
  },
  methods: {
    openContactUsPage () {
      window.open('https://www.cycloid.io/contact-us', '_blank')
    },
  },
  i18n: {
    messages: {
      en: {
        contactUsInfo: {
          btnText: 'Contact us',
          maxUsers: {
            text: 'Please contact us to increase the maximum number of users of your organization.',
            title: 'You reached maximum user capacity',
          },
          trialEnded: {
            text: 'Thank you for trying our product. Please get in touch with us in order to continue using our services.',
            title: 'Your trial has ended',
          },
        },
        currentPlan: 'Current plan',
      },
      es: {
        contactUsInfo: {
          btnText: 'Contactenos',
          maxUsers: {
            text: 'Comuníquese con nosotros para aumentar el número máximo de usuarios de su organización.',
            title: 'Ha alcanzado la capacidad máxima de usuarios',
          },
          trialEnded: {
            text: 'Gracias por probar nuestro producto. Pongase en contacto con nosotros para seguir utilizando nuestros servicios.',
            title: 'Su periodo de prueba ha terminado',
          },
        },
        currentPlan: 'Plan actual',
      },
      fr: {
        contactUsInfo: {
          btnText: 'Nous contacter',
          maxUsers: {
            text: `Veuillez nous contacter pour augmenter le nombre maximum d'utilisateurs de votre organisation.`,
            title: `Vous avez atteint le nombre maximum d'utilisateurs`,
          },
          trialEnded: {
            text: `Merci d'avoir essayé notre produit. Veuillez nous contacter pour pouvoir continuer à utiliser nos services.`,
            title: 'Votre essai est terminé',
          },
        },
        currentPlan: 'Plan actuel',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.panels {
  .panel {
    &__row {
      display: flex;
      flex-direction: row;
    }

    &--contact-us {
      .cy-btn {
        align-self: center;
      }
    }

    ::v-deep .max-users {
      color: cy-get-color("primary");
      font-weight: $font-weight-bolder;
    }

    .plan-info {
      width: 44%;
      font-size: $font-size-sm;

      &--wide {
        width: 100%;
      }

      &--with-padding {
        width: 56%;
        padding-left: 73px;
      }

      &__text {
        display: inline-flex;
      }
    }
  }
}
</style>

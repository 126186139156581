import CyRouterLinkWrapper from '@/components/CyRouterLinkWrapper.vue'
import router from '@/router'

export const linkComponentInstances = []

export default {
  install (Vue) {
    Vue.directive('wrap-children-contents-in-link', {
      async inserted (
        root,
        {
          value: {
            selector,
            to,
            tabindexGetter,
            isEnabled,
          } = {},
        },
      ) {
        if (_.isEmpty(selector)) return console.warn('v-wrap-children-contents-in-link directive was passed an invalid "selector" option:', selector)
        if (!_.isBoolean(isEnabled)) return console.warn('v-wrap-children-contents-in-link directive was passed an invalid "isEnabled" option:', isEnabled)
        if (!isEnabled) return

        await Vue.nextTick()

        root.querySelectorAll(selector).forEach((parent, parentIndex) => {
          const LinkComponent = Vue.extend(CyRouterLinkWrapper)
          const linkInstance = new LinkComponent({
            router,
            propsData: {
              to,
              parentIndex,
              tabindexGetter,
            },
          }).$mount()
          linkComponentInstances.push(linkInstance)
          const linkEl = linkInstance.$el

          Array.from(parent.childNodes).forEach((childNode) => {
            linkEl.append(childNode)
          })

          parent.append(linkEl)
          parent.classList.add('router-link-wrapper__parent')

          // https://www.sarasoueidan.com/blog/nested-links/
          Array.from(linkEl.querySelectorAll('a')).forEach((nestedLink) => {
            const parentEl = nestedLink.parentElement
            Array.from(parentEl.childNodes).forEach((childNode) => {
              if (childNode.tagName?.toLowerCase() === 'a') {
                const objectEl = document.createElement('object')
                objectEl.setAttribute('type', 'owo/uwu')
                objectEl.append(childNode)
                parentEl.append(objectEl)
              } else {
                parentEl.append(childNode)
              }
            })
          })
        })
      },
    })
  },
}

<template>
  <div :class="['line', 'd-flex', { 'line--active': isActive }]">
    <a
      :href="`#${source.id}`"
      class="line-number d-flex"
      @mouseenter="$toggle.showCopyBtn(true)"
      @mouseleave="$toggle.showCopyBtn(false)">
      <CyUrlCopyButton
        v-if="showCopyBtn"
        :copy-value="copyUrl"
        right>
        <span>{{ getTime }}</span>
      </CyUrlCopyButton>
      <span v-else>{{ getTime }}</span>
    </a>
    <div v-html="anserize"/>
  </div>
</template>

<script>
import CyUrlCopyButton from '@/components/CyUrlCopyButton.vue'
import REGEX from '@/utils/config/regex'
import Anser from 'anser'

export default {
  name: 'CyViewsBuildLogLine',
  components: {
    CyUrlCopyButton,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    source: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showCopyBtn: false,
  }),
  computed: {
    anserize () {
      return Anser.ansiToHtml(this.$sanitizeHtml(this.source.log), { use_classes: true })
    },
    copyUrl () {
      const { $route, source: { id: sourceId } } = this
      if (location.href.indexOf('#') < 0) return `${location.href}#${sourceId}`
      return _.replace(location.href, $route.hash, `#${sourceId}`)
    },
    getTime () {
      return $date.format($date.fromUnixTime(_.get(this.source, 'time', 0)), 'HH:mm:ss')
    },
    isActive () {
      const [sourceId,, line] = this.$route.hash.match(REGEX.BUILD_LOG_HASH) || []
      return sourceId === `#${this.source.id}` && Number(line) === this.index
    },
  },
}
</script>

<style lang="scss" scoped>
.line {
  margin: 0 16px;
  transition: background-color 150ms ease-in-out;
  border-radius: 3px;

  &--active {
    background-color: cy-get-color("black");
  }
}

.line-number {
  flex: 0 1 auto !important;
  align-items: flex-start;
  min-width: 95px;
  height: auto;
  margin-right: 14px;
  padding: 0 4px;
  transition: background-color 150ms ease-in-out;
  border-radius: 3px;
  color: cy-get-color("grey", "dark-2");
  font-weight: $font-weight-default;
  white-space: nowrap;

  > span {
    display: flex;
    user-select: none;
  }

  ::v-deep .v-icon {
    display: inline-flex;
    visibility: hidden;
    position: relative;
    width: 12px;
    margin: 0 6px;
    transition: none;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: cy-get-color("grey", "dark-2");
    color: cy-get-color("grey", "terminal");

    ::v-deep .v-icon {
      visibility: visible;
    }
  }

  .line--active & {
    background-color: cy-get-color("accent", "light-1");
    color: cy-get-color("grey", "terminal");

    ::v-deep .v-icon {
      visibility: visible;

      &:focus,
      &:hover {
        color: cy-get-color("primary");
      }
    }
  }
}

.tooltip-content {
  &__text,
  &__icon {
    color: cy-get-color("white");
  }
}
</style>

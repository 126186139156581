<template>
  <div class="d-flex flex-column">
    <v-skeleton-loader
      v-if="loading"
      data-cy="loader-resource-id-field"
      class="my-4"
      :loading="loading"
      type="text"
      width="600"/>
    <CyTooltip
      v-else
      data-cy="tooltip"
      top
      :disabled="_.get(resourceId, 'length', 0) < 75">
      <template #activator="{ on }">
        <v-text-field
          :value="_.truncate(resourceId, { length: 75 })"
          data-cy="resource-id-field"
          label="ID"
          readonly
          v-on="on">
          <CyCopyButton
            slot="append"
            :copy-value="getValueForCopy(resourceId)"
            left
            small/>
        </v-text-field>
      </template>
      {{ resourceId }}
    </CyTooltip>

    <v-skeleton-loader
      v-if="loading"
      data-cy="loader-json-code-editor"
      class="my-4"
      :loading="loading"
      type="image"
      width="600"/>
    <CyCodeEditor
      v-else
      action-btn-icon="content_copy"
      :action-btn-tooltip="$t('copyJSON')"
      class="overflow-hidden rounded"
      data-cy="json-code-editor"
      read-only
      show-gutter
      :value="resourceJSON"
      @action-btn-clicked="$copyText(resourceJSON)"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyCodeEditor from '@/components/CyCodeEditor.vue'
import CyCopyButton from '@/components/CyCopyButton.vue'

export default {
  name: 'CyInventoryResourceJson',
  components: {
    CyCodeEditor,
    CyCopyButton,
  },
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('organization/inventory', [
      'getId',
    ]),
    resourceId () {
      return this.getId(this.resource)
    },
    resourceJSON () {
      return JSON.stringify(this.resource, null, 2)
    },
  },
  methods: {
    getValueForCopy (value) {
      const stringified = _.attempt(JSON.stringify.bind(null, value))
      return _.isError(stringified) ? '' : stringified
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.inventoryResourceJson',
        copyJSON: 'Copy @:untranslated.json',
      },
      es: {
        title: '@:routes.inventoryResourceJson',
        copyJSON: 'Copiar @:untranslated.json',
      },
      fr: {
        title: '@:routes.inventoryResourceJson',
        copyJSON: 'Copier @:untranslated.json',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .code-editor {
    &__action-btn {
      transition: opacity 0.6s ease;
      opacity: 0;
    }

    &:hover .code-editor__action-btn {
      opacity: 1;
    }
  }

  .cy-copy-btn .v-icon {
    margin-top: 2px;
  }
}
</style>

<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Total cost widget
    </h2>

    <CyCcmWidgetsTotalCost/>
  </div>
</template>

<script>
import CyCcmWidgetsTotalCost from '@/components/CyCcmWidgetsTotalCost.vue'

export default {
  name: 'CyCcmWidgetsTotalCostSandbox',
  components: {
    CyCcmWidgetsTotalCost,
  },
}
</script>

<template>
  <div class="account-config__layout">
    <div class="account-config__title">
      <h3>
        {{ $t('accountConfiguration') }}
      </h3>
    </div>
    <div class="account-config__content">
      <CyCcmFormsAccount
        :credential-type="getCredentialType(account.cloud_provider.canonical)"
        :disabled="isAccountDisabled"
        :value="account"
        @is-valid="$emit('is-valid', $event)"
        @change="emitNewFormData"/>
    </div>
  </div>
</template>

<script>
import CyCcmFormsAccount from '@/components/CyCcmFormsAccount.vue'
import { getCredentialType } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCcmAccountsConfiguration',
  components: {
    CyCcmFormsAccount,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFormValid: false,
  }),
  computed: {
    isAccountDisabled () {
      return this.disabled || !this.$cycloid.permissions.canDisplay('UpdateCloudCostManagementAccount')
    },
  },
  mounted () {
    this.$emit('is-valid', this.isFormValid)
  },
  methods: {
    getCredentialType,
    emitNewFormData (formData) {
      Object.entries(formData).forEach(([key, data]) => {
        this.$emit('change', { key, data })
      })
    },
  },
  i18n: {
    messages: {
      en: {
        accountConfiguration: 'Account configuration',
      },
      es: {
        accountConfiguration: 'Configuración de la cuenta',
      },
      fr: {
        accountConfiguration: 'Configuration du compte',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .account-config {
    &__layout {
      display: flex;
    }

    &__title {
      width: 315px;
    }

    &__content {
      width: 490px;
    }
  }
</style>

<template>
  <CyInputsDateTimeRange
    ref="dateTimeRangeInput"
    :relative-options="$static.options"
    :value="value"
    mandatory
    @change="inputChangeHandler">
    <template #activator="{ on, label }">
      <div
        class="date-range-filter px-4 py-3"
        v-on="on">
        <div>
          <div class="date-range-filter__title mb-2">
            {{ $t('dateRange') }}
          </div>
          {{ label }}
        </div>
        <v-icon>arrow_drop_down</v-icon>
      </div>
    </template>
  </CyInputsDateTimeRange>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CyInputsDateTimeRange from '@/components/CyInputsDateTimeRange.vue'

export default {
  name: 'CyCcmSidebarDateRangeFilter',
  components: {
    CyInputsDateTimeRange,
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      queryBody: (state) => state.queryBody,
    }),
    $static () {
      return {
        options: [
          {
            label: this.$t('forms.field.timeRangeLastWeek'),
            value: '1w',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 1),
            value: '1M',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 3, { months: 3 }),
            value: '3M',
          },
          {
            label: this.$t('forms.field.timeRangeLastYear'),
            value: '1y',
          },
        ],
      }
    },
    value: {
      get () {
        return _.mapValues(_.pick(this.queryBody, ['begin', 'end']), (dateString) => $date.parse(dateString, 'yyyy-MM-dd', new Date()).getTime())
      },
      set ({ value: { begin, end } }) {
        this.SET_QUERY_FILTER({ key: 'begin', value: $date.format(begin, 'yyyy-MM-dd') })
        this.SET_QUERY_FILTER({ key: 'end', value: $date.format(end, 'yyyy-MM-dd') })

        if ($date.isBefore($date.addMonths(new Date(begin), 3), new Date(end))) {
          this.SET_QUERY_FILTER({ key: 'granularity', value: 'month' })
        }

        this.$emit('input')
      },
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
    inputChangeHandler (value) {
      this.value = value
      this.$refs.dateTimeRangeInput.isOpen = false
    },
  },
  i18n: {
    messages: {
      en: {
        dateRange: 'Date range',
      },
      es: {
        dateRange: 'Rango de fechas',
      },
      fr: {
        dateRange: 'Plage de dates',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .date-range-filter {
    display: flex;
    justify-content: space-between;
    border: 1px solid cy-get-color("grey");
    border-radius: 4px;
    background-color: cy-get-color("white");
    cursor: pointer;

    .v-icon {
      color: cy-get-color("black");
    }

    &__title {
      color: cy-get-color("grey", "dark-2");
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;
    }
  }
</style>

import i18n from '@/utils/plugins/i18n'

export const WATCH_RULE_FILTER_ICONS = {
  Action: 'mdi-lightning-bolt',
  Entity: 'mdi-shape',
}

export const WATCH_RULE_FILTERS = [
  {
    type: 'select',
    label: i18n.t('forms.type'),
    queryParams: ['type', 'severity'],
    items: [
      ...['Cycloid', 'Custom'].map((type) => ({
        title: i18n.t('forms.type') + ': ' + i18n.t(`events.type.${type}`),
        key: 'type',
        value: type,
      })),
      ...['info', 'warn', 'err', 'crit'].map((severity) => ({
        title: _.capitalize(i18n.t('severities.severity')) + ': ' + i18n.t(`severities.${severity}`),
        key: 'severity',
        value: severity,
      })),
    ],
  },
  {
    type: 'dateTimeRange',
    queryParams: ['timestamp[gt]', 'timestamp[lt]'],
    label: i18n.t('forms.field.timeRange'),
  },
  {
    type: 'tags',
    label: i18n.t('untranslated.tags'),
  },
]

export const WATCH_RULE_SORT_OPTIONS = [
  {
    sortBy: ['timestamp'],
    sortDesc: [true],
  },
  {
    sortBy: ['timestamp'],
    sortDesc: [false],
  },
]

export const WATCH_RULE_TYPES = [
  {
    label: 'Project activity',
    name: i18n.t('watchRules.watchRuleTypes.project.name'),
    description: i18n.t('watchRules.watchRuleTypes.project.description'),
    icon: 'commit',
    color: 'prod',
    key: 'projectActivity',
  },
  {
    label: 'Event tracking',
    name: i18n.t('watchRules.watchRuleTypes.event.name'),
    description: i18n.t('watchRules.watchRuleTypes.event.description'),
    icon: 'podcasts',
    color: 'staging',
    key: 'eventTracking',
  },
]

<template>
  <div
    v-has-rights-to="'GetWorkers'"
    class="height-100">
    <CyWorkersEmpty
      v-if="!hasAvailable('workers') && loaded"
      @add="$toggle.showAddWorker(true)"/>

    <CyAlert
      v-if="hasAvailable('workers') && !hasRunningWorkers"
      class="my-2"
      theme="warning"
      :title="$t('noWorkerRunningTitle')"
      :content="$t('noWorkerRunning')"
      :button-label="$t('learnMore')"
      @click="navigateToDocs"/>

    <CyDataTableYdApi
      v-show="hasAvailable('workers')"
      ref="cyDataTable"
      :fetch-available="{ keyPath: 'workers' }"
      :headers="$static.headers"
      :searchable-fields="$static.searchableFields"
      key-field="fingerprint"
      @loaded="$toggle.loaded(true)">
      <CyButton
        icon="add"
        slot="table-cmp-header-actions"
        @click="$toggle.showAddWorker(true)">
        {{ $t('addWorker') }}
      </CyButton>

      <template #table-cmp-body-row="{ props }">
        <td>
          {{ props.item.name }}
        </td>
        <td>
          <CyTooltip
            class="state"
            right
            content-class="state__tooltip">
            <template #activator="{ on }">
              <span v-on="on">
                <CyTag
                  :variant="$static.stateTagVariants[props.item.state]"
                  element-type="div"
                  class="state__tag"
                  small>
                  {{ props.item.state }}
                </CyTag>
              </span>
            </template>
            {{ $t($static.stateTooltips[props.item.state]) }}
          </CyTooltip>
        </td>
        <td>
          <CyTooltip top>
            <template #activator="{ on }">
              <span v-on="on">
                <CyTag
                  variant="default"
                  class="worker-date"
                  small>
                  {{ getStartDate(props.item.start_time) }}
                </CyTag>
              </span>
            </template>
            {{ getDate(props.item.start_time) }}
          </CyTooltip>
        </td>
        <td class="text-right">
          {{ props.item.active_containers }}
        </td>
        <td class="text-right">
          {{ props.item.active_volumes }}
        </td>
        <td class="text-center">
          <v-icon
            v-if="props.item.ephemeral"
            color="success">
            check
          </v-icon>
        </td>
        <td class="text-right">
          {{ props.item.version }}
        </td>
        <td>
          <span v-if="props.item.tags">{{ props.item.tags.join(', ') }}</span>
        </td>
      </template>
    </CyDataTableYdApi>

    <CyWorkersAdd
      v-if="showAddWorker"
      @close="$toggle.showAddWorker(false)"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyDataTableYdApi from '@/components/CyDataTableYdApi.vue'
import CyWorkersAdd from '@/components/CyWorkersAdd.vue'
import CyWorkersEmpty from '@/components/CyWorkersEmpty.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export const REFRESH_INTERVAL_TIMER = 5000

export default {
  name: 'CyPageWorkers',
  components: {
    CyDataTableYdApi,
    CyWorkersAdd,
    CyWorkersEmpty,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.workers'))
  },
  data: () => ({
    showAddWorker: false,
    refreshIntervalID: null,
    loaded: false,
  }),
  computed: {
    ...mapGetters('organization', [
      'hasRunningWorkers',
      'hasAvailable',
    ]),
    $static () {
      return {
        stateTagVariants: {
          stalled: 'error',
          running: 'success',
          landing: 'warning',
          landed: 'warning',
          retiring: 'warning',
        },
        stateTooltips: {
          stalled: 'tooltipStalled',
          running: 'tooltipRunning',
          landing: 'tooltipLanding',
          landed: 'tooltipLanded',
          retiring: 'tooltipRetiring',
        },
        headers: [
          {
            text: this.$t('workerName'),
            value: 'name',
          },
          {
            text: this.$t('infra.State'),
            value: 'state',
          },
          {
            text: this.$t('startTime'),
            value: 'start_time',
          },
          {
            text: this.$t('activeContainers'),
            value: 'active_containers',
            align: 'right',
          },
          {
            text: this.$t('activeVolumes'),
            value: 'active_volumes',
            align: 'right',
          },
          {
            text: this.$t('ephemeral'),
            value: 'ephemeral',
            align: 'center',
          },
          {
            text: this.$t('version'),
            value: 'version',
            align: 'right',
          },
          {
            text: this.$t('untranslated.tags'),
            value: 'tags',
          },
        ],
        searchableFields: [
          { name: 'name', label: this.$t('workerName') },
        ],
      }
    },
  },
  mounted () {
    this.refreshIntervalID = window.setInterval(() => this.getWorkers(), REFRESH_INTERVAL_TIMER)
  },
  beforeDestroy () {
    window.clearInterval(this.refreshIntervalID)
  },
  methods: {
    getDate (date) {
      return $date.format($date.fromUnixTime(date), 'MM/dd/yyyy h:mm a')
    },
    getStartDate (date) {
      return this.$date.$formatTimeAgo(date)
    },
    async getWorkers () {
      await this.$refs.cyDataTable.retrieveItems()
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Workers configuration',
        activeContainers: 'Active containers',
        activeVolumes: 'Active volumes',
        addWorker: 'Add worker',
        ephemeral: 'Ephemeral',
        noWorkerRunning: 'You need at least one connected worker to trigger your pipelines.',
        noWorkerRunningTitle: `We have no 'running' workers.`,
        startTime: 'Start Time',
        tooltipLanded: 'A worker in this state has successfully waited for all non-interruptible jobs on it after having concourse land-worker called. It will no longer be used to schedule any new containers or create volumes until it registers as RUNNING again.',
        tooltipLanding: `The ATC will wait for builds on the worker for jobs which aren't interruptible to finish, and transition the worker into LANDED state`,
        tooltipRetiring: `The concourse retire-worker command will put a worker in the RETIRING state to remove it from the cluster permanently. The ATC will wait for builds on the worker for jobs which aren't interruptible to finish, and remove the worker.`,
        tooltipRunning: 'A worker in this state is registered with the cluster and ready to start running containers and storing volumes.',
        tooltipStalled: 'A worker in this state was previously registered with the cluster, but stopped advertising itself for some reason. Usually, this is due to network connectivity issues or the worker stopping unexpectedly.',
        version: 'Version',
        workerName: 'Worker Name',
      },
      es: {
        title: 'Configuración de los Workers',
        activeContainers: 'Contenedores activos',
        activeVolumes: 'Volumenes activos',
        addWorker: 'Agregar worker',
        ephemeral: 'Efímero',
        noWorkerRunning: 'Necesita al menos un worker en ejecución para desencadenar sus pipelines.',
        noWorkerRunningTitle: `No tenemos workers 'en funcionamiento'.`,
        startTime: 'Hora de inicio',
        tooltipLanded: 'Un worker en este estado ha esperado con éxito todos los tareas no interrumpibles. Ya no se usará para programar ningún contenedor nuevo o crear volúmenes hasta que se registre como RUNNING nuevamente.',
        tooltipLanding: 'l ATC esperará las construcciones en el worker para tareas que no son interrumpibles para terminar, y hará la transición del worker al estado ATERRIZADO',
        tooltipRetiring: 'El comando concourse retire-worker pondrá a un worker en estado RETIRADO para eliminarlo del clúster permanentemente. El ATC esperará las compilaciones en el jobs que no son interrumpibles para finalizar, y eliminará al worker.',
        tooltipRunning: 'Un worker en este estado está registrado en el clúster y listo para comenzar a ejecutar contenedores y almacenar volúmenes.',
        tooltipStalled: 'Un worker en este estado estaba previamente registrado en el clúster, pero dejó de anunciarse por algún motivo. Usualmente esto se debe a problemas de conectividad de red, o al worker que se detiene inesperadamente.',
        version: 'Versión',
        workerName: 'Nombre del worker',
      },
      fr: {
        title: 'Configuration des Workers',
        activeContainers: 'Containers actifs',
        activeVolumes: 'Volumes actifs',
        addWorker: 'Ajouter un worker',
        ephemeral: 'Ephémère',
        noWorkerRunning: `Vous avez besoin d'au moins un worker en cours d'exécution pour déclencher vos pipelines.`,
        noWorkerRunningTitle: `Nous n'avons pas de worker 'en fonction'.`,
        startTime: `Durée d'activité`,
        tooltipLanded: `Un worker dans cet état a correctement attendu la fin de toutes les tâches non-interruptibles. Il ne sera plus utilisé pour planifier de nouveaux conteneurs ou créer des volumes jusqu'à ce qu'il s'enregistre à nouveau comme RUNNING.`,
        tooltipLanding: `L'ATC attendra que le worker mette fin aux tâches non-interruptibles avant de s'interrompre, et passera le worker à l'état LANDED`,
        tooltipRetiring: `La commande concourse retire-worker mettra un worker dans l'état RETIRING pour le supprimer définitivement du cluster. L'ATC attendra que les builds de tâches non-interruptibles se terminent et supprimera le worker.`,
        tooltipRunning: 'Un worker dans cet état est enregistré auprès du cluster et prêt à commencer à exécuter des conteneurs et à stocker des volumes.',
        tooltipStalled: `Un worker dans cet état était auparavant inscrit auprès du cluster, mais a cessé de répondre pour une raison quelconque. Habituellement, cela est dû à des problèmes de connectivité réseau ou à l'arrêt inattendu du worker.`,
        version: 'Version',
        workerName: 'Nom du worker',
      },
    },
  },
}
</script>

<template>
  <div class="content flex-column mb-n8 mt-n6">
    <div class="table-header d-flex align-center rounded-tl-lg rounded-tr-lg">
      <CyDataTableSorts
        class="mr-3"
        :sorts="$static.sorts"/>

      <CyDataTableFilters :filters="$static.filters"/>

      <CyButton
        v-has-rights-to="'GetEvents'"
        class="ml-auto mr-6"
        :loading="btnRefreshLoading"
        variant="secondary"
        icon="refresh"
        @click="refreshEvents">
        {{ $t('forms.btnRefresh') }}
      </CyButton>
    </div>

    <CyDataTableTags
      v-if="!_.$isEmpty(activeFilters)"
      class="table-tags"
      :filters="$static.filters"
      :hide-values="[`project_canonical[in]:${projectCanonical}`]"/>

    <div class="data-positioner d-flex align-center justify-center flex-grow-1 rounded-bl-lg rounded-br-lg">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="secondary"/>
      <div
        v-else-if="!events.length"
        class="cy-event-empty-state text-center">
        <div class="cy-event-empty-state__heading h5">
          {{ $t('events.noEventsFound') }}
        </div>
        <div class="text-body mt-2">
          {{ $t('events.tryDifferent') }}
        </div>
      </div>
      <CyEventsListTable
        v-else
        :events="events"
        :active-filters="activeFilters"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import CyDataTableFilters from '@/components/CyDataTableFilters.vue'
import CyDataTableSorts from '@/components/CyDataTableSorts.vue'
import CyDataTableTags from '@/components/CyDataTableTags.vue'
import CyEventsListTable from '@/components/CyEventsListTable.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProjectEvents',
  components: {
    CyDataTableSorts,
    CyDataTableFilters,
    CyDataTableTags,
    CyEventsListTable,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this

    return constructBreadcrumb(this.$options.name, this.$t('routes.projectActivity'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  data: () => ({
    btnRefreshLoading: false,
    firstUpdate: true,
  }),
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
      'getDataTableFilters',
    ]),
    ...mapState('organization', {
      loading: (state) => state.fetchInProgress.events,
      events: (state) => state.available.events,
    }),
    ...mapGetters('organization/project', [
      'projectCanonical',
    ]),
    $static () {
      return {
        sorts: [
          { sortBy: ['timestamp'], sortDesc: [false] },
          { sortBy: ['timestamp'], sortDesc: [true] },
        ],
        defaultSort: { sortBy: ['timestamp'], sortDesc: [true] },
        filters: [
          {
            type: 'select',
            label: this.$t('fieldFilterEvents'),
            queryParams: ['type', 'severity'],
            items: [
              ...['AWS', 'Monitoring', 'Cycloid', 'Custom'].map((type) => ({
                title: this.$t('forms.type') + ': ' + this.$t(`events.type.${type}`),
                key: 'type',
                value: type,
              })),
              ...['info', 'warn', 'err', 'crit'].map((severity) => ({
                title: _.capitalize(this.$t('severities.severity')) + ': ' + this.$t(`severities.${severity}`),
                key: 'severity',
                value: severity,
              })),
            ],
          },
          {
            type: 'dateTimeRange',
            queryParams: ['begin', 'end'],
            label: this.$t('forms.field.timeRange'),
          },
          {
            type: 'tags',
            label: this.$t('untranslated.tags'),
          },
        ],
      }
    },
    activeFilters () {
      return this.getDataTableFilters(this.$route.name)
    },
    canSeeEvents () {
      return this.$cycloid.permissions.canDisplay('GetEvents')
    },
  },
  watch: {
    activeFilters: {
      handler (newFilters, oldFilters) {
        if (!_.isEqual(newFilters, oldFilters) && !this.firstUpdate) this.updateQuery()
        this.firstUpdate = false
      },
      deep: true,
    },
  },
  created () {
    this.SET_DATA_TABLE_PROPS({
      name: this.$route.name,
      props: {
        ...this.getDataTableProps(this.$route.name),
        ...this.$static.defaultSort,
      },
    })
    this.SET_DATA_TABLE_FILTERS({
      name: this.$route.name,
      filters: this.setupFilters(),
    })
    this.updateQuery()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
      'SET_DATA_TABLE_PROPS',
      'UPDATE_FILTERS_TIME_RANGE',
      'RESET_DATA_TABLE_PAGE',
    ]),
    async updateQuery () {
      const { begin, end, ...filters } = this.activeFilters
      const query = { ...filters, begin: String(begin), end: String(end) }
      if (!_.isEqual(query, this.$route.query || {})) this.$router.replace({ query }).catch(() => { /* silenced */ })
      if (this.canSeeEvents) {
        await this.FETCH_AVAILABLE({ keyPath: 'events', extraParams: [query] })
      }
    },
    async refreshEvents () {
      const previousFilters = { ...this.activeFilters }
      this.btnRefreshLoading = true
      const updatedCurrentTime = $date.format(Date.now(), 'T')
      this.UPDATE_FILTERS_TIME_RANGE({ name: this.$route.name, time: String(updatedCurrentTime) })
      if (_.isEqual(previousFilters, this.activeFilters)) this.updateQuery()
      this.RESET_DATA_TABLE_PAGE({ name: this.$route.name })
      this.btnRefreshLoading = false
    },
    setupFilters () {
      const { begin = null, end = null, 'type[in]': types, 'severity[in]': severities, ...rest } = this.$route.query
      const nowTimestamp = Date.now()
      const sevenDaysAgoTimestamp = $date.subDays(nowTimestamp, 7)
      const sevenDaysAgo = $date.format(sevenDaysAgoTimestamp, 'T')
      const currentTime = $date.format(nowTimestamp, 'T')

      return {
        begin: String(begin || sevenDaysAgo),
        end: String(end || currentTime),
        'project_canonical[in]': this.projectCanonical,
        ...(types ? { 'type[in]': types } : null),
        ...(severities ? { 'severity[in]': severities } : null),
        ...rest,
      }
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:Activity',
        fieldFilterEvents: 'Event type',
        filteredBy: 'filtered by',
      },
      es: {
        title: '@:Activity',
        fieldFilterEvents: 'Tipo de evento',
        filteredBy: 'filtrado por',
      },
      fr: {
        title: '@:Activity',
        fieldFilterEvents: `Type d'événement`,
        filteredBy: 'filtré par',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {
  position: relative;
  min-height: 60px;
  background-color: transparent;

  &::after {
    @extend .table-line;

    bottom: 0;
  }
}

.table-tags {
  position: relative;
  padding: 8px 0;
  border-bottom: 0;
  background-color: transparent;

  &::after {
    @extend .table-line;

    bottom: 0;
  }
}

.data-positioner {
  position: relative;
  background-color: transparent;
}

.content {
  display: flex;
  flex: 1 1 auto;
}

.table-line {
  content: "";
  position: absolute;
  left: -32px;
  width: calc(100% + 64px);
  height: 1px;
  background-color: map.get($grey, "light-2");
}
</style>

<template>
  <v-card
    v-if="_.isEmpty(owner)"
    outlined
    class="owner-card--empty">
    <v-card-text class="owner-card__content">
      <v-avatar
        size="32"
        color="#90A5C1">
        <v-icon color="white">
          no_accounts
        </v-icon>
      </v-avatar>
      <div class="owner-card__header align-center">
        {{ _.capitalize($t('noOwner')) }}
      </div>
    </v-card-text>
  </v-card>
  <v-card
    v-else
    outlined
    class="owner-card space-y-2">
    <v-card-text class="owner-card__content">
      <CyAvatar :item="owner"/>
      <div class="flex-grow-1">
        <div class="owner-card__header">
          {{ displayName(owner) }}
        </div>
        <div class="owner-card__metadata">
          <CyHeaderMetadata
            :label="owner.username"/>
          <CyHeaderMetadata
            :label="owner.email"/>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import { displayName } from '@/utils/helpers'

export default {
  name: 'CyOwnerCard',
  components: {
    CyHeaderMetadata,
  },
  props: {
    owner: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    displayName,
  },
}
</script>

<style lang="scss">
.owner-card {
  &--empty {
    border: 1px dashed !important;
    border-radius: 4px !important;
    border-color: cy-get-color("primary", "light-4") !important;
    background-color: transparent !important;
  }

  &__content {
    display: flex;
    gap: $spacer-4;
  }

  &__avatar {
    display: flex;
    align-items: center;
  }

  &__header {
    color: cy-get-color("primary");
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: $line-height-sm;
  }

  &__metadata {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>

<template>
  <div>
    <ul
      aria-label="Filter tags"
      class="filter-tags">
      <li
        v-for="[category, values] in _.entries(filters)"
        :key="category"
        aria-lable="Filter tag"
        class="filter-tag">
        <CyTag
          class="filter-tag__category"
          variant="default">
          <v-icon
            v-if="getCategoryIcon(category)"
            class="filter-tag__icon"
            :size="16">
            {{ getCategoryIcon(category) }}
          </v-icon>
          {{ category }}
        </CyTag>
        <CyTag
          class="filter-tag__transition"
          variant="default">
          {{ values.length > 1 ? $t('isAnyOf') : $t('is') }}
        </CyTag>
        <CyTag
          class="filter-tag__value"
          variant="default">
          {{ values.length > 1 ? $tc('nSelected', values.length) : _.head(values) }}
        </CyTag>
        <CyTag
          v-if="!readOnly"
          class="filter-tag__close"
          variant="default">
          <v-icon
            aria-label="Remove filter"
            size="20"
            @click="removeFilterCategory(category)">
            close
          </v-icon>
        </CyTag>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CyFilterTagsList',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    filterIcons: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filters () {
      return this.value.reduce((acc, filter) => {
        const [category, filterName] = filter.split('.')
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(filterName)
        return acc
      }, {})
    },
  },
  methods: {
    removeFilterCategory (category) {
      this.$emit('input', this.value.filter((filter) => !filter.startsWith(category)))
    },
    getCategoryIcon (category) {
      return this.filterIcons?.[category]
    },
  },
  i18n: {
    messages: {
      en: {
        is: 'is',
        isAnyOf: 'is any of',
        nSelected: '{n} selected',
      },
      es: {
        is: 'es',
        isAnyOf: 'es cualquiera de',
        nSelected: '{n} seleccionados',
      },
      fr: {
        is: 'est',
        isAnyOf: `est l'un de`,
        nSelected: '{n} sélectionnés',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-tags {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;

  .filter-tag {
    margin-right: 8px;
    margin-bottom:  8px;

    &__icon {
      margin-right: 6px;
      color: cy-get-color('grey', 'dark-3');
    }

    &__category,
    &__value {
      font-weight: $font-weight-bolder;
    }

    &__category {
      margin-right: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__transition {
      border-radius: 0;
    }

    &__value {
      margin: 0 1px;
      border-radius: 0;
    }

    &__close {
      height: 21px;
      padding: 0 2px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>

import * as components from '@/global/components'
import { throwBetterError } from '@/utils/helpers'

function install (Vue) {
  for (const [componentName, component] of Object.entries(components)) {
    try {
      Vue.component(componentName, component)
    } catch (error) {
      throwBetterError(new Error(error), install)
    }
  }
}

export default { install }

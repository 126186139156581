/**
 * These public pages need to be loaded in a traditional way
 * else we would get flash of the Dashboard on these routes
 */
import Vue from 'vue'
import store from '@/store'
import REGEX from '@/utils/config/regex'
import { getValidOrganization } from '@/utils/helpers'
import {
  gtmOrganizationsEvents,
  gtmObservabilityEvents,
  gtmProjectsEvents,
  gtmSecurityEvents,
  gtmStacksEvents,
} from '@/utils/helpers/analytics'
import i18n from '@/utils/plugins/i18n'
import { actionsWithResources } from './routesPermissions'

const alphaRoutes = []
const betaAppSections = [
  'dev',
]

const fitOnScreen = true
const handlerPage = true
const hasConditionalBreadcrumb = true
const hideFooter = true
const isListPage = true
const isPublic = true
const isTwoPane = true
const noPadding = true
const remainOnPageAfterOrgSwitch = true
const showBackBtn = true

/** Only works on project routes atm */
const noTabs = true

const isStagingDevOrTesting = ['development', 'staging', 'test'].includes(process.env.NODE_ENV)

const $showFeature = (key) => store.getters['dev/$showFeature']?.[key] // eslint-disable-line no-unused-vars

const dashboard = {
  path: 'dashboard',
  name: 'dashboard',
  component: () => import('@/pages/CyPageDashboard.vue'),
  meta: {
    ...getMetaDefaults('dashboard', {
      permissionsKey: 'organization.dashboard',
    }),
    layout: {
      name: 'app',
    },
  },
  beforeEnter: (to, _from, next) => {
    const isLegacyURL = _.isEmpty(to.params.orgCanonical)
    if (!isLegacyURL) return next()
    const { orgCanonical } = store.getters
    next({ name: to.name, params: { orgCanonical } })
  },
}

const organizations = {
  path: '/organizations',
  name: 'organizations',
  component: () => import('@/pages/CyPageOrganizations.vue'),
  meta: {
    ...getMetaDefaults('organizations', {
      permissionsKey: 'organizations.index',
      isListPage,
      remainOnPageAfterOrgSwitch,
    }),
    layout: {
      name: 'app',
      fitOnScreen,
    },
  },
}

// ! DEPRECATED routes
// Sync removal of each redirect with publicly communicating the removal to customers
// We use these redirects instead of alias, so the route is converted to the correct one.
export const redirects = [
  {
    path: '/organizations/manage',
    redirect: (to) => ({ ...to, name: 'organizations' }),
  },
  {
    path: '/organizations/projects/:orgCanonical/manage',
    redirect: (to) => ({ ...to, name: 'projects' }),
  },
  {
    path: '/organizations/projects/:orgCanonical/:projectCanonical',
    redirect: (to) => ({ ...to, name: 'project' }),
  },
  {
    path: '/organizations/:orgCanonical/projects/:projectCanonical/summary',
    redirect: (to) => ({ ...to, name: 'projectMetrics' }),
  },
  {
    path: '/dashboard',
    redirect: (to) => ({ ...to, name: 'dashboard' }),
  },
  {
    path: '/organizations/:orgCanonical/configuration/general',
    redirect: (to) => ({ ...to, name: 'orgSettings' }),
  },
  {
    path: '/organizations/:orgCanonical/configuration/terraform',
    redirect: (to) => ({ ...to, name: 'orgSettingsTerraform' }),
  },
  {
    path: '/organizations/:orgCanonical/configuration/workers',
    redirect: (to) => ({ ...to, name: 'workers' }),
  },
  {
    path: '/organizations/:orgCanonical/stacks/from-template',
    redirect: (to) => ({ ...to, name: 'stackFromBlueprint' }),
  },
  {
    path: '/organizations/:orgCanonical/projects/:projectCanonical/pipelines/:pipelineCanonical',
    redirect: (to) => {
      const { params: { projectCanonical, pipelineCanonical } } = to
      const envCanonical = pipelineCanonical.replace(`${projectCanonical}-`, '')
      return { ...to, name: 'pipeline', params: { ...to.params, envCanonical } }
    },
  },
  {
    path: '/organizations/:orgCanonical/projects/:projectCanonical/pipelines/:pipelineCanonical/jobs/:jobCanonical/builds/:buildId',
    redirect: (to) => {
      const { params: { projectCanonical, pipelineCanonical } } = to
      const envCanonical = pipelineCanonical.replace(`${projectCanonical}-`, '')
      return { ...to, name: 'builds', params: { ...to.params, envCanonical } }
    },
  },
]

// Nested inside of org route
const catalogRepositories = [
  {
    path: 'catalog-repositories',
    name: 'catalogRepositories',
    component: () => import('@/pages/CyPageCatalogRepositories.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('catalogRepositories', {
        permissionsKey: 'catalogRepositories.index',
        isListPage,
      }),
      layout: { name: 'app' },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmStacksEvents.stacksCatalogRepositories)
      },
    },
  },
  {
    path: 'catalog-repositories/create',
    name: 'newCatalogRepository',
    component: () => import('@/pages/CyPageCatalogRepositoryForm.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('catalogRepositories', {
        permissionsKey: 'catalogRepositories.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
  },
  {
    path: 'catalog-repositories/:catalogRepositoryCanonical',
    name: 'catalogRepository',
    component: () => import('@/pages/CyPageCatalogRepository.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('catalogRepositories', {
        permissionsKey: 'catalogRepositories.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
        noPadding,
        header: () => import('@/components/CyHeaderVariantCatalogRepository.vue'),
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
  {
    path: 'catalog-repositories/:catalogRepositoryCanonical/edit',
    name: 'editCatalogRepository',
    component: () => import('@/pages/CyPageCatalogRepositoryForm.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('catalogRepositories', {
        permissionsKey: 'catalogRepositories.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const configRepositories = [
  {
    path: 'config-repositories',
    name: 'configRepositories',
    component: () => import('@/pages/CyPageConfigRepositories.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('configRepositories', {
        permissionsKey: 'configRepositories.index',
        isListPage,
      }),
      layout: { name: 'app' },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmProjectsEvents.configRepositories)
      },
    },
  },
  {
    path: 'config-repositories/create',
    name: 'newConfigRepository',
    component: () => import('@/pages/CyPageConfigRepository.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('configRepositories', {
        permissionsKey: 'configRepositories.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
  },
  {
    path: 'config-repositories/:configRepositoryCanonical',
    name: 'configRepository',
    props: true,
    component: () => import('@/pages/CyPageConfigRepository.vue'),
    meta: {
      ...getMetaDefaults('configRepositories', {
        permissionsKey: 'configRepositories.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const credentials = [
  {
    path: 'credentials',
    name: 'credentials',
    component: () => import('@/pages/CyPageCredentials.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('credentials', {
        permissionsKey: 'credentials.index',
        isListPage,
      }),
      layout: { name: 'app' },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmSecurityEvents.securityCredentials)
      },
    },
  },
  {
    path: 'credentials/create',
    name: 'newCredential',
    component: () => import('@/pages/CyPageCredential.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('credentials', {
        permissionsKey: 'credentials.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
  },
  {
    path: 'credentials/:credentialCanonical',
    name: 'credential',
    component: () => import('@/pages/CyPageCredential.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('credentials', {
        permissionsKey: 'credentials.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const members = [
  {
    path: 'members',
    name: 'members',
    component: () => import('@/pages/CyPageMembers.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('members', {
        permissionsKey: 'members.index',
        isListPage,
      }),
      layout: {
        name: 'app',
        header: () => import('@/components/CyHeaderVariantOrganization.vue'),
      },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmOrganizationsEvents.organizationsMembers)
      },
    },
  },
  {
    path: 'members/invite',
    name: 'memberInvite',
    component: () => import('@/pages/CyPageMemberInvite.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('members', {
        permissionsKey: 'members.invite',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
  },
  {
    path: 'members/pending-invites',
    name: 'pendingInvites',
    component: () => import('@/pages/CyPagePendingInvites.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('pendingInvites', {
        permissionsKey: 'members.pendingInvites',
        isListPage,
        backRouteTo: 'members',
        showBackBtn,
      }),
      layout: {
        name: 'app',
      },
    },
  },
  {
    path: 'members/:id',
    name: 'member',
    component: () => import('@/pages/CyPageMember.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('members', {
        permissionsKey: 'members.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const observability = [
  {
    path: 'observability',
    name: 'observabilitySection',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => ({
      ...route.params,
      routeName: 'observabilitySection',
    }),
    meta: {
      ...getMetaDefaults('pipelinesOverview'),
      layout: { name: 'app' },
      tabs: () => ['events', 'pipelinesOverview'],
    },
    children: [
      {
        path: 'pipelines',
        name: 'pipelinesOverview',
        component: () => import('@/pages/CyPagePipelinesOverview.vue'),
        meta: {
          ...getMetaDefaults('pipelinesOverview', {
            permissionsKey: 'pipelines.index',
            isListPage,
          }),
          layout: { name: 'app' },
          clickAction: () => {
            Vue.prototype.$gtm.trackEvent(gtmObservabilityEvents.observabilityPipelinesOverview)
          },
        },
      },
      {
        path: 'events',
        name: 'events',
        component: () => import('@/pages/CyPageEvents.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('events', {
            permissionsKey: 'events.index',
            isListPage,
          }),
          layout: {
            name: 'app',
            fitOnScreen,
            hideFooter,
          },
          clickAction: () => {
            Vue.prototype.$gtm.trackEvent(gtmObservabilityEvents.observability)
          },
        },
      },
    ],
  },
]
const projects = [
  {
    path: 'projects',
    name: 'projectsSection',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => {
      const tabsToHide = ['configRepository', 'newConfigRepository']

      return {
        ...route.params,
        routeName: 'projectsSection',
        hideTabs: tabsToHide.includes(route.name),
      }
    },
    meta: {
      ...getMetaDefaults('projects'),
      layout: { name: 'app' },
      tabs: () => ['projects', 'configRepositories'],
    },
    children: [
      {
        path: 'all-projects',
        name: 'projects',
        component: () => import('@/pages/CyPageProjects.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'projects.index',
            isListPage,
          }),
          layout: { name: 'app' },
          clickAction: () => {
            Vue.prototype.$gtm.trackEvent(gtmProjectsEvents.projects)
          },
        },
        beforeEnter: (to, _from, next) => {
          const isLegacyURL = to.path.startsWith('/projects')
          if (!isLegacyURL) return next()
          const [,, orgCanonical] = to.path.split('/')
          next({ name: to.name, params: { orgCanonical } })
        },
      },
      ...configRepositories,
    ],
  },
  {
    path: 'projects/from-stack',
    name: 'projectFromStack',
    component: () => import('@/pages/CyPageProjectFromStack.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('projects', {
        permissionsKey: 'projects.index',
        backRouteTo: 'projects',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
  },
  {
    path: 'projects/:projectCanonical',
    name: 'project',
    component: () => import('@/pages/CyPageProject.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('projects', {
        permissionsKey: 'project.details',
        showBackBtn,
      }),
      layout: { name: 'app' },
    },
    beforeEnter: (to, _from, next) => {
      const isLegacyURL = to.path.startsWith('/projects')
      if (!isLegacyURL) return redirectTo404IfHasInvalidParam(to, next)
      const [,, orgCanonical, projectCanonical] = to.path.split('/')
      next({ name: 'project', params: { orgCanonical, projectCanonical } })
    },
    children: [
      {
        path: 'empty',
        name: 'projectEmpty',
        component: () => import('@/pages/CyPageProjectEmpty.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'project.details',
            showBackBtn,
            backRouteTo: 'projects',
          }),
          layout: {
            name: 'app',
            header: () => import('@/components/CyHeaderVariantProject.vue'),
          },
          noTabs,
        },
      },
      {
        path: 'service/create',
        name: 'newService',
        component: () => import('@/pages/CyPageServiceWizard.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'project.create',
            showBackBtn,
            hasConditionalBreadcrumb,
          }),
          layout: { name: 'app' },
          noTabs,
        },
        beforeEnter ({ params: { orgCanonical } }, _from, next) {
          return store.getters['organization/project/hasProject']
            ? next()
            : next({ name: 'projects', params: { orgCanonical }, replace: true })
        },
      },
      {
        path: 'service/addEnv',
        name: 'addServiceEnv',
        component: () => import('@/pages/CyPageEnvironmentCreate.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'project.environments.update',
            backRouteTo: 'projectEnvironments',
            showBackBtn,
            hasConditionalBreadcrumb,
          }),
          layout: { name: 'app' },
          noTabs,
        },
      },
      {
        path: 'metrics',
        name: 'projectMetrics',
        component: () => import('@/pages/CyPageProjectMetrics.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'project.metrics',
            showBackBtn,
          }),
          layout: {
            name: 'app',
            header: () => import('@/components/CyHeaderVariantProject.vue'),
          },
        },
      },
      {
        path: 'environments/:envCanonical',
        name: 'environment',
        component: () => import('@/pages/CyPageEnvironment.vue'),
        redirect: (to) => {
          const { envCanonical, projectCanonical, pipelineCanonical } = to.params
          return {
            ...to,
            name: 'pipeline',
            params: {
              ...to.params,
              pipelineCanonical: pipelineCanonical || `${projectCanonical}-${envCanonical}`,
            },
          }
        },
        props: true,
        meta: {
          ...getMetaDefaults('environments'),
          layout: { name: 'app' },
        },
        children: [
          {
            path: 'pipelines/:pipelineCanonical?',
            name: 'pipeline',
            component: () => import('@/pages/CyPagePipelines.vue'),
            props: (route) => ({
              ...route.params,
              pipelineCanonical: route.params.pipelineCanonical ?? `${route.params.projectCanonical}-${route.params.envCanonical}`,
            }),
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.pipelines.index',
              }),
              layout: {
                name: 'app',
                fitOnScreen,
                header: () => import('@/components/CyHeaderVariantEnvironment.vue'),
                hideFooter,
              },
            },
            beforeEnter: (to, _from, next) => {
              if (to.params.pipelineCanonical?.startsWith('start-stop-')) {
                to.meta.layout.header = null
                to.meta.showBackBtn = true
                to.meta.backRouteTo = 'projectConfigurationEnvironment'
                to.meta.noTabs = true
              } else {
                to.meta.layout.header = () => import('@/components/CyHeaderVariantEnvironment.vue')
                to.meta.showBackBtn = false
                to.meta.backRouteTo = undefined
                to.meta.noTabs = false
              }
              next()
            },
          },
          {
            path: 'pipelines/:pipelineCanonical?/jobs/:jobCanonical',
            name: 'jobs',
            component: () => import('@/pages/CyPageBuilds.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.pipelines.builds',
                backRouteTo: 'pipeline',
                showBackBtn,
              }),
              layout: { name: 'app' },
              noTabs,
            },
          },
          {
            path: 'pipelines/:pipelineCanonical?/jobs/:jobCanonical/builds/:buildId?',
            name: 'builds',
            component: () => import('@/pages/CyPageBuilds.vue'),
            props: (route) => ({
              ...route.params,
              ...setIdProp(route),
            }),
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.pipelines.builds',
                backRouteTo: 'pipeline',
                showBackBtn,
              }),
              layout: { name: 'app' },
              noTabs,
            },
          },
          {
            path: 'pipelines/:pipelineCanonical?/resources/:resourceCanonical',
            name: 'resource',
            component: () => import('@/pages/CyPageResource.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.pipelines.resources',
                backRouteTo: 'pipeline',
                showBackBtn,
              }),
              layout: { name: 'app' },
              noTabs,
            },
          },
          {
            path: 'infrastructure',
            name: 'infraView',
            component: () => import('@/pages/CyPageInfraview.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.infraview.index',
              }),
              layout: {
                name: 'app',
                fitOnScreen,
                header: () => import('@/components/CyHeaderVariantEnvironment.vue'),
                hideFooter,
              },
            },
          },
          {
            path: 'logs',
            name: 'logs',
            component: () => import('@/pages/CyPageLogs.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.logs.index',
              }),
              layout: {
                name: 'app',
                header: () => import('@/components/CyHeaderVariantEnvironment.vue'),
              },
            },
          },
          {
            path: 'metrics',
            name: 'environmentMetrics',
            component: () => import('@/pages/CyPageEnvironmentMetrics.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.metrics',
              }),
              layout: {
                name: 'app',
                header: () => import('@/components/CyHeaderVariantEnvironment.vue'),
              },
            },
          },
          {
            path: 'activity',
            name: 'environmentActivity',
            component: () => import('@/pages/CyPageEnvironmentEvents.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('events', {
                permissionsKey: 'events.index',
                isListPage,
              }),
              layout: {
                name: 'app',
                header: () => import('@/components/CyHeaderVariantEnvironment.vue'),
                fitOnScreen,
                hideFooter,
              },
            },
          },
          {
            path: 'config',
            name: 'environmentConfig',
            component: () => import('@/pages/CyPageEnvironmentConfig.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.environments.index',
              }),
              layout: {
                name: 'app',
                header: () => import('@/components/CyHeaderVariantEnvironment.vue'),
                fitOnScreen,
                hideFooter,
              },
            },
            beforeEnter: (to, _from, next) => {
              redirectTo404IfHasInvalidParam(to, next)
            },
          },
        ],
      },
      {
        path: 'environments',
        name: 'projectEnvironments',
        component: () => import('@/pages/CyPageEnvironments.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'project.environments.index',
            showBackBtn,
          }),
          layout: {
            name: 'app',
            header: () => import('@/components/CyHeaderVariantProject.vue'),
            hideFooter,
          },
        },
      },
      {
        path: 'environments/:envCanonical/clone',
        name: 'environmentClone',
        component: () => import('@/pages/CyPageEnvironmentCreate.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'project.environments.update',
            backRouteTo: 'projectEnvironments',
            showBackBtn,
          }),
          layout: { name: 'app' },
          noTabs,
        },
        beforeEnter: (to, _from, next) => {
          redirectTo404IfHasInvalidParam(to, next)
        },
      },
      {
        path: 'configuration',
        name: 'projectConfiguration',
        component: () => import('@/pages/CyPageProjectConfiguration.vue'),
        redirect: { name: 'projectConfigurationGeneral' },
        props: true,
        meta: {
          ...getMetaDefaults('projects', {
            permissionsKey: 'project.configuration',
            showBackBtn,
          }),
          layout: { name: 'app' },
          noTabs,
        },
        children: [
          {
            path: 'general',
            name: 'projectConfigurationGeneral',
            component: () => import('@/pages/CyPageProjectConfigurationGeneral.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.configuration',
                backRouteTo: 'project',
                showBackBtn,
              }),
              layout: {
                name: 'app',
                hideFooter,
              },
              noTabs,
            },
          },
          {
            path: 'logs',
            name: 'projectConfigurationLogs',
            component: () => import('@/pages/CyPageProjectConfigurationLogs.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.configuration',
                backRouteTo: 'project',
                showBackBtn,
              }),
              layout: {
                name: 'app',
                hideFooter,
              },
              noTabs,
            },
          },
          {
            path: 'infraview/create/:envCanonical',
            name: 'newProjectConfigInfraView',
            component: () => import('@/components/CyProjectConfigurationDetail.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.infraview.create',
                backRouteTo: 'projectConfigurationEnvironment',
                showBackBtn,
              }),
              layout: {
                name: 'app',
                variant: 'full-width',
              },
              noTabs,
            },
          },
          {
            path: 'infraview/:externalBackendId',
            name: 'projectConfigInfraView',
            component: () => import('@/components/CyProjectConfigurationDetail.vue'),
            props: (route) => ({ ...route.params, ...setIdProp(route) }),
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.infraview.details',
                backRouteTo: 'projectConfigurationEnvironment',
                showBackBtn,
              }),
              layout: {
                name: 'app',
                variant: 'full-width',
                hideFooter,
              },
              noTabs,
            },
            beforeEnter: (to, _from, next) => {
              redirectTo404IfHasInvalidParam(to, next)
            },
          },
          {
            path: 'logs/elasticsearch/create',
            name: 'newProjectConfigElasticSearch',
            component: () => import('@/pages/CyPageProjectConfigurationEsDetails.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.logs.elasticsearch.create',
                backRouteTo: 'projectConfigurationLogs',
                showBackBtn,
              }),
              layout: {
                name: 'app',
                variant: 'full-width',
              },
              noTabs,
            },
          },
          {
            path: 'logs/elasticsearch/:externalBackendId',
            name: 'projectConfigElasticSearch',
            component: () => import('@/pages/CyPageProjectConfigurationEsDetails.vue'),
            props: (route) => ({ ...route.params, ...setIdProp(route) }),
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.logs.elasticsearch.details',
                backRouteTo: 'projectConfigurationLogs',
                showBackBtn,
              }),
              layout: {
                name: 'app',
                variant: 'full-width',
              },
              noTabs,
            },
            beforeEnter: (to, _from, next) => {
              redirectTo404IfHasInvalidParam(to, next)
            },
          },
          {
            path: 'environments/:envCanonical',
            name: 'projectConfigurationEnvironment',
            component: () => import('@/pages/CyPageProjectConfigurationEnvironment.vue'),
            props: true,
            meta: {
              ...getMetaDefaults('projects', {
                permissionsKey: 'project.configuration',
                backRouteTo: 'projectEnvironments',
                showBackBtn,
              }),
              layout: {
                name: 'app',
                hideFooter,
              },
              noTabs,
            },
          },
        ],
      },
      {
        path: 'activity',
        name: 'projectActivity',
        component: () => import('@/pages/CyPageProjectEvents.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('events', {
            permissionsKey: 'events.index',
            isListPage,
            showBackBtn,
            backRouteTo: 'projects',
          }),
          layout: {
            name: 'app',
            header: () => import('@/components/CyHeaderVariantProject.vue'),
            fitOnScreen,
            hideFooter,
          },
        },
      },
    ],
  },
]
const cloudCostManagement = [
  {
    path: 'finops-greenops',
    name: 'cloudCostManagementSection',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => {
      const importingAccounts = store.getters['organization/cloudCostManagement/getImportingAccounts']
      const erroredAccounts = store.getters['organization/cloudCostManagement/getErroredAccounts']

      return {
        ...route.params,
        routeName: 'cloudCostManagementSection',
        hideTabs: /^cloudCostManagementAccounts.+/.test(route.name) || ['cloudCostManagementWelcome', 'cloudCostManagementProviderDetail'].includes(route.name),
        tabsIcons: [
          {
            condition: !_.isEmpty(importingAccounts),
            name: 'autorenew',
            color: 'accent',
            targetTab: 'cloudCostManagementAccounts',
            tooltip: i18n.tc('cloudCostManagement.importingAccounts', importingAccounts.length, { nb: importingAccounts.length }),
          },
          {
            condition: !_.isEmpty(erroredAccounts),
            name: 'mdi-alert-outline',
            color: 'error',
            targetTab: 'cloudCostManagementAccounts',
            tooltip: i18n.tc('cloudCostManagement.erroredAccounts', erroredAccounts.length, { nb: erroredAccounts.length }),
          },
        ],
      }
    },
    meta: {
      ...getMetaDefaults('cloudCostManagement', {
        permissionsKey: 'cloudCostManagement.index',
      }),
      layout: { name: 'app' },
      tabs: () => [
        'cloudCostManagementDashboard',
        'cloudCostManagementAccounts',
        'cloudCostManagementTagMapping',
      ],
    },
    beforeEnter: async ({ name, params }, _from, next) => {
      const shouldRedirect = name !== 'cloudCostManagementWelcome' && !(await hasCCMAccounts())
      if (shouldRedirect) return next({ name: 'cloudCostManagementWelcome', params })
      if (_.isEmpty(store.state.organization.available.cloudProviders)) {
        store.dispatch('organization/FETCH_AVAILABLE', { keyPath: 'cloudProviders' })
      }
      return next()
    },
    children: [
      {
        path: 'dashboard',
        name: 'cloudCostManagementDashboard',
        component: () => import('@/pages/CyPageCloudCostManagementDashboard.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.index',
          }),
          layout: { name: 'app' },
        },
        beforeEnter: handleCCMRedirect,
      },
      {
        path: 'accounts',
        name: 'cloudCostManagementAccounts',
        component: () => import('@/pages/CyPageCloudCostManagementAccounts.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.accounts.list',
          }),
          layout: { name: 'app' },
        },
        beforeEnter: handleCCMRedirect,
      },
      {
        path: 'accounts/credentials',
        name: 'cloudCostManagementAccountsCredentials',
        component: () => import('@/pages/CyPageCloudCostManagementAccountsCredentials.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.accounts.create',
          }),
          showBackBtn,
          backRouteTo: 'cloudCostManagementAccounts',
          layout: { name: 'app' },
        },
      },
      {
        path: 'accounts/new',
        name: 'cloudCostManagementAccountsNew',
        component: () => import('@/pages/CyPageCloudCostManagementAccountsNew.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.accounts.create',
          }),
          showBackBtn,
          backRouteTo: 'cloudCostManagementAccountsCredentials',
          layout: {
            name: 'app',
            hideFooter,
          },
        },
      },
      {
        path: 'accounts/:accountCanonical',
        name: 'cloudCostManagementAccountsAccount',
        component: () => import('@/pages/CyPageCloudCostManagementAccountsAccount.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.accounts.details',
          }),
          showBackBtn,
          backRouteTo: 'cloudCostManagementAccounts',
          layout: {
            name: 'app',
            hideFooter,
          },
        },
        beforeEnter: handleCCMRedirect,
      },
      {
        path: 'config/tag-mapping',
        name: 'cloudCostManagementTagMapping',
        component: () => import('@/pages/CyPageCloudCostManagementTagMapping.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.tagMapping',
          }),
          layout: {
            name: 'app',
            hideFooter,
          },
        },
        beforeEnter: handleCCMRedirect,
      },
      {
        path: ':providerCanonical/provider-detail',
        name: 'cloudCostManagementProviderDetail',
        component: () => import('@/pages/CyPageCloudCostManagementProviderDetail.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.index',
          }),
          showBackBtn,
          backRouteTo: 'cloudCostManagementDashboard',
          layout: { name: 'app' },
        },
        beforeEnter: handleCCMRedirect,
      },
      {
        path: 'welcome',
        name: 'cloudCostManagementWelcome',
        component: () => import('@/pages/CyPageCloudCostManagementWelcome.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('cloudCostManagement', {
            permissionsKey: 'cloudCostManagement.index',
          }),
          layout: {
            name: 'app',
            fitOnScreen,
          },
        },
        beforeEnter: async ({ params }, from, next) => {
          // if from.name is not null it means we're coming from an existing page,
          // which means we just did a redirect, which means there's need to check
          // on accounts or redirect again
          if (_.$isEmpty(from.name) && await hasCCMAccounts()) {
            return next({ name: 'cloudCostManagementDashboard', params })
          }
          return next()
        },
      },
    ],
  },
]
const roles = [
  {
    path: 'roles',
    name: 'roles',
    component: () => import('@/pages/CyPageRoles.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('roles', {
        permissionsKey: 'roles.index',
        isListPage,
      }),
      layout: { name: 'app' },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmSecurityEvents.securityRoles)
      },
    },
  },
  {
    path: 'roles/create',
    name: 'newRole',
    component: () => import('@/pages/CyPageRole.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('roles', {
        permissionsKey: 'roles.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
  },
  {
    path: 'roles/:roleCanonical',
    name: 'role',
    component: () => import('@/pages/CyPageRole.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('roles', {
        permissionsKey: 'roles.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const infraPolicies = [
  {
    path: 'infra-policies',
    name: 'infraPolicies',
    component: () => import('@/pages/CyPageInfraPolicies.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('infraPolicies', {
        permissionsKey: 'infraPolicies.index',
        isListPage,
      }),
      layout: { name: 'app' },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmSecurityEvents.securityInfraPolicies)
      },
    },
  },
  {
    path: 'infra-policies/create',
    name: 'newInfraPolicy',
    component: () => import('@/pages/CyPageInfraPolicy.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('infraPolicies', {
        permissionsKey: 'infraPolicies.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
  },
  {
    path: 'infra-policies/:infraPolicyCanonical',
    name: 'infraPolicy',
    component: () => import('@/pages/CyPageInfraPolicy.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('infraPolicies', {
        permissionsKey: 'infraPolicies.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const inventory = [
  {
    path: 'inventory',
    name: 'inventory',
    component: () => import('@/pages/CyPageInventory.vue'),
    props: (route) => ({
      requestedResourceId: Number(route.query.resourceId),
      requestedResourceTab: route.query.resourceTab,
    }),
    meta: {
      ...getMetaDefaults('inventory', {
        isListPage,
        permissionsKey: 'inventory.index',
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
  },
]
const quotas = [
  {
    path: 'quotas',
    name: 'quotas',
    component: () => import('@/pages/CyPageQuotas.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('quotas', {
        permissionsKey: 'quotas.index',
      }),
      layout: {
        name: 'app',
        fitOnScreen,
      },
    },
  },
  {
    path: 'quotas/create',
    name: 'newQuota',
    component: () => import('@/pages/CyPageQuota.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('quotas', {
        permissionsKey: 'quotas.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
  },
  {
    path: 'quotas/:quotaId',
    name: 'quota',
    component: () => import('@/pages/CyPageQuota.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('quotas', {
        permissionsKey: 'quotas.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const resourcePools = [
  {
    path: 'resource-pools',
    name: 'resourcePools',
    component: () => import('@/pages/CyPageResourcePools.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('resourcePools', {
        permissionsKey: 'resourcePools.index',
      }),
      layout: {
        name: 'app',
        fitOnScreen,
      },
    },
  },
]
const resources = [
  {
    path: 'resources',
    name: 'resourcesSection',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => {
      const tabsToHide = [
        'newResource',
        'newResourcePool',
        'resourcePool',
        'newQuota',
        'quota',
        'resourcePoolResource',
      ]
      return {
        ...route.params,
        routeName: 'resourcesSection',
        hideTabs: tabsToHide.includes(route.name),
      }
    },
    meta: {
      ...getMetaDefaults('resources'),
      layout: { name: 'app' },
      tabs: () => [
        'inventory',
        ...(store.getters['organization/hasQuotasEnabled'] ? ['quotas', 'resourcePools'] : []),
      ],
    },
    children: [
      ...inventory,
      ...quotas,
      ...resourcePools,
      {
        path: 'resource-pools/resources/create',
        name: 'newResource',
        component: () => import('@/pages/CyPageResourcePoolResource.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('resourcePools', {
            permissionsKey: 'inventory.create',
          }),
          showBackBtn,
          backRouteTo: 'resourcePools',
          layout: {
            name: 'app',
            hideFooter,
          },
        },
      },
      {
        path: 'resource-pools/resources/:resourceId',
        name: 'resourcePoolResource',
        component: () => import('@/pages/CyPageResourcePoolResource.vue'),
        props: (route) => ({ ...route.params, ...setIdProp(route) }),
        meta: {
          ...getMetaDefaults('resourcePools', {
            permissionsKey: 'inventory.details',
          }),
          showBackBtn,
          backRouteTo: 'resourcePools',
          layout: {
            name: 'app',
            hideFooter,
          },
        },
        beforeEnter: (to, _from, next) => {
          redirectTo404IfHasInvalidParam(to, next)
        },
      },
      {
        path: 'resource-pools/create',
        name: 'newResourcePool',
        component: () => import('@/pages/CyPageResourcePool.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('resourcePools', {
            permissionsKey: 'resourcePools.create',
          }),
          showBackBtn,
          backRouteTo: 'resourcePools',
          layout: {
            name: 'app',
            hideFooter,
          },
        },
      },
      {
        path: 'resource-pools/:resourcePoolCanonical',
        name: 'resourcePool',
        component: () => import('@/pages/CyPageResourcePool.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('resourcePools', {
            permissionsKey: 'resourcePools.details',
            showBackBtn,
          }),
          layout: {
            name: 'app',
            backRouteTo: 'resourcePools',
            layout: {
              name: 'app',
              hideFooter,
            },
          },
        },
        beforeEnter: (to, _from, next) => {
          redirectTo404IfHasInvalidParam(to, next)
        },
      },
    ],
  },
]
const apiKeys = [
  {
    path: 'api-keys',
    name: 'apiKeys',
    component: () => import('@/pages/CyPageApiKeys.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('apiKeys', {
        permissionsKey: 'apiKeys.index',
        isListPage,
      }),
      layout: { name: 'app' },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmSecurityEvents.securityApiKeys)
      },
    },
  },
  {
    path: 'api-keys/create',
    name: 'newApiKey',
    component: () => import('@/pages/CyPageApiKey.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('apiKeys', {
        permissionsKey: 'apiKeys.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
  },
  {
    path: 'api-keys/:apiKeyCanonical',
    name: 'apiKey',
    component: () => import('@/pages/CyPageApiKey.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('apiKeys', {
        permissionsKey: 'apiKeys.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const security = [
  {
    path: 'security',
    name: 'securitySection',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => {
      const tabsToHide = [
        'credential',
        'newCredential',
        'infraPolicy',
        'newInfraPolicy',
        'role',
        'newRole',
        'apiKey',
        'newApiKey',
      ]

      return {
        ...route.params,
        routeName: 'securitySection',
        hideTabs: tabsToHide.includes(route.name),
      }
    },
    meta: {
      ...getMetaDefaults('roles'),
      layout: { name: 'app' },
      tabs: () => ['credentials', 'infraPolicies', 'roles', 'apiKeys'],
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmSecurityEvents.security)
      },
    },
    children: [
      ...credentials,
      ...infraPolicies,
      ...roles,
      ...apiKeys,
    ],
  },
]
const stacks = [
  {
    path: 'stacks',
    name: 'stacksSection',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => {
      const tabsToHide = [
        'newCatalogRepository',
        'editCatalogRepository',
        'catalogRepository',
      ]
      return {
        ...route.params,
        routeName: 'stacksSection',
        hideTabs: tabsToHide.includes(route.name),
      }
    },
    meta: {
      ...getMetaDefaults('stacks'),
      layout: { name: 'app' },
      tabs: () => ['stacks', 'catalogRepositories', 'infraTools'],
    },
    children: [
      {
        path: 'all-stacks',
        name: 'stacks',
        component: () => import('@/pages/CyPageStacks.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('stacks', {
            permissionsKey: 'stacks.index',
          }),
          layout: {
            name: 'app',
            hideFooter,
          },
          clickAction: () => {
            Vue.prototype.$gtm.trackEvent(gtmStacksEvents.stacks)
          },
        },
      },
      ...catalogRepositories,
      {
        path: 'stacks/tools',
        name: 'infraTools',
        component: () => import('@/pages/CyPageInfraTools.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('infraTools', {
            permissionsKey: 'stacks.tools',
          }),
          layout: { name: 'app' },
        },
      },
    ],
  },
  {
    path: 'stacks/infra-import',
    name: 'infraImport',
    component: () => import('@/pages/CyPageInfraImport.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('infraImport', {
        permissionsKey: 'infraImport.index',
        backRouteTo: 'infraTools',
        showBackBtn,
      }),
      layout: { name: 'app' },
    },
  },
  {
    path: 'stacks/from-blueprint',
    name: 'stackFromBlueprint',
    component: () => import('@/pages/CyPageStackFromBlueprint.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('stacks', {
        permissionsKey: 'stacks.create',
      }),
      layout: { name: 'app' },
    },
  },
  {
    path: 'stacks/editor',
    name: 'formsEditor',
    component: () => import('@/pages/CyPageFormsEditor.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('formsEditor', {
        permissionsKey: 'infraImport.index', // 'stacks.tools'
        backRouteTo: 'infraTools',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        fitOnScreen,
        hideFooter,
        noPadding,
      },
    },
  },
  {
    path: 'stacks/:stackRef',
    name: 'stack',
    component: () => import('@/pages/CyPageStack.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('stacks', {
        permissionsKey: 'stacks.details',
        backRouteTo: 'stacks',
        figmaKey: 'stack',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
        header: () => import('@/components/CyHeaderVariantStack.vue'),
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const teams = [
  {
    path: 'teams',
    name: 'teams',
    component: () => import('@/pages/CyPageTeams.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('teams', {
        permissionsKey: 'teams.index',
        isListPage,
      }),
      layout: {
        name: 'app',
        header: () => import('@/components/CyHeaderVariantOrganization.vue'),
      },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmOrganizationsEvents.organizationsTeams)
      },
    },
  },
  {
    path: 'teams/create',
    name: 'newTeam',
    component: () => import('@/pages/CyPageTeam.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('teams', {
        permissionsKey: 'teams.create',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
  },
  {
    path: 'teams/:teamCanonical',
    name: 'team',
    component: () => import('@/pages/CyPageTeam.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('teams', {
        permissionsKey: 'teams.details',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
    beforeEnter: (to, _from, next) => {
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
]
const workers = [
  {
    path: 'workers',
    name: 'workers',
    component: () => import('@/pages/CyPageWorkers.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('organizations', {
        permissionsKey: 'organization.workers',
        isListPage,
      }),
      layout: {
        name: 'app',
        header: () => import('@/components/CyHeaderVariantOrganization.vue'),
      },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmOrganizationsEvents.organizationsWorkers)
      },
    },
  },
]

const orgConfig = [
  {
    path: 'settings',
    name: 'orgSettings',
    component: () => import('@/pages/CyPageOrgSettings.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('organizations', {
        permissionsKey: 'organization.settings.index',
        remainOnPageAfterOrgSwitch,
      }),
      layout: {
        name: 'app',
        hideFooter,
        header: () => import('@/components/CyHeaderVariantOrganization.vue'),
      },
      clickAction: () => {
        Vue.prototype.$gtm.trackEvent(gtmOrganizationsEvents.organizationsGeneral)
      },
    },
  },
  {
    path: 'settings/appearance/create',
    name: 'newOrgSettingsAppearance',
    component: () => import('@/pages/CyPageOrgAppearance.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('organizations', {
        permissionsKey: 'organization.settings.appearance.create',
        backRouteTo: 'orgSettings',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
        fitOnScreen,
      },
    },
  },
  {
    path: 'settings/appearance/:appearanceCanonical',
    name: 'orgSettingsAppearance',
    component: () => import('@/pages/CyPageOrgAppearance.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('organizations', {
        permissionsKey: 'organization.settings.appearance.details',
        backRouteTo: 'orgSettings',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
        fitOnScreen,
      },
      clickAction: () => {
        // TODO: add GTM tracker for appearance page click
        // Vue.prototype.$gtm.trackEvent(gtmOrganizationsEvents.organizationsGeneral)
      },
    },
    beforeEnter: (to, _from, next) => {
      if (to.params.appearanceCanonical === 'default') {
        return next({
          name: 'forbidden',
          params: {
            0: to.path,
            backRouteTo: 'orgSettings',
            permissionsKey: 'organization.settings.appearance.details',
          },
          replace: true,
        })
      }
      redirectTo404IfHasInvalidParam(to, next)
    },
  },
  {
    path: 'settings/terraform',
    name: 'orgSettingsTerraform',
    component: () => import('@/pages/CyPageOrgTerraform.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('organizations', {
        permissionsKey: 'organization.settings.terraform',
        backRouteTo: 'orgSettings',
        showBackBtn,
      }),
      layout: {
        name: 'app',
        hideFooter,
      },
    },
  },
  ...workers,
  ...members,
  ...teams,
]

const devRoutes = [
  {
    path: '/dev',
    name: 'dev',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => ({
      ...route.params,
      routeName: 'dev',
    }),
    meta: {
      ...getMetaDefaults('dev'),
      layout: { name: 'app' },
      tabs: () => [
        'sandbox',
      ],
    },
    children: [
      {
        path: 'sandbox',
        name: 'sandbox',
        component: () => import('@/sandbox/sandbox.vue'),
        props: true,
        meta: {
          ...getMetaDefaults('dev'),
          layout: { name: 'app' },
        },
      },
    ],
  },
]
const publicRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'login' },
    meta: {
      ...getMetaDefaults('home'),
      isPublic,
    },
  },
  {
    path: '/login/:orgCanonicalToLogIn?',
    name: 'login',
    props: true,
    component: () => import('@/pages/CyPageLogIn.vue'),
    meta: {
      ...getMetaDefaults('login'),
      layout: { name: 'public', isTwoPane },
      isPublic,
    },
    async beforeEnter ({ params: { orgCanonicalToLogIn } }, _from, next) {
      store.commit('organization/licence/SET_FETCHED', false, { root: true })
      if (orgCanonicalToLogIn) await store.dispatch('FETCH_APP_APPEARANCE', orgCanonicalToLogIn)
      return next()
    },
  },
  {
    path: '/signup/:orgCanonical?',
    name: 'signUp',
    component: () => import('@/pages/CyPageSignUp.vue'),
    meta: {
      ...getMetaDefaults('login'),
      layout: { name: 'public', isTwoPane },
      isPublic,
    },
    async beforeEnter ({ params: { orgCanonical } }, _from, next) {
      if (orgCanonical) await store.dispatch('FETCH_APP_APPEARANCE', orgCanonical)
      return next()
    },
  },
  {
    path: '/mfa/login-link-notice/:email',
    name: 'MFALoginLinkNotice',
    component: () => import('@/pages/CyPageMfaLoginLinkNotice.vue'),
    meta: {
      ...getMetaDefaults('login'),
      layout: { name: 'public', isTwoPane },
      isPublic,
    },
    props: true,
  },
  {
    path: '/mfa/invalid-login-link',
    name: 'MFAInvalidLoginLink',
    component: () => import('@/pages/CyPageMfaInvalidLoginLink.vue'),
    meta: {
      ...getMetaDefaults('login'),
      layout: { name: 'public', isTwoPane },
      isPublic,
    },
  },
  {
    path: '/marketplace/new-org',
    name: 'marketplaceNewOrganization',
    component: () => import('@/pages/CyPageMarketplaceNewOrganization.vue'),
    meta: {
      ...getMetaDefaults('organizations'),
      layout: { name: 'public', isTwoPane },
      isPublic,
    },
  },
  {
    path: '/account-verification',
    name: 'accountVerification',
    component: () => import('@/pages/CyPageAccountVerification.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('verifyAccount'),
      layout: { name: 'public', isTwoPane },
      isPublic,
    },
  },
  {
    path: '/logout/:orgCanonical?',
    name: 'logout',
    meta: {
      ...getMetaDefaults('logout'),
      isPublic,
      handlerPage,
    },
    beforeEnter ({ params }, _from, next) {
      store.dispatch('auth/LOGOUT')
      next({ name: 'login', params })
    },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/pages/CyPageForbidden.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('forbidden'),
      isPublic,
    },
  },
  {
    path: '/invitation/:token',
    name: 'invitation',
    meta: {
      ...getMetaDefaults('invite'),
      isPublic,
      handlerPage,
    },
    async beforeEnter ({ params }, _from, next) {
      const token = _.get(params, 'token', null)
      store.dispatch('auth/LOGOUT')
      await store.dispatch('auth/RETRIEVE_INVITATION_DATA', token)

      const { email } = store.state.auth.invitation
      next({ name: 'signUp', params: { ...params, email } })
    },
  },
  {
    path: '/user/email/authentication/:token',
    name: 'authenticateEmail',
    meta: {
      ...getMetaDefaults('authenticateEmail', {
        permissionsKey: 'user',
      }),
      isPublic,
      handlerPage,
    },

    async beforeEnter ({ params: { token }, query }, _from, next) {
      const orgCanonicalFromQuery = _.get(query, 'organization_canonical', null)
      const orgCanonical = orgCanonicalFromQuery || store.getters.orgCanonical
      store.commit('organization/licence/SET_FETCHED', false, { root: true })
      if ($showFeature.whiteLabel && orgCanonical) store.dispatch('FETCH_APP_APPEARANCE', orgCanonical)
      await store.dispatch('auth/LOGIN', { token, next })

      if (store.getters.hasOrgs) {
        const nextCanonical = getValidOrganization(store.state.organizations, orgCanonical).canonical

        if (!_.$isEmpty(nextCanonical)) {
          await store.dispatch('organization/CHANGE_ORGANIZATION', { nextCanonical })
          return next(store.getters.defaultRoute)
        }
      }
      await store.dispatch('organization/SET_ORGANIZATION')
      return next({ name: 'organizations' })
    },
  },
  {
    path: '/user/email/verified/:token',
    name: 'verifyEmail',
    meta: {
      ...getMetaDefaults('verifyEmail', {
        permissionsKey: 'user',
      }),
      isPublic,
      handlerPage,
    },
    async beforeEnter ({ params, query }, _from, next) {
      const token = _.get(params, 'token', null)
      store.dispatch('auth/LOGOUT')
      await store.dispatch('auth/VERIFY_EMAIL', token)
      next({ name: 'login', params: { ...params }, query: { ...query, action: 'emailConfirmed' } })
    },
  },
  {
    path: '/user/password/reset/:token',
    name: 'resetPassword',
    component: () => import('@/pages/CyPageResetPassword.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('login', {
        permissionsKey: 'user',
      }),
      layout: { name: 'public', isTwoPane },
      isPublic,
    },
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/pages/CyPageStatus.vue'),
    meta: {
      ...getMetaDefaults('status'),
      isPublic,
    },
    beforeEnter (to, _from, next) {
      to.meta.layout = {
        ...getLayout(store.getters.isAuthenticated),
        isWhite: !store.getters.isAuthenticated,
      }
      next()
    },
  },
  {
    path: '/oidc-popup-callback', // Needs to match popupRedirectUri in utils/oidc.js
    name: 'oidcPopupCallback',
    component: () => import('@/components/CySsoPopupCallback.vue'),
    meta: {
      ...getMetaDefaults('oidc'),
      isPublic,
    },
  },
  {
    path: '/saml2-callback',
    name: 'saml2Callback',
    component: () => import('@/components/CySsoPopupCallback.vue'),
    meta: {
      ...getMetaDefaults('saml2'),
      isPublic,
    },
  },
  {
    path: '/oauth2-callback',
    name: 'githubCallback',
    component: () => import('@/components/CySsoPopupCallback.vue'),
    meta: {
      ...getMetaDefaults('github'),
      isPublic,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () => import('@/pages/CyPagePrivacyPolicy.vue'),
    meta: {
      ...getMetaDefaults('privacy'),
      layout: { name: 'public', isWhite: true },
      isPublic,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/pages/CyPageTerms.vue'),
    meta: {
      ...getMetaDefaults('terms'),
      layout: { name: 'public', isWhite: true },
      isPublic,
    },
  },
]
const orgDependentRoutes = [
  {
    path: '/organizations/:orgCanonical',
    name: 'organization',
    component: () => import('@/pages/CyPageTabs.vue'),
    props: (route) => ({
      ...route.params,
      routeName: 'organization',
      hideTabs: [
        'orgSettingsAppearance',
        'newOrgSettingsAppearance',
        'orgSettingsTerraform',
        'member',
        'team',
        'newTeam',
        'memberInvite',
        'pendingInvites',
      ].includes(route.name) || !_.map(orgConfig, 'name').includes(route.name),
    }),
    meta: {
      ...getMetaDefaults('organizations'),
      layout: { name: 'app' },
      tabs: () => [
        'orgSettings',
        'workers',
        'members',
        'teams',
      ],
    },
    children: [
      dashboard,
      ...orgConfig,
      ...cloudCostManagement,
      ...observability,
      ...resources,
      ...security,
      ...stacks,
      ...projects,
    ],
  },
]
const userRoutes = [
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/pages/CyPageNotifications.vue'),
    meta: {
      ...getMetaDefaults('profile', {
        permissionsKey: 'user',
      }),
      layout: { name: 'app' },
    },
  },
  {
    path: '/user',
    name: 'profile',
    component: () => import('@/pages/CyPageProfile.vue'),
    redirect: { name: 'userAccount' },
    meta: {
      ...getMetaDefaults('profile', {
        permissionsKey: 'user',
      }),
      layout: { name: 'app' },
    },
    children: [
      {
        path: 'account',
        name: 'userAccount',
        component: () => import('@/pages/CyPageUserAccount.vue'),
        meta: {
          ...getMetaDefaults('profile', {
            permissionsKey: 'user',
          }),
          layout: { name: 'app' },
        },
      },
      {
        path: 'emails',
        name: 'userEmails',
        component: () => import('@/pages/CyPageUserEmails.vue'),
        meta: {
          ...getMetaDefaults('profile', {
            permissionsKey: 'user',
          }),
          layout: { name: 'app' },
        },
      },
      {
        path: 'security',
        name: 'userSecurity',
        component: () => import('@/pages/CyPageUserSecurity.vue'),
        meta: {
          ...getMetaDefaults('profile', {
            permissionsKey: 'user',
          }),
          layout: { name: 'app' },
        },
      },
      {
        path: 'watch-rules',
        name: 'userWatchRules',
        component: () => import('@/pages/CyPageUserWatchRules.vue'),
        meta: {
          ...getMetaDefaults('profile', {
            permissionsKey: 'user',
          }),
          layout: { name: 'app' },
        },
      },
    ],
  },
  {
    path: '/watch-rules/create',
    name: 'newUserWatchRule',
    component: () => import('@/pages/CyPageUserWatchRule.vue'),
    meta: {
      ...getMetaDefaults('profile', {
        permissionsKey: 'user',
      }),
      layout: { name: 'app' },
    },
  },
  {
    path: '/watch-rules/:watchRuleCanonical/edit',
    name: 'userWatchRuleEdit',
    component: () => import('@/pages/CyPageUserWatchRule.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('profile', {
        permissionsKey: 'user',
      }),
      layout: { name: 'app' },
    },
  },
  {
    path: '/watch-rules/:watchRuleCanonical',
    name: 'userWatchRule',
    component: () => import('@/pages/CyPageUserWatchRuleDetail.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('profile', {
        permissionsKey: 'user',
      }),
      layout: {
        name: 'app',
        noPadding,
      },
    },
  },
]

export function getMetaDefaults (appSection, {
  backRouteTo = appSection,
  figmaKey = appSection,
  permissionsKey = 'organizations',
  ...options
} = {}) {
  return {
    actions: _.$get(actionsWithResources, `${permissionsKey}.actions`, {}),
    appSection,
    backRouteTo,
    breadcrumb: {},
    figmaKey,
    hasConditionalBreadcrumb: options.hasConditionalBreadcrumb ?? false,
    isAlpha: _.some([alphaRoutes.includes(appSection), options.isAlpha]) ?? false,
    isBeta: _.some([betaAppSections.includes(appSection), options.isBeta]) ?? false,
    isListPage: options.isListPage ?? false,
    permissionsKey,
    remainOnPageAfterOrgSwitch: options.remainOnPageAfterOrgSwitch ?? false,
    requiredActions: _.$get(actionsWithResources, `${permissionsKey}.requiredActions`, []),
    showBackBtn: options.showBackBtn ?? false,
  }
}

export function setIdProp (route) {
  const props = { ...route.params }
  const idKey = _.keys(props).find((key) => key.endsWith('Id'))
  props[idKey] = _.$isEmpty(props[idKey]) ? null : Number(props[idKey])
  return props
}

export function redirectTo404IfHasInvalidParam (to, next) {
  const hasAllValidCanonicalsAndIds = _.every(_.toPairs(to.params), isParamValid)
  if (hasAllValidCanonicalsAndIds) return next()

  const { backRouteTo } = _.cloneDeep(to.meta)
  next({ name: 'notFound', params: { 0: to.path, backRouteTo }, replace: true })
}

export function getLayout (isAuth) {
  const appLayout = {
    name: 'app',
    variant: 'one-column',
    fitOnScreen,
  }
  const publicLayout = { name: 'public' }
  return isAuth ? appLayout : publicLayout
}

async function hasCCMAccounts () {
  await store.dispatch('organization/cloudCostManagement/GET_ACCOUNTS')
  return !_.$isEmpty(store.state.organization.cloudCostManagement.accounts)
}

async function handleCCMRedirect ({ name, params }, _from, next) {
  const shouldRedirect = name !== 'cloudCostManagementWelcome' && !(await hasCCMAccounts())
  if (shouldRedirect) next({ name: 'cloudCostManagementWelcome', params })
  return next()
}

function isParamValid ([paramKey, paramValue]) {
  const paramCategory = paramKey.match(REGEX.ROUTE.PARAM_CATEGORY)?.at(2)
  const paramType = { Id: 'number', Ref: 'ref' }[paramCategory] ?? 'string'

  if (_.isNil(paramValue)) return false
  if (!paramCategory) return true

  const paramValidators = {
    /** Default checks, per paramType. */
    byType: {
      ref: /^[\w-]{3,100}:[\w-]{3,100}$/,
      string: /^[\w-]{3,100}$/,
      number: /^(?!0)[(?!0)1-9]+$/,
    },
    /** Custom checks, per paramKey. _Add edge cases here._ */
    custom: {
      projectCanonical: /^[\w-]{1,100}$/,
      envCanonical: /^[\w.-]{2,100}$/,
      pipelineCanonical: /^[\w.-]{2,100}$/,
    },
  }

  return (paramValidators.custom[paramKey] ?? paramValidators.byType[paramType]).test(paramValue)
}

export default [
  ...publicRoutes,
  ...redirects,
  organizations,
  ...orgDependentRoutes,
  ...userRoutes,
  ...(isStagingDevOrTesting ? devRoutes : []),
  // 404 (when all other routes fail) //
  {
    path: '*',
    name: 'notFound',
    component: () => import('@/pages/CyPageNotFound.vue'),
    props: true,
    meta: {
      ...getMetaDefaults('404'),
      isPublic,
    },
    beforeEnter (_to, from, next) {
      const fromRoute = _.pick(from, ['path', 'name', 'query', 'params'])
      if (_.isEqual(fromRoute, store.getters.defaultRoute)) return next()

      // This case happens when the user has accessed for any reason
      // to a route whose path doesn't match to any of the ones defined
      // in the application, so it's resolved by this route which is the
      // one which matches everything (i.e. path: '*')
      store.commit('auth/SET_DEFAULT_ROUTE', { name: process.env.VUE_APP_DEFAULT_ROUTE_NAME_REDIRECT })
      next()
    },
  },
]

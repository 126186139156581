var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:['cy-sidebar', {
    'cy-sidebar--hidden': !_vm.isSidebarVisible,
    'cy-sidebar--collapsed': _vm.isSidebarCollapsed,
    'cy-sidebar--mobile': _vm.isMobile,
  }],attrs:{"app":!_vm.isOverlapping,"mobile-breakpoint":600,"disable-resize-watcher":false,"disable-route-watcher":"","mini-variant":_vm.isSidebarCollapsed,"mini-variant-width":72,"value":_vm.isSidebarVisible,"width":_vm.isMobile ? '100%' : '256',"dark":_vm.isDark,"fixed":"","floating":"","permanent":""}},[_c('div',{class:['main-nav', { 'main-nav--collapsed': _vm.isSidebarCollapsed }]},[_c('div',{staticClass:"main-nav__header"},[_c('CyCompanyLogo',{attrs:{"data-cy":"company-logo"}}),(_vm.isMobile)?_c('button',{staticClass:"main-nav__item ml-auto",attrs:{"data-cy":"nav-show-hide"},on:{"click":_vm.TOGGLE_SIDEBAR_VISIBILITY}},[_c('v-icon',[_vm._v("close")])],1):_vm._e()],1),_c('v-divider',{staticClass:"mb-4 mx-4"}),_c('div',{staticClass:"main-nav__scroll-container"},[_c('transition',{attrs:{"name":"slide-fade-top"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPrevIconVisible),expression:"isPrevIconVisible"}],staticClass:"main-nav__scroll-container__icon main-nav__scroll-container__icon--prev"},[_c('v-icon',[_vm._v("$vuetify.icons.doubleArrowUp")])],1)]),(!!_vm.orgCanonical)?_c('v-list',{directives:[{name:"resize-observer",rawName:"v-resize-observer",value:(_vm.onListResize),expression:"onListResize"}],ref:"list",class:['main-nav__list main-nav__list--scrollable', {
          'main-nav__list--disabled': !_vm.hasActiveBillingPlan,
        }],nativeOn:{"scroll":function($event){return _vm.onListResize.apply(null, arguments)}}},_vm._l((_vm.menuItems),function(ref,index){
        var action = ref.action;
        var name = ref.name;
        var routeName = ref.routeName;
        var icon = ref.icon;
        var params = ref.params;
        var children = ref.children;
        var isActive = ref.isActive;
return _c('div',{key:("item-" + name + "-" + _vm.isSidebarCollapsed),staticClass:"main-nav__menu-item"},[_c('CyTooltip',{attrs:{"disabled":!_vm.isSidebarCollapsed,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(!_vm.isSidebarCollapsed && children)?_c('v-list-group',{attrs:{"value":isActive,"disabled":!_vm.hasActiveBillingPlan || isActive},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('router-link',{class:['main-nav__list-item-link',
                             { 'main-nav__list-item-link--deep-child-active' : isActive && _vm.isOnDeeplyNestedRoute(children) }],attrs:{"to":{ name: name, params: params },"custom":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(("routes." + routeName)))+" ")])],1)],1)]},proxy:true}],null,true)},_vm._l((children),function(ref){
                             var childName = ref.name;
                             var childParams = ref.params;
return _c('v-list-item',{key:("item-" + name + "-" + childName),attrs:{"disabled":!_vm.hasActiveBillingPlan}},[_c('router-link',{staticClass:"main-nav__list-item-link",attrs:{"to":{ name: childName, params: childParams }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(("routes." + childName)))+" ")])],1)],1)],1)}),1):_c('v-list-item',_vm._g({attrs:{"data-cy":("nav-" + name),"disabled":!_vm.hasActiveBillingPlan},nativeOn:{"click":function($event){return action.apply(null, arguments)}}},on),[_c('router-link',{staticClass:"main-nav__list-item-link",attrs:{"to":{ name: name, params: params }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(("routes." + name)))+" ")])],1)],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t(("routes." + name)))+" ")]),(!_vm.isMobile)?_c('CyProductTourStep',_vm._b({attrs:{"total-step-count":_vm.menuItems.length}},'CyProductTourStep',{ index: index, name: name },false)):_vm._e()],1)}),0):_vm._e(),_c('transition',{attrs:{"name":"slide-fade-bottom"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNextIconVisible),expression:"isNextIconVisible"}],staticClass:"main-nav__scroll-container__icon main-nav__scroll-container__icon--next"},[_c('v-icon',[_vm._v("$vuetify.icons.doubleArrowDown")])],1)])],1),_c('v-divider',{staticClass:"mt-auto ma-4"}),(_vm.$showDevThings)?_c('v-list',{class:['main-nav__list mb-4 d-flex flex-column align-start dev-list', {
        'main-nav__list--collapsed': _vm.isSidebarCollapsed,
      }]},[_c('CyDevLocaleSwitcher',_vm._b({},'CyDevLocaleSwitcher',{ isSidebarCollapsed: _vm.isSidebarCollapsed },false)),_c('CyDevLayerActivatorButton',_vm._b({},'CyDevLayerActivatorButton',{ isSidebarCollapsed: _vm.isSidebarCollapsed },false))],1):_vm._e(),(!_vm.isMobile)?_c('button',{class:['main-nav__item main-nav__item--toggler', {
        'main-nav__item--toggler-rotate ml-auto mr-3': !_vm.isSidebarCollapsed,
      }],attrs:{"slot":"activator"},on:{"click":_vm.toggleSidebarMode},slot:"activator"},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Accounts table
    </h2>

    <CyCcmAccountsTable/>
  </div>
</template>

<script>
import CyCcmAccountsTable from '@/components/CyCcmAccountsTable.vue'

export default {
  name: 'CyCcmAccountsTableSandbox',
  components: {
    CyCcmAccountsTable,
  },
}
</script>

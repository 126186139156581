<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Top projects table
    </h2>

    <CyCcmTopProjectsTable class="elevation-2"/>
  </div>
</template>

<script>
import CyCcmTopProjectsTable from '@/components/CyCcmTopProjectsTable.vue'

export default {
  name: 'CyCcmTopProjectsTableSandbox',
  components: {
    CyCcmTopProjectsTable,
  },
}
</script>

<style lang="scss" scoped>
  .sandbox__cy-cloud-cost-management-top-projects-table {
    max-width: 1200px;
  }
</style>

<template>
  <v-card class="tag-mapping-not-found text-center">
    <v-card-text class="pa-6">
      <h2 class="tag-mapping-not-found__title font-size-xxl">
        {{ $t("tagsNotMapped.title") }}
      </h2>
      <div class="tag-mapping-not-found__body py-0 mt-8">
        <p class="font-weight-bold">
          <i18n path="tagsNotMapped.line1">
            <span class="text-darker">{{ $t("tags") }}</span>
          </i18n>
        </p>
        <p class="font-weight-bold">
          <i18n path="tagsNotMapped.line2">
            <span class="text-darker">{{ _.startCase($t("untranslated.cloudCostManagement")) }}</span>
          </i18n>
        </p>
        <p class="font-weight-bold">
          <i18n path="tagsNotMapped.line3">
            <router-link
              :to="{ name: 'cloudCostManagementTagMapping' }"
              class="cy-link">
              {{ _.startCase($t("routes.cloudCostManagementTagMapping")) }}
            </router-link>
          </i18n>
        </p>
      </div>
      <CyButton
        class="mt-6"
        variant="secondary"
        theme="secondary"
        :to="{ name: 'cloudCostManagementTagMapping' }">
        {{ $t('routes.cloudCostManagementTagMapping') }}
      </CyButton>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CyCcmTagMappingNotFound',
  i18n: {
    messages: {
      en: {
        tags: 'Tags',
        tagsNotMapped: {
          title: 'Tags not mapped!',
          line1: '{0} are an essential ingredient for providing you with FinOps data.',
          line2: '{0} made an assumption and tried to assign a tag for the project and environment for you using common practices, but it seems that your tagging conventions vary a bit.',
          line3: 'Go to {0} to configure your account.',
        },
      },
      es: {
        tags: 'Los tags',
        tagsNotMapped: {
          title: 'Tags no asignados!',
          line1: '{0} son un ingrediente esencial para proporcionarle datos de FinOps.',
          line2: '{0} hizo una suposición e intentó asignarle una etiqueta para el proyecto y el entorno utilizando prácticas comunes, pero parece que sus convenciones de etiquetado varían un poco.',
          line3: 'Vaya a {0} para configurar su cuenta.',
        },
      },
      fr: {
        tags: 'Les tags',
        tagsNotMapped: {
          title: 'Tags non configurés!',
          line1: '{0} sont un ingrédient essentiel pour vous fournir des données FinOps.',
          line2: `{0} a émis une hypothèse et a essayé d'attribuer un tag pour le projet et l'environnement en utilisant des pratiques courantes, mais il semble que vos conventions de tags varient un peu.`,
          line3: 'Accédez à {0} pour configurer votre compte.',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-mapping-not-found {
  &__body {
    color: cy-get-color("primary", "light-3");

    .text-darker {
      color: cy-get-color("primary", "light-2");
    }
  }

  .cy-link:visited:not(:hover, :focus) {
    color: cy-get-color("secondary");
  }
}
</style>

<template>
  <div class="rappid-popover">
    <div class="rappid-popover__header">
      <h4 class="title-row font-weight-bold">
        <span class="resource-wrapper">
          <span class="resource-name">
            {{ nodeName || resource._name }}
          </span>
          <v-chip
            v-if="status"
            x-small
            :class="['resource-status', 'font-weight-normal', {
              'v-chip--success': status === 'running',
              'v-chip--warning': status === 'pending',
              'v-chip--error': status === 'stopping',
              'v-chip--default': status === 'unknown',
            }]">
            {{ _.upperFirst(status) }}
          </v-chip>
        </span>
      </h4>
      <CyInfraViewResourceType
        :resource="resource"
        bottom/>
    </div>
    <div class="rappid-popover__fields my-2">
      <CyInfraViewProperty
        v-for="({ key, value }, index) in mappedFields"
        :key="`${value}-${key}-field-${index}`"
        :property="{ key, value }"/>
    </div>
    <a
      href="#"
      class="cy-link"
      @click="$emit('open-right-panel')">
      {{ $t('seeDetails' ) }}
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyInfraViewProperty from '@/components/CyInfraViewProperty.vue'
import CyInfraViewResourceType from '@/components/CyInfraViewResourceType.vue'

export default {
  name: 'CyInfraViewPopover',
  components: {
    CyInfraViewProperty,
    CyInfraViewResourceType,
  },
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('organization/project/infraview', {
      diagram: (state) => state.diagram,
    }),
    mappedFields () {
      return _(this.fields)
        .map((key) => {
          const [typeKey, ...keyField] = key.split('.')
          const { isTypeSet } = this.resource._schema[typeKey] || {}
          return {
            key,
            value: isTypeSet
              ? _.get(_.get(this.resource, typeKey)[0], keyField)
              : _.get(this.resource, key),
          }
        })
        .filter(({ value }) => !_.$isEmpty(value))
        .value()
    },
    fields () {
      return this.resource?._attributes?.important || []
    },
    status () {
      const { status = '' } = this.resource
      return this.resource?._attributes?.normalized_status[status] || null
    },
    nodeName () {
      const cell = _.find(this.diagram?.cells, ['attrs.canonical', this.resource._canonical], {})
      return _.get(cell, 'attrs.nodeName')
    },
  },
  i18n: {
    messages: {
      en: {
        seeDetails: 'See more details',
      },
      es: {
        seeDetails: 'Ver mas detalles',
      },
      fr: {
        seeDetails: 'Voir plus de détails',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.rappid-popover {
  width: 280px;
  font-family: $font-family-default;
  font-size: $font-size-sm;
  line-height: 150%;

  &__header {
    .title-row {
      display: flex;
      align-items: center;
    }

    .resource-wrapper {
      display: flex;
      align-items: center;
      width: 280px;
    }

    .resource-name {
      display: inline-block;
      flex: 0 1 auto;
      font-size: $font-size-default;

      @extend %ellipsis;
    }

    .resource-status {
      flex: 0 0 auto;
      margin-top: -1px;
      margin-left: 9px;
      padding-right: 5px;
      padding-left: 5px;
      font-size: $font-size-xs;
    }

    .resource-type {
      display: flex;
      overflow: hidden;
      color: cy-get-color("primary", "light-2");
      text-overflow: ellipsis;
    }
  }

  &__fields {
    list-style-type: none;

    .element-property {
      display: block;
      margin-bottom: 0;
      padding-right: 24px;
      padding-left: 0;
    }
  }
}
</style>

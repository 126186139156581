import { render, staticRenderFns } from "./CySecondaryNavUserMenu.vue?vue&type=template&id=e4f51c66&scoped=true"
import script from "./CySecondaryNavUserMenu.vue?vue&type=script&lang=js"
export * from "./CySecondaryNavUserMenu.vue?vue&type=script&lang=js"
import style0 from "./CySecondaryNavUserMenu.vue?vue&type=style&index=0&id=e4f51c66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4f51c66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VIcon,VList,VListItem,VListItemTitle})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})

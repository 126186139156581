<template>
  <div class="width-100">
    <div
      v-if="isLoading"
      class="mt-4 text-center">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>

    <form v-else>
      <!-- Step 1: General config -->
      <CyStepper
        v-model="step"
        vertical
        :before-step-change="validateStep">
        <v-stepper-step
          :complete="step > 1"
          editable
          :step="1">
          {{ $t('generalConfiguration') }}
        </v-stepper-step>

        <v-stepper-content step="1">
          <div class="fieldset px-2">
            <!-- Credential -->
            <v-select
              v-model="form.credentialCanonical"
              :items="credentials"
              :label="$t('Credential')"
              item-value="canonical"
              item-text="name"
              class="required-field">
              <template
                #selection="{ item }">
                <v-avatar
                  v-if="item.type"
                  :key="item.type"
                  size="24"
                  class="mr-2">
                  <CyIconCredential
                    :type="item.type"
                    show-tooltip/>
                </v-avatar>
                <div>{{ item.name }}</div>
              </template>

              <template
                #item="{ item }">
                <v-list-item-avatar class="ml-1 mr-3">
                  <CyIconCredential
                    :type="item.type"
                    show-tooltip/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <!-- URLs -->
            <v-combobox
              ref="urls"
              v-model="$v.form.urls.$model"
              :label="$t('urls.label')"
              :hint="$t('urls.hint')"
              :error-messages="urlsErrors"
              persistent-hint
              hide-no-data
              append-icon=""
              multiple
              small-chips>
              <template #selection="{ item, parent, selected }">
                <v-chip
                  :input-value="selected"
                  label
                  small>
                  <span class="pr-2">{{ item }}</span>
                  <v-icon
                    small
                    @click="parent.selectItem(item)">
                    close
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </div>

          <div class="text-left space-x-4 ml-2 mt-4">
            <CyButton
              sm
              @click="nextStep()">
              {{ $t('forms.next') }}
            </CyButton>
          </div>
        </v-stepper-content>

        <!-- Step 2+: Setup sources for each env -->
        <template v-for="({ canonical, icon, color }, index) in envs">
          <v-stepper-step
            :key="`step-${index}`"
            :step="index + 2"
            :complete="step > index + 2"
            editable>
            <span class="d-flex align-center">
              {{ $t('configEnvSources') }}:
              <div class="d-flex align-center ml-2">
                <CyAvatar
                  :item="{ icon, color }"
                  class="mr-2"
                  sm/>
                <span
                  :disabled="step < index + 2">
                  {{ canonical }}
                </span>
              </div>
            </span>
          </v-stepper-step>

          <v-stepper-content
            :key="`step-content-${index}`"
            :step="index + 2">
            <div class="px-2">
              <!-- Empty state -->
              <v-expand-transition>
                <div v-if="hasEnvNoSource(canonical)">
                  <div class="empty-state">
                    <span class="empty-state__title">{{ $t('sourcesWillAppearHere') }}</span>
                    <p class="empty-state__description">
                      {{ $t('addEnvSources', { canonical }) }}
                    </p>
                  </div>
                </div>
              </v-expand-transition>

              <!-- Sources -->
              <v-slide-y-transition group>
                <CyFormsElasticSearchSource
                  v-for="(source, sourceIndex) in form.sources[canonical]"
                  :key="`source-${canonical}-${sourceIndex}`"
                  :ref="`source-${canonical}`"
                  v-model="form.sources[canonical][sourceIndex]"
                  :restricted-names="getRestrictedNames(canonical, sourceIndex)"
                  :require-urls="areSourceUrlsRequired"
                  @remove="removeSource(canonical, sourceIndex)"/>
              </v-slide-y-transition>

              <!-- Add source -->
              <button
                v-ripple="{ class: `secondary--text` }"
                class="btn-dashed mt-4"
                type="button"
                @click="addSource(canonical)">
                <v-icon color="secondary">
                  add
                </v-icon>
                {{ $t('addSource') }}
              </button>
            </div>

            <div class="text-left space-x-4 ml-2 mt-4">
              <CyButton
                variant="secondary"
                sm
                @click="prevStep()">
                {{ $t('forms.back') }}
              </CyButton>
              <CyButton
                sm
                @click="nextStep()">
                {{ $t('forms.next') }}
              </CyButton>
            </div>
          </v-stepper-content>
        </template>

        <!-- Last step: Review -->
        <v-stepper-step
          :step="envs.length + 2"
          editable>
          {{ $t('forms.reviewAndSave') }}
        </v-stepper-step>
        <v-stepper-content :step="envs.length + 2">
          <div class="px-2">
            <CyAlert
              v-if="hasNoSource"
              theme="error"
              :title="$t('noSourceError.details')"
              :content="$t('noSourceError.message')"/>

            <v-data-table
              v-if="!hasNoSource"
              :items="sourceCountByEnv"
              hide-default-footer
              class="summary-table elevation-1">
              <template #header>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-left">
                      {{ $t('Environment') }}
                    </th>
                    <th class="text-right">
                      {{ $t('summaryTable.sourceCount') }}
                    </th>
                  </tr>
                </thead>
              </template>
              <template #item="{ item: { env, sourceCount, icon, color } }">
                <tr>
                  <td class="d-flex align-center">
                    <CyAvatar
                      :item="{ icon, color }"
                      class="mr-2"
                      sm/>
                    <span
                      :key="env">
                      {{ env }}
                    </span>
                  </td>
                  <td
                    class="text-right"
                    v-html="$tc('sources', sourceCount, { nb: sourceCount })"/>
                </tr>
              </template>
            </v-data-table>
          </div>

          <div class="text-left space-x-4 ml-2 mt-4">
            <CyButton
              variant="secondary"
              sm
              @click="prevStep()">
              {{ $t('forms.back') }}
            </CyButton>
            <CyButton
              v-has-rights-to="['UpdateExternalBackend', projectCanonical]"
              sm
              icon="done"
              :disabled="!canSave"
              @click="save">
              {{ $t('forms.btnSave') }}
            </CyButton>
          </div>
        </v-stepper-content>
      </CyStepper>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyFormsElasticSearchSource from '@/components/CyFormsElasticSearchSource.vue'
import CyStepper from '@/components/CyStepper.vue'
import { LOGS_EXTERNAL_BACKENDS } from '@/utils/config/external-backends'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageProjectConfigurationEsDetails',
  components: {
    CyFormsElasticSearchSource,
    CyStepper,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectConfigElasticSearch'), [
      {
        label: this.$t('routes.projectConfiguration'),
        name: 'projectConfiguration',
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return { title: this.$t('routes.projectConfigElasticSearch') }
  },
  props: {
    externalBackendId: {
      type: Number,
      default: null,
    },
  },
  validations: () => ({
    form: {
      urls: {
        hasSchema: (urls) => urls
          .map((url) => REGEX.URL_SCHEME.test(url))
          .every((isValid) => isValid),
      },
      sources: { required },
    },
  }),
  data: () => ({
    form: {
      credentialCanonical: '',
      urls: [],
      sources: {},
    },
    initial: {
      form: {},
    },
    credentials: [],
    isLoading: false,
    isSaving: false,
    step: 1,
  }),
  computed: {
    ...mapState('organization', {
      storeCredentials: (state) => state.available.credentials,
    }),
    ...mapGetters([
      'projectCanonical',
    ]),
    ...mapGetters('organization/project', [
      'envs',
      'hasNoExternalBackendErrors',
      'logProvider',
    ]),
    sourceCountByEnv () {
      return this.envs
        .filter(({ canonical }) => !_.isEmpty(this.form.sources[canonical]))
        .map(({ canonical, icon, color }) => ({
          env: canonical,
          icon,
          color,
          sourceCount: this.form.sources[canonical].length,
        }))
    },
    hasNoSource () {
      return _.isEmpty(this.sourceCountByEnv)
    },
    urlsErrors () {
      const errors = []
      const { $dirty, hasSchema } = this.$v.form.urls
      if (!$dirty) return errors
      if (!hasSchema) errors.push(this.$t('urls.mustHaveScheme'))
      return errors
    },
    areSourceUrlsRequired () {
      return _.isEmpty(this.form.urls)
    },
    canSave () {
      return !_.isEqual(this.initial.form, this.form) && !this.hasNoSource
    },
  },
  watch: {
    sources: {
      handler () {
        this.$v.form.sources.$touch()
      },
      immediate: true,
    },
  },
  async mounted () {
    this.isLoading = true
    await this.getConfiguration()
    this.setInitial()
    this.isLoading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'GET_EXTERNAL_BACKENDS',
      'CREATE_EXTERNAL_BACKEND',
      'UPDATE_EXTERNAL_BACKEND',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    async getConfiguration () {
      await this.getCredentials()
      await this.GET_EXTERNAL_BACKENDS()
      if (!this.$isCreationRoute && this.logProvider) {
        const { credential_canonical: credentialCanonical = null, configuration: { sources: sourcesByEnv, urls, engine } } = _.cloneDeep(this.logProvider)

        if (engine === 'ElasticsearchLogs') {
          this.form.credentialCanonical = credentialCanonical
          this.form.urls = urls
          this.form.sources = _.mapValues(sourcesByEnv, (sources) => {
            return _.map(sources, (configuration, name) => ({ name, ...configuration }))
          })
        }
      }
    },
    setInitial () {
      this.initial.form = _.cloneDeep(this.form)
    },
    addSource (env) {
      const sources = _.get(this.form.sources, env, [])
      sources.push({})
      this.$set(this.form.sources, env, sources)
    },
    removeSource (env, index) {
      this.$delete(this.form.sources[env], index)
    },
    async getCredentials () {
      await this.FETCH_AVAILABLE({ keyPath: 'credentials', extraParams: ['elasticsearch'] })
      const credentials = [
        {
          canonical: null,
          name: this.$t('unauthenticated'),
        },
        ...this.storeCredentials,
      ]
      this.credentials = credentials
    },
    getRestrictedNames (env, index) {
      const otherSources = this.form.sources[env]
        .filter((source, _index) => _index !== index && source.name)
      const names = otherSources.map((source) => source.name)
      return names
    },
    getFormattedSources () {
      return _.chain(this.form.sources)
        .omitBy((source) => _.isEmpty(source))
        .mapValues((sources) => {
          return sources.reduce((acc, { name, ...source }) => {
            acc[name] = source
            return acc
          }, {})
        })
        .value()
    },
    async save () {
      this.isSaving = true
      this.CLEAR_PROJ_ERRORS()

      const { projectCanonical, form, externalBackendId } = this
      const { purpose, type } = LOGS_EXTERNAL_BACKENDS.ElasticsearchLogs
      const config = {
        purpose,
        type,
        credential_canonical: form.credentialCanonical,
        configuration: {
          engine: 'ElasticsearchLogs',
          version: '7',
          urls: form.urls,
          sources: this.getFormattedSources(),
        },
        project_canonical: projectCanonical,
      }

      if (this.$isCreationRoute) await this.CREATE_EXTERNAL_BACKEND(config)
      else await this.UPDATE_EXTERNAL_BACKEND({ externalBackendId, config })

      this.isSaving = false
      if (this.hasNoExternalBackendErrors) {
        this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.project.config.elasticSearch.saved') })
        this.$router.push({ name: 'projectConfiguration' })
      }
    },
    validateEnvSources ({ canonical }) {
      return _.chain(this.$refs[`source-${canonical}`])
        .map((source) => {
          source.$v.form.$touch()
          return source.$v.form.$invalid
        })
        .every((result) => !result)
        .value()
    },
    hasEnvNoSource (env) {
      const source = _.get(this.form.sources, env)
      return _.isEmpty(source)
    },
    async prevStep () {
      if (await this.validateStep(this.step)) this.step -= 1
    },
    async nextStep () {
      if (await this.validateStep(this.step)) this.step += 1
    },
    async validateStep (step) {
      let isValid = true
      const isEnvStep = (this.step > 1 && this.step < (this.envs.length + 2))

      if (step === 1) {
        this.$refs.urls.blur()
        await this.$nextTick()
        this.$v.form.urls.$touch()
        isValid = !this.$v.form.urls.$invalid
      } else if (isEnvStep) {
        isValid = this.validateEnvSources(this.envs[step - 2])
      }

      return isValid
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectConfigElasticSearch',
        addEnvSources: `Add one or several log sources for the '{env}' environment, and map the fields of your Elastic Search configuration`,
        addSource: 'Add source',
        configEnvSources: 'Configure environment sources',
        generalConfiguration: 'General configuration',
        noSourceError: {
          details: 'At least one source is required in order for Elastic Search logs to work.',
          message: 'No source defined',
        },
        sources: '<strong>{nb}</strong> source | <strong>{nb}</strong> sources',
        sourcesWillAppearHere: 'Sources will appear here',
        summaryTable: {
          sourceCount: 'Source count',
        },
        unauthenticated: 'unauthenticated',
        urls: {
          hint: 'URLs to fetch data from. Press TAB or ENTER to add a URL.',
          label: 'URLs',
          mustHaveScheme: 'URLs must begin with either http:// or https://',
        },
      },
      es: {
        title: '@:routes.projectConfigElasticSearch',
        addEnvSources: `Agregue una o varias fuentes de log para el entorno '{env}' y asigne los campos de su configuración de Elastic Search`,
        addSource: 'Agregue una fuente',
        configEnvSources: 'Configurar fuentes de entorno',
        generalConfiguration: 'Configuración general',
        noSourceError: {
          details: 'Se requiere al menos una fuente para que funcionen los logs de Elastic Search.',
          message: 'Ninguna fuente definida',
        },
        sources: '<strong>{nb}</strong> fuente | <strong>{nb}</strong> fuentes',
        sourcesWillAppearHere: 'Las fuentes aparecerán aquí',
        summaryTable: {
          sourceCount: 'Recuento de fuente',
        },
        unauthenticated: 'no autenticado',
        urls: {
          hint: 'URLs para obtener datos. Presione TAB o ENTER para agregar una URL.',
          label: 'URLs',
          mustHaveScheme: 'Las URLs deben comenzar con http:// o https://',
        },
      },
      fr: {
        title: '@:routes.projectConfigElasticSearch',
        addEnvSources: `Ajoutez une ou plusieurs sources de log pour l'environnement '{env}' et mappez les champs de votre configuration Elastic Search`,
        addSource: 'Ajouter une source',
        configEnvSources: `Configurer les sources d'environnement`,
        generalConfiguration: 'Configuration générale',
        noSourceError: {
          details: 'Au moins une source est requise pour que les logs Elastic Search fonctionnent.',
          message: 'Aucune source définie',
        },
        sources: '<strong>{nb}</strong> source | <strong>{nb}</strong> sources',
        sourcesWillAppearHere: 'Les sources apparaîtront ici',
        summaryTable: {
          sourceCount: 'Nombre de sources',
        },
        unauthenticated: 'non authentifié',
        urls: {
          hint: 'URLs desquelles récupérer les données. Appuyez sur TAB ou ENTRÉE pour ajouter une URL.',
          label: 'URLs',
          mustHaveScheme: 'Les URL doivent commencer par http:// ou https://',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: solid 1px cy-get-color("grey", "light-1");
  border-radius: 4px;
  background-color: cy-get-color("grey", "light-2");
  text-align: center;

  &__title {
    color: cy-get-color("primary");
    font-size: 16px;
    line-height: 21px;
  }

  &__description {
    color: cy-get-color("grey", "dark-2");
    font-size: 14px;
    line-height: 21px;
  }

  &__title + &__description {
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.fieldset {
  max-width: 450px;
}

.btn-dashed {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 14px;
  transition: all 0.2s ease;
  border: 1px dashed cy-get-color("secondary");
  border-radius: 4px;
  color: cy-get-color("secondary");

  > .v-icon {
    margin-right: 14px;
  }

  &:focus,
  &:hover {
    background-color: cy-get-color("secondary", "light-3");
    color: cy-get-color("secondary", "dark-2");
  }

  &:focus {
    box-shadow: 0 0 0 3px cy-get-color("secondary", "light-2");
  }

  &:active {
    background-color: cy-get-color("secondary", "light-4");
  }
}

.summary-table {
  max-width: 32rem;
}
</style>

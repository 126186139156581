<template>
  <div>
    <h2>Beta tag</h2>

    <CyBetaTag/>
  </div>
</template>

<script>

export default {
  name: 'CyBetaTagSandbox',
}
</script>

<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Emission equivalence
    </h2>

    <v-text-field
      v-model.number="carbon"
      label="CO2"
      type="number"
      class="flex-grow-0"/>
    <div class="pa-2">
      <CyCcmEmissionEquivalence :value="carbon"/>
    </div>
    <div class="primary pa-2">
      <CyCcmEmissionEquivalence
        dark
        :value="carbon"/>
    </div>
  </div>
</template>

<script>
import CyCcmEmissionEquivalence from '@/components/CyCcmEmissionEquivalence.vue'

export default {
  name: 'CyCcmEmissionEquivalenceSandbox',
  components: {
    CyCcmEmissionEquivalence,
  },
  data: () => ({
    carbon: 500,
  }),
}
</script>

<template>
  <div class="resource-instance d-flex py-6 px-4">
    <div class="resource-instance__icon-area text-center">
      <img
        :src="resource._image"
        class="resource-icon">
      <v-chip
        v-if="status"
        x-small
        :class="['resource-status', {
          'v-chip--success': status === 'running',
          'v-chip--warning': status === 'pending',
          'v-chip--error': status === 'stopping',
          'v-chip--default': status === 'unknown',
        }]">
        {{ _.upperFirst(status) }}
      </v-chip>
    </div>

    <div class="resource-instance__details-area resource-details mx-2">
      <!-- first row: name, type, refresh -->
      <div class="resource-details__row pb-2">
        <div class="d-flex justify-space-between">
          <div class="resource-details__name-type d-flex flex-column">
            <span class="h5">
              {{ resource._name }}
            </span>
            <CyInfraViewResourceType
              :resource="resource"
              bottom
              only-show-for-modules/>
          </div>

          <span class="font-weight-bold text-uppercase">
            <v-icon
              v-if="region"
              color="accent">
              place
            </v-icon>
            {{ region }}
          </span>

          <div class="resource-details__refresh-instance d-flex align-start justify-end">
            <!-- TODO: FE#4322 enable refreshing with InfraView 3.0 -->
            <div v-if="false">
              <span
                v-if="refreshing"
                class="refresh-text mr-2">
                {{ $t('refreshing') }}
              </span>

              <CyTooltip left>
                <template #activator="{ on }">
                  <span v-on="on">
                    <CyButton
                      icon="refresh"
                      icon-only
                      theme="secondary"
                      variant="tertiary"
                      sm/>
                  </span>
                </template>
                {{ $t('refreshResourceInstance', { resourceName: resource._name }) }}
              </CyTooltip>
            </div>
          </div>
        </div>
      </div>

      <!-- second row: all properties -->
      <div class="resource-details__row py-2">
        <v-expansion-panels v-model="showConfiguration">
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0">
              <div class="resource-details__property font-weight-bold pr-4">
                {{ _.isUndefined(showConfiguration) ? $t('seeWholeConfiguration') : $t('hideWholeConfiguration') }}
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="pa-0">
              <CyInfraViewProperty
                v-for="[key, value] in propertiesToDisplay"
                :key="key"
                :property="{ key, value }"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <!-- third row: tags -->
      <div class="resource-details__row pt-2">
        <CyTagList
          v-if="!_.isEmpty(tags)"
          :tags="tags"
          contained
          variant="default"
          small/>
        <span
          v-else
          class="no-tags-text">
          {{ $t('noTagsDefined') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CyInfraViewProperty from '@/components/CyInfraViewProperty.vue'
import CyInfraViewResourceType from '@/components/CyInfraViewResourceType.vue'
import CyTagList from '@/components/CyTagList.vue'

export const EXCLUDED_PROPERTIES = ['available', '_private', 'schema', 'tags', 'State', 'instance_state']

export default {
  name: 'CyInfraViewListResourceInstance',
  components: {
    CyInfraViewProperty,
    CyInfraViewResourceType,
    CyTagList,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    refreshing: false,
    showConfiguration: undefined,
  }),
  computed: {
    region () {
      const { availability_zone: zone, region } = this.resource
      return zone || region
    },
    propertiesToDisplay () {
      return _.filter(_.entries(this.resource), ([key, _value]) => !_.includes(EXCLUDED_PROPERTIES, key))
    },
    // possible normalized statuses are: "unknown", "pending", "running", "stopping"
    status () {
      const { status = '' } = this.resource
      return _.lowerCase(this.resource._attributes?.normalized_status[status]) || 'unknown'
    },
    tags () {
      const { tags } = this.resource
      return _.map(tags, (content, label) => ({ label, content }))
    },
  },
  i18n: {
    messages: {
      en: {
        hideWholeConfiguration: 'hide the whole configuration',
        noTagsDefined: 'No tags defined',
        refreshResourceInstance: 'refresh {resourceName}',
        refreshing: 'refresh in progress...',
        seeWholeConfiguration: 'see the whole configuration',
      },
      es: {
        hideWholeConfiguration: 'ocultar toda la configuración',
        noTagsDefined: 'No hay etiquetas definidas',
        refreshResourceInstance: 'actualizar {resourceName}',
        refreshing: 'actualización en progreso...',
        seeWholeConfiguration: 'ver toda la configuración',
      },
      fr: {
        hideWholeConfiguration: 'cacher toute la configuration',
        noTagsDefined: 'Aucune balise définie',
        refreshResourceInstance: 'actualiser {resourceName}',
        refreshing: 'rafraîchissement en cours...',
        seeWholeConfiguration: 'voir toute la configuration',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-instance {
  border-top: 1px solid cy-get-color("grey", "dark-1");

  &__details-area {
    width: calc(100% - 56px);
    max-width: 790px;
  }

  &__icon-area {
    width: 56px;

    .resource-icon {
      width: 40px;
      height: 40px;
    }

    .resource-status {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .resource-details {
    &__row {
      ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
      }

      &:not(:last-child) {
        border-bottom: solid 1px cy-get-color("grey");
      }
    }

    &__name-type {
      width: 250px;
    }

    &__property {
      color: cy-get-color("primary");
    }

    &__resource-type {
      color: cy-get-color("grey", "dark-1");
      font-size: $font-size-sm;
    }

    &__refresh-instance {
      position: relative;
      top: -6px;
      right: -4px;
      width: 200px;
    }

    .no-tags-text {
      color: cy-get-color("grey", "dark-1");
      font-size: $font-size-sm;
    }
  }

  ::v-deep .resource-type {
    margin-top: 1px;
    font-size: 12px;
  }
}
</style>

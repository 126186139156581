<template>
  <h3 class="d-flex justify-space-between">
    <span v-if="!isCollapsed">
      {{ $t('filters') }}
    </span>
    <CyTooltip
      :key="`tooltip-sidebar-${isCollapsed}`"
      left>
      <template #activator="{ on }">
        <CyButton
          class="mr-2"
          data-cy="link-collapse-filter"
          icon-only
          variant="tertiary"
          theme="primary"
          :icon="isCollapsed ? 'first_page' : 'last_page'"
          v-on="on"
          @click="setSidebar(!isCollapsed)"/>
      </template>
      {{ $t('toggleFiltersPanel') }}
    </CyTooltip>
  </h3>
</template>

<script>
export default {
  name: 'CyCcmSidebarToggle',
  data: () => ({
    isCollapsed: false,
  }),
  created () {
    this.setSidebar(localStorage.getItem(LSK.CCM_SIDEBAR_COLLAPSED) === 'true')
  },
  methods: {
    setSidebar (value) {
      this.isCollapsed = value
      this.$emit('sidebar-collapse', value)
      localStorage.setItem(LSK.CCM_SIDEBAR_COLLAPSED, value)
    },
  },
  i18n: {
    messages: {
      en: {
        toggleFiltersPanel: 'Toggle Filters panel',
      },
      es: {
        toggleFiltersPanel: 'Conmutar panel de filtros',
      },
      fr: {
        toggleFiltersPanel: 'Basculer le panneau des filtres',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-btn {
  margin-top: -8px;
  margin-bottom: -8px;
}
</style>

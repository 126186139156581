<template>
  <div class="d-flex flex-column grow white mt-0">
    <div class="toolbar space-x-4">
      <CyButton
        variant="secondary"
        data-cy="samples-button"
        @click="$toggle.show.samplesModal(true)">
        {{ $t('samplesButton') }}
      </CyButton>

      <CyButtonToggle
        v-model="editorMode"
        :items="$static.editorMode"
        class="ml-auto"/>

      <CyTooltip left>
        <template #activator="{ on: expandTooltip }">
          <CyButton
            data-cy="toggle-fullscreen"
            class="ml-2"
            theme="primary"
            variant="tertiary"
            :icon="isFullscreen ? 'fullscreen_exit' : 'fullscreen'"
            icon-only
            sm
            @click="TOGGLE_FULL_SCREEN"
            v-on="expandTooltip"/>
        </template>
        <span>{{ $t('fullScreen') }}</span>
      </CyTooltip>
    </div>

    <CyFormsFormsEditor
      v-model="editorContent"
      class="grow"
      :errors="formsValidationErrors"
      :editor-content="editorContent"
      :editor-mode="editorMode"
      :forms="forms"/>

    <footer
      :class="['footer', {
        'sticky-footer__footer--full-screen': isFullscreen,
      }]">
      <CyButton
        class="footer-controls__left--validate"
        data-cy="validate-button"
        variant="primary"
        :disabled="!editorContentUpdated"
        @click="validate">
        {{ $t('actionValidate') }}
      </CyButton>

      <div
        data-cy="validation-status"
        class="ml-2 mr-auto">
        <p class="mb-0">
          <template v-if="validationStatus === $static.status.VALIDATION_IN_PROGRESS">
            <v-progress-circular
              class="loading-spinner"
              indeterminate
              size="22"
              color="secondary"/>
            {{ $t('validation.pending') }}
          </template>
          <template v-if="validationStatus === $static.status.CHANGES_DETECTED">
            {{ $t('validation.ready') }}
          </template>
          <template v-if="validationStatus === $static.status.VALIDATION_FAILED">
            <v-icon
              data-cy="alert-icon"
              color="error"
              class="mr-1">
              mdi-alert-circle
            </v-icon>
            {{ $t('validation.failure') }}
          </template>
          <template v-if="validationStatus === $static.status.VALIDATION_SUCCEEDED">
            <v-icon
              data-cy="success-icon"
              color="secondary"
              class="mr-1">
              mdi-check-circle
            </v-icon>
            {{ $t('validation.success', { time: lastSuccessTime }) }}
          </template>
          <template v-if="validationStatus === $static.status.NO_CHANGES_DETECTED">
            {{ $t('validation.standby') }}
          </template>
        </p>
      </div>

      <CyCopyButton
        data-cy="copy-button"
        copy-hint="yml"
        left
        :disabled="validationStatus !== $static.status.VALIDATION_SUCCEEDED"
        :copy-value="editorContent"/>
      <CyButton
        data-cy="download-button"
        class="ml-2"
        icon="mdi-download"
        icon-only
        sm
        download=".forms.yaml"
        :disabled="validationStatus !== $static.status.VALIDATION_SUCCEEDED"
        :href="downloadContent()"/>
    </footer>

    <!-- Samples Modal -->
    <CyModal
      v-if="show.samplesModal"
      data-cy="samples-modal"
      action-btn-hidden
      :header-title="$t('samples.modalHeader')"
      :cancel-btn-func="() => $toggle.show.samplesModal(false)"
      modal-type="create">
      <div>
        <section
          v-for="(sample, sampleIndex) in $static.samples"
          :key="sampleIndex"
          class="sample">
          <div>
            <header
              class="sample__title">
              {{ sample.header }}
            </header>
            <p class="sample__body">
              {{ sample.body }}
            </p>
          </div>
          <CyButton
            @click="fillModalWith(sample.content)">
            {{ $t('forms.btnSelect') }}
          </CyButton>
        </section>
      </div>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CyButtonToggle from '@/components/CyButtonToggle.vue'
import CyCopyButton from '@/components/CyCopyButton.vue'
import CyFormsFormsEditor from '@/components/CyFormsFormsEditor.vue'
import { formExamples } from '@/utils/forms'
import { constructBreadcrumb } from '@/utils/helpers'
import * as yaml from 'js-yaml'
import MD5 from 'md5.js'

export const STATUS = {
  VALIDATION_IN_PROGRESS: 1,
  CHANGES_DETECTED: 2,
  VALIDATION_FAILED: 3,
  VALIDATION_SUCCEEDED: 4,
  NO_CHANGES_DETECTED: 5,
}

export default {
  name: 'CyPageFormsEditor',
  components: {
    CyButtonToggle,
    CyCopyButton,
    CyFormsFormsEditor,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.formsEditor'), [
      {
        label: this.$t('routes.infraTools'),
        name: 'infraTools',
      },
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('title'),
      description: {
        text: this.$t('description'),
      },
    }
  },
  data: () => ({
    lastSubmittedContentHash: null,
    validationInProgress: false,
    lastSuccessTime: null,
    editorContent: '',
    editorMode: 'preview',
    show: {
      samplesModal: false,
      editorComponent: true,
    },
  }),
  computed: {
    ...mapState({
      isFullscreen: (state) => state.layout.isFullScreen,
    }),
    ...mapState('organization/project', {
      forms: (state) => state.validatedForms || {},
      formsValidationErrors: (state) => state.errors.formsValidation,
    }),
    $static () {
      return {
        editorMode: [
          {
            key: 'preview',
            value: 'preview',
            text: this.$t('editorModePreview'),
          },
          {
            key: 'editor',
            value: 'editor',
            text: this.$t('editorModeEditor'),
          },
        ],
        samples: [
          {
            header: this.$t('samples.commonUseCaseSample.header'),
            body: this.$t('samples.commonUseCaseSample.content'),
            content: formExamples.commonUseCase,
          },
          {
            header: this.$t('samples.multiUseCaseSample.header'),
            body: this.$t('samples.multiUseCaseSample.content'),
            content: formExamples.formsWithUseCases,
          },
          {
            header: this.$t('samples.conditionalFieldsSample.header'),
            body: this.$t('samples.conditionalFieldsSample.content'),
            content: formExamples.formsWithConditions,
          },
          {
            header: this.$t('samples.dynamicValuesSample.header'),
            body: this.$t('samples.dynamicValuesSample.content'),
            content: formExamples.formsWithDynamicValues,
          },
          {
            header: this.$t('samples.sharedVariablesSample.header'),
            body: this.$t('samples.sharedVariablesSample.content'),
            content: formExamples.formsWithSharedVariables,
          },
        ],
        status: STATUS,
      }
    },
    hasContent () {
      return !!this.editorContent.trim()
    },
    contentHash () {
      return new MD5().update(this.editorContent.trim()).digest('hex')
    },
    editorContentUpdated () {
      return (this.contentHash !== this.lastSubmittedContentHash) && this.hasContent
    },
    validationStatus () {
      if (this.validationInProgress) return STATUS.VALIDATION_IN_PROGRESS
      if (this.editorContentUpdated) return STATUS.CHANGES_DETECTED
      if (!_.isEmpty(this.formsValidationErrors)) return STATUS.VALIDATION_FAILED
      if (this.lastSuccessTime && this.hasContent) return STATUS.VALIDATION_SUCCEEDED

      return STATUS.NO_CHANGES_DETECTED
    },
  },
  watch: {
    editorContent: {
      handler (editorContent) {
        // Clear out the forms and reset the validation status
        // if the editor is cleared out
        if (editorContent === '') this.resetState()
      },
      immediate: true,
    },
  },
  created () {
    this.resetState()
  },
  mounted () {
    try {
      const lastValidated = localStorage.getItem(LSK.STACKFORMS_FORM_VALIDATION)
      if (lastValidated !== null) this.editorContent = atob(lastValidated)
    } catch (e) {
      console.error('Failed to set forms from Local Storage', e)
    }

    this._keyListener = function (e) {
      if ((e.metaKey || e.ctrlKey || e.shiftKey) && e.keyCode === 13) {
        if (!this.editorContentUpdated) return

        e.preventDefault()
        this.validate()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  errorCaptured: function (error) {
    this.SHOW_ALERT({ type: 'error', title: 'Unhandled error encountered', content: error.message, keepOpen: false })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this._keyListener)
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization/project', [
      'VALIDATE_FORMS_CONFIG',
      'RESET_FORM_VALIDATION_STATE',
    ]),
    ...mapMutations('organization/project', [
      'SET_ERRORS',
    ]),
    ...mapMutations('layout', [
      'TOGGLE_FULL_SCREEN',
    ]),
    downloadContent () {
      return `data:application/x-yaml;charset=utf-8,${encodeURIComponent(this.editorContent)}`
    },
    fillModalWith (content) {
      this.editorContent = atob(content).trim()
      this.show.samplesModal = false
      this.validate()
    },
    isValidJson (content) {
      try {
        JSON.parse(content)
      } catch (e) {
        return false
      }
      return true
    },
    objectFromYaml (content) {
      try {
        const parsedYaml = yaml.load(content)

        // Only objects are considered valid yaml
        if (!_.isObject(parsedYaml)) {
          throw new Error('Invalid yaml')
        }

        // Json is parsed by yaml.load() so we will block any valid json string
        if (this.isValidJson(content)) {
          throw new Error('JSON input is not supported')
        }

        return parsedYaml
      } catch (error) {
        this.RESET_FORM_VALIDATION_STATE()
        this.SET_ERRORS({
          key: 'formsValidation',
          errors: [{ code: error.name, message: error.message }],
        })
        return null
      }
    },
    prepareForValidation () {
      this.validationInProgress = true
      this.lastSubmittedContentHash = this.contentHash
      this.lastSuccessTime = null
    },
    resetState () {
      this.RESET_FORM_VALIDATION_STATE()
      this.validationInProgress = false
      this.lastSubmittedContentHash = this.contentHash
      this.lastSuccessTime = null
    },
    async validate () {
      this.prepareForValidation()

      const formsFile = this.objectFromYaml(this.editorContent)
      if (formsFile === null) {
        this.validationFailed()
        return
      }

      localStorage.setItem(LSK.STACKFORMS_FORM_VALIDATION, btoa(this.editorContent))
      await this.VALIDATE_FORMS_CONFIG({ form_file: formsFile })
      this.validationSuccessful()
    },
    validationFailed () {
      this.validationInProgress = false
      this.lastSuccessTime = null
    },
    validationSuccessful () {
      this.validationInProgress = false
      this.lastSuccessTime = $date.format(new Date(), 'HH:mm')
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.formsEditor',
        description: 'Compose and validate the form files of your stacks (.forms.yml), to expose parameters that users can configure when using it.',
        importButton: 'Import',
        samplesButton: 'Samples',
        editorModePreview: 'Side-by-side',
        editorModeEditor: '@:editor',
        actionValidate: 'Validate',
        validation: {
          standby: 'No changes detected.',
          ready: 'Changes have been detected. Please Validate.',
          pending: 'Validating',
          success: 'No errors were found. Last validated {time}.',
          failure: 'Errors were found.',
        },
        samples: {
          modalHeader: 'Import sample files to get started',
          commonUseCaseSample: {
            header: 'Basic form',
            content: 'A form that showcases how to structure a form with widgets, groups, and sections.',
          },
          multiUseCaseSample: {
            header: 'Multi use cases',
            content: 'A form that leverages use cases to support multiple cloud providers.',
          },
          conditionalFieldsSample: {
            header: 'Conditional fields',
            content: 'Dynamically reveal fields based on the state of another field.',
          },
          dynamicValuesSample: {
            header: 'Dynamic values',
            content: 'Dynamically display different widget values/options based on the state of another field.',
          },
          sharedVariablesSample: {
            header: 'Shared variables',
            content: 'Simplify the configuration of a stack, without defining variables multiple times across technologies if they are identical.',
          },
        },
      },
      es: {
        title: '@:routes.formsEditor',
        description: 'Componga y valide los archivos de formulario de sus stacks (.forms.yml), para exponer los parámetros que los usuarios pueden configurar al usarlo.',
        importButton: 'Importar',
        samplesButton: 'Muestras',
        editorModePreview: 'Lado a lado',
        editorModeEditor: '@:editor',
        actionValidate: 'Validar',
        validation: {
          standby: 'No se detectaron cambios.',
          ready: 'Se han detectado cambios. Por favor, valide.',
          pending: 'Validando',
          success: 'No se encontraron errores. Última validación {time}.',
          failure: 'Se encontraron errores.',
        },
        samples: {
          modalHeader: 'Importe archivos de muestra para comenzar',
          commonUseCaseSample: {
            header: 'Formulario básico',
            content: 'Un formulario que muestra cómo estructurar un formulario con widgets, grupos y secciones.',
          },
          multiUseCaseSample: {
            header: 'Casos de usos múltiples',
            content: 'Un formulario que aprovecha los casos de uso para admitir múltiples proveedores de nube.',
          },
          conditionalFieldsSample: {
            header: 'Campos condicionales',
            content: 'Revela campos de forma dinámica en función del estado de otro campo.',
          },
          dynamicValuesSample: {
            header: 'Valores dinámicos',
            content: 'Muestre dinámicamente diferentes valores/opciones de widgets en función del estado de otro campo.',
          },
          sharedVariablesSample: {
            header: 'Variables compartidas',
            content: 'Simplifique la configuración de un stack, sin definir variables varias veces entre tecnologías si son idénticas.',
          },
        },
      },
      fr: {
        title: '@:routes.formsEditor',
        description: 'Composez et validez les fichiers de formulaire de vos stacks (.forms.yml), pour exposer les paramètres que les utilisateurs peuvent configurer lors de leur utilisation.',
        importButton: 'Importer',
        samplesButton: 'Modèles',
        editorModePreview: 'Côte à côte',
        editorModeEditor: '@:editor',
        actionValidate: 'Valider',
        validation: {
          standby: 'Aucun changement détecté.',
          ready: 'Des changements ont été détectés, veuillez valider.',
          pending: 'Validation en cours',
          success: `Aucune erreur n'a été détectée. Dernière validation à {time}.`,
          failure: 'Des erreurs ont été détectées.',
        },
        samples: {
          modalHeader: 'Importez des modèles de fichiers pour commencer',
          commonUseCaseSample: {
            header: 'Formulaire basique',
            content: 'Un formulaire qui montre comment structurer un formulaire avec des widgets, des groupes et des sections.',
          },
          multiUseCaseSample: {
            header: `Cas d'usage multiples`,
            content: `Un formulaire qui tire parti des cas d'usage pour prendre en charge plusieurs fournisseurs de cloud.`,
          },
          conditionalFieldsSample: {
            header: 'Champs conditionnels',
            content: `Révélez dynamiquement des champs en fonction de l'état d'un autre champ.`,
          },
          dynamicValuesSample: {
            header: 'Valeurs dynamiques',
            content: `Affichez dynamiquement différentes valeurs/options de widget en fonction de l'état d'un autre champ.`,
          },
          sharedVariablesSample: {
            header: 'Variables partagées',
            content: `Simplifiez la configuration d'une stack, sans définir de variables plusieurs fois sur plusieurs technologies si elles sont identiques.`,
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  align-items: center;
  padding: $spacer-4;
  border-bottom: solid 1px cy-get-color("grey", "light-2");
}

.forms-editor {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  min-height: 0;
}

.footer {
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: solid 1px cy-get-color("grey", "light-2");

  ::v-deep .cy-copy-btn {
    width: 32px;
    height: 32px;
    border: 1px solid cy-get-color("secondary") !important;
    border-radius: 50%;
    background-color: cy-get-color("secondary", "light-4");

    > i {
      color: cy-get-color("secondary") !important;
      font-size: 20px;
    }

    &[disabled] {
      border-color: cy-get-color("grey", "dark-1") !important;
      background-color: cy-get-color("white");
      color: cy-get-color("grey", "dark-1") !important;
      cursor: not-allowed;

      > i,
      > i:hover {
        color: cy-get-color("grey", "dark-1") !important;
      }
    }
  }
}

.sample {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: rgba(0  0  0 / 0.16) 0 1px 4px;

  &__title {
    margin-bottom: 8px;
    padding-top: 8px;
    font-size: 14px;
    font-weight: $font-weight-bolder;
    line-height: 120%;
  }

  &__body {
    margin-bottom: 8px;
  }
}
</style>

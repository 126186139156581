<template>
  <div class="data-charts">
    <div class="cost-chart">
      <h3
        class="cost-chart__title mb-4"
        v-text="$t('cloudCostManagement.cost')"/>
      <ECharts
        ref="costChart"
        class="bar-chart"
        theme="cycloid"
        autoresize
        :option="options('cost')"
        @legendselectchanged="syncLegend"
        @highlight="syncHighlighting"
        @downplay="syncHighlighting"/>
    </div>
    <div class="d-flex justify-space-between">
      <div class="co2e-chart">
        <h3
          class="cost-chart__title my-4"
          v-text="$t('cloudCostManagement.carbonEmissions')"/>
        <ECharts
          ref="co2eChart"
          class="bar-chart"
          theme="cycloid"
          autoresize
          :option="options('co2e')"
          @highlight="syncHighlighting"
          @downplay="syncHighlighting"/>
      </div>
      <div class="kwh-chart">
        <h3
          class="cost-chart__title my-4"
          v-text="$t('cloudCostManagement.energyConsumption')"/>
        <ECharts
          ref="kwhChart"
          class="bar-chart"
          theme="cycloid"
          autoresize
          :option="options('kwh')"
          @highlight="syncHighlighting"
          @downplay="syncHighlighting"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrencySymbol } from '@/utils/helpers'
import {
  formatDate,
  formatTooltip,
  getDisplayName,
  getTrimmedSeries,
  graphColors,
  graphTypes,
  graphTypesConfig,
} from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCcmCostChart',
  props: {
    series: {
      type: Object,
      required: true,
    },
    totals: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
      validator: (type) => graphTypes.includes(type),
    },
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      currency: (state) => state.queryBody.currency,
      graphOrderingParam: (state) => state.graphOrderingParam,
      groupBy: (state) => state.queryBody.group_by,
    }),
  },
  methods: {
    getCurrencySymbol,
    formatDate,
    getTrimmedSeries,
    options (type) {
      const trimmedSeries = this.getTrimmedSeries(this.series, this.graphOrderingParam)

      const mergingFn = (firstValue, secondValue) => {
        if (_.isArray(firstValue) && _.isObject(secondValue)) {
          return _.map(firstValue, (obj) => _.merge(obj, secondValue))
        }
      }

      const mainGroupingParam = this.$route.name === 'cloudCostManagementDashboard' ? 'project' : this.groupBy[0]

      const axisNames = {
        cost: `${this.getCurrencySymbol(this.currency)} - ${this.currency}`,
        co2e: 'KgCO2e',
        kwh: 'kWh',
      }

      return _.mergeWith({
        xAxis: {
          type: 'category',
          data: _.map(_.values(trimmedSeries)[0], ({ date }) => this.formatDate(date)),
          boundaryGap: false,
          axisLabel: {
            formatter: (date) => $date.format(new Date(date), 'MMM d'),
          },
        },
        yAxis: {
          type: 'value',
          name: axisNames[type],
          nameLocation: 'end',
          nameTextStyle: {
            color: '#131A25',
            fontWeight: '500',
            align: 'left',
            padding: [0, 0, type === 'cost' ? 60 : 4, 0],
          },
          position: 'right',
          axisLabel: {
            formatter: '{value}',
          },
          splitNumber: 2,
        },
        grid: {
          top: type === 'cost' ? 100 : 40,
          bottom: type === 'cost' ? 343 : 0,
          left: 0,
          right: 40,
          containLabel: true,
        },
        legend: {
          type: 'scroll',
          padding: [24, 0],
          itemWidth: 6,
          itemHeight: 6,
          itemGap: 24,
          show: type === 'cost' && !_.every(_.keys(trimmedSeries), (key) => key === 'undefined'),
          left: 0,
          bottom: 12,
          icon: 'circle',
          textStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 6],
          },
          formatter: (canonical) => getDisplayName(canonical, _.camelCase(mainGroupingParam)),
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: (series) => formatTooltip(series, _.camelCase(mainGroupingParam), type, this.currency, this.totals),
        },
        series: _.map(trimmedSeries, (data, name) => ({
          name,
          data: _.map(data, (item) => [this.formatDate(item.date), item[type]]),
          showSymbol: false,
          itemStyle: {
            color: graphColors[_.keys(trimmedSeries).indexOf(name)],
          },
          barWidth: '100%',
          emphasis: {
            disabled: false,
            areaStyle: {
              color: 'red',
              opacity: 0.5,
            },
          },
        })),
      }, graphTypesConfig[this.type] || {}, mergingFn)
    },
    syncLegend ({ selected }) {
      _.toPairs(selected).forEach(([name, value]) => {
        const type = value ? 'legendSelect' : 'legendUnSelect'
        this.$refs.co2eChart.dispatchAction({ type, name })
        this.$refs.kwhChart.dispatchAction({ type, name })
      })
    },
    async syncHighlighting ({ type, batch }) {
      if (_.isEmpty(batch)) return
      const { dataIndex } = _.head(batch)
      this.$refs.costChart.dispatchAction({ type, dataIndex })
      this.$refs.co2eChart.dispatchAction({ type, dataIndex })
      this.$refs.kwhChart.dispatchAction({ type, dataIndex })
    },
  },
}
</script>

<style lang="scss" scoped>
.cost-chart {
  width: 100%;
  height: 660px;
}

.co2e-chart,
.kwh-chart {
  width: 48%;
  height: 180px;
  margin-top: -275px;
}

.echarts {
  width: unset !important;
}
</style>

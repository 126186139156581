var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"fetch-available":{ keyPath: 'teams' },"bulk":_vm.hasBulkModeEnabled,"headers":_vm.$static.headers,"link-builder":_vm.getLinkTarget,"searchable-fields":_vm.$static.searchableFields,"key-field":"canonical"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
var selected = ref.selected;
return [(!_vm._.isEmpty(selected))?[_c('CyDevButton',{staticClass:"my-0 mr-2",nativeOn:{"click":function($event){return _vm.setMissingOwners(selected)}}},[_vm._v(" Set missing owner ")]),_c('CyButton',{attrs:{"theme":"error","icon":"delete"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm.$tc('deleteTeamBtn', selected.length, { nb: selected.length }))+" ")])]:_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateTeam'),expression:"'CreateTeam'"}],attrs:{"icon":"add","to":{ name: 'newTeam' }},on:{"click":function($event){return _vm.$gtm.trackEvent(_vm.$static.gtmOrganizationsEvents.organizationsCreateNewTeam)}}},[_vm._v(" "+_vm._s(_vm.$t('createTeamBtn'))+" ")]),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$tc('confirmDeleteTitle', _vm.toDelete.length),"action-btn-func":_vm.onDelete,"action-btn-text":_vm.$tc('deleteTeamBtn', selected.length, { nb: selected.length }),"cancel-btn-func":_vm.closeDeleteModal,"modal-type":"delete"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('areYouSure', _vm.toDelete.length, { item: _vm.toDelete[0].name }))}})]),_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.toDelete),function(ref){
var canonical = ref.canonical;
var name = ref.name;
return _c('li',{key:canonical},[_c('b',[_vm._v(_vm._s(name))])])}),0)]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var item = ref.props.item;
return [_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.getRolesNamesList(item.roles))+" ")]),_c('td',[(_vm.canUpdateOwner(item))?_c('CyFormsAssignOwner',{attrs:{"errors":_vm.errors,"form-content":item,"action-btn-func":_vm.assignNewOwner,"sm":""}}):_c('CyButton',{attrs:{"to":{
            name: 'member',
            params: {
              id: String(item.owner.id),
              backRouteTo: 'teams',
            },
          },"disabled":!_vm.canGoToMember(item),"readonly":!_vm.canGoToMember(item),"theme":"grey","variant":"tertiary","sm":"","member-link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('CyMember',{attrs:{"member":item.owner,"simple":"","sm":""}})],1)],1),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"member__container"},[_c('span',{staticClass:"member__count"},[_vm._v(_vm._s(item.member_count))]),_vm._l((item.members_preview),function(member){return _c('CyTooltip',{key:("member-" + (member.id)),attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('CyButton',_vm._g({staticClass:"mr-1",attrs:{"to":{
                  name: 'member',
                  params: {
                    id: String(member.id),
                    backRouteTo: 'teams',
                  },
                },"disabled":!_vm.canGoToMember(member),"readonly":!_vm.canGoToMember(member),"theme":"grey","variant":"tertiary","light":"","member-link":"mini"},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('CyMember',{attrs:{"member":member,"avatar-only":"","sm":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.displayName(member)))])])}),(item.member_count > item.members_preview.length)?_c('span',{staticClass:"text-truncate"},[_vm._v(" ... ")]):_vm._e()],2)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
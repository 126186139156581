<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Provider breakdown widget
    </h2>

    <CyCcmWidgetsProviderBreakdown/>
  </div>
</template>

<script>
import CyCcmWidgetsProviderBreakdown from '@/components/CyCcmWidgetsProviderBreakdown.vue'

export default {
  name: 'CyCcmWidgetsProviderBreakdownSandbox',
  components: {
    CyCcmWidgetsProviderBreakdown,
  },
}
</script>

<template>
  <div
    class="element-property"
    @click="triggerCopy">
    <span
      v-if="property.key"
      class="element-property__key">
      {{ property.key }}:
    </span>

    <CyInfraViewMapProperty
      v-if="isSet(property.value)"
      class="element-property__value"
      :map-property="property.value[0]"/>

    <CyInfraViewListProperty
      v-else-if="_.isArray(property.value)"
      class="element-property__value"
      :list-property="property.value"/>

    <CyInfraViewMapProperty
      v-else-if="_.isPlainObject(property.value)"
      class="element-property__value"
      :map-property="property.value"/>

    <template v-else>
      <span class="element-property__value">{{ property.value }}</span>
      <span class="copy-value-btn">
        <CyCopyButton
          ref="copyBtn"
          :copy-value="String(property.value)"
          small/>
      </span>
    </template>
  </div>
</template>

<script>
import CyCopyButton from '@/components/CyCopyButton.vue'
import CyInfraViewListProperty from '@/components/CyInfraViewListProperty.vue'
import CyInfraViewMapProperty from '@/components/CyInfraViewMapProperty.vue'

export default {
  name: 'CyInfraViewProperty',
  components: {
    CyInfraViewListProperty,
    CyInfraViewMapProperty,
    CyCopyButton,
  },
  props: {
    property: {
      type: Object,
      validator: (property) => _.has(property, 'value'),
      required: true,
    },
  },
  methods: {
    isSet (value) {
      return _.isArray(value) && _.every(value, _.isPlainObject) && value.length === 1
    },
    triggerCopy () {
      if (_.has(this.$refs, 'copyBtn')) this.$refs.copyBtn.copy(this.property.value)
    },
  },
}
</script>

<style lang="scss" scoped>
  .element-property {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1em;
    padding-left: 0.5em;
    color: cy-get-color("primary");
    cursor: pointer;

    &__key {
      flex: 0 1 auto;
      padding-right: 0.25em;
      font-weight: $font-weight-bold;
    }

    &__value {
      display: inline;
      word-break: break-all;
    }

    > .copy-value-btn {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      text-align: right;

      ::v-deep .cy-copy-btn > i {
        color: cy-get-color("secondary") !important;
      }
    }

    &:hover {
      > .copy-value-btn {
        display: inline;
      }
    }

    &--map,
    &--list {
      margin-bottom: 0.5em;
    }
  }

</style>

import mock from './componentStubs'

export default {
  /* eslint sort-keys: "error" */
  CyButton: () => {
    jest.mock('@/components/CyButton.vue', () => mock.CyButton)
  },
  CyTooltip: () => {
    jest.mock('@/components/CyTooltip.vue', () => mock.CyTooltip)
  },
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-circular',{staticClass:"loading-spinner",attrs:{"indeterminate":"","size":"100","color":"secondary"}}):_c('div',{staticClass:"catalog-repository"},[_c('div',{staticClass:"catalog-repository__content"},[_c('CyAlert',{attrs:{"theme":"error","content":_vm.errors}}),_c('CyWizardServiceCatalog',{attrs:{"hide-use-btns":"","has-create-stack-btn-visible":false,"catalog-repository-canonical":_vm.catalogRepositoryCanonical}})],1),_c('div',{staticClass:"catalog-repository__sidebar"},[_c('div',{staticClass:"sidebar"},[(_vm.catalogRepository)?_c('div',{staticClass:"sidebar__section"},[_c('p',{staticClass:"sidebar__title"},[_vm._v(" "+_vm._s(_vm.$t('stacks.gitRepo'))+" ")]),(_vm.hasRepoURL)?_c('a',{staticClass:"cy-link cy-link--external d-flex align-center",attrs:{"href":_vm.$sanitizeUrl(_vm.repoURL),"target":"_blank","rel":"noopener noreferrer"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16","color":"primary"}},[_vm._v(" "+_vm._s(_vm.repoInfo.provider.icon)+" ")]),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.repoInfo.text))])],1):_c('span',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.catalogRepository.url)+" ")])]):_vm._e(),(_vm._.get(_vm.catalogRepository, 'credential_canonical'))?_c('div',{staticClass:"sidebar__section"},[_c('div',{staticClass:"sidebar__title"},[_vm._v(" "+_vm._s(_vm.$t('Credential'))+" ")]),_c('router-link',{staticClass:"cy-link d-flex align-center",attrs:{"to":{
              name: 'credential',
              params: {
                credentialCanonical: _vm.catalogRepository.credential_canonical,
                backRouteTo: 'catalogRepositories',
              },
            }}},[_c('span',{staticClass:"truncate ml-1"},[_vm._v(_vm._s(_vm.catalogRepository.credential_canonical))])])],1):_vm._e(),_c('CyWizardStackInsightsCard',{staticClass:"sidebar__section"})],1)])]),(_vm.refreshedRepository)?_c('CyModal',{attrs:{"modal-type":"success","cancel-btn-text":_vm.$t('forms.btnClose'),"header-title":("" + (_vm.$t('repoRefreshSuccess', { repo: _vm.refreshedRepository }))),"cancel-btn-func":function () { return _vm.refreshedRepository = null; },"action-btn-hidden":""}},[(_vm.repositoryStackChanges.length)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},_vm._l((_vm.repositoryStackChanges),function(ref){
            var typeOfChange = ref[0];
            var stacks = ref[1];
return _c('div',{key:typeOfChange},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s((typeOfChange + " " + (_vm._.toLower(_vm.$tc('stackCount', stacks.length, { count: stacks.length })))))+" ")]),_c('CyTagList',{staticClass:"d-flex flex-column mt-2",attrs:{"tags":stacks},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
            var tag = ref.tag;
return [_c('CyTag',{attrs:{"variant":_vm.stackVariant(typeOfChange)}},[_vm._v(" "+_vm._s(tag.canonical)+" ")])]}}],null,true)})],1)}),0)]):_c('p',[_vm._v(" "+_vm._s(_vm.$t('noStacksChanged'))+" ")])]):_vm._e(),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$t('delete.header'),"action-btn-func":_vm.onDeleteConfirm,"cancel-btn-func":function () { return _vm.$toggle.showDeleteModal(false); },"close-modal-on-action-click":false,"loading":_vm.deleting,"modal-type":"delete","small":""}},[_c('p',[_vm._v(_vm._s(_vm.$t('delete.sentence')))]),_c('h3',[_vm._v(_vm._s(_vm.catalogRepository.name))]),_c('p',{staticClass:"url"},[_vm._v(" "+_vm._s(_vm.catalogRepository.url)+" ")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t('delete.repository'))+" ")])]):_vm._e(),_c('portal',{attrs:{"to":"header-actions"}},[_c('div',{staticClass:"d-flex justify-end space-x-2"},[(!_vm.loading)?_c('CyWizardStackCloneMenu',{attrs:{"catalog-repository":_vm.catalogRepository}}):_vm._e(),(!_vm.$isCreationRoute)?_c('CyTooltip',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(['RefreshServiceCatalogSource', _vm.catalogRepositoryCanonical]),expression:"['RefreshServiceCatalogSource', catalogRepositoryCanonical]"}],attrs:{"max-width":240,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('CyButton',_vm._g({attrs:{"loading":_vm.refreshing,"icon":"refresh","data-cy":"refresh-button","variant":"secondary","theme":"primary"},on:{"click":_vm.refreshCR}},on),[_vm._v(" "+_vm._s(_vm.$t('forms.btnRefresh'))+" ")])]}}],null,false,3434456819)},[_vm._v(" "+_vm._s(_vm.$t('refreshCRTooltip'))+" ")]):_vm._e(),(!_vm._.$isEmpty(_vm.$static.menuItems))?_c('CyMenu',{attrs:{"bottom":"","left":"","offset-y":"","items":_vm.$static.menuItems},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('CyButton',_vm._g(_vm._b({attrs:{"icon":"more_horiz","theme":"primary","variant":"tertiary","aria-label":"More actions","icon-only":""}},'CyButton',attrs,false),on))]}}],null,false,3797305048)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
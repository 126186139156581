import { render, staticRenderFns } from "./CyPageInfraPolicies.vue?vue&type=template&id=eae63c80&scoped=true"
import script from "./CyPageInfraPolicies.vue?vue&type=script&lang=js"
export * from "./CyPageInfraPolicies.vue?vue&type=script&lang=js"
import style0 from "./CyPageInfraPolicies.vue?vue&type=style&index=0&id=eae63c80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eae63c80",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VChip,VCol,VSwitch})

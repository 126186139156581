<template>
  <v-col>
    <CyAlert
      theme="error"
      :content="errors"/>
    <CyAlert
      v-if="_.isEmpty(configRepository) && $cycloid.permissions.canDisplay('GetConfigRepository')"
      class="mx-n3"
      theme="warning"
      :title="$t('missingConfigRepo')"
      :content="$t('missingConfigRepoInfo')"
      :button-label="$t('addConfigRepo')"
      @click="$router.push({ ...$route, name: 'projectConfiguration' })"/>
    <v-row class="environments-wrapper height-100">
      <v-col
        class="layout-general pl-0"
        cols="12"
        md="7"
        lg="8"
        xl="9">
        <CyEnvironmentListTable
          :environments="environments"
          :project-canonical="projectCanonical"
          :config-repository="configRepository"
          :loading="status.loadingPipelines || status.loadingEnvironments"
          @start="startEnvironment"
          @stop="stopEnvironment"
          @delete="onDeleteButtonClick"/>
        <div class="content-title h5 mt-7 font-weight-bold">
          {{ $t('latestActivity') }}
        </div>
        <template v-if="!status.loadingEvents">
          <CyEventsTimeline
            v-if="events.length"
            :show-tags="false"
            :indent-items="true"
            :events="events.slice(0, 10)"/>
          <!-- This will probably never see the light of day, but we have it
           in case the API returns 0 events for some reason. -->
          <v-card
            v-else
            class="d-flex justify-center text-center mt-4 mb-2 py-4"
            outlined>
            <v-card-text>
              <v-icon
                class="mb-6"
                color="#90A5C1"
                size="26">
                monitor_heart
              </v-icon>
              <div class="h5 mb-2">
                {{ $t('noActivityHeadline') }}
              </div>
              <p class="mb-2">
                {{ $t('noActivityText') }}
              </p>
            </v-card-text>
          </v-card>
        </template>
        <v-skeleton-loader
          v-else
          class="my-4 width-50"
          type="text@2"/>
        <CyButton
          v-if="events.length"
          class="mt-2"
          variant="secondary"
          theme="primary"
          :to="{ name: 'projectActivity' }">
          {{ $t('viewAllActivity') }}
        </CyButton>
      </v-col>
      <v-col
        class="side-column overflow-hidden"
        cols="12"
        md="5"
        lg="4"
        xl="3">
        <h2 class="content-title mb-2 font-size-base font-weight-bold">
          {{ $t('Stack') }}
        </h2>
        <div
          v-if="loadingStack"
          class="d-flex flex-column sk-block pa-4 mb-5 space-y-7">
          <div class="d-flex space-x-4">
            <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
            <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
            <div class="sk-block sk-h-8 sk-dark sk-w-24"/>
          </div>
          <div class="sk-block sk-dark sk-h-6 sk-full-width"/>
        </div>
        <CyWizardServiceCard
          v-else
          class="mb-5"
          hide-more-btn
          :on-click-action="() => { previewedStack = stack }"
          :service="stack"/>
        <div class="content-title mb-2 font-weight-bold">
          {{ $t('ConfigRepository') }}
          <CyTooltip
            top
            :max-width="240">
            <template #activator="{ on }">
              <v-icon
                class="help-icon"
                size="12"
                color="grey"
                v-on="on">
                help_outline
              </v-icon>
            </template>
            {{ $t('configRepositoryHelpText') }}
          </CyTooltip>
        </div>
        <v-skeleton-loader
          v-if="!configRepository"
          type="list-item-avatar-two-line"/>
        <CyConfigRepositoryCard
          v-else
          class="mb-5"
          :config-repository="configRepository"/>
        <div class="content-title mb-2 font-weight-bold">
          {{ $t('activityOverPastYear') }}
        </div>
        <v-skeleton-loader
          v-if="status.loadingEvents"
          class="my-4"
          type="text@2"/>
        <ECharts
          v-else
          class="line-chart"
          theme="cycloid"
          autoresize
          :option="chartOptions"/>
      </v-col>
      <CyFormsEnvironmentDelete
        v-if="environmentToDelete"
        :environment="environmentToDelete"
        :project-canonical="projectCanonical"
        @cancel="onCancelButtonClick"
        @success="onDeleteSuccess"/>
    </v-row>
    <portal
      v-if="previewedStack"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: closePreview,
            include: getClickOutsideExceptions,
          }"
          aria-label="Stack preview"
          role="region"
          class="side-panel">
          <CyStackPreview
            :stack="previewedStack"
            :show-select-btn="false"
            @close="closePreview"/>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </v-col>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import CyConfigRepositoryCard from '@/components/CyConfigRepositoryCard.vue'
import CyEnvironmentListTable from '@/components/CyEnvironmentListTable.vue'
import CyEventsTimeline from '@/components/CyEventsTimeline.vue'
import CyFormsEnvironmentDelete from '@/components/CyFormsEnvironmentDelete.vue'
import CyStackPreview from '@/components/CyStackPreview.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import { constructBreadcrumb, extractStartStopPipelines } from '@/utils/helpers'

const defaultEventProps = {
  itemsPerPage: 50,
  sortBy: ['timestamp'],
  sortDesc: [true],
}

const weeksOfPastYear = _.reverse(Array.from({ length: 52 }, (_, weeks) => {
  const today = new Date()
  today.setDate(today.getDate() - weeks * 7)
  return {
    start: $date.format(today, 'MMM d, yyyy'),
    end: $date.format(new Date(today).setDate(today.getDate() + 6), 'MMM d, yyyy'),
  }
}))

export default {
  name: 'CyPageEnvironments',
  components: {
    CyConfigRepositoryCard,
    CyEnvironmentListTable,
    CyEventsTimeline,
    CyFormsEnvironmentDelete,
    CyWizardServiceCard,
    CyStackPreview,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectEnvironments'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },

    ])
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    apiQueriesErrors: [],
    environmentToDelete: null,
    environments: [],
    previewedStack: null,
    status: {
      loadingPipelines: false,
      loadingEnvironments: false,
      loadingEvents: true,
      deleting: false,
    },
    show: {
      deleteDialog: false,
    },
  }),
  computed: {
    ...mapState('organization', {
      events: (state) => state.available.events,
    }),
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
      pipelinesErrors: (state) => state.errors.pipelines,
      projectErrors: (state) => state.errors,
    }),
    ...mapState('organization/stack', {
      loadingStack: (state) => state.fetchInProgress.stack,
    }),
    ...mapGetters('layout', [
      'getDataTableProps',
    ]),
    ...mapGetters('organization/project', [
      'envs',
      'hasEnvs',
      'stackRef',
    ]),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    ...mapGetters('organization/project/configRepository', [
      'configRepository',
    ]),
    errors () {
      return [
        ...this.apiQueriesErrors,
        ...this.pipelinesErrors,
      ]
    },
    eventProps: {
      get () {
        return this.getDataTableProps(this.$route.name)
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({ name: this.$route.name, props })
      },
    },
    eventFilters () {
      return {
        'project_canonical[in]': this.projectCanonical,
        begin: $date.format(new Date(weeksOfPastYear[0].start), 'T'),
        end: $date.format(Date.now(), 'T'),
      }
    },
    canSeeEvents () {
      return this.$cycloid.permissions.canDisplay('GetEvents')
    },
    chartOptions () {
      return {
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        series: [{
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: '#54BABA',
          },
          areaStyle: {
            color: '#E8F5F5',
          },
          data: weeksOfPastYear.map((week) => ([week.start, this.events.filter(({ timestamp }) => $date.isSameWeek(new Date(week.start), new Date(timestamp)))?.length])),
        }],
        tooltip: {
          trigger: 'axis',
          formatter: ([{ data: [date, events] }]) => {
            return `
              <p class='mb-1'>${this.$t('weekOf')} ${date}</p>
              <strong>${events} ${this.$t('Events').toLowerCase()}</strong>
            `
          },
        },
        xAxis: {
          type: 'category',
          data: weeksOfPastYear.map(({ start }) => start),
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          type: 'value',
        },
      }
    },
  },
  watch: {
    envs (newValue) {
      if (!_.isEmpty(newValue)) this.fetchStartStopPipelines()
    },
  },
  async created () {
    await this.GET_STACK({ stackRef: this.stackRef })
    if (this.canSeeEvents) {
      await this.FETCH_AVAILABLE({ keyPath: 'tags' })
    }
    this.eventProps = _.merge({}, this.eventProps, defaultEventProps, { filters: this.eventFilters })
    await this.getEvents()
  },
  async mounted () {
    if (this.hasEnvs) await this.fetchStartStopPipelines()
    if (this.$cycloid.permissions.canDisplay('ListConfigRepositories')) {
      await this.FETCH_AVAILABLE({ keyPath: 'configRepositories' })
    }
  },
  destroyed () {
    this.RESET_ORG_STATE('available.events')
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('organization', [
      'RESET_ORG_STATE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    async getEvents () {
      this.status.loadingEvents = true
      if (this.canSeeEvents) {
        await this.FETCH_AVAILABLE({
          keyPath: 'events',
          // For now events endpoint doesn't support pagination.
          extraParams: [this.eventFilters],
        })
      }
      this.status.loadingEvents = false
    },
    getUsecase (env) {
      return _.find(this.envs, (envList) => envList.canonical === env)
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    onDeleteButtonClick (environment) {
      this.environmentToDelete = environment
      this.show.deleteDialog = true
    },
    onCancelButtonClick () {
      this.environmentToDelete = null
      this.show.deleteDialog = false
    },
    onDeleteSuccess () {
      if (!this.hasEnvs && _.isEmpty(this.projectErrors.env)) this.$router.push({ name: 'projects' })
      else {
        this.show.deleteDialog = false
        this.environmentToDelete = null
      }
    },
    async stopEnvironment (env) {
      _.set(env, 'startStop.stopping', true)
      await this.startStopEnvironment(env, 'stop')
      _.set(env, 'startStop.stopping', false)
    },
    async startEnvironment (env) {
      _.set(env, 'startStop.starting', true)
      await this.startStopEnvironment(env, 'start')
      _.set(env, 'startStop.starting', false)
    },
    async startStopEnvironment (env, type) {
      this.apiQueriesErrors = []
      const pipelineCanonical = `start-stop-${this.projectCanonical}-${env.canonical}`
      const { data, errors } = await this.$cycloid.ydAPI.createBuild(this.orgCanonical, this.projectCanonical, pipelineCanonical, type) || {}
      if (data) {
        const successMessage = type === 'start' ? 'started' : 'stopped'
        this.SHOW_ALERT({ type: 'success', content: this.$t(`alerts.success.environment.${successMessage}`) })
      }
      if (errors) this.apiQueriesErrors = errors
    },
    async fetchStartStopPipelines () {
      this.apiQueriesErrors = []
      this.status.loadingPipelines = true
      let startStopPipelines = []

      await this.GET_PROJECT_PIPELINES()
      if (!_.isEmpty(this.pipelines)) startStopPipelines = extractStartStopPipelines(this.pipelines)?.startStop

      this.environments = this.envs.map((env) => {
        const { canonical, use_case: useCase, icon, color } = env
        const pipeline = startStopPipelines.find(({ environment }) => environment.canonical === canonical)
        const pipelineStatus = this.pipelines.find(({ environment }) => environment.canonical === canonical)?.status

        return {
          canonical,
          useCase,
          icon,
          color,
          cloudProviderName: this.getUsecase(canonical)?.cloud_provider_name,
          cloudProviderCanonical: this.getUsecase(canonical)?.cloud_provider_canonical,
          pipelineStatus,
          ...(pipeline
            ? {
                startStop: {
                  enabled: !pipeline.paused,
                  starting: false,
                  stopping: false,
                  toggling: false,
                },
              }
            : {}
          ),
        }
      })

      this.status.loadingPipelines = false
    },
    closePreview () {
      this.previewedStack = null
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectEnvironments',
        addConfigRepo: 'Add Config Repository',
        confirmDelete: {
          header: {
            andProject: ' and project',
          },
        },
        missingConfigRepo: 'Missing Config Repository',
        missingConfigRepoInfo: 'In order to add, clone and configure environments, you need to supply a config repository.',
        latestActivity: 'Latest activity',
        viewAllActivity: 'View all activity',
        noActivityHeadline: 'No activity associated with this project',
        noActivityText: 'Events will appear here once you start interacting with this entity.',
        activityOverPastYear: 'Activity over past year',
        configRepositoryHelpText: 'A Git repository used to store configuration files that define your environments as code.',
      },
      es: {
        title: '@:routes.projectEnvironments',
        addConfigRepo: 'Añadir repositorio de configuración',
        confirmDelete: {
          header: {
            andProject: ' y proyecto',
          },
        },
        missingConfigRepo: 'Repositorio de configuración faltante',
        missingConfigRepoInfo: 'Para agregar, clonar y configurar entornos, debe proporcionar un repositorio de configuración.',
        latestActivity: 'Actividad recientes',
        viewAllActivity: 'Ver toda la actividad',
        noActivityHeadline: 'Ninguna actividad asociada a este proyecto',
        noActivityText: 'Los eventos aparecerán aquí una vez que empieces a interactuar con esta entidad.',
        activityOverPastYear: 'Actividad en el último año',
        configRepositoryHelpText: 'Un repositorio Git utilizado para almacenar archivos de configuración que definen tus entornos como código.',
      },
      fr: {
        title: '@:routes.projectEnvironments',
        addConfigRepo: 'Ajouter une source de configuration',
        confirmDelete: {
          header: {
            andProject: ' et le projet',
          },
        },
        missingConfigRepo: 'Source de configuration manquante',
        missingConfigRepoInfo: 'Pour ajouter, cloner et configurer des environnements, vous devez fournir un référentiel de configuration.',
        latestActivity: 'Activité récente',
        viewAllActivity: `Voir toute l'activité`,
        noActivityHeadline: `Aucune activité associée à ce projet`,
        noActivityText: 'Les événements apparaîtront ici lorsque vous commencerez à interagir avec cette entité.',
        activityOverPastYear: `Activité au cours de l'année écoulée`,
        configRepositoryHelpText: 'Un dépôt Git utilisé pour stocker les fichiers de configuration qui définissent vos environnements en tant que code.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card > :first-child:not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

::v-deep .stack-card__description {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

::v-deep .v-timeline-item {
  .v-timeline-item__opposite {
    background-color: cy-get-color("grey", "light-4");
  }
}

.side-column {
  display: flex;
  flex-direction: column;
  max-height: calc(95vh - 191px - 24px); // screen height minus header and its margins
}

.environments-wrapper {
  padding-bottom: 40px;

  @media screen and (width >= 960px) {
    padding-bottom: 0;
  }

  &__column {
    height: 100%;
    padding-right: 0;
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 0.5);
  }
}

.v-tooltip .v-icon {
  font-size: inherit;
}

.line-chart {
  height: 50px;
}
</style>

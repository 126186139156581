<template>
  <v-card
    :class="['emissions-summary', {
      'single-provider': _.get(aggregateData, 'providerDatas', []).length < 2,
    }]">
    <template v-if="!loading">
      <v-card-title class="emissions-summary__title">
        {{ $t('carbonFootprintData') }}
      </v-card-title>
      <v-card-text
        data-cy="content"
        class="px-0 pb-0">
        <div class="d-flex px-4">
          <div
            data-cy="co2e"
            class="co2e">
            <v-badge
              class="co2e__badge"
              :color="$static.dataTypeColors.co2e"
              inline
              left/>
            <div class="co2e__amount">
              {{ formatAmount(aggregateData.co2e) }}
            </div>
            <div
              class="co2e__unit"
              v-html="$sanitizeHtml($t('untranslated.co2e'))"/>
            <CyCcmTrendChip
              data-cy="co2e-trend"
              class="co2e__trend"
              :value="[_.get(_.head(totalCo2eData), 'co2e', 0), _.get(_.last(totalCo2eData), 'co2e', 0)]"/>
          </div>
          <div
            data-cy="kwh"
            class="kwh">
            <v-badge
              class="co2__badge"
              :color="$static.dataTypeColors.kwh"
              inline
              left/>
            <div class="kwh__amount">
              {{ formatAmount(aggregateData.kwh) }}
            </div>
            <div class="kwh__unit">
              {{ $t('untranslated.kwh') }}
            </div>
            <CyCcmTrendChip
              data-cy="kwh-trend"
              class="kwh__trend"
              :value="[_.get(_.head(totalKwhData), 'kwh', 0), _.get(_.last(totalKwhData), 'kwh', 0)]"/>
          </div>
        </div>

        <div
          class="emissions-summary__graph">
          <ECharts
            data-cy="chart"
            class="line-chart"
            theme="cycloid"
            autoresize
            :option="options"/>
        </div>
      </v-card-text>
    </template>
    <div
      v-else
      data-cy="loader"
      class="sk-template d-flex flex-column">
      <v-card-title class="provider-card__title d-flex pt-5">
        <div class="sk-block sk-title sk-dark"/>
      </v-card-title>
      <v-card-text class="px-4 d-flex flex-column">
        <div class="d-flex justify-space-between space-x-8 mb-4">
          <div class="sk-block sk-title flex-grow-1 sk-h-12"/>
          <div class="sk-block sk-title flex-grow-1 sk-h-12"/>
        </div>
        <div class="sk-block sk-title sk-full-width sk-h-27"/>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CyCcmTrendChip from '@/components/CyCcmTrendChip.vue'
import { formatAmount } from '@/utils/helpers'
import { dataTypeColors } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCcmEmissionsSummary',
  components: {
    CyCcmTrendChip,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      aggregateData: (state) => state.aggregateData,
    }),
    $static: () => ({
      dataTypeColors,
    }),
    options () {
      return {
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          containLabel: false,
        },
        series: ['co2e', 'kwh'].map((type) => ({
          areaStyle: {
            opacity: 0,
          },
          lineStyle: {
            color: this.$static.dataTypeColors[type],
          },
          data: this[`total${_.capitalize(type)}Data`].map((it) => [it.date, it[type]]),
          symbol: 'none',
          type: 'line',
        })),
        tooltip: {
          trigger: 'axis',
          formatter: this.formatTooltip,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          type: 'value',
        },
      }
    },
    totalCo2eData () {
      return this.aggregateData.providerDatas?.reduce((acc, { co2eData }) => {
        if (_.isEmpty(acc)) return co2eData
        return acc.map(({ co2e, date }, index) => ({ date, co2e: co2eData[index].co2e + co2e }))
      }, []) || []
    },
    totalKwhData () {
      return this.aggregateData.providerDatas?.reduce((acc, { kwhData }) => {
        if (_.isEmpty(acc)) return kwhData
        return acc.map(({ kwh, date }, index) => ({ date, kwh: kwhData[index].kwh + kwh }))
      }, []) || []
    },
  },
  methods: {
    formatAmount,
    formatDate (date) {
      return $date.format(new Date(date), 'MMM d, yyyy')
    },
    formatTooltip ([{ data: [date, co2e] }, { data: [_, kwh] }]) {
      return `
        <p class='mb-1'>${this.formatDate(date)}</p>
        <ul class='pl-0'>
          <li>
            <span class="marker" style="background-color:${this.$static.dataTypeColors.co2e}"></span>
            <span class="ml-n1">${this.$t('cloudCostManagement.emissions')}:</span>
            <strong>${this.formatAmount(co2e)}</strong>
            <span>${this.$t('untranslated.co2e')}</span>
          </li>
          <li>
            <span class="marker" style="background-color:${this.$static.dataTypeColors.kwh}"></span>
            <span class="ml-n1">${this.$t('cloudCostManagement.energy')}:</span>
            <strong>${this.formatAmount(kwh)}</strong>
            <span>${this.$t('untranslated.kwh')}</span>
          </li>
        </ul>
      `
    },
  },
  i18n: {
    messages: {
      en: {
        carbonFootprintData: 'Carbon footprint data',
      },
      es: {
        carbonFootprintData: 'Datos de la huella de carbono',
      },
      fr: {
        carbonFootprintData: `Données d'empreinte carbone`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.emissions-summary {
  overflow: hidden;
  transition: box-shadow 0.2s ease-out;
  border-radius: 8px;
  font-weight: $font-weight-bolder;

  &__title {
    font-family: $font-family-default;
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
  }

  .co2e,
  .kwh {
    display: grid;
    grid-template-columns: 16px fit-content(40px) 60px;
    grid-template-rows: 1fr 1fr;
    width: 50%;
    justify-items: start;
    font-family: $font-family-condensed;

    ::v-deep .v-badge__badge {
      width: 6px;
      min-width: 0;
      height: 6px;
      margin-right: 10px;
      padding: 0;
    }

    &__trend {
      grid-column: 1 / 4;
      margin-left: 12px;
      font-size: $font-size-sm;
    }

    &__amount {
      margin-right: 4px;
      color: cy-get-color("primary");
      font-size: $font-size-xl;
      font-weight: $font-weight-bolder;
    }

    &__unit {
      color: cy-get-color("primary", "light-2");
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
    }
  }

  &__graph {
    height: 124px;
    margin-top: 36px;

    .line-chart {
      width: 100%;
      height: 92px;

      ::v-deep {
        > div {
          cursor: pointer !important;
        }

        .marker {
          display: inline-block;
          position: relative;
          top: 1px;
          width: 10px;
          height: 10px;
          margin-right: 5px;
          border: solid 1px white;
          border-radius: 10px;
        }

        ul {
          list-style-type: none;
        }
      }
    }
  }

  &.single-provider {
    .emissions-summary__graph {
      height: 140px;

      .line-chart {
        height: 140px;
      }
    }
  }
}
</style>

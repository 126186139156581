<template>
  <div
    data-cy="wizard-summary"
    class="cy-infra-import-wizard-summary mx-1">
    <div>
      <h3
        class="section-title mt-7 mb-4 pb-4"
        data-cy="stack-details">
        {{ $t('stackDetails') }}
      </h3>

      <CyWizardServiceCard
        v-if="!_.$isEmpty(stack)"
        class="pb-4 cy-infra-import-wizard-summary__card"
        hide-more-btn
        data-cy="stack-card-summary"
        :service="stack"
        horizontal/>
    </div>

    <v-divider class="my-4"/>

    <div class="cy-infra-import-wizard-summary__details-container">
      <div :class="['d-flex flex-row', !hasProjectDetails || _.$isEmpty(externalBackendConfig) ? 'justify-start' : 'justify-space-between']">
        <div class="d-flex">
          <!-- Provider Details -->
          <div
            v-if="hasProviderDetails"
            class="d-flex flex-column mr-16">
            <h3 class="section-title mb-6">
              {{ $t('providerDetails') }}
            </h3>

            <CyInfraImportItemDetails
              data-cy="provider-details-summary"
              :item="{ provider: provider.name, ...providerConfig }"
              :provider-abbreviation="provider.abbreviation"/>
          </div>
        </div>

        <!-- Project Details -->
        <div
          v-if="hasProjectDetails"
          class="d-flex flex-column">
          <h3 class="section-title mb-6">
            {{ $t('projectDetails') }}
          </h3>

          <CyInfraImportItemDetails :item="{ ...project, configRepository: _.$get(project, 'configRepository.canonical', '') }"/>
        </div>

        <!-- External Backend Config -->
        <div
          v-if="!_.$isEmpty(externalBackendConfig)"
          class="d-flex flex-column cy-infra-import-wizard-summary__details-item">
          <h3 class="section-title mb-6">
            {{ $t('externalBackendConfig') }}
          </h3>

          <CyInfraImportItemDetails :item="externalBackendConfig"/>
        </div>
      </div>
    </div>

    <v-divider class="my-4"/>

    <div>
      <h3 class="section-title">
        {{ $t('importedResources') }}
      </h3>

      <CyDataTable
        class="elevation-2  mt-8"
        :headers="$static.headers"
        show-expand
        :items="importedResources">
        <template #table-cmp-body-row="{ props: { item } }">
          <td class="resources-list-table__column py-1 px-2">
            <div class="d-flex">
              <div>
                <v-avatar
                  tile
                  :rounded="false">
                  <v-img
                    contain
                    :src="item.image"/>
                </v-avatar>
              </div>
              <div class="ml-2">
                <div
                  data-cy="resource-description-summary"
                  class="item-description--primary">
                  {{ _.truncate(item.canonical, { length: 30 }) }}
                </div>
                <div
                  :class="['item-description--secondary font-size-sm', {
                    'resources-list-table__column--expanded': item.isExpanded,
                  }]">
                  {{ item.isExpanded ? item.short_description : _.truncate(item.short_description, { length: 40 }) }}
                </div>
              </div>
            </div>
          </td>
          <td :class="['resources-list-table__column pl-4']">
            <CyTagList
              :contained="!item.isExpanded"
              :tags="item.resourceIds">
              <template #tag="{ tag }">
                <CyTag
                  small
                  variant="default">
                  {{ _.truncate(tag, { length: 40 }) }}
                </CyTag>
              </template>
            </CyTagList>
          </td>
          <td class="resources-list-table__column pl-4">
            <CyTagList
              :contained="!item.isExpanded"
              :tags="
                item.variables">
              <template #tag="{ tag }">
                <CyTag
                  small
                  variant="primary">
                  {{ _.truncate(tag, { length: 40 }) }}
                </CyTag>
              </template>
            </CyTagList>
          </td>
          <td>
            <v-icon @click.stop="() => item.isExpanded = !item.isExpanded">
              {{ item.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </td>
        </template>
      </CyDataTable>

      <div class="mt-10 d-flex justify-end">
        <CyButton
          variant="secondary"
          @click="$emit('previous')">
          {{ $t('forms.back') }}
        </CyButton>

        <CyButton
          data-cy="import-infrastructure"
          :disabled="!canStartImport"
          @click.stop="$emit('start-infra-import')">
          {{ $t('importInfrastructure') }}
        </CyButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CyDataTable from '@/components/CyDataTable.vue'
import CyInfraImportItemDetails from '@/components/CyInfraImportItemDetails.vue'
import CyTagList from '@/components/CyTagList.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import { checksPass } from '@/utils/helpers'

export default {
  name: 'CyInfraImportWizardSummary',
  components: {
    CyWizardServiceCard,
    CyInfraImportItemDetails,
    CyTagList,
    CyDataTable,
  },
  computed: {
    ...mapState('organization/infraImport', {
      stack: (state) => state.stack,
      providerConfig: (state) => state.providerConfig,
      providerCanonical: (state) => state.providerCanonical,
      provider: (state) => state.provider,
      project: (state) => state.project,
      externalBackendConfig: (state) => state.externalBackendConfig,
      resources: (state) => state.entities,
      resourceIds: (state) => state.resourceIds,
      variables: (state) => state.variables,
      wizardConfig: (state) => state.wizardConfig,
    }),
    ...mapGetters('organization/infraImport', [
      'list',
    ]),
    $static () {
      return {
        headers: [
          {
            text: this.$t('terracost.resource.name'),
            value: 'name',
            align: 'left',
            width: 363,
          },
          {
            text: this.$t('terracost.resource.id'),
            value: 'type',
            align: 'left',
            width: 332,
          },
          {
            text: this.$t('variables'),
            value: 'path',
            align: 'left',
            width: 332,
          },
          {
            text: '',
            value: 'data-table-expand',
            sortable: false,
            align: 'right',
            width: 54,
          },
        ],
      }
    },
    importedResources () {
      const { resources, variables, resourceIds } = this

      return !_.$isEmpty(resources)
        ? resources.map((resource) => ({
          ...resource,
          isExpanded: false,
          variables: _.$get(variables, resource.canonical, []),
          resourceIds: _.$get(resourceIds, resource.canonical, []).map(({ id }) => id),
        }))
        : this.list('available.resources').map((resource) => ({
          ...resource,
          isExpanded: false,
          variables: _.$get(variables, resource.canonical, []),
        }))
    },
    hasProviderDetails () {
      return !_.$isEmpty(this.provider) && !_.$isEmpty(this.providerConfig) && !_.$isEmpty(this.providerCanonical)
    },
    hasProjectDetails () {
      return !_.$isEmpty(this.project)
    },
    canStartImport () {
      const { wizardConfig: { createStackOnly }, hasProjectDetails, stack, hasProviderDetails, externalBackendConfig } = this
      const hasValidStackPath = checksPass({
        hasProviderDetails: !_.$isEmpty(hasProviderDetails),
        hasStackDetails: !_.$isEmpty(stack),
      })

      return createStackOnly
        ? hasValidStackPath
        : hasValidStackPath && hasProjectDetails && !_.$isEmpty(externalBackendConfig)
    },
  },
  i18n: {
    messages: {
      en: {
        externalBackendConfig: 'External backend config',
        importInfrastructure: 'Import infrastructure',
        importedResources: 'Imported resources',
        projectDetails: 'Project details',
        providerDetails: 'Provider details',
        stackDetails: 'Stack details',
        variables: 'Variables',
      },
      es: {
        externalBackendConfig: 'Configuración de backend externa',
        importInfrastructure: 'Importar infraestructura',
        importedResources: 'recursos importados',
        projectDetails: 'Detalles del proyecto',
        providerDetails: 'Detalles del proveedor',
        stackDetails: 'Detalles de la stack',
        variables: 'Variables',
      },
      fr: {
        externalBackendConfig: 'Configuration du backend externe',
        importInfrastructure: `Infrastructure d'importation`,
        importedResources: 'ressources importées',
        projectDetails: 'Détails du projet',
        provider: 'Pournisseur:',
        providerDetails: 'Détails du fournisseur',
        stackDetails: 'Détails de la stack',
        variables: 'Variables',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-infra-import-wizard-summary {
  &__card {
    width: 384px;
  }

  &__details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 173px;

    .details-content {
      margin-right: 180px;
    }
  }

  &__details-item {
    max-width: 33%;
  }

  .resources-list-table__column {
    max-width: 120px;
    padding: 8px;
    transition: all 0.45s linear;
    vertical-align: top;

    ::v-deep {
      table {
        table-layout: fixed;
      }

      .tag-list__toggle-btn {
        display: none;
      }
    }
  }

  .section-title {
    color: map.get($slate-grey, "light-2");
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .item-description--primary {
    color: cy-get-color("primary");
  }

  .item-description--secondary {
    color: map.get($grey, "dark-2");
  }
}
</style>

<template>
  <v-form class="project-form">
    <div
      v-if="matchedNameProjectLinks.length"
      class="found-matching-project-names"
      aria-label="Duplicate project name">
      <v-icon class="found-matching-project-names__icon">
        warning
      </v-icon>
      <div class="found-matching-project-names__info">
        <span class="message--projects">
          {{ $tc('warning.projectNameAlreadyExists', matchedNameProjectLinks.length) }}
          <router-link
            v-for="{ text, to } of matchedNameProjectLinks"
            :key="text"
            :to="to"
            class="cy-link project-link"
            target="_blank"
            rel="noopener noreferrer">
            {{ text }}
          </router-link>
        </span>
        <span class="message--warning">{{ $t('warning.youMayContinue') }}</span>
      </div>
    </div>
    <v-text-field
      v-model.trim="$v.formData.name.$model"
      :label="$t('forms.fields.projectName')"
      :error-messages="nameErrors"
      required
      class="required-field"
      @blur="$v.formData.name.$touch()"/>
    <v-textarea
      v-model="$v.formData.description.$model"
      :label="$t('forms.fieldDescription')"
      :hint="$t('forms.fields.descriptionHint')"
      class="mb-8"
      persistent-hint
      rows="3"
      auto-grow/>
    <CyInputsConfigRepositorySelect
      v-model="$v.formData.configRepositoryCanonical.$model"
      :hint="$t('forms.fields.configRepoHint')"
      :return-object="false"
      :error-messages="configRepoErrors"
      :loading="fetchInProgress.configRepositories"
      :disabled="fetchInProgress.configRepositories"
      item-value="canonical"
      class="mb-4"
      required
      persistent-hint
      @blur="$v.formData.configRepositoryCanonical.$touch()"/>
    <CySelectOwner
      v-model="$v.formData.owner.$model"
      :show-on-creation="true"
      :current-owner="formData.owner"/>
    <v-autocomplete
      v-if="quotasEnabled"
      v-model="$v.formData.teamCanonical.$model"
      :label="$t('Team')"
      :hint="$t('forms.fields.teamHint')"
      :items="selectedOwnerTeams"
      :error-messages="teamErrors"
      :loading="fetchInProgress.teams"
      :disabled="fetchInProgress.teams"
      item-text="name"
      item-value="canonical"
      class="required-field"
      required
      persistent-hint
      @blur="$v.formData.teamCanonical.$touch()"/>
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyInputsConfigRepositorySelect from '@/components/CyInputsConfigRepositorySelect.vue'
import CySelectOwner from '@/components/CySelectOwner.vue'
import REGEX from '@/utils/config/regex'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsProject',
  components: {
    CyInputsConfigRepositorySelect,
    CySelectOwner,
  },
  validations () {
    const { isCanonicalUnique, quotasEnabled } = this
    return {
      formData: {
        name: {
          required,
          alphaNumeric: (val) => _.isEmpty(val) || REGEX.PROJECT_NAME.test(val),
          isUnique: () => isCanonicalUnique,
        },
        description: {},
        configRepositoryCanonical: { required },
        owner: {},
        teamCanonical: { required: requiredIf(() => quotasEnabled) },
      },
    }
  },
  data: () => ({
    formData: {
      name: null,
      description: null,
      configRepositoryCanonical: null,
      owner: null,
      teamCanonical: null,
    },
  }),
  computed: {
    ...mapState('organization', {
      configRepositories: (state) => _.orderBy(state.available.configRepositories, ['default', 'canonical'], ['desc', 'asc']),
      projects: (state) => state.available.projects,
      teams: (state) => state.available.teams,
      fetchInProgress: (state) => state.fetchInProgress,
    }),
    ...mapState({
      user: (state) => state.user.profile,
    }),
    quotasEnabled () {
      return this.organization.quotas
    },
    selectedOwnerTeams () {
      const { formData, teams } = this
      if (formData.owner === null) return []

      return _.filter(teams, (team) => _.some(team.members_preview, ({ username }) => username === formData.owner.username))
    },
    projectCanonical () {
      return _.get(this.project, 'canonical', this.$getSlug(this.formData.name))
    },
    isCanonicalUnique () {
      const projects = _.map(this.projects, 'canonical')
      return !_.includes(projects, this.projectCanonical)
    },
    matchedNameProjectLinks () {
      const { projectCanonical, formData: { name: projectName } } = this
      return this.projects
        .filter(({ name, canonical }) => _.isEqual(name, projectName) && !_.isEqual(canonical, projectCanonical))
        .map(({ canonical: projectCanonical }) => ({
          text: projectCanonical,
          to: { name: 'project', params: { projectCanonical } },
        }))
    },
    nameErrors () {
      const errors = []
      const { $dirty, required, alphaNumeric, isUnique } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!alphaNumeric) errors.push(this.$t('forms.fieldNotAlphaNum'))
      if (!isUnique) errors.push(this.$t('forms.projectCanonicalAlreadyExists'))
      return errors
    },
    configRepoErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.configRepositoryCanonical
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    teamErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.teamCanonical
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  watch: {
    formData: {
      handler (formData) {
        const projectConfig = {
          ...formData,
          owner: formData?.owner?.username,
          canonical: this.projectCanonical,
        }
        this.$emit('input', projectConfig)
        this.$emit('is-valid', !this.$v.$invalid)
      },
      deep: true,
    },
  },
  async created () {
    const { user, quotasEnabled } = this

    const fetchConfigRepositories = this.$cycloid.permissions.canDisplay('ListConfigRepositories')
    const fetchTeams = quotasEnabled && this.$cycloid.permissions.canDisplay('GetTeams')

    await Promise.all([
      this.FETCH_AVAILABLE({ keyPath: 'projects' }),
      fetchConfigRepositories && this.FETCH_AVAILABLE({ keyPath: 'configRepositories' }),
      fetchTeams && this.FETCH_AVAILABLE({ keyPath: 'teams' }),
    ])

    this.formData.owner = user
    this.formData.configRepositoryCanonical = _.find(this.configRepositories, 'default')?.canonical || ''
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
  },
  i18n: {
    messages: {
      en: {
        forms: {
          fields: {
            projectName: 'Project name',
            descriptionHint: 'What is the purpose of this project? This will appear in the project list and within project views.',
            configRepoHint: 'The repository where to store this project configuration files.',
            teamHint: 'The team to consume quotas from.',
          },
        },
        warning: {
          noConfigRepositories: 'No config repositories have been found.<br/>To complete project creation you need to set up at least one.',
          projectNameAlreadyExists: 'This name is already used for this project: | This name is already used for these projects:',
          youMayContinue: 'You may continue but it could cause confusion if multiple projects share the same name.',
        },
      },
      es: {
        forms: {
          fields: {
            projectName: 'Nombre del proyecto',
            descriptionHint: '¿Cuál es el propósito de este proyecto? Esto aparecerá en la lista de proyectos y dentro de las vistas de proyectos.',
            configRepoHint: 'El repositorio donde almacenar los archivos de configuración de este proyecto.',
            teamHint: 'El equipo del que consumir cuotas.',
          },
        },
        warning: {
          noConfigRepositories: 'No se han encontrado repositorios de configuración.<br/>Para completar la creación del proyecto, debe configurar al menos uno.',
          projectNameAlreadyExists: 'Este nombre ya se encuentra en uso para este proyecto: | Este nombre ya se encuentra en uso para estos proyectos:',
          youMayContinue: 'Puede continuar, pero podría causar confusión si varios proyectos comparten el mismo nombre.',
        },
      },
      fr: {
        forms: {
          fields: {
            projectName: 'Nom du projet',
            descriptionHint: 'Quel est le but de ce projet ? Cela apparaîtra dans la liste des projets et dans les vues de projet.',
            configRepoHint: 'Le référentiel où stocker les fichiers de configuration de ce projet.',
            teamHint: `L'équipe à partir de laquelle consommer des quotas.`,
          },
        },
        warning: {
          noConfigRepositories: `Aucune source de configuration n'a été trouvée.<br/>Pour terminer la création du projet, vous devez en configurer au moins une.`,
          projectNameAlreadyExists: 'Ce nom est déjà utilisé pour ce projet: | Ce nom est déjà utilisé pour ces projets:',
          youMayContinue: 'Vous pouvez continuer mais cela pourrait causer de la confusion si plusieurs projets partagent le même nom.',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.found-matching-project-names {
  display: flex;
  max-width: 520px;
  margin: auto;
  margin-bottom: 2em;
  padding: 1em;
  border: 1px solid cy-get-color("warning");
  border-radius: 6px;

  &__icon {
    align-items: center;
    margin: 0.25em;
    margin-right: 0.5em;
    color: cy-get-color("warning");
    font-size: 40px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .message {
      color: cy-get-color("warning", "dark-1");
      text-align: left;

      &--projects {
        font-size: 16px;

        @extend .message;
      }

      &--warning {
        padding-top: 1em;
        font-size: 14px;

        @extend .message;
      }
    }

    .project-link {
      color: cy-get-color("warning", "dark-2");

      &:hover {
        color: cy-get-color("accent");
      }

      &:not(:last-child) {
        &::after {
          content: ",";
          margin-left: -0.2em;
        }
      }
    }
  }
}
</style>

import Vue from 'vue'
import routes from '@/router/routes'
import { AppError } from '@/utils/config/errors'
import componentMocks from 'mocks/componentMocks'
import componentStubs from 'mocks/componentStubs'
import { createYDAPIMock } from 'mocks/Cycloid'
import userEvent from '@testing-library/user-event'
import { render } from '@testing-library/vue'
import Vuetify from 'vuetify'
import { createI18n, createRouter, createStore } from './helpers-vue'

export const ONE_MIN_MS = 60000
export const ONE_HOUR_MS = 600000
export const ONE_DAY_MS = 86400000
export const ONE_MIN_S = 60
export const ONE_HOUR_S = 600
export const ONE_DAY_S = 86400

/** Check that an error is an instance of AppError with the specified data.
 *
 * @param {AppError}      error         - The error to check
 * @param {RegExp}        msgRegExp     - Regexp to match error message
 * @param {RegExp}        userMsgRegExp - Regexp to match user error message
 * @param {Error~type}    type          - The expected type of the `error`
 * @param {Object}        [metadata={}] - The fundamental properties reported via metadata errors
 * @param {Object?}       [options={}]  - Optional checks to be performed on the raw error
 * @param {typeof Error?} [options.constructor=null] - The type expected that `err.metadata.error` have
 * @param {RegExp?}       [options.message=null]     - The regexp which must match the raw error message
 *
 * ---
 * @example
 * checkAppError(err, /error message/, /user message/, 'error type', { key: 'value' })
 * // tests will fail if the error is not an instance of AppError
 * checkAppError(err, /error message/, /user message/, 'error type', { key: 'value' }, {
 *  constructor: TypeError,
 *  message: /error message/,
 * })
 * // tests will fail if the error is not an instance of AppError
 */
export function checkAppError (err, msgRegExp, userMsgRegExp, type, metadata = {}, rawErrChecker = { constructor: null, message: null }) {
  expect(err).toBeInstanceOf(AppError)
  expect(err?.message).toMatch(msgRegExp)
  expect(err?.userMessage).toMatch(userMsgRegExp)
  expect(err?.type).toBe(type)

  for (const m in metadata) {
    expect(err.metadata).toHaveProperty(m)
    expect(err.metadata[m]).toEqual(metadata[m])
  }

  if (rawErrChecker) {
    if (rawErrChecker.constructor) {
      expect(err.metadata.error).toBeInstanceOf(rawErrChecker.constructor)
    }

    if (rawErrChecker.message) {
      expect(err.metadata.error.message).toMatch(rawErrChecker.message)
    }
  }
}

export function flattenRoutes (routes) {
  const flatRoutes = []
  _.forEach(routes, (route) => {
    flatRoutes.push(route)
    const { children } = route
    if (!_.isEmpty(children)) flatRoutes.push(...flattenRoutes(children))
  })
  return flatRoutes
}

/** Find and populate any vue-router route
 *
 * @param {string} routeName
 * @param {{
 *   hash,
 *   meta: object,
 *   params: object,
 *   query: object,
 * }} [options={}]
 */
export function findRoute (routeName, {
  hash,
  meta = {},
  params = {},
  query = {},
  ...invalidOptions
} = {}) {
  _.$handleInvalidOptions(findRoute, arguments, { invalidOptions })

  const flatRoutes = flattenRoutes(routes)
  const route = flatRoutes.find(({ name }) => name === routeName)
  const matched = getMatchedRoutes(route)
  return _.merge({}, route, _.omit({
    query,
    params,
    hash,
    meta,
    matched,
    href: route?.path ?? '/',
  }, _.isEmpty))
}

export function findPublicRoutes () {
  return routes.filter(({ meta }) => meta?.isPublic)
}

/** Stubs all vuex actions and mutations within a component */
export function stubVuexMappedMethods (component, vm) {
  const { mixins = [] } = component

  const actions = []
  const mutations = []
  _.each([component, ...mixins], ({ methods }) => {
    _(methods)
      .entries()
      .each(([key, { name }]) => {
        if (name === 'mappedAction') {
          actions.push(key)
          if (vm) vm[key] = jest.fn().mockResolvedValue()
          else component.methods[key] = jest.fn().mockResolvedValue()
          // TODO: change stubVuexMappedMethods to use spies
          // jest.spyOn(vm ?? component.methods, key).mockResolvedValue()
        }
        if (name === 'mappedMutation') {
          mutations.push(key)
          if (vm) vm[key] = jest.fn()
          else component.methods[key] = jest.fn()

          // TODO: change stubVuexMappedMethods to use spies
          // jest.spyOn(vm ?? component.methods, key).mockImplementation()
        }
      })
  })

  return { actions, mutations }
}

/** Returns a decimalized number, more testable with `toBeCloseTo` */
export function decimalize (timestamp) {
  return Number(`0.${timestamp}`)
}

/** Stub components. Pass via `stubs` options within `shallowMount`/`mount`. */
export function stubComponents (componentNames = []) {
  return _.pick(componentStubs, componentNames)
}

/** Mock components via their imports. Call this function before describe.component. */
export function mockComponents (componentNames = []) {
  for (const mock of _.pick(componentMocks, componentNames)) mock()
}

function isMS (timestamp) {
  return String(timestamp).length === 13
}

export function minsAgo (n, { timestamp = Date.now() } = {}) {
  if (isNaN(n)) return console.error('[minsAgo] was not passed a number', n)
  return isMS(timestamp)
    ? timestamp - (n * ONE_MIN_MS)
    : timestamp - (n * ONE_MIN_S)
}

export function minsAhead (n, { timestamp = Date.now() } = {}) {
  if (isNaN(n)) console.error('[minsAhead] was not passed a number', n)
  return isMS(timestamp)
    ? timestamp + (n * ONE_MIN_MS)
    : timestamp + (n * ONE_MIN_S)
}

export function hoursAgo (n, { timestamp = Date.now() } = {}) {
  if (isNaN(n)) console.error('[hoursAgo] was not passed a number', n)
  return isMS(timestamp)
    ? timestamp - (n * ONE_HOUR_MS)
    : timestamp - (n * ONE_HOUR_S)
}

export function hoursAhead (n, { timestamp = Date.now() } = {}) {
  if (isNaN(n)) console.error('[hoursAhead] was not passed a number', n)
  return isMS(timestamp)
    ? timestamp + (n * ONE_HOUR_MS)
    : timestamp + (n * ONE_HOUR_S)
}

export function daysAgo (n, { timestamp = Date.now() } = {}) {
  if (isNaN(n)) console.error('[daysAgo] was not passed a number', n)
  return isMS(timestamp)
    ? timestamp - (n * ONE_DAY_MS)
    : timestamp - (n * ONE_DAY_S)
}

export function daysAhead (n, { timestamp = Date.now() } = {}) {
  if (isNaN(n)) console.error('[daysAhead] was not passed a number', n)
  return isMS(timestamp)
    ? timestamp + (n * ONE_DAY_MS)
    : timestamp + (n * ONE_DAY_S)
}

function getMatchedRoutes (route) {
  const res = []
  while (route) {
    res.unshift(route)
    route = route.parent
  }
  return res
}

function getPropDefaults (component) {
  return _(component?.props)
    .pickBy(({ required }) => !required)
    .transform((result, prop, name) => {
      result[name] = _.isFunction(prop.default) ? prop.default() : prop.default
    }, {})
    .value()
}

function getDataDefaults (component) {
  return component?.data
    ? _.cloneDeep(component?.data())
    : null
}

export async function mockPermission (wrapper, permKey, value) {
  wrapper.vm.$cycloid.permissions.canDisplay.mockImplementation((action) => action !== permKey || value)
  await wrapper.vm.$forceUpdate()
}

async function resetPermissions (wrapper, initialValue) {
  wrapper.vm.$cycloid.permissions.canDisplay.mockImplementation(() => initialValue)
  await wrapper.vm.$forceUpdate()
}

export async function resetComponent (component, wrapper, { spy, initialCanDisplayValue = true } = {}) {
  if (_.some([component, wrapper], _.isEmpty)) return console.error(`[resetComponent] was not passed required params. \n\t- component: ${component}\n\t- wrapper: ${wrapper}`)

  if (spy) for (const key in spy) delete spy[key]

  if (!wrapper.exists()) return // ! To avoid problems with destroyed components

  const propDefaults = getPropDefaults(component)
  const dataDefaults = getDataDefaults(component)

  if (propDefaults) {
    await wrapper.setProps(propDefaults)
  }

  if (dataDefaults) {
    for (const key in dataDefaults) {
      if (wrapper.vm.$hasDataChanged(key)) {
        await wrapper.setData({ [key]: dataDefaults[key] })
      }
    }
    wrapper.vm.$setOriginalData()
  }

  await resetPermissions(wrapper, initialCanDisplayValue)
}

export function generateStub ({
  slots = [],
  scopedSlots = {},
  element = 'div',
} = {}) {
  return {
    render (h) {
      return h(element, [
        ..._.values(_.pick(this.$slots, slots)),
        ..._.map(scopedSlots, (props, name) => this.$scopedSlots[name]?.(props)),
      ])
    },
  }
}

/** Uses testing-library and other helpers to setup a component.
 *
 * - Stubs all `mappedMutations` and `mappedActions`.
 * - Stubs **permissions** `canDisplay` and `canDisplayRoute` methods.
 * - Stubs **router**, using `createRouter` helper with `useGlobalGuards: false`.
 *   _We need to stub this way, else router links will not render properly in the DOM._
 * - Uses **vuetify**, so long as `hasVuetify` prop is true (default: true).
 * - Uses **store**, using `createStore`, can be overridden with the `store` prop.
 *   _Alternatively, you can amend store data via `beforeRender`_.
 * - Populates the $route, by calling router.replace using the passed $route,
 *   generated by `findRoute` helper (default: dashboard page).
 * - Populated `i18n`, to use globals and local translations.
 *
 * @returns component `rendered` via testing-library/vue & additional properties:
 *   - store
 *   - router
 *   - user
 */
export async function renderComponent (component, {
  $route,
  beforeRender,
  hasVuetify = true,
  mockRouterMethods = true,
  routeAfterRender = false,
  mocks = {},
  permissions = {},
  propsData = {},
  attrs = {},
  slots = {},
  store = createStore(),
  stubs = {},
  ...invalidOptions
} = {}) {
  _.$handleInvalidOptions(renderComponent, arguments, {
    invalidOptions,
    corrections: { route: '$route' },
  })

  if (hasVuetify) Vue.use(Vuetify)
  stubVuexMappedMethods(component)
  beforeRender?.(store)

  const router = createRouter(store, { useGlobalGuards: false })
  if ($route && !routeAfterRender) await router.replace($route).catch(_.noop)

  const utils = {
    user: userEvent.setup(),
    store,
    router,
    ...render(component, {
      i18n: createI18n(component),
      routes: router,
      mocks: {
        $cycloid: {
          permissions: {
            canDisplay: jest.fn().mockImplementation((key) => permissions[key] ?? true),
            canDisplayRoute: jest.fn().mockImplementation((key) => permissions[key] ?? true),
            getRouteRequiredActions: jest.fn().mockReturnValue($route?.meta?.requiredActions || []),
            ..._.pick(permissions, ['canDisplay', 'canDisplayRoute', 'getRouteRequiredActions']),
          },
          ydAPI: { ...createYDAPIMock(), ...mocks?.$cycloid?.ydAPI || {} },
        },
        ..._.omit(mocks, '$cycloid'),
      },
      propsData,
      store,
      stubs,
      attrs,
      slots,
      ...(hasVuetify ? { vuetify: new Vuetify() } : {}),
    }),
  }

  if ($route && routeAfterRender) await router.replace($route).catch(_.noop)

  if (mockRouterMethods) {
    jest.spyOn(utils.router, 'push').mockReturnValue({ catch: _.noop })
    jest.spyOn(utils.router, 'replace').mockReturnValue({ catch: _.noop })
  }

  return utils
}

<template>
  <v-container
    data-cy="stack-forms"
    class="stack-forms">
    <CyExpansionPanel
      ref="expansionPanels"
      v-model="panels"
      :class="['stack-forms__expansion-panels', 'cy-scrollbars', {
        'stack-forms__expansion-panels--with-sidepanel': showSidePanel,
      }]"
      accordion>
      <CyAlert
        v-if="_.filter(conflictingFieldsFor).length"
        theme="warning"
        :content="$t('conflictExplanation')"
        no-bottom-margin/>

      <v-expansion-panel
        v-for="(section, index) in filteredSections"
        :key="`category-row-${section.name}`"
        @click="scroll">
        <v-expansion-panel-header>
          <h3 class="category-title font-weight-bold">
            {{ _.upperFirst(section.name) }}
            <span
              v-if="requiredFieldsRemainingFor[section.name].length > 0"
              class="category-title__required-remaining"
              @click.stop="scrollToFirstRequiredField(section.name, index)">
              <v-icon
                class="section-info-icon"
                small
                color="error">
                error
              </v-icon>
              {{ $t('requiredRemaining', { requiredRemaining: requiredFieldsRemainingFor[section.name].length }) }}
            </span>
            <span
              v-if="conflictingFieldsFor[section.name] > 0"
              class="category-title__conflicting">
              <v-icon
                class="section-info-icon"
                small
                color="warning">
                warning
              </v-icon>
              {{ $tc('conflictsCount', conflictingFieldsFor[section.name], { count: conflictingFieldsFor[section.name] }) }}
            </span>
          </h3>
        </v-expansion-panel-header>
        <transition-group name="expand">
          <v-expansion-panel-content
            v-for="(group, gIndex) in section.groups"
            :key="`group-row-${section.name}-${group.name}`"
            :data-index="gIndex"
            eager
            class="stack-forms__group">
            <h4 class="group-name">
              {{ group.name }}
            </h4>
            <div
              v-for="(widgetObj, variableIndex) in group.vars.filter(({ widget }) => widget !== 'hidden')"
              :key="`variables-row-${section.name}-${variableIndex}`"
              :ref="`ref-${section.name}-${widgetObj.name}`"
              :class="['variables-container', {
                'odd': !(variableIndex % 2),
                'even': variableIndex % 2,
                'required-field': widgetObj.required,
              }]">
              <div class="label-container">
                <label class="font-weight-bold font-size-small mb-1">
                  <span>{{ widgetObj.name }}</span>
                  <span
                    v-if="widgetObj.unit"
                    class="font-italic">
                    ({{ $t('in') }} {{ widgetObj.unit }})
                  </span>
                  <v-chip
                    v-if="!_.isEmpty(_.get(widgetObj, 'mismatch_values'))"
                    :class="['ml-1', {
                      'v-chip--warning': !disabled,
                      'v-chip--default': disabled,
                    }]"
                    x-small>
                    {{ $t('conflictHeader') }}
                  </v-chip>
                  <v-chip
                    v-if="widgetObj.isNew && _.isEmpty(_.get(widgetObj, 'mismatch_values'))"
                    :class="['ml-1', {
                      'v-chip--accent': !disabled,
                      'v-chip--default': disabled,
                    }]"
                    x-small>
                    {{ $t('untranslated.new') }}
                  </v-chip>
                </label>
                <CyFormsWidgetDescription :description="widgetObj.description"/>
              </div>
              <div v-if="!_.isEmpty(getMissingWidgetDependencies(widgetObj))">
                <div v-text="$t('dependsOnText')"/>
                <ul class="widget-dependencies">
                  <li
                    v-for="dependency in getMissingWidgetDependencies(widgetObj)"
                    :key="dependency"
                    class="widget-dependency">
                    <CyTag variant="default">
                      {{ dependency }}
                    </CyTag>
                  </li>
                </ul>
              </div>
              <div
                v-else
                class="variables-container__widget">
                <component
                  :ref="`category-${section.name}-widget`"
                  v-model="inputVars[section.name][group.name][widgetObj.key]"
                  v-bind="setExtendedConfig(widgetObj.extendedConfig)"
                  :name="widgetObj.name"
                  :description="widgetObj.description"
                  :disabled="disabled"
                  :min="getMinValue(widgetObj)"
                  :max="getMaxValue(widgetObj)"
                  :display-keys="_.get(widgetObj, 'widget_config.display_keys', true)"
                  :cred-types="_.get(widgetObj, 'widget_config.cred_types')"
                  :type="widgetObj.type"
                  :technology="_.lowerCase(_.first(group.technologies))"
                  :items="_.get(widgetValues, getWidgetPath(section, group, widgetObj), [])"
                  :has-value-mapping="hasValueMapping(widgetObj, false)"
                  :dependencies="getWidgetDependenciesValues(widgetObj)"
                  :config="widgetObj.widget_config"
                  :allow-free-text="widgetObj.allowFreeText"
                  :credential-canonical="credentialCanonicals[widgetObj.source]"
                  :git-url="inputVars[section.name][group.name][widgetObj.source]"
                  :required="widgetObj.required"
                  :forms-validations="_.get(widgetObj, 'validations', [])"
                  :resource-values="getResourceValues(section.name, group.name, widgetObj.key)"
                  @credential-change="setCredentialCanonical($event, widgetObj.key)"
                  @input="setValueForLinkedWidgetsWithDynamics(widgetObj)"
                  @update-resource-id="setSelectedResourceIds($event, section.name, group.name, widgetObj.key)"
                  @hook:created="populateWidgetValues(section, group, widgetObj)"
                  :is="getWidgetByType(widgetObj)"/>
                <div
                  v-if="!_.isEmpty(_.get(widgetObj, 'mismatch_values'))"
                  class="variables-container__widget conflict-info">
                  {{ $t('conflicts') }}
                  <ul>
                    <li
                      v-for="(conflictValue, conflictIndex) in _.get(widgetObj, 'mismatch_values', [])"
                      :key="conflictIndex">
                      {{ conflictValue }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </transition-group>
      </v-expansion-panel>
    </CyExpansionPanel>

    <div
      v-if="showSidePanel"
      class="side-panel">
      <v-tabs
        v-model="sidePanelTab"
        color="primary"
        slider-color="secondary"
        class="flex-grow-0 mb-4">
        <v-tab v-if="quotasEnabled">
          {{ $t('Quotas') }}
          <v-icon
            v-if="_.isEmpty(quotas) && !fetchingQuotas"
            class="ml-2"
            color="error"
            small>
            cancel
          </v-icon>
          <v-icon
            v-else-if="!quotasLoading"
            class="ml-2"
            :color="isEnvQuotaValid(environmentCanonical) ? 'success' : 'error'"
            small>
            {{ isEnvQuotaValid(environmentCanonical) ? 'check' : 'cancel' }}
          </v-icon>
          <v-progress-circular
            v-else
            :width="2"
            :size="16"
            class="ml-2"
            color="secondary"
            indeterminate/>
        </v-tab>
        <v-tab>{{ $t('costEstimation') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="sidePanelTab">
        <v-tab-item v-if="quotasEnabled">
          <CyQuotasRequirementPanel
            :stack-forms-input="_.cloneDeep({
              service_catalog_ref: stackRef,
              inputs: [
                {
                  environment_canonical: environmentCanonical,
                  use_case: useCase,
                  vars: inputVars,
                  resource_pool_canonical: envsResourcePool[environmentCanonical],
                },
              ],
            })"
            :is-form-valid="isValid"
            :is-creation-mode="$route.name !== 'environmentConfig'"/>
        </v-tab-item>
        <v-tab-item>
          <CyTerracostPricingPanel
            :stack-forms-input="{
              service_catalog_ref: stackRef,
              inputs: [
                {
                  environment_canonical: environmentCanonical,
                  use_case: useCase,
                  vars: inputVars,
                },
              ],
            }"
            :required-fields-filled="isValid"/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CyExpansionPanel from '@/components/CyExpansionPanel.vue'
import CyFormsWidgetAutocomplete from '@/components/CyFormsWidgetAutocomplete.vue'
import CyFormsWidgetBranches from '@/components/CyFormsWidgetBranches.vue'
import CyFormsWidgetCatalogRepositories from '@/components/CyFormsWidgetCatalogRepositories.vue'
import CyFormsWidgetConfigRepositories from '@/components/CyFormsWidgetConfigRepositories.vue'
import CyFormsWidgetCredentials from '@/components/CyFormsWidgetCredentials.vue'
import CyFormsWidgetDescription from '@/components/CyFormsWidgetDescription.vue'
import CyFormsWidgetDropdown from '@/components/CyFormsWidgetDropdown.vue'
import CyFormsWidgetInventoryResource from '@/components/CyFormsWidgetInventoryResource.vue'
import CyFormsWidgetNumber from '@/components/CyFormsWidgetNumber.vue'
import CyFormsWidgetRadios from '@/components/CyFormsWidgetRadios.vue'
import CyFormsWidgetSimpleText from '@/components/CyFormsWidgetSimpleText.vue'
import CyFormsWidgetSliderList from '@/components/CyFormsWidgetSliderList.vue'
import CyFormsWidgetSliderRange from '@/components/CyFormsWidgetSliderRange.vue'
import CyFormsWidgetSwitch from '@/components/CyFormsWidgetSwitch.vue'
import CyFormsWidgetTextarea from '@/components/CyFormsWidgetTextarea.vue'
import CyQuotasRequirementPanel from '@/components/CyQuotasRequirementPanel.vue'
import CyTerracostPricingPanel from '@/components/CyTerracostPricingPanel.vue'

export default {
  name: 'CyWizardStackForms',
  components: {
    CyExpansionPanel,
    CyFormsWidgetAutocomplete,
    CyFormsWidgetBranches,
    CyFormsWidgetCatalogRepositories,
    CyFormsWidgetConfigRepositories,
    CyFormsWidgetCredentials,
    CyFormsWidgetDescription,
    CyFormsWidgetDropdown,
    CyFormsWidgetInventoryResource,
    CyFormsWidgetNumber,
    CyFormsWidgetRadios,
    CyFormsWidgetSimpleText,
    CyFormsWidgetSliderList,
    CyFormsWidgetSliderRange,
    CyFormsWidgetSwitch,
    CyFormsWidgetTextarea,
    CyQuotasRequirementPanel,
    CyTerracostPricingPanel,
  },
  props: {
    forms: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSidePanel: {
      type: Boolean,
      default: true,
    },
    stackRef: {
      type: String,
      default: null,
    },
    environmentCanonical: {
      type: String,
      default: null,
    },
    useCase: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    credentialCanonicals: {},
    inputVars: {},
    refs: [],
    panels: 0,
    sidePanelTab: 0,
    allResources: [],
    widgetValues: {},
    widgetStates: {},

  }),
  computed: {
    ...mapState('organization', {
      fetchingQuotas: (state) => state.fetchInProgress.quotas,
      quotas: (state) => state.available.quotas,
      resources: (state) => state.available.inventoryResources,
    }),
    ...mapState('organization/quota', {
      debouncingQuotas: (state) => state.debouncing,
      envsResourcePool: (state) => state.envsResourcePool,
      fetchingQuotaRequirements: (state) => state.fetchingRequirements,
    }),
    ...mapGetters('organization/quota', {
      isEnvQuotaValid: 'isEnvValid',
    }),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    isValid () {
      const widgets = _.flatMap(this.refs, (section) => section)

      for (const { getErrors, required, value = '' } of widgets) {
        const hasErrorsOrIsEmpty = !_.$isEmpty(getErrors) || _.$isEmpty(value)
        if (required && hasErrorsOrIsEmpty) return false
      }

      return true
    },
    /**
      *Returns an Object with the names of required fields that are still missing a value
      * e.g. { pipeline: ['subnet-id', 'subnet-name'], terraform: [], ansible: ['configuration-a'] }
      */
    requiredFieldsRemainingFor () {
      return _.reduce(this.forms.sections, (result, { name, groups }) => {
        // this will extrapolate the number of "required and empty" fields per group
        result[name] = _.flatMap(groups, ({ vars, name: GroupName }) => {
          const emptyRequiredFields = _.filter(vars, ({ required, key }) => required && _.$isEmpty(this.inputVars?.[name]?.[GroupName]?.[key]))
          return _.map(emptyRequiredFields, 'name')
        })

        return result
      }, {})
    },
    /**
      *Returns an Object with the count of fields with conflicts per group
      * e.g. { pipeline: 1, terraform: 0, ansible: 1 }
      */
    conflictingFieldsFor () {
      return _.reduce(this.forms.sections, (result, { name, groups }) => {
        result[name] = _.chain(groups)
          .flatMap(({ vars }) => vars)
          .filter(({ mismatch_values }) => !_.isEmpty(mismatch_values))
          .size()
          .value()

        return result
      }, {})
    },
    filteredSections () {
      return this.forms.sections.map((section) => {
        // filter out groups with no visible vars
        const groups = section.groups.filter(({ condition = '' }) => this.isConditionalFieldVisible(condition))
        return { ...section, groups }
      }).filter(({ groups }) => !_.isEmpty(groups)) // filter out sections with empty groups
    },
    quotasEnabled () {
      return this.organization.quotas && this.stack?.quota_enabled
    },
    quotasLoading () {
      return this.fetchingQuotas ||
        this.fetchingQuotaRequirements[this.environmentCanonical] ||
        this.debouncingQuotas[this.environmentCanonical]
    },
    hasResourceFields () {
      return _.some(this.forms.sections, ({ groups }) => _.some(groups, ({ vars }) => _.some(vars, ({ widget }) => widget === 'cy_inventory_resource')))
    },
  },
  watch: {
    forms: {
      handler () {
        this.initializeFormsConfiguration()
      },
      deep: true,
      immediate: true,
    },
    inputVars: {
      handler (inputVars) {
        this.emitFilteredInputVars(inputVars)
      },
      deep: true,
      immediate: true,
    },
    isValid: {
      handler (isValid) {
        this.$emit('validate', isValid)
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted () {
    // To ensure isValid computes properly, we need to store this.$refs in data
    await this.$nextTick()
    this.$set(this, 'refs', this.$refs)
    if (this.hasResourceFields) this.fetchResources()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    flatInputs (obj, path = []) {
      return !_.isObject(obj)
        ? { [_.last(path)]: obj }
        : _.reduce(obj, (cum, next, key) => _.merge(cum, this.flatInputs(next, [...path, key])), {})
    },
    hasValueMapping (widget, defaultEmptyAsFalse = true) {
      const [sectionName, groupName] = this.findWidgetFullPathByKey(widget.key)
      const values = _.get(this.widgetValues, `${sectionName}.${groupName}.${widget.key}`, [])
      if (_.isEmpty(values) && defaultEmptyAsFalse) return false
      const widgetValues = this.hasDynamicDefaultOrValues(values) ? values.options[0]?.values : values
      return _.every(widgetValues, (value) => _.isObject(value) && !_.isArray(value) && _.$hasAll(value, ['label', 'value']))
    },
    getMinValue (widgetObj) {
      const { widget: type, values } = widgetObj
      if (type !== 'slider_range') return
      if (this.hasDynamicDefaultOrValues(values)) {
        return Number(_.first(this.getDynamicDefaultAndValues(widgetObj)?.values))
      }
      return _.first(values)
    },
    getMaxValue (widgetObj) {
      const { widget: type, values } = widgetObj
      if (type !== 'slider_range') return
      if (this.hasDynamicDefaultOrValues(values)) {
        return Number(_.last(this.getDynamicDefaultAndValues(widgetObj)?.values))
      }
      return _.last(values)
    },
    async populateWidgetValues (section, group, widgetObj, resetValues = false) {
      const { widget: type, values, values_ref: valuesRef, key } = widgetObj
      const widgetsWithDynamicValues = ['auto_complete', 'dropdown', 'radios', 'slider_list']
      const dependencies = this.getWidgetDependenciesValues(widgetObj)

      if (!_.isEmpty(this.getMissingWidgetDependencies(widgetObj))) return

      if (type === 'cy_inventory_resource') {
        this.initializeWidgetValue(section.name, group.name, widgetObj)
        if (resetValues) this.$set(this.inputVars[section.name][group.name], `_cy_${key}_id`, [])
        return
      }
      if (!widgetsWithDynamicValues.includes(type)) {
        this.$set(this.widgetValues, this.getWidgetPath(section, group, widgetObj), [])
        return
      }
      if (this.hasDynamicDefaultOrValues(values)) {
        const dynamicValues = this.getDynamicDefaultAndValues(widgetObj)?.values
        this.$set(this.widgetValues, this.getWidgetPath(section, group, widgetObj), dynamicValues)
        return
      }
      if (valuesRef && /\${[a-zA-Z_$0-9]*}/g.test(valuesRef)) {
        const url = valuesRef.replace(/\${([a-zA-Z_$0-9]*)}/g, (_, key) => dependencies[key])
        const { data } = await this.$cycloid.ydAPI.getFormsValuesRef(this.orgCanonical, url) || {}
        this.$set(this.widgetValues, this.getWidgetPath(section, group, widgetObj), data)
        if (resetValues) this.$set(this.inputVars[section.name][group.name], widgetObj.key, '')
        return
      }
      this.$set(this.widgetValues, this.getWidgetPath(section, group, widgetObj), values)
    },
    getWidgetByType (widgetObj) {
      const widgetPrefix = 'CyFormsWidget'
      const { widget, values } = widgetObj
      const widgetPath = this.getWidgetPathFromObj(widgetObj)
      const currentWidget = this.widgetStates[widgetPath] || widgetObj.widget

      if (widget === 'slider_list' && _.isArray(values) && values.length === 1) {
        return `${widgetPrefix}Radios`
      }

      if (widget === 'dropdown') {
        widgetObj.allowFreeText = false
        return `${widgetPrefix}Autocomplete`
      }

      return {
        auto_complete: `${widgetPrefix}Autocomplete`,
        switch: `${widgetPrefix}Switch`,
        dropdown: `${widgetPrefix}Dropdown`,
        radios: `${widgetPrefix}Radios`,
        slider_list: `${widgetPrefix}SliderList`,
        slider_range: `${widgetPrefix}SliderRange`,
        number: `${widgetPrefix}Number`,
        text_area: `${widgetPrefix}Textarea`,
        simple_text: `${widgetPrefix}SimpleText`,
        cy_cred: `${widgetPrefix}Credentials`,
        cy_scs: `${widgetPrefix}CatalogRepositories`,
        cy_crs: `${widgetPrefix}ConfigRepositories`,
        cy_branch: `${widgetPrefix}Branches`,
        cy_inventory_resource: `${widgetPrefix}InventoryResource`,
      }[currentWidget]
    },
    setCredentialCanonical (credentialCanonical, key) {
      this.$set(this.credentialCanonicals, key, credentialCanonical)
    },
    setExtendedConfig (extendedConfig = '') {
      if (!extendedConfig) return {}

      try {
        return JSON.parse(atob(extendedConfig))
      } catch {
        return {}
      }
    },
    initializeWidgetValue (sectionName, groupName, widgetObj) {
      const { key, default: defaultValue, current = null } = widgetObj
      const currentValue = current ?? defaultValue

      if (this.hasDynamicDefaultOrValues(currentValue)) {
        this.handleDropdownConditionWidgetChange(widgetObj)
      }

      const finalWidgetValue = this.hasDynamicDefaultOrValues(currentValue)
        ? this.getDynamicDefaultAndValues(widgetObj)?.default
        : currentValue

      this.$set(this.inputVars[sectionName][groupName], key, finalWidgetValue)
    },
    initializeFormsConfiguration () {
      this.$set(this, 'inputVars', {})

      this.forms.sections.forEach(({ name: sectionName, groups }) => {
        this.$set(this.inputVars, sectionName, {})
        groups.forEach(({ vars, name: groupName }) => {
          this.$set(this.inputVars[sectionName], groupName, {})
          vars.forEach((widgetObj) => {
            this.initializeWidgetValue(sectionName, groupName, widgetObj)
          })
        })
      })
    },
    emitFilteredInputVars (inputs) {
      // filter out non-visible groups because of conditional rule
      const inputVars = {}

      Object.entries(inputs).forEach(([sectionName, groups]) => {
        inputVars[sectionName] = {}

        const entity = this.filteredSections.find(({ name }) => name === sectionName)
        if (!entity) return

        Object.entries(groups).forEach(([groupName, keys]) => {
          if (entity.groups.find(({ name }) => name === groupName)) {
            inputVars[sectionName][groupName] = keys
          }
        })
      })

      // filter out empty sections
      this.$emit('input', _.omitBy(inputVars, _.isEmpty))
    },
    scrollToFirstRequiredField (sectionName, panelIndex) {
      // trigger validation to highlight invalid fields
      const widgets = this.$refs[`category-${sectionName}-widget`]
      widgets.forEach((widget) => {
        if (_.has(widget, '$v.$touch')) widget.$v.$touch()
      })

      // ensure parent expansion-panel is open
      this.panels = panelIndex

      const requiredField = this.requiredFieldsRemainingFor[sectionName][0]
      const refName = `ref-${sectionName}-${requiredField}`

      this.$refs[refName][0].scrollIntoView({ behavior: 'smooth' })
      this.scroll()
    },
    scroll () {
      this.$vuetify.goTo(0, { container: this.$refs.expansionPanels })
    },
    getDynamicDefaultAndValues (widgetObj) {
      const defaultValues = this.$wasm.getValuesAndDefault(
        JSON.stringify(widgetObj),
        JSON.stringify(this.flatInputs(this.inputVars)),
      )
      return defaultValues
    },
    hasDynamicDefaultOrValues (field) {
      return _.has(field, 'options')
    },
    isConditionalFieldVisible (condition) {
      if (condition === '') return true

      const conclusion = this.$wasm.evaluateCondition(
        condition,
        JSON.stringify(this.flatInputs(this.inputVars)),
      )

      if (typeof conclusion !== 'boolean') {
        // We probably want to capture the parse error and show it in an alert
        console.error({ conclusion })
        return false
      }

      return conclusion
    },
    handleDropdownConditionWidgetChange (widgetObj) {
      const widgetPath = this.getWidgetPathFromObj(widgetObj)
      const originalWidgetType = widgetObj.widget

      if (originalWidgetType === 'dropdown') {
        const currentWidgetType = this.widgetStates[widgetPath] || originalWidgetType
        const newWidgetType = 'auto_complete'

        if (currentWidgetType !== newWidgetType) {
          this.$set(this.widgetStates, widgetPath, newWidgetType)
        }
      }
    },
    getWidgetPathFromObj (widgetObj) {
      const [sectionName, groupName, key] = this.findWidgetFullPathByKey(widgetObj.key)
      if (!sectionName || !groupName || !key) {
        return ''
      }
      return `${sectionName}.${groupName}.${key}`
    },
    async handleDynamicValues (triggerObjKey, widgetObj, sectionName, groupName, key) {
      const { default: dynamicDefault, values: dynamicValues } = this.getDynamicDefaultAndValues(widgetObj)
      for (const { condition } of _.get(widgetObj.values, 'options')) {
        if (_.includes(condition, `$${triggerObjKey}`)) {
          await this.$nextTick()
          this.$set(this.inputVars[sectionName][groupName], key, undefined)

          const mappedValues = _.map(dynamicValues, (v) => _.has(v, 'label') ? v.label : v)
          this.handleDropdownConditionWidgetChange(widgetObj)

          if (_.includes(mappedValues, dynamicDefault)) {
            this.$set(this.inputVars[sectionName][groupName], key, dynamicDefault)
          }
        }
      }
    },
    handleDynamicDefault (triggerObjKey, widgetObj, sectionName, groupName, key) {
      const { default: dynamicDefault } = this.getDynamicDefaultAndValues(widgetObj)
      for (const { condition } of _.get(widgetObj.default, 'options')) {
        if (_.includes(condition, `$${triggerObjKey}`)) {
          this.$set(this.inputVars[sectionName][groupName], key, dynamicDefault)
        }
      }
    },
    setValueForLinkedWidgetsWithDynamics ({ key: triggerObjKey }) {
      this.forms.sections.forEach(({ name: sectionName, groups }) => {
        groups.forEach(({ vars, name: groupName }) => {
          vars.forEach(async (widgetObj) => {
            const { key, default: defaultValue, current = null, values } = widgetObj

            const currentValue = current ?? defaultValue
            const hasDynamicDefault = this.hasDynamicDefaultOrValues(currentValue)
            const hasDependentValues = _.includes(widgetObj.depends_on, triggerObjKey)
            const hasDynamicValues = this.hasDynamicDefaultOrValues(values)
            // if variable has dynamic values:
            //
            // 1 - check if trigger widget is included in its conditions
            // 2 - if so, unset the variable (reset)
            // 3 - check if the new default can be applied
            // 4 - apply that value
            if (hasDynamicValues) {
              await this.handleDynamicValues(triggerObjKey, widgetObj, sectionName, groupName, key)
              this.populateWidgetValues({ name: sectionName }, { name: groupName }, widgetObj, true)
            }

            if (hasDependentValues) {
              await this.populateWidgetValues({ name: sectionName }, { name: groupName }, widgetObj, true)
            }

            // if variable has dynamic default:
            //
            // 1 - check if trigger widget is included in its conditions
            // 2 - if so, for the new value
            if (hasDynamicDefault) {
              this.handleDynamicDefault(triggerObjKey, widgetObj, sectionName, groupName, key)
            }
          })
        })
      })
    },
    setSelectedResourceIds (resourceIds, sectionName, groupName, key) {
      this.$set(this.inputVars[sectionName][groupName], `_cy_${key}_id`, resourceIds)
    },
    async fetchResources () {
      await this.FETCH_AVAILABLE({ keyPath: 'inventoryResources' })
      this.allResources = this.resources
    },
    getResourceValues (sectionName, groupName, key) {
      const resourceIds = this.inputVars[sectionName][groupName][`_cy_${key}_id`]
      if (!resourceIds || _.isEmpty(this.allResources)) return null

      if (typeof resourceIds === 'string') return this.allResources.find(({ id }) => _.toString(id) === resourceIds)

      return _.filter(_.map(resourceIds, (resourceId) => {
        return this.allResources.find(({ id }) => _.toString(id) === resourceId)
      }))
    },
    findWidgetFullPathByKey (key) {
      const result = _.flatMap(this.forms.sections, ({ name: sectionName, groups }) => {
        return groups.map(({ name: groupName, vars }) => {
          return vars.find(({ key: widgetKey }) => widgetKey === key) ? [sectionName, groupName, key] : null
        })
      }).filter(Boolean)[0]

      return result || [null, null, null]
    },
    getMissingWidgetDependencies (widgetObj) {
      const { depends_on: dependencies } = widgetObj
      if (!dependencies) return []

      return _.reduce(dependencies, (missingDependencies, dependency) => {
        const [dependencySectionName, dependencyGroupName] = this.findWidgetFullPathByKey(dependency)
        const dependencyValue = _.get(this.inputVars, `${dependencySectionName}.${dependencyGroupName}.${dependency}`)
        if (!_.has(this.widgetValues, `${dependencySectionName}.${dependencyGroupName}.${dependency}`)) {
          missingDependencies.push(dependency)
        }
        if (_.isEmpty(dependencyValue)) {
          const section = _.find(this.forms.sections, { name: dependencySectionName })
          const group = _.find(section.groups, { name: dependencyGroupName })
          const variable = _.find(group.vars, { key: dependency })
          if (variable?.name) {
            missingDependencies.push(variable.name)
          }
        }
        return missingDependencies
      }, [])
    },
    getWidgetDependenciesValues (widgetObj) {
      const { depends_on: dependencies } = widgetObj
      if (!dependencies) return {}
      return _.reduce(dependencies, (result, dependency) => {
        const [dependencySectionName, dependencyGroupName] = this.findWidgetFullPathByKey(dependency)
        const section = _.find(this.forms.sections, { name: dependencySectionName })
        const group = _.find(section.groups, { name: dependencyGroupName })
        const dependencyField = _.find(group.vars, { key: dependency })
        const dependencyValue = _.get(this.inputVars, `${dependencySectionName}.${dependencyGroupName}.${dependency}`)
        if (this.hasValueMapping(dependencyField)) {
          const dependencyValues = _.get(this.widgetValues, `${dependencySectionName}.${dependencyGroupName}.${dependency}`, [])
          const matchingMap = _.find(dependencyValues, { label: dependencyValue })
          result[dependency] = matchingMap ? matchingMap.value : undefined
        } else {
          result[dependency] = dependencyValue
        }
        return result
      }, {})
    },
    getWidgetPath (section, group, widgetObj) {
      return `${section.name}.${group.name}.${widgetObj.key}`
    },
  },
  i18n: {
    messages: {
      en: {
        in: 'in',
        requiredRemaining: '{requiredRemaining} required remaining',
        conflictHeader: 'Conflict',
        conflictsCount: '{count} conflict | {count} conflicts',
        conflicts: 'Conflicting values:',
        conflictExplanation: '<strong>Value conflicts detected.</strong> Some conflicting variables were reset to their default values. Please review every variable marked as conflicting.',
        costEstimation: 'Cost estimation',
        dependsOnText: `This field's options depend on other fields. Please fill the following fields before you can proceed with this one.`,
      },
      es: {
        in: 'en',
        requiredRemaining: 'quedan {requiredRemaining} requeridos',
        conflictHeader: 'Conflicto',
        conflictsCount: '{count} conflicto | {count} conflictos',
        conflicts: 'Valores en conflicto:',
        conflictExplanation: '<strong>Conflictos de valores detectados.</strong> Algunas variables en conflicto se restablecieron a sus valores predeterminados. Revise todas las variables marcadas como conflictivas.',
        costEstimation: 'Estimación de costos',
        dependsOnText: 'Las opciones de este campo dependen de otros campos. Complete los siguientes campos antes de poder continuar con este.',
      },
      fr: {
        in: 'en',
        requiredRemaining: '{requiredRemaining} requis restant',
        conflictHeader: 'Conflit',
        conflictsCount: '{count} conflict | {count} conflits',
        conflicts: 'Valeurs contradictoires:',
        conflictExplanation: '<strong>Conflits de valeurs détectés.</strong> Certaines variables en conflit ont été réinitialisées à leurs valeurs par défaut. Veuillez examiner toutes les variables marquées comme étant en conflit.',
        costEstimation: 'Estimation du coût',
        dependsOnText: `Les options de ce champ dépendent d'autres champs. Veuillez remplir les champs suivants avant de pouvoir continuer avec celui-ci.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$padding-side-each: 24px;

.stack-forms {
  display: flex;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
  min-height: 650px;
  border-top: solid 1px cy-get-color("grey", "light-2");

  .widget-dependencies {
    padding-top: 12px;
    padding-left: 0;
    list-style-type: none;

    .widget-dependency {
      margin-bottom: 4px;
    }
  }

  ::v-deep .v-expansion-panel-header:first-child {
    border-top: none !important;
  }

  &__expansion-panels {
    display: initial;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    justify-content: initial;
    overflow: auto;
    border-right: solid 1px cy-get-color("grey", "light-2");

    &--with-sidepanel {
      right: 348px;
      width: auto;
    }
  }

  &__sidebar {
    max-width: 300px;
  }

  &__group {
    margin-left: 16px;

    &:not(:last-child) {
      margin-bottom: 12px;
      border-bottom: 1px solid cy-get-color("grey");
    }

    .group-name {
      margin-bottom: 12px;
      color: cy-get-color("primary", "light-2");
    }
  }

  ::v-deep .v-expansion-panels {
    .category-title {
      font-size: 15px;

      &__required-remaining {
        margin-left: 5px;
        padding: 7px 12px;
        color: cy-get-color("error");
        font-size: 0.85rem;

        &:hover {
          background-color: cy-get-color("error", "light-2");
        }
      }

      &__conflicting {
        margin-left: 5px;
        padding: 7px 12px;
        color: cy-get-color("warning");
        font-size: 0.85rem;
      }

      .section-info-icon {
        margin-bottom: 1px;
      }
    }

    .v-expansion-panel {
      &-header {
        flex-direction: row-reverse;
        padding-right: 12px;
        padding-left: 12px;
        border-top: solid 1px cy-get-color("grey", "light-2");

        &__icon > .v-icon {
          color: cy-get-color("grey", "dark-4") !important;
        }
      }

      &--active {
        > .v-expansion-panel-header {
          min-height: 0;
        }
      }

      &-content__wrap {
        padding-bottom: 0;
        padding-left: 0;
      }
    }

    .variables-container {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 36px;

      .label-container {
        width: 360px;

        @media screen and (width <= 1104px) {
          width: 100%;
        }
      }

      .v-input,
      .v-input--is-focused {
        margin-top: 0;
        padding-top: 0;

        &__slot {
          &::after,
          &::before {
            border: 0;
          }
        }
      }

      &.odd {
        background-color: cy-get-color("grey", "light-4");
      }

      &.even {
        background-color: cy-get-color("white");
      }

      &__widget {
        display: flex;
        flex-direction: column;
      }

      .conflict-info {
        color: cy-get-color("grey", "dark-2");
      }
    }
  }
}

.side-panel {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  width: calc(300px + #{$padding-side-each} * 2);
  padding: 16px #{$padding-side-each};
  padding-top: 0;
  overflow: auto;
}

.expand {
  &-enter-active,
  &-leave-active {
    transition:
      transform 0.2s ease,
      opacity 0.2s ease;
  }

  &-enter,
  &-leave-to {
    transform: translateY(-10px) !important;
    opacity: 0;
  }
}

</style>

<template>
  <CyModal
    v-if="!loading"
    small
    action-btn-hidden
    :header-title="$t('addWorker')">
    <v-stepper
      v-model="currentStep"
      data-cy="stepper"
      elevation="0"
      class="px-0">
      <v-stepper-header class="elevation-0 px-0">
        <v-stepper-step
          :complete="currentStep > $static.FIRST_STEP"
          :step="$static.FIRST_STEP">
          {{ $t('forms.type') }}
        </v-stepper-step>

        <v-divider/>

        <template v-if="isThreeStepProvider">
          <v-stepper-step
            :complete="currentStep > $static.FIRST_STEP + 1"
            :step="$static.FIRST_STEP + 1">
            {{ $t('configuration') }}
          </v-stepper-step>

          <v-divider/>
        </template>

        <v-stepper-step
          :complete="currentStep > finalStep"
          :step="finalStep">
          {{ $t('deploy') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          :step="$static.FIRST_STEP"
          class="px-0">
          <p class="mt-4">
            {{ $t('select') }}
          </p>

          <div
            v-for="(group, groupIndex) of $static.configGroups"
            :key="`configuration-group-${groupIndex}`"
            class="selection">
            <div class="selection__title">
              {{ group.text }}
            </div>

            <div
              v-for="(provider, providerIndex) of group.items"
              :key="`configuration-group-${groupIndex}-item-${providerIndex}`"
              :class="['selection__item', {
                'selection__item--selected': selectedProvider === provider,
              }]"
              :disabled="!canSelect(provider)"
              @click="selectItem(provider)">
              <v-icon class="selection__icon mr-4">
                {{ selectedProvider === provider ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
              </v-icon>
              <CyIconCredential
                v-if="providersConfig[provider].hasLogo"
                class="selection__img"
                size="20"
                :type="provider"/>
              <CyTooltip
                right
                :disabled="canSelect(provider)"
                content-class="missing-tooltip">
                <template #activator="{ on }">
                  <div
                    class="selection__text d-flex"
                    v-on="on">
                    {{ providersConfig[provider].text }}
                    <CyTag
                      v-show="providersConfig[provider].hasDevelopmentTag"
                      class="ml-2"
                      small
                      variant="default">
                      {{ $t('development') }}
                    </CyTag>
                  </div>
                </template>
                {{ $tc('missingCredential', getMissingCredentials(provider).length) }}
                <code
                  v-for="key of getMissingCredentials(provider)"
                  :key="key"
                  v-html="key"/>
              </CyTooltip>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content :step="$static.FIRST_STEP + 1">
          <template v-for="[providerKey, { component, ...providerProps }] of _.entries(providersConfig)">
            <component
              v-if="selectedProvider === providerKey"
              :key="component.name"
              class="mt-4"
              @update="setProperties"
              v-bind="providerProps"
              :is="component"/>
          </template>
        </v-stepper-content>

        <v-stepper-content
          v-if="isThreeStepProvider"
          :step="finalStep">
          <template v-for="[providerKey, { componentStepTwo, ...providerProps }] of _.entries(providersConfig)">
            <component
              v-if="selectedProvider === providerKey"
              :key="componentStepTwo.name"
              class="mt-4"
              @update="setProperties"
              v-bind="providerProps"
              :is="componentStepTwo"/>
          </template>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <template slot="modal-buttons">
      <CyButton
        v-if="currentStep > $static.FIRST_STEP"
        icon="mdi-chevron-left"
        theme="grey"
        variant="tertiary"
        @click="currentStep--">
        {{ $t('forms.back') }}
      </CyButton>

      <v-spacer/>

      <CyButton
        icon="close"
        theme="grey"
        variant="secondary"
        @click="$emit('close')">
        {{ $t('forms.btnClose') }}
      </CyButton>

      <CyButton
        v-if="currentStep < finalStep"
        icon="mdi-chevron-right"
        theme="secondary"
        :disabled="!selectedProvider || isOnStepTwoAndNoDataIsProvided"
        @click="currentStep++">
        {{ $t('forms.btnContinue') }}
      </CyButton>

      <CyButton
        v-else-if="hasAction(selectedProvider)"
        :disabled="!canPerformAction(selectedProvider)"
        icon="check"
        @click="providersConfig[selectedProvider].action">
        {{ providersConfig[selectedProvider].actionText || $t('forms.btnConfirm') }}
      </CyButton>
    </template>
  </CyModal>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import CyWorkersAddAwsStepOne from '@/components/CyWorkersAddAwsStepOne.vue'
import CyWorkersAddAwsStepTwo from '@/components/CyWorkersAddAwsStepTwo.vue'
import CyWorkersAddAzure from '@/components/CyWorkersAddAzure.vue'
import CyWorkersAddBaremetalStepOne from '@/components/CyWorkersAddBaremetalStepOne.vue'
import CyWorkersAddBaremetalStepTwo from '@/components/CyWorkersAddBaremetalStepTwo.vue'
import CyWorkersAddDockerStepOne from '@/components/CyWorkersAddDockerStepOne.vue'
import CyWorkersAddDockerStepTwo from '@/components/CyWorkersAddDockerStepTwo.vue'
import CyWorkersAddFlexeng from '@/components/CyWorkersAddFlexeng.vue'
import CyWorkersAddGcpStepOne from '@/components/CyWorkersAddGcpStepOne.vue'
import CyWorkersAddGcpStepTwo from '@/components/CyWorkersAddGcpStepTwo.vue'
import { generateAWSLink, generateAWSLinkOnPrem } from '@/utils/config/workers'

export const FIRST_STEP = 1

export const cycloidCreds = {
  'cycloid-worker-keys': ['workerKey'],
  vault: ['vaultRoleId', 'vaultSecretId'],
}

export default {
  name: 'CyWorkersAdd',
  components: {
    CyWorkersAddAwsStepOne,
    CyWorkersAddAwsStepTwo,
    CyWorkersAddAzure,
    CyWorkersAddGcpStepOne,
    CyWorkersAddGcpStepTwo,
    CyWorkersAddFlexeng,
    CyWorkersAddDockerStepOne,
    CyWorkersAddDockerStepTwo,
    CyWorkersAddBaremetalStepOne,
    CyWorkersAddBaremetalStepTwo,
  },
  data: () => ({
    loading: false,
    selectedProvider: null,
    currentStep: 1,
    // Dependencies
    cloudWatchRegion: null,
    teamId: null,
    vaultRoleId: null,
    vaultSecretId: null,
    workerKey: null,
    schedulerHost: null,
    schedulerPort: null,
    schedulerPublicTSAKey: null,
  }),
  computed: {
    ...mapState('organization', {
      cycloidCredentials: (state) => state.cycloidCredentials,
    }),
    ...mapGetters('organization', [
      'hasCycloidCredentialOfType',
    ]),
    ...mapGetters('organization/licence', [
      'isOnPrem',
    ]),
    $static () {
      const threeStepProviders = ['aws', 'gcp', 'docker', 'baremetal']
      const configGroups = [
        {
          text: this.$t('cloudHosted'),
          items: [
            'aws',
            'azure',
            'flexibleengine',
            'gcp',
          ],
        },
        {
          text: this.$t('others'),
          items: [
            'baremetal',
            'docker',
          ],
        },
      ]

      return {
        threeStepProviders,
        configGroups,
        FIRST_STEP,
      }
    },
    providersConfig () {
      const dependencies = _.pick(this, [
        'teamId',
        'workerKey',
        'vaultRoleId',
        'vaultSecretId',
      ])
      if (this.isOnPrem) {
        dependencies.schedulerHost = this.schedulerHost
        dependencies.schedulerPort = this.schedulerPort
        dependencies.schedulerPublicTSAKey = this.schedulerPublicTSAKey
        dependencies.schedulerAPIAddress = this.schedulerAPIAddress
      }
      const hasLogo = true
      const hasDevelopmentTag = true

      return {
        aws: {
          component: this.isThreeStepProvider ? CyWorkersAddAwsStepOne : CyWorkersAddAwsStepTwo,
          componentStepTwo: CyWorkersAddAwsStepTwo,
          action: () => {
            const link = this.getAWSLink()

            if (link) window.open(link, '_blank')
          },
          actionText: this.$t('deployToAws'),
          hasLogo,
          text: this.$t('untranslated.aws.name'),
          requiredProps: this.isThreeStepProvider ? ['cloudWatchRegion', 'schedulerHost', 'schedulerPort', 'schedulerPublicTSAKey'] : ['cloudWatchRegion'],
          ...dependencies,
        },
        azure: {
          component: CyWorkersAddAzure,
          action: () => {
            window.open($docLinks.workers.azureLink, '_blank')
          },
          actionText: this.$t('deployToAzure'),
          hasLogo,
          text: this.$t('untranslated.azure'),
          ...dependencies,
        },
        baremetal: {
          component: this.isThreeStepProvider ? CyWorkersAddBaremetalStepOne : CyWorkersAddBaremetalStepTwo,
          componentStepTwo: CyWorkersAddBaremetalStepTwo,
          text: this.$t('bareMetalOrOther'),
          ...dependencies,
        },
        docker: {
          component: this.isThreeStepProvider ? CyWorkersAddDockerStepOne : CyWorkersAddDockerStepTwo,
          componentStepTwo: CyWorkersAddDockerStepTwo,
          text: this.$t('untranslated.docker'),
          hasDevelopmentTag,
          ...dependencies,
        },
        flexibleengine: {
          component: CyWorkersAddFlexeng,
          hasLogo,
          text: this.$t('untranslated.flexeng'),
          ...dependencies,
        },
        gcp: {
          component: this.isThreeStepProvider ? CyWorkersAddGcpStepOne : CyWorkersAddGcpStepTwo,
          componentStepTwo: CyWorkersAddGcpStepTwo,
          hasLogo,
          text: this.$t('untranslated.gcp.name'),
          ...dependencies,
        },
      }
    },
    isThreeStepProvider () {
      return this.isOnPrem && this.$static.threeStepProviders.includes(this.selectedProvider)
    },
    finalStep () {
      return this.isThreeStepProvider ? 3 : 2
    },
    isOnStepTwoAndNoDataIsProvided () {
      return this.currentStep === 2 && (!this.schedulerHost || !this.schedulerPort || !this.schedulerPublicTSAKey)
    },
    schedulerAPIAddress () {
      return this.organization?.ci_port === '443'
        ? this.organization?.ci_url
        : `${this.organization?.ci_url}:${this.organization?.ci_port}`
    },
  },
  watch: {
    selectedProvider () {
      this.resetDependencyInfo()
    },
  },
  async mounted () {
    this.$toggle.loading(true)
    await this.getCycloidCredentials()
    this.$toggle.loading(false)
  },
  beforeDestroy () {
    this.RESET_ORG_STATE('cycloidCredentials')
  },
  methods: {
    ...mapActions('organization', [
      'GET_CYCLOID_CREDENTIALS',
    ]),
    ...mapMutations('organization', [
      'RESET_ORG_STATE',
    ]),
    canPerformAction (provider) {
      const { requiredProps = [] } = this.providersConfig?.[provider] || {}
      return this.canSelect() && _(requiredProps)
        .map((key) => this[key])
        .every((value) => !_.$isEmpty(value))
    },
    canSelect (provider) {
      const extractDependencyProps = (props) => {
        return _(props).pickBy('required')
          .keys()
          .without('logo', 'text')
          .map((key) => this[key])
          .value()
      }
      let dependencyProps = extractDependencyProps(this.providersConfig[provider]?.component?.props)

      if (this.isThreeStepProvider) {
        const dependencyPropsStepTwo = extractDependencyProps(this.providersConfig[provider]?.componentStepTwo?.props)
        dependencyProps = { ...dependencyProps, ...dependencyPropsStepTwo }
      }

      return _(dependencyProps).every((value) => !_.$isEmpty(value))
    },
    async getCycloidCredentials () {
      this.teamId = this.organization?.ci_team_name

      await this.GET_CYCLOID_CREDENTIALS()
      if (this.hasCycloidCredentialOfType('vault')) {
        this.vaultRoleId = this.cycloidCredentials.vault.raw?.raw?.role_id || null
        this.vaultSecretId = this.cycloidCredentials.vault.raw?.raw?.secret_id || null
      }
      if (this.hasCycloidCredentialOfType('cycloid-worker-keys')) {
        this.workerKey = btoa(this.cycloidCredentials['cycloid-worker-keys'].raw?.raw?.ssh_prv)
      }
    },
    getMissingCredentials (provider) {
      if (this.canSelect(provider)) return []

      const extractMissingCredentials = (props) => {
        return _(props).pickBy('required')
          .keys()
          .filter((key) => _.flattenDeep(_.values(cycloidCreds)).includes(key) && _.$isEmpty(this[key]))
          .map((key) => _.findKey(cycloidCreds, (value) => value.includes(key)))
          .uniq()
          .value()
      }
      let missingCredentials = extractMissingCredentials(this.providersConfig[provider]?.component?.props)

      if (this.isThreeStepProvider) {
        const missingCredentialsStepTwo = extractMissingCredentials(this.providersConfig[provider]?.componentStepTwo?.props)
        missingCredentials = { ...missingCredentials, ...missingCredentialsStepTwo }
      }

      return _(missingCredentials).uniq().value()
    },
    hasAction (provider) {
      const { action } = this.providersConfig[provider] || {}
      return _.isFunction(action)
    },
    selectItem (provider) {
      if (this.canSelect(provider)) this.selectedProvider = provider
    },
    setProperties (properties) {
      for (const [key, value] of _.entries(properties)) {
        if (_.has(this, key)) this.$set(this, key, value)
      }
    },
    getAWSLink () {
      const {
        cloudWatchRegion,
        teamId,
        workerKey,
        orgCanonical,
        schedulerHost,
        schedulerPort,
        schedulerPublicTSAKey,
        schedulerAPIAddress,
      } = this

      if (this.isOnPrem) {
        return generateAWSLinkOnPrem({
          cloudWatchRegion,
          teamId,
          workerKey,
          orgCanonical,
          schedulerHost,
          schedulerPort,
          schedulerPublicTSAKey,
          schedulerAPIAddress,
        })
      }

      return generateAWSLink({ cloudWatchRegion, teamId, workerKey, orgCanonical })
    },
    resetDependencyInfo () {
      this.teamId = this.organization?.ci_team_name
      this.vaultRoleId = this.cycloidCredentials.vault.raw?.raw?.role_id || null
      this.vaultSecretId = this.cycloidCredentials.vault.raw?.raw?.secret_id || null
      this.workerKey = btoa(this.cycloidCredentials['cycloid-worker-keys'].raw?.raw?.ssh_prv)
    },
  },
  i18n: {
    messages: {
      en: {
        addWorker: 'Add worker',
        bareMetalOrOther: '@:untranslated.bareMetal or other cloud',
        cloudHosted: 'Cloud hosted',
        deployToAws: 'Deploy to AWS',
        deployToAzure: 'Deploy to Azure',
        missingCredential: 'The following credential is missing: | The following credentials are missing: ',
        select: 'Select the type of environment on which you want to deploy your worker.',
        deploy: 'Deploy',
      },
      es: {
        addWorker: 'Agregar worker',
        bareMetalOrOther: '@:untranslated.bareMetal u otra cloud',
        cloudHosted: 'Cloud alojado',
        deployToAws: 'Desplegar en AWS',
        deployToAzure: 'Desplegar en Azure',
        how: 'Cómo desplegar',
        missingCredential: `Falta la siguiente credencial: | Faltan las siguientes credenciales: `,
        select: 'Seleccione el tipo de entorno en el que desea desplegar su worker.',
        deploy: 'Desplegar',
      },
      fr: {
        addWorker: 'Ajouter un worker',
        bareMetalOrOther: '@:untranslated.bareMetal ou autre cloud',
        cloudHosted: 'Hébergement cloud',
        deployToAws: 'Déployer sur AWS',
        deployToAzure: 'Déployer sur Azure',
        how: 'Comment déployer',
        missingCredential: `Les informations d'identification suivantes sont manquantes: | Les informations d'identification suivantes sont manquantes: `,
        select: `Sélectionnez le type d'environnement sur lequel vous souhaitez déployer votre worker.`,
        deploy: 'Déployer',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-stepper {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    gap: 1.25rem;
    border: 1px solid cy-get-color("primary", "light-4");
    border-radius: 8px;
    background-color: cy-get-color("primary", "light-5");
  }

  ::v-deep .v-stepper__label {
    line-height: 1.2 !important;
  }

  &__content {
    padding: 10px 0;
  }

  .v-divider {
    border-color: cy-get-color("primary", "main") !important;
    opacity: 0.5;
  }
}

.selection {
  display: flex;
  flex-direction: column;

  &__title {
    color: cy-get-color("grey", "dark-2");
    font-size: $font-size-xs;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 4px 0;
    padding: 8px 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: cy-get-color("grey", "light-1");
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    cursor: pointer;

    .selection__icon {
      color: cy-get-color("grey");
    }

    &--selected {
      border-color: cy-get-color("secondary");
      background: cy-get-color("secondary", "light-3");

      .selection__icon {
        color: cy-get-color("secondary");
      }
    }

    &[disabled] {
      border-color: cy-get-color("grey", "light-5");
      color: cy-get-color("grey", "light-1");
      cursor: not-allowed;

      img {
        filter: opacity(0.5);
      }

      .selection__icon {
        color: cy-get-color("grey", "light-1");
      }
    }
  }

  &__img {
    width: 20px;
    height: 20px;
    margin-left: 16px;
    object-fit: contain;
  }
}

::v-deep .ace {
  &_content {
    height: inherit !important;
    margin-top: 0 !important;
  }

  &_scroller {
    width: 100% !important;
    height: 100% !important;
  }

  &_scrollbar-v {
    display: none !important;
  }

  &_scrollbar-h {
    width: inherit;
  }
}

.missing-tooltip {
  display: flex;
  align-items: center;

  code {
    margin-left: 4px;
    background-color: cy-get-color("grey", "terminal");
    box-shadow: none;
    color: cy-get-color("white");
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 1.2em;
  }
}
</style>

<template>
  <article class="resource">
    <header class="pa-4 d-flex align-center">
      <template v-if="isDataLoaded">
        <CyInventoryResourceIcon
          :image-src="getImage(resource)"
          :is-custom="isCustomResource(resource)"
          size="48"
          class="resource-icon--side-panel"
          data-cy="icon"/>
      </template>
      <v-skeleton-loader
        v-else
        type="image"
        width="48"
        height="48"
        class="mb-1"
        data-cy="loader-image"/>
      <div class="ml-4">
        <h1
          v-if="isDataLoaded"
          data-cy="name"
          v-text="resource.name"/>
        <v-skeleton-loader
          v-else
          type="heading"
          width="200"
          height="25"
          class="mb-2"
          data-cy="loader-name"/>
        <div class="resource__metadata">
          <template v-if="isDataLoaded">
            <div class="line-height-xs">
              <div
                :title="resource.type.length > 30 ? resource.type : ''"
                data-cy="type"
                v-text="_.truncate(resource.type)"/>
            </div>
            <div class="line-height-xs d-flex align-center">
              <v-icon
                v-if="isCustomResource(resource)"
                size="20"
                class="mr-2"
                data-cy="custom-provider-icon">
                extension
              </v-icon>
              <CyIconCredential
                v-else-if="providerTranslation"
                :type="provider"
                size="20"
                data-cy="provider-icon"/>
              <span
                data-cy="provider-text"
                v-text="providerTranslation || getProvider(resource)"/>
            </div>
            <div
              v-if="!isCustomResource(resource)"
              class="line-height-xs d-flex align-center">
              <CyIconCredential
                type="terraform"
                size="20"
                data-cy="infraTool-icon"/> <span
                  data-cy="infraTool-text"
                  v-text="$t('infraTool.terraform')"/>
            </div>
          </template>
          <template v-else>
            <v-skeleton-loader
              type="text"
              width="100"
              data-cy="loader-type"/>
            <v-skeleton-loader
              type="text"
              width="50"
              data-cy="loader-provider"/>
            <v-skeleton-loader
              type="text"
              width="80"
              data-cy="loader-infraTool"/>
          </template>
        </div>
      </div>
      <v-spacer/>
      <div class="resource__header-actions">
        <CyButton
          v-if="isDataLoaded && !isCustomResource(resource)"
          :href="docPageLink"
          target="_blank"
          icon-only
          theme="primary"
          variant="tertiary"
          icon="help_outline"
          sm
          class="resource__action resource__action--help"
          data-cy="header-button-help"/>
        <CyMenu
          v-if="isDataLoaded && isCustomResource(resource) && !_.$isEmpty(getMenuItems())"
          content-class="resource-menu"
          :items="getMenuItems()"
          data-cy="resource-menu"/>
        <CyButton
          aria-label="Resource panel close"
          icon-only
          theme="primary"
          variant="tertiary"
          icon="close"
          class="resource__action resource__action--close"
          data-cy="header-button-close"
          @click="$emit('close')"/>
      </div>
    </header>
    <v-tabs
      v-model="activeTab"
      :grow="false"
      optional
      background-color="transparent"
      class="flex-grow-0"
      data-cy="tabs">
      <v-tabs-slider color="secondary"/>
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
        :ref="`tab-${tab.id}`"
        :href="`#${tab.id}`">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-divider/>
    <main class="resource__content">
      <v-tabs-items
        v-model="activeTab"
        data-cy="tabs-content">
        <v-tab-item
          :id="tab.id"
          v-for="tab in tabs"
          :key="tab.id"
          :ref="`tab-content-${tab.id}`">
          <component
            v-bind="{
              resource,
              loading,
            }"
            class="tab__viewport"
            :is="tab.component"/>
        </v-tab-item>
      </v-tabs-items>
    </main>
    <CyInventoryResourceDelete
      v-if="isDeleteDialogVisible"
      :resource="resource"
      data-cy="delete-modal"
      @cancel="$toggle.isDeleteDialogVisible(false)"
      @error="onDeleteError"
      @success="onDeleteSuccess"/>
  </article>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CyInventoryResourceDelete from '@/components/CyInventoryResourceDelete.vue'
import CyInventoryResourceDetails from '@/components/CyInventoryResourceDetails.vue'
import CyInventoryResourceIcon from '@/components/CyInventoryResourceIcon.vue'
import CyInventoryResourceJson from '@/components/CyInventoryResourceJson.vue'

export default {
  name: 'CyInventoryResource',
  components: {
    CyInventoryResourceDelete,
    CyInventoryResourceIcon,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    resource: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isDeleteDialogVisible: false,
  }),
  computed: {
    ...mapState('organization/resource', {
      deleteErrors: (state) => state.errors.delete,
    }),
    ...mapGetters('organization/inventory', [
      'getImage',
      'getProvider',
      'getProviderTranslation',
      'isCustomResource',
    ]),
    tabs () {
      return [
        {
          id: 'details',
          name: this.$t('Details'),
          component: CyInventoryResourceDetails,
        },
        {
          id: 'json',
          name: this.$t('untranslated.json'),
          component: CyInventoryResourceJson,
        },
      ]
    },
    activeTab: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    isDataLoaded () {
      return !this.loading && !_.isEmpty(this.resource)
    },
    docPageLink () {
      const { provider, typeWithoutProvider } = this
      return `${$docLinks.terraform.providers}/${provider}/r/${typeWithoutProvider}`
    },
    typeWithoutProvider () {
      const prefix = this.provider
      const type = this.resource.type
      return type.startsWith(prefix)
        ? type.slice(prefix.length + 1)
        : type
    },
    provider () {
      return this.getProvider(this.resource)
    },
    providerTranslation () {
      return this.getProviderTranslation(this.resource, 'name')
    },
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    getMenuItems () {
      const resourceId = this.resource.id
      const isPermitted = ({ permissionKey }) => !permissionKey ||
        this.$cycloid.permissions.canDisplay(permissionKey, resourceId)
      return [
        {
          icon: 'edit',
          label: this.$t('forms.btnEdit'),
          action: () => {
            this.$router.push({
              name: 'resourcePoolResource',
              params: { resourceId },
            })
          },
          permissionKey: 'UpdateInventoryResource',
        },
        {
          icon: 'delete',
          label: this.$t('forms.btnDelete'),
          action: () => { this.$toggle.isDeleteDialogVisible(true) },
          permissionKey: 'DeleteInventoryResource',
        },
      ].filter(isPermitted)
    },
    onDeleteError () {
      this.SHOW_ALERT({ type: 'error', content: this.deleteErrors })
    },
    onDeleteSuccess () {
      this.$toggle.isDeleteDialogVisible(false)
      this.$emit('deleted')
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.inventoryResource',
        terraform: '@:untranslated.terraform',
        infraTool: {
          terraform: '@.capitalize:terraform',
          undefined: 'Unknown infrastructure tool',
        },
        resourceIcon: 'Resource icon',
      },
      es: {
        title: '@:routes.inventoryResource',
        terraform: '@:untranslated.terraform',
        infraTool: {
          terraform: '@.capitalize:terraform',
          undefined: 'Herramienta de infraestructura desconocida',
        },
        resourceIcon: 'Icono de recurso',
      },
      fr: {
        title: '@:routes.inventoryResource',
        terraform: '@:untranslated.terraform',
        infraTool: {
          terraform: '@.capitalize:terraform',
          undefined: `Outil d'infrastructure inconnu`,
        },
        resourceIcon: 'Icône de ressource',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.resource {
  display: flex;
  flex-direction: column;
}

.resource__metadata {
  display: flex;
  align-items: center;
  color: cy-get-color("primary", "light-2");
  gap: $spacer-4;

  ::v-deep .v-skeleton-loader__text {
    margin-bottom: 0;
  }
}

.resource__header-actions {
  display: flex;
  align-items: center;
  align-self: start;
}

.resource__action--help {
  color: cy-get-color("grey", "dark-1") !important;
}

.resource__action--close {
  margin-left: 0;
}

.resource__content {
  flex-grow: 1;
  height: 100%;

  ::v-deep .v-window {
    &,
    &__container,
    &-item {
      height: 100%;
    }
  }
}

.tab__viewport {
  @extend %cy-scrollbars-always-show;

  position: absolute;
  inset: 0;
  padding: $spacer-4;
}
</style>

<template>
  <div class="d-flex filters">
    <div class="filter-by mr-3">
      <v-icon
        color="primary"
        size="20"
        class="mr-1">
        mdi-filter
      </v-icon>
      {{ $t('filterBy') }}
    </div>
    <component
      v-for="(filter, idx) in filters"
      :key="`${filter.type}-${idx}`"
      :height="height"
      :label="filter.label"
      :options="filter"
      class="filter-menu"
      :is="getFilterComponent(filter.type)">
      <template
        v-if="$scopedSlots['item-content']"
        #item-content="{ item, items }">
        <slot
          name="item-content"
          :item="item"
          :items="items"
          :type="filter.type"/>
      </template>
    </component>
  </div>
</template>

<script>
import CyDataTableDateFilter from '@/components/CyDataTableDateFilter.vue'
import CyDataTableDateTimeRangeFilter from '@/components/CyDataTableDateTimeRangeFilter.vue'
import CyDataTableOwnersFilter from '@/components/CyDataTableOwnersFilter.vue'
import CyDataTableRadioFilter from '@/components/CyDataTableRadioFilter.vue'
import CyDataTableSelectFilter from '@/components/CyDataTableSelectFilter.vue'
import CyDataTableTagsFilter from '@/components/CyDataTableTagsFilter.vue'

export const filterComponents = {
  owner: 'CyDataTableOwnersFilter',
  tags: 'CyDataTableTagsFilter',
  date: 'CyDataTableDateFilter',
  dateTimeRange: 'CyDataTableDateTimeRangeFilter',
  select: 'CyDataTableSelectFilter',
  radio: 'CyDataTableRadioFilter',
}

/**
 * Filters component that wraps the specific filters used in CyDataTable and pages that use the v-data-iterator
 * Newly created filters need to be added to the `filterComponents` object above
 *
 * @param {Array}    filters          An array (required) of objects containing the options for each filter component. I.e:
 *                                    [
                                        {
                                          queryParam: 'user_id',
                                          type: 'owner',
                                          label: this.$t('owner')
                                        }
                                      ]
 *                                    `type` (required): determines which filter component to show.
 *                                    `label` (required): is a display name for the filter shown in the table header
 *                                    `queryParam` (optional): the LHS filters param to manipulate in the query
 *                                    there can be other props like `items` for the select filter on events page
 *
 * The general idea of the filters is that they will call the SET_DATA_TABLE_FILTERS mutation when changed
 * The CyDataTable or other component using the filters is supposed to watch on the getDataTableFilters($route.name)
 * And call FETCH_AVAILABLE action when the filters object is changed
 */

export default {
  name: 'CyDataTableFilters',
  components: {
    CyDataTableOwnersFilter,
    CyDataTableTagsFilter,
    CyDataTableDateFilter,
    CyDataTableDateTimeRangeFilter,
    CyDataTableSelectFilter,
    CyDataTableRadioFilter,
  },
  props: {
    filters: {
      type: Array,
      validator: (filters) => _.isEmpty(filters) || _.every(filters, (filter) => _.$hasAll(filter, ['type', 'label'])),
      required: true,
    },
    height: {
      type: [Number, String],
      default: '248',
    },
  },
  methods: {
    getFilterComponent (type) {
      return filterComponents[type] ||
        console.warn(`[filters]: type not supported. currently supported types are: ${Object.keys(filterComponents)}`)
    },
  },
  i18n: {
    messages: {
      en: {
        filterBy: 'Filter by',
      },
      es: {
        filterBy: 'Filtrar por',
      },
      fr: {
        filterBy: 'Filtrer par',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.filters {
  flex-wrap: wrap;
}

::v-deep .cy-btn__theme--primary.cy-btn__variant--tertiary {
  font-weight: $font-weight-default !important;
}

::v-deep .filter-menu + span > .cy-btn.cy-btn {
  padding-right: 6px;
}

.filter-by {
  display: flex;
  justify-content: center;
  min-width: 86px;
  margin: auto;
  color: cy-get-color("primary");
  font-weight: $font-weight-bolder;
}
</style>

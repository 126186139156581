<template>
  <CyButton
    v-if="_.some([$isStagingOrDev, $isBeta])"
    class="show-dev-things-btn"
    theme="grey"
    variant="tertiary"
    @click.native="TOGGLE_DEV_BTNS">
    <v-icon>{{ $showDevThings ? 'visibility_off' : 'visibility' }}</v-icon>
    🦄 {{ $showDevThings ? 'Hide' : 'Show' }} dev things
  </CyButton>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'CyDevShowDevThingsButton',
  methods: {
    ...mapMutations('dev', [
      'TOGGLE_DEV_BTNS',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.show-dev-things-btn {
  z-index: 5;
  flex: 0 1 auto;
  justify-content: flex-start;
  height: 40px;
  padding: 6px;
  border: none;
  border-radius: 8px;
  background: none;
  box-shadow: none;
  color: var(--menu-text-color, #fff) !important;

  .dev-layer-activator__btn-icon.v-icon.v-icon.v-icon {
    width: 1em;
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  &.cy-btn.v-btn.v-btn.v-btn:hover {
    @extend %rainbow-background;
  }
}
</style>

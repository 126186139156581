<template>
  <div
    :class="['project-container', {
      'project-container--no-tabs': loading || hideTabs,
      'project-container--fit-on-screen': fitOnScreen,
    }]">
    <v-progress-circular
      v-if="loading"
      class="loading-spinner"
      indeterminate
      size="100"
      color="secondary"/>

    <template v-else-if="hideTabs">
      <CyWorkersWarningMessage
        v-if="!hasRunningWorkers && !$static.hideWorkersWarningRoutes.includes($route.name)"
        class="project-container__no-workers-warning"/>
      <router-view/>
    </template>

    <template v-else>
      <div class="cy-tabs-nav px-5 mx-n8">
        <v-tabs
          :grow="false"
          background-color="transparent"
          show-arrows
          class="project-container__tabs"
          mandatory>
          <v-tabs-slider/>
          <v-tab
            v-for="{ name, params } of tabsItems"
            :key="name"
            :ripple="false"
            :to="$route.name === name ? $route : { name, params }">
            {{ $t(`routes.${name}`) }}
          </v-tab>
        </v-tabs>
      </div>

      <CyWorkersWarningMessage
        v-if="!hasRunningWorkers && !$static.hideWorkersWarningRoutes.includes($route.name)"
        class="project-container__no-workers-warning mt-6 mb-0"/>

      <div class="project-subpage-container mt-6 new-header">
        <router-view/>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import CyWorkersWarningMessage from '@/components/CyWorkersWarningMessage.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProject',
  components: {
    CyWorkersWarningMessage,
  },
  beforeRouteUpdate (to, from, next) {
    this.chooseRedirection({ to, from, next })
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
    pipelineCanonical: {
      type: String,
      default: '',
    },
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.projectName, [
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters('organization', [
      'hasRunningWorkers',
    ]),
    ...mapGetters('organization/project', [
      'hasEnvs',
    ]),
    $static: () => ({
      hideWorkersWarningRoutes: [
        'addServiceEnv',
        'environmentClone',
      ],
      baseRoutes: ['project', 'environment'],
      projectTabs: ['projectEnvironments', 'projectMetrics', 'projectActivity'],
      environmentTabs: ['pipeline', 'logs', 'infraView', 'environmentMetrics', 'environmentActivity', 'environmentConfig'],
    }),
    hideTabs () {
      return this.$route?.meta?.noTabs ?? this.$route?.params?.hideTabs ?? false
    },
    fitOnScreen () {
      return this.$route?.meta?.layout?.fitOnScreen ?? false
    },
    tabsItems () {
      const { orgCanonical, projectCanonical, envCanonical, pipelineCanonical } = this

      const { canDisplayRoute } = this.$cycloid.permissions
      const tabs = envCanonical ? this.$static.environmentTabs : this.$static.projectTabs

      return _.transform(tabs, (acc, name) => {
        const params = _.pickBy({ orgCanonical, projectCanonical, envCanonical, pipelineCanonical })
        if (/pipeline/i.test(name)) params.pipelineCanonical ??= `${projectCanonical}-${envCanonical}`
        if (canDisplayRoute({ name })) acc.push({ name, params })
      }, [])
    },
  },
  async created () {
    const { projectCanonical, envCanonical } = this

    await this.$evaluateUserActions(null, { routeName: 'project' })
    this.FETCH_AVAILABLE({ keyPath: 'workers' })
    if (projectCanonical) await this.GET_PROJECT({ projectCanonical, envCanonical })
    this.$toggle.loading(false)

    this.chooseRedirection()
  },
  beforeDestroy () {
    this.RESET_PROJ_STATE()
    this.RESET_CR_STATE()
    this.RESET_STACK_STATE()
    this.RESET_KPI_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT',
    ]),
    ...mapMutations('organization/project', [
      'RESET_PROJ_STATE',
    ]),
    ...mapMutations('organization/project/kpi', [
      'RESET_KPI_STATE',
    ]),
    ...mapMutations('organization/project/stack', [
      'RESET_STACK_STATE',
    ]),
    ...mapMutations('organization/project/configRepository', [
      'RESET_CR_STATE',
    ]),
    chooseRedirection ({ to, next } = {}) {
      const {
        orgCanonical, projectCanonical, envCanonical, pipelineCanonical,
        hasEnvs, tabsItems, $static, $route,
      } = this

      to ??= $route
      const firstAllowedTab = tabsItems?.[0]?.name
      const isBaseRoute = $static.baseRoutes.includes(to.name)
      const isRouteWhitelisted = projectCanonical && !_.flatten([
        $static.projectTabs,
        $static.environmentTabs,
      ]).includes(to.name)

      const redirectableRoutes = _.without([
        ...(hasEnvs ? _.map(tabsItems, 'name') : []),
        ...(projectCanonical ? ['projectEmpty'] : []),
        'projects',
        'projectEnvironments',
      ], to.name)

      const redirectTo = _.transform(redirectableRoutes, (acc, name) => {
        const paramKeys = _.keys(this.$router.resolve({ name })?.route?.params ?? {})
        const params = _.pick({
          ...to.params,
          orgCanonical,
          projectCanonical,
          envCanonical,
          pipelineCanonical,
        }, paramKeys)
        acc[name] = () => {
          const hash = to?.hash
          const routeObj = { name, params: _.pickBy(params) }
          if (to.hash) routeObj.hash = hash
          next?.(routeObj) ?? this.$router.replace(routeObj).catch(_.noop)
        }
      }, {})

      if (!isBaseRoute && isRouteWhitelisted) return next?.()
      if (!projectCanonical) return redirectTo.projects()
      if (!hasEnvs) return redirectTo.projectEmpty()
      if (to.name === 'project') return redirectTo.projectEnvironments()
      if (isBaseRoute && firstAllowedTab !== to.name) return redirectTo[firstAllowedTab]()

      next?.()
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.project',
      },
      es: {
        title: '@:routes.project',
      },
      fr: {
        title: '@:routes.project',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .project-container {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    place-content: stretch flex-start;
    align-items: stretch;
    height: 100%;
    margin-top: 0 !important;

    .project-subpage-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: 100%;
      min-height: 0;
      padding: 1.5em;
      border: 1px solid cy-get-color("grey", "dark-2", 0.1);
      background-color: white;
      box-shadow: 0 0 3px cy-get-color("grey", "dark-2", 0.32);

      &.new-header {
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
    }

    &__tabs {
      flex-grow: 0;
    }

    &--no-tabs {
      .cy-app--full-screen & {
        max-width: 100vw;
      }
    }

    &--fit-on-screen {
      flex-grow: 1;
      align-content: stretch;
      height: 100%;
      min-height: 0;
      max-height: 100%;

      .project-container__no-workers-warning,
      .project-container__tabs {
        flex: 0 1 auto;
      }

      .project-subpage-container {
        flex: 1 1 auto;
      }
    }
  }

  .loading-spinner {
    position: absolute;
    top: 45vh;
    align-self: center;
  }

  ::v-deep {
    .v-expansion-panel {
      .new-header & {
        background-color: transparent;
      }
    }

    .v-item-group {
      .new-header & {
        background-color: transparent;
      }
    }
  }
</style>

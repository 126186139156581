import { render, staticRenderFns } from "./CyWorkersEmpty.vue?vue&type=template&id=32c06e2c&scoped=true"
import script from "./CyWorkersEmpty.vue?vue&type=script&lang=js"
export * from "./CyWorkersEmpty.vue?vue&type=script&lang=js"
import style0 from "./CyWorkersEmpty.vue?vue&type=style&index=0&id=32c06e2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c06e2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})

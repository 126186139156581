<template>
  <div>
    <CyWorkersAddTitle icon-type="gcp">
      {{ $t('untranslated.gcp.name') }}
    </CyWorkersAddTitle>

    <p>
      <i18n path="checkSetup">
        <a
          :href="$docLinks.workers.gcpSetup"
          class="cy-link"
          target="_blank">{{ $t('linkSetupText') }}</a>
      </i18n>
    </p>

    <p>{{ $t('p1') }}</p>

    <CyWorkersCodeEditor :code="code"/>

    <div class="mt-6">
      <i18n path="checkDoc">
        <a
          :href="$docLinks.workers.gcp"
          class="cy-link"
          target="_blank">
          {{ $t('linkText') }}
        </a>
      </i18n>
      <i18n path="workers.troubleshoot">
        <a
          :href="$docLinks.workers.troubleshooting"
          class="cy-link"
          target="_blank">
          {{ $t('workers.troubleshootLink') }}
        </a>
      </i18n>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyWorkersAddTitle from '@/components/CyWorkersAddTitle.vue'
import CyWorkersCodeEditor from '@/components/CyWorkersCodeEditor.vue'
import { generateGCPCode, generateGCPCodeOnPrem } from '@/utils/config/workers'

export default {
  name: 'CyWorkersAddGcpStepTwo',
  components: {
    CyWorkersCodeEditor,
    CyWorkersAddTitle,
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
    workerKey: {
      type: String,
      required: true,
    },
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
    schedulerAPIAddress: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('organization/licence', [
      'isOnPrem',
    ]),
    code () {
      const {
        teamId,
        workerKey,
        orgCanonical,
        schedulerHost,
        schedulerPort,
        schedulerPublicTSAKey,
        schedulerAPIAddress,
      } = this

      return this.isOnPrem
        ? generateGCPCodeOnPrem({
          teamId,
          workerKey,
          orgCanonical,
          schedulerHost,
          schedulerPort,
          schedulerPublicTSAKey,
          schedulerAPIAddress,
        })
        : generateGCPCode({ teamId, workerKey, orgCanonical })
    },
  },
  i18n: {
    messages: {
      en: {
        checkDoc: 'Check the {0} to get more information. ',
        checkSetup: 'Workers are deployed on GCP using Deployment Manager. Make sure you have it installed on your machine, see {0}.',
        linkSetupText: 'deployment manager setup',
        linkText: 'GCP workers documentation',
        p1: 'Run the command below to deploy your worker:',
      },
      es: {
        checkDoc: 'Verifique la {0} para obtener más información. ',
        checkSetup: 'Los workers se despliegan en GCP usando el administrador de despliegue. Asegúrese de tenerlo instalado en su máquina, consulte {0}.',
        linkSetupText: 'administrador de despliegue',
        linkText: 'documentación de los workers de GCP',
        p1: 'Ejecute el comando a continuación para desplegar su worker:',
      },
      fr: {
        checkDoc: `Vérifiez la {0} pour obtenir plus d'informations. `,
        checkSetup: `Les workers sont déployés sur GCP en utilisant le gestionnaire de déploiement. Assurez-vous de l'avoir installé sur votre machine, voir le {0}.`,
        linkSetupText: 'gestionnaire de déploiement',
        linkText: 'documentation des workers GCP',
        p1: 'Exécutez la commande ci-dessous pour déployer votre worker:',
      },
    },
  },
}
</script>

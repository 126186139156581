<template>
  <div
    class="appearance-page"
    :style="{
      '--appearance-detail-color': cssColor,
    }">
    <CyDetails
      :item-id="appearanceCanonical"
      :on-delete="$toggle.showDeleteModal"
      :delete-btn-text="$t('deleteAppearance')"
      :can-cancel="canCancel"
      :can-save="canSave"
      :loading="false"
      :deleting="deleting"
      :saving="saving"
      :on-cancel="onCancel"
      :on-save="onSave"
      :is-read-only="isReadOnly"
      :hide-delete="$isCreationRoute">
      <template #details_formFullWidth>
        <section class="section">
          <div class="section__header">
            <h2 class="h5">
              {{ $t('sections.sidebar.title') }}
            </h2>
            <p class="subtitle">
              {{ $t('sections.sidebar.subtitle') }}
            </p>
          </div>

          <div class="section__content">
            <div :class="['option-color', { 'option-color--disabled': !loading && isReadOnly }]">
              <CyMenu
                :loading="loading"
                :close-on-content-click="false"
                :disabled="isReadOnly"
                eager
                offset-y>
                <template #activator="{ on }">
                  <div
                    class="option-color__button cy-emulated-field required-field"
                    v-on="on">
                    <label class="option-color__label">{{ $t('sections.sidebar.color.label') }}</label>
                    <div
                      :class="['color-input', {
                        'color-input--loading': loading,
                        'color-input--disabled': isReadOnly,
                      }]">
                      <v-progress-linear
                        v-if="loading"
                        :height="3"
                        indeterminate/>
                      <template v-else>
                        <span class="color-blob-preview"/>
                        <span class="color-text">{{ colorAsHex }}</span>
                        <v-icon
                          v-if="!isReadOnly"
                          class="color-arrow-icon">
                          arrow_drop_down
                        </v-icon>
                      </template>
                    </div>
                  </div>
                </template>
                <v-color-picker
                  ref="colorPicker"
                  v-model="$v.formContent.color.$model"
                  mode="hexa"
                  required
                  class="required-field"
                  @input="setHexColor"/>
              </CyMenu>
            </div>

            <v-text-field
              v-model="$v.formContent.displayName.$model"
              :label="$t('sections.sidebar.displayName.label')"
              :hint="$t('sections.sidebar.displayName.hint')"
              :loading="loading"
              :error-messages="displayNameErrors"
              :disabled="isReadOnly"
              :readonly="isReadOnly"
              persistent-hint
              required
              class="option-display-name required-field mt-5"
              @blur="$v.formContent.displayName.$touch()"/>

            <div class="option-logo mt-5">
              <template v-if="!loading">
                <div
                  v-show="showDefaultLogo"
                  class="option-logo__preview option-logo__preview--empty">
                  <v-icon>photo_size_select_actual</v-icon>
                </div>
                <img
                  v-show="!showDefaultLogo"
                  class="option-logo__preview"
                  :src="formContent.logo"
                  alt="favicon"
                  @load="checkLogoSize"
                  @error="$set(isLogoValidFor, formContent.logo, false)">
              </template>
              <v-text-field
                v-model="$v.formContent.logo.$model"
                class="option-logo__input required-field"
                :label="$t('sections.sidebar.logo.label')"
                :hint="$t('sections.sidebar.logo.hint')"
                :loading="loading"
                :error-messages="logoErrors"
                :disabled="isReadOnly"
                :readonly="isReadOnly"
                persistent-hint
                required
                @input="$set(isLogoValidFor, formContent.logo, true)">
                <template
                  v-if="$showDevThings"
                  #append>
                  <CyMenu offset-y>
                    <v-list>
                      <v-list-item
                        v-for="{ text, action } of $static.devLogoOptions"
                        :key="text"
                        @click="action">
                        <v-list-item-title>{{ text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </CyMenu>
                </template>
              </v-text-field>
            </div>
          </div>
        </section>

        <section class="section">
          <div class="section__header">
            <h2 class="h5">
              {{ $t('sections.tabs.title') }}
            </h2>
            <p class="subtitle">
              {{ $t('sections.tabs.subtitle') }}
            </p>
          </div>

          <div class="section__content">
            <div class="option-favicon">
              <template v-if="!loading">
                <div
                  v-show="showDefaultFavicon"
                  class="option-favicon__preview option-favicon__preview--empty">
                  <v-icon>photo_size_select_actual</v-icon>
                </div>
                <img
                  v-show="!showDefaultFavicon"
                  class="option-favicon__preview"
                  :src="formContent.favicon"
                  alt="Favicon"
                  @load="checkFaviconSize"
                  @error="$set(isFaviconValidFor, formContent.favicon, false)">
              </template>
              <v-text-field
                v-model="$v.formContent.favicon.$model"
                class="option-favicon__input required-field"
                :label="$t('sections.tabs.favicon.label')"
                :hint="$t('sections.tabs.favicon.hint')"
                :loading="loading"
                :error-messages="faviconErrors"
                :disabled="isReadOnly"
                :readonly="isReadOnly"
                persistent-hint
                required
                @input="$set(isFaviconValidFor, formContent.favicon, true)">
                <template
                  v-if="$showDevThings"
                  #append>
                  <CyMenu offset-y>
                    <v-list>
                      <v-list-item
                        v-for="{ text, action } of $static.devFaviconOptions"
                        :key="text"
                        @click="action">
                        <v-list-item-title>{{ text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </CyMenu>
                </template>
              </v-text-field>
            </div>

            <v-text-field
              v-model="$v.formContent.tabTitle.$model"
              class="option-page-title mt-5"
              :label="$t('sections.tabs.pageTitle.label')"
              :hint="$t('sections.tabs.pageTitle.hint')"
              :loading="loading"
              :error-messages="tabTitleErrors"
              :disabled="isReadOnly"
              :readonly="isReadOnly"
              :prefix="`${$t('sections.tabs.pageTitle.pageName')} | `"
              persistent-hint
              required/>
          </div>
        </section>

        <section class="section">
          <div class="section__header">
            <h2 class="h5">
              {{ $t('sections.footer.title') }}
            </h2>
            <p
              v-show="showFooterForm"
              class="subtitle">
              {{ $t('sections.footer.subtitle') }}
            </p>
          </div>

          <div class="section__content">
            <v-card
              outlined
              class="cy-card">
              <div class="d-flex align-center justify-space-between px-6 py-4">
                <div>
                  <h3 class="content__title">
                    {{ $t('sections.footer.title') }}
                  </h3>

                  {{ $t('sections.footer.text') }}
                </div>

                <v-switch
                  class="mt-0"
                  color="secondary"
                  hide-details
                  :input-value="showFooterForm"
                  @change="$toggle.showFooterForm(!showFooterForm)"/>
              </div>
              <template v-if="showFooterForm">
                <v-divider/>
                <v-md-editor
                  ref="vMdEditor"
                  v-model="formContent.footer"
                  class="text-center"
                  left-toolbar="bold italic underlineCmd link"
                  right-toolbar="preview"
                  :toolbar="$static.customToolbar"
                  :mode="vMdEditorMode"
                  height="400"/>
                <p class="markdown-info">
                  {{ $t('editor.markdownInfo') }}
                </p>
              </template>
            </v-card>
          </div>
        </section>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteModal"
      :header-title="$t('deleteAppearance')"
      :action-btn-func="onDelete"
      :cancel-btn-func="() => $toggle.showDeleteModal(false)"
      :action-btn-text="$t('deleteAppearance')"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyDetails from '@/components/CyDetails.vue'
import { RGBColor } from '@/utils/classes'
import { constructBreadcrumb } from '@/utils/helpers'
import { faker } from '@faker-js/faker'
import VMdEditor from '@kangc/v-md-editor'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'
import esES from '@kangc/v-md-editor/lib/lang/es-ES'
import frFR from '@kangc/v-md-editor/lib/lang/fr-FR'
import { required, url } from 'vuelidate/lib/validators'

const CYCLOID_DEFAULT_COLOR_RGB = { r: 28, g: 151, b: 151 }
const MAX_LOGO_SIZE = 120
const MAX_FAVICON_SIZE = 32
/**
 * square: https://www.pngmart.com/files/15/Funny-Firefox-Logo-Transparent-PNG.png
 * landscape: https://www.docker.com/wp-content/uploads/2022/03/horizontal-logo-monochromatic-white.png
 * portrait: https://thumbs.dreamstime.com/z/portrait-man-glasses-beard-illustration-hairdresser-logo-stylist-black-white-logo-portrait-158423165.jpg
 */

export default {
  name: 'CyPageOrgAppearance',
  components: {
    CyDetails,
  },
  breadcrumb () {
    const { orgCanonical } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.orgSettingsAppearance'), [
      {
        label: this.$t('routes.orgSettings'),
        name: 'orgSettings',
      },
      {
        name: 'dashboard',
        params: { orgCanonical },
      },
    ])
  },
  header () {
    return {
      title: this.$t('title'),
      description: {
        text: this.$t('subtitle'),
      },
    }
  },
  props: {
    appearanceCanonical: {
      type: String,
      default: 'custom',
    },
  },
  validations: {
    formContent: {
      color: { required },
      logo: {
        required,
        url,
        imageFound (logo) {
          return _.isEmpty(logo) || this.isLogoValidFor[logo]
        },
        // TODO: Add or remove this code when deciding about errors for logo size
        // isValidSize (logo) {
        //   return _.isEmpty(logo) || this.isLogoValidSize
        // },
      },
      favicon: {
        required,
        url,
        imageFound (favicon) {
          return _.isEmpty(favicon) || this.isFaviconValidFor[favicon]
        },
        // TODO: Add or remove this code when deciding about errors for favicon size
        // isValidSize (favicon) {
        //   return _.isEmpty(favicon) || this.isFaviconValidSize
        // },
      },
      displayName: { required },
      tabTitle: { required },
    },
  },
  data: ({ appearanceCanonical: name } = {}) => ({
    formContent: {
      color: '',
      logo: '',
      displayName: '',
      name,
      favicon: '',
      tabTitle: '',
      footer: '',
    },
    colorAsHex: '',
    isLogoValidFor: {},
    isLogoValidSize: false,
    isFaviconValidFor: {},
    isFaviconValidSize: false,
    deleting: false,
    loading: true,
    saving: false,
    showDeleteModal: false,
    showFooterForm: false,
    vMdEditorMode: 'edit',
  }),
  computed: {
    ...mapState({
      appearance: (state) => state.organization.appearance.detail,
      appearances: (state) => state.organization.available.appearances,
      errors: (state) => state.organization.appearance.errors,
    }),
    $static () {
      const underlineTitle = this.$t('editor.underline')
      const underlineAction = (editor) => {
        editor.insert((selected) => {
          const content = selected || underlineTitle
          return {
            text: `<u>${content}</u>`,
            selected: content,
          }
        })
      }

      return {
        customHotkeys: {
          underline: {
            modifier: 'ctrl',
            key: 'u',
            action: (editor) => underlineAction(editor),
          },
        },
        customToolbar: {
          underlineCmd: {
            name: 'underline',
            title: underlineTitle,
            icon: 'v-md-icon-under-line',
            action: (editor) => underlineAction(editor),
          },
        },
        locales: {
          en: ['en-US', enUS],
          es: ['es-ES', esES],
          fr: ['fr-FR', frFR],
        },
        devLogoOptions: [
          {
            text: '🦄 Get Cycloid logo',
            action: () => this.devModeSetCycloidLogo(),
          },
          {
            text: '🦄 Get random square logo',
            action: () => this.devModeSetRandomLogo(),
          },
          {
            text: '🦄 Get random landscape logo',
            action: () => this.devModeSetRandomLogo({ height: MAX_LOGO_SIZE / 2 }),
          },
          {
            text: '🦄 Get random portrait logo',
            action: () => this.devModeSetRandomLogo({ width: MAX_LOGO_SIZE / 2 }),
          },
        ],
        devFaviconOptions: [
          {
            text: '🦄 Get Cycloid favicon',
            action: () => this.devModeSetCycloidFavicon(),
          },
        ],
      }
    },
    isReadOnly () {
      return this.$isCreationRoute
        ? !this.$cycloid.permissions.canDisplay('CreateAppearance')
        : !this.$cycloid.permissions.canDisplay('UpdateAppearance', this.appearanceCanonical)
    },
    canCancel () {
      return !this.$isCreationRoute && this.$hasDataChanged('formContent')
    },
    canSave () {
      return this.$isCreationRoute
        ? !this.$v.formContent.$invalid
        : this.canCancel && !this.$v.formContent.$invalid
    },
    cssColor () {
      return _.isPlainObject(this.formContent.color)
        ? new RGBColor(this.formContent.color).cssString
        : null
    },
    defaultAppearance () {
      return _.find(this.appearances, ['canonical', 'default'])
    },
    showDefaultLogo () {
      return !this.formContent.logo || !this.isLogoValidFor[this.formContent.logo]
    },
    showDefaultFavicon () {
      return !this.formContent.favicon || !this.isFaviconValidFor[this.formContent.favicon]
    },
    displayNameErrors () {
      const { $dirty, required } = this.$v.formContent.displayName
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    logoErrors () {
      const { $dirty, required, url, imageFound } = this.$v.formContent.logo
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!url) errors.push(this.$t('forms.fieldInvalidUrl'))
      if (!imageFound) errors.push(this.$t('forms.fieldImageNotFound'))
      // TODO: Add or remove this code when deciding about errors for logo size
      // if (!isValidSize) errors.push(this.$t('sections.sidebar.logo.errorImgTooBig'))
      return errors
    },
    faviconErrors () {
      const { $dirty, required, url, imageFound } = this.$v.formContent.favicon
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!url) errors.push(this.$t('forms.fieldInvalidUrl'))
      if (!imageFound) errors.push(this.$t('forms.fieldImageNotFound'))
      // TODO: Add or remove this code when deciding about errors for logo size
      // if (!isValidSize) errors.push(this.$t('sections.tabs.favicon.errorImgTooBig'))
      return errors
    },
    tabTitleErrors () {
      const { $dirty, required } = this.$v.formContent.tabTitle
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  watch: {
    '$i18n.locale' () {
      this.setVMdEditorLocale()
    },
    async showFooterForm (value) {
      if (value) {
        await _.$pause(500)
        this.$refs.vMdEditor.registerHotkeys(this.$static.customHotkeys.underline)
      }
    },
  },
  async mounted () {
    if (this.$cycloid.permissions.canDisplay('ListAppearances')) {
      await this.FETCH_AVAILABLE({ keyPath: 'appearances' })
    }
    this.$isCreationRoute ? this.setAppearance() : await this.getAppearance()
    if (!_.isEmpty(this.formContent.footer)) this.$toggle.showFooterForm(true)
    this.setVMdEditorLocale()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/appearance', [
      'CREATE_APPEARANCE',
      'DELETE_APPEARANCE',
      'GET_APPEARANCE',
      'UPDATE_APPEARANCE',
    ]),
    async getAppearance () {
      await this.GET_APPEARANCE(this.appearanceCanonical)
      this.setAppearance()
      this.$setOriginalData()
    },
    setAppearance () {
      const { color = CYCLOID_DEFAULT_COLOR_RGB } = this.defaultAppearance || {}
      const displayName = this.orgName
      this.formContent = this.$isCreationRoute
        ? _.merge(this.formContent, { color, displayName, tabTitle: displayName, logo: '', favicon: '' })
        : _.merge(this.formContent, _.$camelCaseKeys(this.appearance))

      this.$set(this.isLogoValidFor, this.formContent.logo, true)
      this.$set(this.isFaviconValidFor, this.formContent.favicon, true)
      this.setHexColor()
      this.$toggle.loading(false)
    },
    onCancel () {
      this.$resetData('formContent')
      this.setHexColor()
      this.$v.$reset()
    },
    async onSave () {
      const { $router } = this
      const appearance = _.$snakeCaseKeys({ ...this.formContent, canonical: 'custom' })

      this.$toggle.saving(true)
      this.$isCreationRoute
        ? await this.CREATE_APPEARANCE({ appearance, $router })
        : await this.UPDATE_APPEARANCE({ appearance, $router })
      this.$toggle.saving(false)

      if (_.isEmpty(this.errors)) this.$setOriginalData()
    },
    async onDelete () {
      this.$toggle.deleting(true)
      const { formContent: appearance, $router } = this
      await this.DELETE_APPEARANCE({ appearance, $router })
      this.$toggle.deleting(false)
    },
    async setHexColor () {
      await this.$nextTick()
      const { hex } = this.$refs.colorPicker?.internalValue || {}
      if (hex) this.$set(this, 'colorAsHex', hex)
    },
    checkLogoSize ({ target }) {
      const { naturalHeight: height, naturalWidth: width } = target
      const isValidSize = _.every([height, width], (size) => size <= MAX_LOGO_SIZE)
      this.isLogoValidSize = isValidSize
      // TODO: Add or remove this code when deciding about errors for logo size
      // this.$v.formContent.logo.$touch()
    },
    checkFaviconSize ({ target }) {
      const { naturalHeight: height, naturalWidth: width } = target
      const isValidSize = _.every([height, width], (size) => size <= MAX_FAVICON_SIZE)
      this.isFaviconValidSize = isValidSize
      // TODO: Add or remove this code when deciding about errors for logo size
      // this.$v.formContent.favicon.$touch()
    },
    devModeSetRandomLogo ({ height = MAX_LOGO_SIZE, width = MAX_LOGO_SIZE } = {}) {
      const randomImage = `${faker.image.imageUrl(width, height)}?${Date.now()}`
      this.$v.formContent.logo.$model = randomImage
      this.$set(this.isLogoValidFor, randomImage, true)
    },
    devModeSetCycloidLogo ({ height = MAX_LOGO_SIZE, width = MAX_LOGO_SIZE } = {}) {
      const cycloidImage = 'https://console.cycloid.io/static/images/app-logo-square.png'
      this.$v.formContent.logo.$model = cycloidImage
      this.$set(this.isLogoValidFor, cycloidImage, true)
    },
    devModeSetCycloidFavicon () {
      const cycloidImage = 'https://console.cycloid.io/static/favicons/prod/favicon.ico'
      this.$v.formContent.favicon.$model = cycloidImage
      this.$set(this.isFaviconValidFor, cycloidImage, true)
    },
    setVMdEditorLocale () {
      VMdEditor.lang.use(
        ...this.$static.locales[this.$i18n.locale],
      )
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.orgSettingsAppearance',
        subtitle: 'Use your own brand colors and logos to display throughout the application.',
        confirmDeleteSentence: 'Are you sure that you want to delete this @:appearance?',
        deleteAppearance: '@:forms.btnDelete @:appearance',
        editor: {
          markdownInfo: 'Markdown syntax is supported.',
          underline: 'Underline (CTRL+U)',
        },
        sections: {
          footer: {
            text: 'Display custom text for legal, informational, or promotional reasons.',
            title: 'Footer',
            subtitle: 'Users in child organizations inherit settings from the parent organization.',
          },
          sidebar: {
            title: 'Sidebar',
            subtitle: 'Customize the sidebar to make your users feel at home.',
            color: {
              label: 'Sidebar color',
            },
            logo: {
              label: 'Sidebar logo URL',
              altImageText: 'Sidebar logo',
              hint: `Recommended size ${MAX_LOGO_SIZE}x${MAX_LOGO_SIZE}. Accepted formats: .png, .jpg, .gif`,
              errorImgTooBig: `Image is larger than the recommended size ${MAX_LOGO_SIZE}x${MAX_LOGO_SIZE}. Please provide a smaller image.`,
            },
            displayName: {
              label: 'Display name',
              hint: 'The name that appears next to the logo on the expanded sidebar.',
            },
          },
          tabs: {
            title: 'Tabs',
            subtitle: 'Customize the favicon and the brand name in the page title.',
            favicon: {
              label: 'Favicon URL',
              hint: `Recommended size ${MAX_FAVICON_SIZE}x${MAX_FAVICON_SIZE}. Accepted formats: .ico, .png`,
            },
            pageTitle: {
              label: 'Page title',
              hint: 'The name displayed in the browser tab, next to the favicon.',
              pageName: 'Page name',
            },
          },
        },
      },
      es: {
        title: '@:routes.orgSettingsAppearance',
        subtitle: 'Utilice los colores y logotipos de su propia marca para mostrarlos en toda la aplicación.',
        sidebar: 'barra lateral',
        confirmDeleteSentence: '¿Está seguro de querer eliminar este @:appearance?',
        deleteAppearance: '@:forms.btnDelete @:appearance',
        editor: {
          markdownInfo: 'Se admite la sintaxis de Markdown.',
          underline: 'Subraya (CTRL+U)',
        },
        sections: {
          footer: {
            text: 'Mostrar texto personalizado por motivos legales, informativos o promocionales.',
            title: 'Footer',
            subtitle: 'Los usuarios de organizaciones secundarias heredan la configuración de la organización principal.',
          },
          sidebar: {
            title: 'Barra lateral',
            subtitle: 'Personaliza la @:sidebar para que tus usuarios se sientan como en casa.',
            color: {
              label: 'Color de la @:sidebar',
            },
            logo: {
              label: 'URL del logotipo de la @:sidebar',
              altImageText: 'Logotipo de la @:sidebar',
              hint: `Tamaño recomendado ${MAX_LOGO_SIZE}x${MAX_LOGO_SIZE}. Formatos aceptados: .png, .jpg, .gif`,
              errorImgTooBig: `La imagen es más grande que el tamaño recomendado ${MAX_LOGO_SIZE}x${MAX_LOGO_SIZE}. Proporcione una imagen más pequeña.`,
            },
            displayName: {
              label: 'Nombre para mostrar',
              hint: 'El nombre que aparece junto al logotipo en la @:sidebar ampliada.',
            },
          },
          tabs: {
            title: 'Pestañas',
            subtitle: 'Personaliza el favicon y el nombre de la marca en el título de la página.',
            favicon: {
              label: 'URL de icono favorito',
              hint: `Tamaño recomendado ${MAX_FAVICON_SIZE}x${MAX_FAVICON_SIZE}. Formatos aceptados: .ico, .png`,
            },
            pageTitle: {
              label: 'Título de la página',
              hint: 'Define el nombre de la marca mencionado en la pestaña del navegador, junto al favicon.',
              pageName: 'Nombre de la página',
            },
          },
        },
      },
      fr: {
        title: '@:routes.orgSettingsAppearance',
        subtitle: `Utilisez les couleurs et les logos de votre propre marque pour les afficher dans l'application.`,
        sidebar: 'barre latérale',
        confirmDeleteSentence: 'Êtes vous sûr de vouloir retirer cette @:appearance ?',
        deleteAppearance: '@:forms.btnDelete @:appearance',
        editor: {
          markdownInfo: `La syntaxe Markdown est prise en charge.`,
          underline: 'Souligne (CTRL+U)',
        },
        sections: {
          footer: {
            text: `Affichez du texte personnalisé pour des raisons légales, informatives ou promotionnelles.`,
            title: 'Footer',
            subtitle: `Les utilisateurs des organisations enfants héritent des paramètres de l'organisation parente.`,
          },
          sidebar: {
            title: 'Barre latérale',
            subtitle: 'Personnalisez la @:sidebar pour que vos utilisateurs se sentent chez eux.',
            color: {
              label: 'Couleur de la @:sidebar',
            },
            logo: {
              label: 'URL du logo de la @:sidebar',
              altImageText: 'logo de la @:sidebar',
              hint: `Taille recommandée ${MAX_LOGO_SIZE}x${MAX_LOGO_SIZE}. Formats acceptés : .png, .jpg, .gif`,
              errorImgTooBig: `L'image est plus grande que la taille recommandée ${MAX_LOGO_SIZE}x${MAX_LOGO_SIZE}. Veuillez fournir une image plus petite.`,
            },
            displayName: {
              label: `Nom d'affichage`,
              hint: 'Le nom qui apparaît à côté du logo sur la @:sidebar développée.',
            },
          },
          tabs: {
            title: 'Onglets',
            subtitle: 'Personnalisez la favicon et le nom de la marque dans le titre de la page.',
            favicon: {
              label: 'URL de la favicon',
              hint: `Taille recommandée ${MAX_FAVICON_SIZE}x${MAX_FAVICON_SIZE}. Formats acceptés : .ico, .png`,
            },
            pageTitle: {
              label: 'Titre de la page',
              hint: `Définissez le nom de la marque mentionné dans l'onglet du navigateur, à côté de la favicon.`,
              pageName: 'Nom de la page',
            },
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .cy-details {
  &__content {
    padding: 0;
  }

  &__section--top {
    border-bottom: 1px solid cy-get-color("grey", "light-1");
  }

  &__actions {
    border-top: 1px solid cy-get-color("grey", "light-1");
  }
}

.appearance-page {
  * {
    color: cy-get-color("primary");
  }

  &__title {
    margin-bottom: 8px;
  }

  &__description {
    margin: 0;
  }
}

.section {
  display: grid;
  grid-template-areas: "header content";
  grid-template-columns: 336px 1fr;
  gap: 32px;
  padding: 24px 0;

  &:not(:first-child) {
    padding-top: 24px;
    border-top: 1px solid cy-get-color("grey", "light-1");
  }

  &__header {
    grid-area: header;

    .subtitle {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }

  &__content {
    grid-area: content;

    .content__title {
      margin-bottom: 8px;
      color: cy-get-color("grey", "dark-2");
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      letter-spacing: 0.05em;
      line-height: 14.06px;
      text-transform: uppercase;
    }
  }
}

.option-color {
  position: relative;

  &__label {
    position: absolute;
    top: -18px;
    left: 0;
    height: 20px;
    color: rgba(0 0 0 / 0.6);
    font-size: 12px;
    line-height: 20px;
  }

  &__button {
    width: 160px;
    min-height: 24px;
    cursor: pointer;

    .color-input {
      display: flex;
      align-items: center;
      border-bottom: 1px solid cy-get-color("grey", "dark-1");

      &--loading {
        align-items: flex-end;
        min-height: 24px;
        border: none;
      }

      &--disabled {
        border-bottom-style: dashed;

        .color-text {
          color: cy-get-color("grey", "dark-1");
        }
      }
    }

    .color-blob-preview {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border: 1px solid currentColor;
      border-radius: 4px;
      background-color: var(--appearance-detail-color);
    }

    .color-arrow-icon {
      margin-left: auto;
      color: cy-get-color("grey", "dark-2");

      &.open {
        transform: rotate(-90deg);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    .option-color__label {
      color: cy-get-color("grey", "dark-1");
    }

    .color-input {
      cursor: not-allowed;
    }
  }
}

.option-logo,
.option-favicon {
  display: flex;

  &__preview {
    object-fit: contain;
    align-self: center;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    margin-right: 12px;
    border: 8px solid cy-get-color("grey", "light-3");
    border-radius: 4px;
    background-color: cy-get-color("grey", "light-3");

    .option-favicon & {
      padding: 14px;
    }

    &--empty {
      display: flex;
      justify-content: center;
      border: 1px dashed cy-get-color("primary", "light-4");
      background: none;

      ::v-deep {
        .v-icon {
          color: cy-get-color("primary", "light-4");
        }
      }
    }
  }
}

.v-md-editor {
  box-shadow: none;

  &--editable ::v-deep {
    .v-md-editor {
      &__editor-wrapper {
        display: none;
        min-height: 71px;
      }

      &__preview-wrapper {
        min-height: 71px;
      }

      &__preview-wrapper,
      &__editor-wrapper {
        p {
          font-size: $font-size-default;
        }
      }
    }
  }

  &__menu,
  &__tooltip {
    margin-left: -40px;
  }

  ::v-deep {
    .v-md-editor__toolbar-right {
      margin-right: 25px;
    }

    .v-md-textarea-editor pre,
    .v-md-textarea-editor textarea,
    .github-markdown-body {
      padding-bottom: 20px;
    }
  }
}

.markdown-info {
  width: calc(100% - 48px);
  margin: 0 24px 24px;
  border-top: solid 1px cy-get-color("grey", "dark-2");
  color: cy-get-color("grey", "dark-2");
  font-size: $font-size-sm;
  font-weight: $font-weight-default;
}
</style>

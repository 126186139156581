<template>
  <div>
    <CyDetails
      :save-btn-text="$isCreationRoute ? $t('createWatchRule') : $t('forms.btnSave')"
      :can-cancel="$isCreationRoute || $hasDataChanged('formContent') || $hasDataChanged('selectedFilters')"
      :on-cancel="onCancel"
      :can-save="canSave"
      :on-save="onSave"
      :saving="saving"
      :on-delete="$toggle.showDeleteDialog"
      :deleting="deleting"
      :loading="loading">
      <template #details_formFullWidth>
        <v-container
          v-if="!selectedWatchRuleType"
          class="px-0 pt-6"
          fluid>
          <v-row
            align="start"
            justify="start"
            no-gutters>
            <v-col cols="3">
              <h3 v-text="$t('sections.type.title')"/>
            </v-col>
            <v-col cols="6">
              <ul class="watch-rule-types">
                <li
                  v-for="watchRuleType in $static.watchRuleTypes"
                  :key="watchRuleType.key"
                  :aria-label="watchRuleType.label"
                  class="watch-rule-types__item mb-2"
                  @click="selectedWatchRuleType = watchRuleType">
                  <CyAvatar
                    :item="{ icon: watchRuleType.icon, color: watchRuleType.color }"
                    class="mr-4"/>
                  <div>
                    <div
                      class="h6"
                      v-text="watchRuleType.name"/>
                    <div v-text="watchRuleType.description"/>
                  </div>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          v-else
          class="px-0 pt-6"
          fluid>
          <v-row
            align="start"
            justify="start"
            no-gutters>
            <v-col cols="3">
              <h3 v-text="$t('sections.general.title')"/>
            </v-col>
            <v-col cols="6">
              <label
                class="watch-rule-type__label"
                v-text="$t('watchRules.watchRuleType')"/>
              <ul class="watch-rule-types--selected">
                <li
                  :aria-label="selectedWatchRuleType.label"
                  class="watch-rule-types__item my-1">
                  <CyAvatar
                    :item="{ icon: selectedWatchRuleType.icon, color: selectedWatchRuleType.color }"
                    class="mr-4"/>
                  <div>
                    <div
                      class="h6"
                      v-text="selectedWatchRuleType.name"/>
                    <div v-text="selectedWatchRuleType.description"/>
                  </div>
                </li>
              </ul>
              <button
                v-if="$isCreationRoute"
                class="change-type__btn"
                @click="changeType"
                v-text="$t('changeType')"/>
              <v-text-field
                v-model="$v.formContent.name.$model"
                :label="$t('fields.ruleName.label')"
                :hint="$t('fields.ruleName.hint')"
                :error-messages="nameErrors"
                persistent-hint
                class="mb-6 mt-4 required-field"
                @blur="$v.formContent.name.$touch()"/>
              <v-select
                v-model="$v.formContent.organization.$model"
                :items="organizations"
                item-text="name"
                item-value="canonical"
                :error-messages="organizationErrors"
                :label="$t('fields.organization.label')"
                :hint="$t('fields.organization.hint')"
                :readonly="!$isCreationRoute"
                persistent-hint
                class="mb-6 required-field"
                @blur="$v.formContent.organization.$touch()">
                <template #selection="{ item }">
                  <CyAvatar
                    class="mr-2 my-2"
                    :item="item"
                    sm/>
                  {{ item.name }}
                </template>
                <template #item="{ item, attrs, on }">
                  <v-list-item
                    role="option"
                    class="px-1"
                    v-bind="attrs"
                    v-on="on">
                    <v-list-item-avatar class="mr-2">
                      <CyAvatar
                        :item="item"
                        sm/>
                    </v-list-item-avatar>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                v-if="isProjectActivityType"
                v-model="$v.formContent.project.$model"
                :items="projects"
                item-text="name"
                item-value="canonical"
                :error-messages="projectErrors"
                :label="$t('fields.project.label')"
                :hint="$t('fields.project.hint')"
                :readonly="!$isCreationRoute"
                persistent-hint
                class="mb-6 required-field"
                @blur="$v.formContent.project.$touch()">
                <template #selection="{ item }">
                  <v-icon class="mr-2 my-2">
                    folder_open
                  </v-icon>
                  {{ item.name }}
                </template>
                <template #item="{ item, attrs, on }">
                  <v-list-item
                    role="option"
                    class="px-3"
                    v-bind="attrs"
                    v-on="on">
                    <v-list-item-icon class="mr-3">
                      <v-icon>folder_open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-switch
                v-model="$v.formContent.muted.$model"
                color="secondary"
                :label="$t('fields.mute.label')"
                :hint="$t('fields.mute.hint')"
                persistent-hint/>
            </v-col>
          </v-row>
          <v-divider class="my-8"/>
          <v-row
            align="start"
            justify="start"
            no-gutters>
            <v-col cols="3">
              <h3 v-text="$t('sections.configuration.title')"/>
            </v-col>
            <v-col cols="6">
              <div>
                <CyTag>
                  {{ $t('when') }}
                </CyTag>
                {{ $t('eventIsFired') }}
              </div>
              <div class="d-flex my-4">
                <CyFilterTagsList
                  v-model="selectedFilters"
                  :filter-icons="$static.filterIcons"/>
                <CyFilterTree
                  v-model="selectedFilters"
                  :filter-tree="filterTree"/>
              </div>
              <div>
                <CyTag>
                  {{ $t('then') }}
                </CyTag>
                {{ $t('sendNotification') }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-8"/>
          <v-row
            align="start"
            justify="start"
            no-gutters>
            <v-col cols="3">
              <h3 v-text="$t('sections.preview.title')"/>
              <p v-text="$t('sections.preview.description')"/>
            </v-col>
            <v-col cols="6">
              <div
                v-if="previewUnavailableText"
                class="rule-preview--empty">
                <h3 v-text="$t('previewUnavailable.title')"/>
                <p v-text="$t(`previewUnavailable.${previewUnavailableText}`)"/>
              </div>
              <div
                v-else
                class="rule-preview">
                <div
                  class="rule-preview__chart"
                  role="figure"
                  aria-label="Preview chart">
                  <h4 v-text="$t('matchingEvents')"/>
                  <ECharts
                    class="preview-chart"
                    theme="cycloid"
                    autoresize
                    :option="previewChartsConfig"/>
                </div>
                <div class="rule-preview__timeline">
                  <CyEventsTimeline
                    v-if="matchingEvents.length"
                    v-bind="{ events: matchingEvents }"
                    :options.sync="options"/>
                  <div
                    v-else
                    class="rule-preview__timeline--empty">
                    <h4 v-text="$t('noMatchingEvents.title')"/>
                    <p v-text="$t('noMatchingEvents.description')"/>
                  </div>
                </div>
                <div
                  v-if="matchingEvents.length"
                  class="rule-preview__pagination">
                  <CyDataTablePagination
                    class="v-data-footer py-1"
                    :items-per-page-options="[10, 25, 50]"
                    :items-length="matchingEvents.length"
                    :options.sync="options"/>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmDeleteHeader')"
      :loading="deleting"
      :action-btn-func="onDeleteConfirm"
      :action-btn-disabled="deleting"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
      <h3>{{ watchRule.name }}</h3>
      <p class="url">
        {{ watchRule.canonical }}
      </p>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyDataTablePagination from '@/components/CyDataTablePagination.vue'
import CyDetails from '@/components/CyDetails.vue'
import CyEventsTimeline from '@/components/CyEventsTimeline.vue'
import CyFilterTagsList from '@/components/CyFilterTagsList.vue'
import CyFilterTree from '@/components/CyFilterTree.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { WATCH_RULE_FILTER_ICONS, WATCH_RULE_TYPES } from '@/utils/helpers/watchRules'
import { required, requiredIf } from 'vuelidate/lib/validators'

const EMPTY_FORM_CONTENT = {
  name: '',
  organization: '',
  project: '',
  muted: false,
}

export default {
  name: 'CyPageUserWatchRule',
  components: {
    CyDataTablePagination,
    CyDetails,
    CyEventsTimeline,
    CyFilterTagsList,
    CyFilterTree,
  },
  breadcrumb () {
    const header = this.$isCreationRoute ? this.$t('createWatchRule') : this.watchRule?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  header () {
    const title = this.$isCreationRoute ? this.$t('createWatchRule') : this.watchRule?.name

    return {
      title,
      ...(
        this.$isCreationRoute
          ? {
              description: {
                text: this.$t('description'),
              },
            }
          : {}
      ),
    }
  },
  validations () {
    const { isProjectActivityType } = this
    return {
      formContent: {
        name: { required },
        organization: { required },
        project: {
          required: requiredIf(() => isProjectActivityType),
        },
        muted: {},
      },
    }
  },
  data: () => ({
    selectedWatchRuleType: null,
    formContent: _.cloneDeep(EMPTY_FORM_CONTENT),
    selectedFilters: [],
    saving: false,
    loading: true,
    deleting: false,
    showDeleteDialog: false,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
  }),
  computed: {
    ...mapState(['organizations']),
    ...mapState('organization', {
      projects: (state) => state.available.projects,
      tags: (state) => state.available.tags,
      matchingEvents: (state) => state.available.events,
    }),
    ...mapState('notifications', {
      watchRule: (state) => state.watchRule ?? {},
    }),
    $static () {
      return {
        watchRuleTypes: WATCH_RULE_TYPES,
        filterIcons: WATCH_RULE_FILTER_ICONS,
      }
    },
    nameErrors () {
      const { $dirty, required } = this.$v.formContent.name
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    organizationErrors () {
      const { $dirty, required } = this.$v.formContent.organization
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    projectErrors () {
      if (this.selectedWatchRuleType.key !== 'projectActivity') return []
      const { $dirty, required } = this.$v.formContent.project
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canSave () {
      if (this.$isCreationRoute) return !this.$v.$invalid
      return !this.$v.$invalid && (this.$hasDataChanged('formContent') || this.$hasDataChanged('selectedFilters'))
    },
    filterTree () {
      return {
        Action: this.tags.action || [],
        Entity: this.tags.entity || [],
      }
    },
    previewUnavailableText () {
      if (!this.formContent.organization) return 'selectOrganization'
      if (this.isProjectActivityType && !this.formContent.project) return 'selectProject'
      if (_.isEmpty(this.selectedFilters)) return 'selectFilters'
      return null
    },
    isProjectActivityType () {
      return this.selectedWatchRuleType?.key === 'projectActivity'
    },
    previewChartsConfig () {
      const dates = Array.from({ length: 7 }, (_, i) => $date.format($date.subDays(Date.now(), 6 - i), 'MMM dd'))
      const eventCounts = dates.map((date) => this.matchingEvents.filter((event) => $date.format(event.timestamp, 'MMM dd') === date).length)
      const eventsPerDayAverage = Number(_.mean(eventCounts).toFixed(2))

      const totalEventsText = this.$tc('eventsTotal', this.matchingEvents.length, { count: this.matchingEvents.length })
      const eventsPerDayAverageText = this.$tc('eventsPerDay', eventsPerDayAverage === 1 ? 1 : 2, { count: eventsPerDayAverage })

      return {
        xAxis: {
          type: 'category',
          data: dates,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#EDF1F7',
              type: 'solid',
            },
          },
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
        },
        series: [
          {
            name: totalEventsText,
            data: eventCounts,
            type: 'bar',
            itemStyle: {
              color: '#F6B967',
            },
            barWidth: '80%',
            markLine: {
              data: [
                {
                  type: 'average',
                  name: eventsPerDayAverageText,
                },
              ],
              lineStyle: {
                type: 'dashed',
                color: '#52698b',
              },
              symbol: 'none',
            },
          },
          {
            name: eventsPerDayAverageText,
            data: Array.from({ length: 7 }, () => eventsPerDayAverage),
            type: 'line',
            showSymbol: false,
            lineStyle: {
              type: 'dashed',
              color: '#52698b',
              opacity: 0,
            },
          },
        ],
        grid: {
          show: true,
          borderWidth: '1px',
          left: 19,
          right: 1,
          top: 8,
          bottom: 62,
        },
        legend: {
          selectedMode: false,
          data: [
            {
              name: totalEventsText,
              icon: 'circle',
            },
            {
              name: eventsPerDayAverageText,
            },
          ],
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 6],
          },
          left: 0,
          bottom: 0,
        },
      }
    },
  },
  watch: {
    selectedFilters () {
      if (this.previewUnavailableText) return
      this.fetchPreview()
    },
    'formContent.project' () {
      if (this.previewUnavailableText) return
      this.fetchPreview()
    },
    'formContent.organization' () {
      if (this.previewUnavailableText) return
      this.fetchPreview()
    },
  },
  async created () {
    await Promise.all([
      ...(this.$isCreationRoute ? [] : [this.GET_WATCH_RULE({ canonical: this.$route.params.watchRuleCanonical })]),
      this.FETCH_AVAILABLE({ keyPath: 'projects' }),
      this.FETCH_AVAILABLE({ keyPath: 'tags' }),
    ])
    if (!this.$isCreationRoute) this.populateForm()
    this.loading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('notifications', [
      'CREATE_WATCH_RULE',
      'DELETE_WATCH_RULE',
      'UPDATE_WATCH_RULE',
      'GET_WATCH_RULE',
    ]),
    getFiltersArray () {
      if (_.isEmpty(this.selectedFilters)) return [{ operation: '*', entity: '*' }]

      const actionFilters = _.filter(this.selectedFilters, (path) => path.startsWith('Action')).map((path) => path.split('.').pop())
      const entityFilters = _.filter(this.selectedFilters, (path) => path.startsWith('Entity')).map((path) => path.split('.').pop())

      if (_.isEmpty(actionFilters)) {
        return entityFilters.map((entity) => ({ entity, operation: '*' }))
      }

      if (_.isEmpty(entityFilters)) {
        return actionFilters.map((action) => ({ entity: '*', operation: action }))
      }

      return actionFilters.map((action) => entityFilters.map((entity) => ({ entity, operation: action }))).flat()
    },
    async createWatchRule () {
      this.saving = true
      const { name, muted } = this.formContent
      const watchRule = {
        name,
        canonical: this.$getSlug(name),
        organization_canonical: this.formContent.organization,
        ...(this.isProjectActivityType
          ? {
              project_canonical: this.formContent.project,
            }
          : {}),
        muted,
        filters: this.getFiltersArray(),
      }
      await this.CREATE_WATCH_RULE({ watchRule, $router: this.$router })
      this.saving = false
    },
    async updateWatchRule () {
      this.saving = true
      const { name, muted } = this.formContent
      const watchRule = {
        name,
        muted,
        filters: _.cloneDeep(this.watchRule.filters),
      }
      await this.UPDATE_WATCH_RULE({ watchRuleCanonical: this.watchRule.canonical, watchRule })
      this.$setOriginalData('formContent')
      this.$setOriginalData('selectedFilters')
      this.saving = false
    },
    async onDeleteConfirm () {
      this.deleting = true
      await this.DELETE_WATCH_RULE({ watchRuleCanonical: this.watchRule.canonical })
      this.deleting = false
      this.$toggle.showDeleteDialog(false)
      this.$router.push({ name: 'userWatchRules' })
    },
    setFormContentFromStore () {
      this.formContent = {
        name: this.watchRule.name,
        organization: this.watchRule.organization_canonical,
        project: this.watchRule.project_canonical,
        muted: this.watchRule.muted,
      }
    },
    populateFilters () {
      if (!this.watchRule.filters) return

      const filterPairs = this.watchRule.filters.map(({ entity, operation }) => {
        if (entity === '*' && operation === '*') return []
        if (entity === '*') return [`Action.${operation}`]
        if (operation === '*') return [`Entity.${entity}`]
        return [
          `Action.${operation}`,
          `Entity.${entity}`,
        ]
      })
      this.selectedFilters = _.uniq(_.flatten(filterPairs))
    },
    populateForm () {
      const type = this.watchRule.project_canonical ? 'projectActivity' : 'eventTracking'
      this.selectedWatchRuleType = _.find(this.$static.watchRuleTypes, { key: type })
      this.setFormContentFromStore()
      this.populateFilters()
      this.$setOriginalData('formContent')
      this.$setOriginalData('selectedFilters')
    },
    changeType () {
      this.selectedWatchRuleType = null
      this.clearForm()
    },
    clearForm () {
      this.formContent = _.cloneDeep(EMPTY_FORM_CONTENT)
      this.$v.$reset()
    },
    onSave () {
      if (this.$isCreationRoute) return this.createWatchRule()
      this.updateWatchRule()
    },
    onCancel () {
      if (this.$isCreationRoute) return this.$router.push({ name: 'userWatchRules' })
      this.setFormContentFromStore()
    },
    fetchPreview () {
      const selectedEntities = this.selectedFilters
        .filter((path) => path.startsWith('Entity'))
        .map((path) => path.split('.').pop())

      const selectedOperations = this.selectedFilters
        .filter((path) => path.startsWith('Action'))
        .map((path) => path.split('.').pop())

      const end = Date.now()
      const begin = $date.format($date.subDays(end, 7), 'T')

      const query = _.omitBy({
        'entity[in]': selectedEntities.join(','),
        'action[in]': selectedOperations.join(','),
        ...(this.isProjectActivityType ? { 'project_canonical[eq]': this.formContent.project } : {}),
        begin: String(begin),
        end: String(end),
      }, _.isEmpty)
      this.FETCH_AVAILABLE({ keyPath: 'events', extraParams: [query] })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.userWatchRule',
        description: 'Watch rules help you stay in the loop by sending notifications whenever changes are made to a project or a specific event occurs.',
        changeType: 'Change rule type',
        confirmDeleteHeader: 'Delete watch rule?',
        confirmDeleteSentence: 'Are you sure you want to delete this watch rule?',
        createWatchRule: 'Create watch rule',
        eventsTotal: '{count} events total | {count} event total | {count} events total',
        eventsPerDay: '{count} event per day on average | {count} events per day on average',
        fields: {
          ruleName: {
            label: 'Rule name',
            hint: 'Enter a descriptive name for the watch rule',
          },
          organization: {
            label: '@:Organization',
            hint: 'Select the organization for this rule. A rule is always scoped to a single organization.',
          },
          project: {
            label: '@:Project',
            hint: 'Select the project to watch',
          },
          mute: {
            label: '@:watchRules.mute',
            hint: 'Prevent the watch rule from emitting notifications',
          },
        },
        sections: {
          type: {
            title: 'Select watch rule type',
          },
          general: {
            title: '@:general',
          },
          configuration: {
            title: '@:configuration',
          },
          preview: {
            title: '@:preview',
            description: 'Get an overview of the rule trigger frequency and preview events that would trigger it.',
          },
        },
        previewUnavailable: {
          title: 'Preview unavailable',
          selectOrganization: 'Select an organization above to see matching events.',
          selectProject: 'Select a project above to see matching events.',
          selectFilters: 'Add filters above to see matching events.',
        },
        noMatchingEvents: {
          title: 'No matching events',
          description: 'There have been no events matching the selected filters in the last 7 days.',
        },
        matchingEvents: 'Matching events from last 7 days',
        when: 'When',
        eventIsFired: 'an event is fired that matches all the following filters',
        then: 'Then',
        sendNotification: 'send me an in app notification',
      },
      es: {
        title: '@:routes.userWatchRule',
        description: 'Las reglas de monitoreo te ayudan a mantenerte informado enviando notificaciones cada vez que se realizan cambios en un proyecto o ocurre un evento específico.',
        changeType: 'Cambiar tipo de regla',
        confirmDeleteHeader: '¿Eliminar regla de monitoreo?',
        confirmDeleteSentence: '¿Estás seguro de que deseas eliminar esta regla de monitoreo?',
        createWatchRule: 'Crear regla de monitoreo',
        eventsTotal: '{count} eventos en total | {count} evento en total | {count} eventos en total',
        eventsPerDay: '{count} evento por día en promedio | {count} eventos por día en promedio',
        fields: {
          ruleName: {
            label: 'Nombre de la regla',
            hint: 'Ingresa un nombre descriptivo para la regla de monitoreo',
          },
          organization: {
            label: '@:Organization',
            hint: 'Selecciona la organización para esta regla. Una regla siempre está limitada a una sola organización.',
          },
          project: {
            label: '@:Project',
            hint: 'Selecciona el proyecto a monitorear',
          },
          mute: {
            label: 'Silenciar',
            hint: 'Evita que la regla de monitoreo emita notificaciones',
          },
        },
        sections: {
          type: {
            title: 'Selecciona el tipo de regla de monitoreo',
          },
          general: {
            title: '@:general',
          },
          configuration: {
            title: '@:configuration',
          },
          preview: {
            title: '@:preview',
            description: 'Obtén una descripción general de la frecuencia de activación de la regla y previsualiza los eventos que la activarían.',
          },
        },
        previewUnavailable: {
          title: 'Vista previa no disponible',
          selectOrganization: 'Selecciona una organización arriba para ver eventos coincidentes.',
          selectProject: 'Selecciona un proyecto arriba para ver eventos coincidentes.',
          selectFilters: 'Agrega filtros arriba para ver eventos coincidentes.',
        },
        noMatchingEvents: {
          title: 'No hay eventos coincidentes',
          description: 'No ha habido eventos coincidentes con los filtros seleccionados en los últimos 7 días.',
        },
        matchingEvents: 'Eventos coincidentes de los últimos 7 días',
        when: 'Cuando',
        eventIsFired: 'se dispara un evento que coincide con todos los siguientes filtros',
        then: 'Entonces',
        sendNotification: 'envíame una notificación en la aplicación',
      },
      fr: {
        title: '@:routes.userWatchRule',
        description: `Les règles de notification vous aident à rester informé en vous envoyant des notifications chaque fois qu'un changement est apporté à un projet ou qu'un événement spécifique se produit.`,
        changeType: 'Changer le type de règle',
        confirmDeleteHeader: 'Supprimer la règle de notification ?',
        confirmDeleteSentence: 'Voulez-vous vraiment supprimer cette règle de notification ?',
        createWatchRule: 'Créer une règle de notification',
        eventsTotal: '{count} événements au total | {count} événement au total | {count} événements au total',
        eventsPerDay: '{count} événement par jour en moyenne | {count} événements par jour en moyenne',
        fields: {
          ruleName: {
            label: 'Nom de la règle',
            hint: 'Entrez un nom descriptif pour la règle de notification',
          },
          organization: {
            label: '@:Organization',
            hint: `Sélectionnez l'organisation pour cette règle. Une règle est toujours limitée à une seule organisation.`,
          },
          project: {
            label: '@:Project',
            hint: `Sélectionnez le projet à surveiller`,
          },
          mute: {
            label: 'Désactivée',
            hint: `Empêchez la règle de notification d'émettre des notifications`,
          },
        },
        sections: {
          type: {
            title: 'Sélectionner le type de règle de notification',
          },
          general: {
            title: '@:general',
          },
          configuration: {
            title: '@:configuration',
          },
          preview: {
            title: '@:preview',
            description: `Obtenez un aperçu de la fréquence de déclenchement de la règle et prévisualisez les événements qui la déclencheraient.`,
          },
        },
        previewUnavailable: {
          title: `Aperçu indisponible`,
          selectOrganization: `Sélectionnez une organisation ci-dessus pour voir les événements correspondants.`,
          selectProject: `Sélectionnez un projet ci-dessus pour voir les événements correspondants.`,
          selectFilters: `Ajoutez des filtres ci-dessus pour voir les événements correspondants.`,
        },
        noMatchingEvents: {
          title: `Aucun événement correspondant`,
          description: `Il n'y a pas eu d'événements correspondant aux filtres sélectionnés au cours des 7 derniers jours.`,
        },
        matchingEvents: `Événements correspondants des 7 derniers jours`,
        when: 'Quand',
        eventIsFired: 'un événement est déclenché qui correspond à tous les filtres suivants',
        then: 'Alors',
        sendNotification: `envoyez-moi une notification dans l'application`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.change-type__btn {
  color: cy-get-color("secondary");
  font-weight: $font-weight-bolder;
  cursor: pointer;
}

.watch-rule-type__label {
  color: cy-get-color("grey", "dark-2");
}

.watch-rule-types {
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    padding: 16px;
    padding-left: 8px;
    border: 1px solid cy-get-color("primary", "light-4");
    border-radius: 4px;
    background-color: cy-get-color("white");
    color: cy-get-color("primary");
    cursor: pointer;

    &:hover {
      background: cy-get-color("grey", "light-4");
    }
  }

  &--selected {
    padding-left: 0;
  }
}

.rule-preview,
.rule-preview--empty {
  border: 1px solid cy-get-color("primary", "light-4");
  border-radius: 4px;
  background-color: cy-get-color("white");
}

.rule-preview {
  &--empty {
    padding: 16px;
  }

  &__chart,
  &__timeline {
    padding: 16px;
  }

  &__chart {
    border-bottom: 1px solid cy-get-color("primary", "light-4");

    h4 {
      margin-bottom: 16px;
    }
  }

  &__timeline {
    padding-top: 20px;

    &--empty {
      text-align: center;
    }
  }

  &__pagination {
    border-top: 1px solid cy-get-color("primary", "light-4");
  }

  .preview-chart {
    height: 156px;
  }
}

</style>

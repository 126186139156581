<template>
  <div class="emissions-overview-widget">
    <div
      v-if="!loading">
      <h4 class="mb-6">
        {{ $t('untranslated.greenops') }}
      </h4>
      <div
        data-cy="content"
        class="content">
        <div class="content-item">
          <div
            class="subtitle"
            v-text="$t('cloudCostManagement.carbonEmissions')"/>
          <div
            data-cy="total-value"
            class="total__value mr-1">
            <span class="total__value--number">
              {{ formatAmount(aggregateData.co2e) }}
            </span>
            <span
              class="total__value--unit"
              v-html="$sanitizeHtml($t('untranslated.co2e'))"/>
            <CyCcmTrendChip
              data-cy="trend"
              :value="[_.get(_.head(totalCo2eData), 'co2e', 0), _.get(_.last(totalCo2eData), 'co2e', 0)]"/>
          </div>
        </div>
        <div class="content-item">
          <div
            class="subtitle"
            v-text="$t('cloudCostManagement.energyConsumption')"/>
          <div
            data-cy="total-value"
            class="total__value mr-1">
            <span class="total__value--number">
              {{ formatAmount(aggregateData.kwh) }}
            </span>
            <span class="total__value--unit">
              {{ $t('untranslated.kwh') }}
            </span>
            <CyCcmTrendChip
              data-cy="trend"
              :value="[_.get(_.head(totalKwhData), 'kwh', 0), _.get(_.last(totalKwhData), 'kwh', 0)]"/>
          </div>
        </div>
      </div>

      <CyCcmEmissionEquivalence
        :value="aggregateData.co2e"
        class="d-flex align-center my-7"
        icon-size="37"/>

      <router-link
        class="ccm-link cy-headline unstyled-link"
        :to="{ name: 'cloudCostManagementDashboard' }">
        {{ $t('viewCloudEmissions') }}
      </router-link>
    </div>
    <div
      v-else
      data-cy="loader"
      class="sk-template d-flex align-stretch flex-wrap mb-n4">
      <div class="flex-column flex-grow-1">
        <div class="sk-block sk-title sk-w-30 sk-h-6 mb-6"/>
        <div class="sk-block sk-title sk-w-10 sk-h-4"/>
        <div class="d-flex align-center mt-1 mb-3">
          <div class="sk-block sk-title sk-w-28 sk-h-7 sk-dark"/>
          <div class="sk-block sk-title sk-w-10 sk-h-4 ml-1"/>
          <div class="sk-block sk-title sk-w-16 sk-h-4 ml-1"/>
        </div>
        <div class="d-flex align-stretch mb-6 pt-2">
          <div class="sk-block sk-avatar sk-w-16 sk-h-16 sk-dark"/>
          <div class="d-flex flex-column justify-center">
            <div class="sk-block sk-title sk-w-20 sk-h-4 ml-2 ml-4 mb-2"/>
            <div class="sk-block sk-title sk-w-20 sk-h-4 ml-2 ml-4 mb-2"/>
          </div>
        </div>
        <div class="ccm-link">
          <div class="sk-block sk-title sk-w-30 sk-h-4"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyCcmEmissionEquivalence from '@/components/CyCcmEmissionEquivalence.vue'
import CyCcmTrendChip from '@/components/CyCcmTrendChip.vue'
import { formatAmount } from '@/utils/helpers'

export default {
  name: 'CyCcmWidgetsEmissionsOverview',
  components: {
    CyCcmTrendChip,
    CyCcmEmissionEquivalence,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      aggregateData: (state) => state.aggregateData,
    }),
    totalCo2eData () {
      return this.aggregateData.providerDatas?.reduce((acc, { co2eData }) => {
        if (_.isEmpty(acc)) return co2eData
        return acc.map(({ co2e, date }, index) => ({ date, co2e: co2eData[index].co2e + co2e }))
      }, []) || []
    },
    totalKwhData () {
      return this.aggregateData.providerDatas?.reduce((acc, { kwhData }) => {
        if (_.isEmpty(acc)) return kwhData
        return acc.map(({ kwh, date }, index) => ({ date, kwh: kwhData[index].kwh + kwh }))
      }, []) || []
    },
  },
  methods: {
    formatAmount,
  },
  i18n: {
    messages: {
      en: {
        viewCloudEmissions: 'View carbon emissions',
      },
      es: {
        viewCloudEmissions: 'Ver las emisiones de carbono',
      },
      fr: {
        viewCloudEmissions: 'Voir les émissions de carbone',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.emissions-overview-widget {
  position: relative;
  height: 100%;
  min-height: 255px;
  padding: 16px;
  overflow: hidden;
  transition: border-color 0.3s ease;
  border: 1px solid cy-get-color("primary", "light-4");
  border-radius: 8px;
  background: cy-get-color("white");

  &:hover {
    border-color: cy-get-color("primary", "light-2");
  }

  .subtitle {
    color: cy-get-color("primary", "light-3");
    font-size: $font-size-sm;
  }

  .content {
    display: flex;
    gap: 35px;
  }

  .total {
    &__value {
      display: flex;
      align-items: baseline;
      margin-top: 5px;

      &--number {
        color: cy-get-color("primary", "light-1");
        font-family: $font-family-condensed;
        font-size: $font-size-h3;
        font-weight: $font-weight-bolder;
        line-height: 100%;
      }

      &--unit {
        padding: 0 5px;
        color: cy-get-color("primary", "light-2");
        font-family: $font-family-condensed;
        font-size: $font-size-lg;
        font-weight: $font-weight-bolder;
      }

      .trend-chip {
        align-self: end;
        font-size: $font-size-sm;
      }
    }
  }

  .ccm-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    border-top: 1px solid cy-get-color("primary", "light-4");
    color: cy-get-color("primary", "light-3");

    &:hover {
      color: cy-get-color("accent");
    }
  }
}
</style>

<template>
  <div class="layout__container pb-6">
    <div class="layout__content">
      <CyAlert
        theme="error"
        :content="credentialsErrors"
        closeable
        @close="CLEAR_ERRORS('credentials')"/>
      <CyCcmCredentialsTable/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CyCcmCredentialsTable from '@/components/CyCcmCredentialsTable.vue'
import { pagination as createAPIPage } from '@/utils/api'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageCloudCostManagementAccountsCredentials',
  components: {
    CyCcmCredentialsTable,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.cloudCostManagementAccountsCredentials'), [
      {
        name: 'cloudCostManagementAccounts',
        label: this.$t('routes.cloudCostManagementAccounts'),
      },
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagementSection'),
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.cloudCostManagementAccountsCredentials'),
      description: {
        text: this.$t('subtitle'),
      },
    }
  },
  computed: {
    ...mapState('organization', {
      credentialsErrors: (state) => state.errors.credentials,
    }),
    ...mapState('organization/cloudCostManagement', {
    }),
  },
  async mounted () {
    await this.GET_ACCOUNTS({ reqPage: createAPIPage(1, 100) })
  },
  methods: {
    ...mapActions('organization/cloudCostManagement', [
      'GET_ACCOUNTS',
    ]),
    ...mapMutations('organization', [
      'CLEAR_ERRORS',
    ]),
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.cloudCostManagementAccountsCredentials',
        subtitle: 'Select an existing credential or create a new one.',
      },
      es: {
        title: '@:routes.cloudCostManagementAccountsCredentials',
        subtitle: 'Seleccione una credencial existente o cree una nueva.',
      },
      fr: {
        title: '@:routes.cloudCostManagementAccountsCredentials',
        subtitle: 'Sélectionnez un identifiant existant ou créez-en un nouveau.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__sidebar {
    padding-right: 18px;
    padding-left: 18px;
  }
}
</style>

<template>
  <div class="environment-config mt-n6">
    <CyAlert
      v-if="!_.isEmpty(errors)"
      class="mt-4"
      theme="error"
      :content="errors"/>

    <CyAlert
      v-if="!canEdit"
      theme="info"
      :title="$t('readOnlyNotification.title')"
      :content="$t('readOnlyNotification.text')"/>

    <div
      v-if="pageLoading"
      class="loading-spinner">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>

    <template v-else>
      <CyWizardStackForms
        v-if="_.isEmpty(missingUsecaseErrors)"
        v-model="rawFormsConfig"
        class="environment-config__stackforms--margin-correction"
        :disabled="!canEdit || updating"
        :forms="formsConfigCurrent"
        :stack-ref="_.get(project, 'service_catalog.ref')"
        :environment-canonical="envCanonical"
        :use-case="environmentUseCase"
        @validate="setConfigurationValidity(envCanonical, $event)"/>

      <div class="environment-config__actions d-flex align-center space-x-4">
        <CyButton
          class="ml-auto"
          theme="primary"
          variant="secondary"
          :disabled="updating || !hasUnsavedChanges"
          @click="resetConfig()">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          theme="success"
          :loading="updating"
          :disabled="isSaveBtnDisabled"
          icon="done"
          @click="$toggle.showStackFormsDiff(true)">
          {{ $t('forms.reviewAndSave') }}...
        </CyButton>
      </div>
    </template>

    <CyModal
      v-if="showStackFormsDiff"
      :header-title="$t('stackFormsDiffDialog.title')"
      :action-btn-func="pushEnvironmentConfig"
      :action-btn-text="$t('stackFormsDiffDialog.actionBtnText')"
      :cancel-btn-func="() => $toggle.showStackFormsDiff(false)"
      :cancel-btn-text="$t('forms.btnCancel')"
      dialog-class="v-dialog--medium"
      :loading="updating"
      modal-type="info"
      large>
      <template slot="default">
        <CyStackFormsDiff
          :vars="{
            old: oldVariables,
            new: rawFormsConfig,
          }"/>
      </template>
    </CyModal>

    <CyModal
      v-if="showConfirmCloseDialog"
      :header-title="$t('confirmCloseDialog.title')"
      :action-btn-func="exitRoute"
      :action-btn-text="$t('confirmCloseDialog.actionBtnText')"
      :cancel-btn-func="stayOnPage"
      :cancel-btn-text="$t('confirmCloseDialog.cancelBtnText')"
      modal-type="warning"
      small>
      <template slot="default">
        <p class="ma-0">
          {{ $t('confirmCloseDialog.text') }}
        </p>
      </template>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import CyStackFormsDiff from '@/components/CyStackFormsDiff.vue'
import CyWizardStackForms from '@/components/CyWizardStackForms.vue'
import { constructBreadcrumb, extractStartStopPipelines } from '@/utils/helpers'

export default {
  name: 'CyPageEnvironmentConfig',
  components: {
    CyStackFormsDiff,
    CyWizardStackForms,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this

    return constructBreadcrumb(this.$options.name, envCanonical, [
      {
        label: this.$t('routes.projectEnvironments'),
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  beforeRouteLeave (to, from, next) {
    const userCanLeave = () => !this.hasUnsavedChanges || !_.isEmpty(this.to)

    const pipelineCanonical = `${this.projectCanonical}-${this.envCanonical}`
    const pipelineCanonicalNeedsCorrection = to.name === 'pipeline' && to.params.pipelineCanonical !== pipelineCanonical

    if (pipelineCanonicalNeedsCorrection) {
      next({ ...to, params: { ...to.params, pipelineCanonical } })
    }

    if (userCanLeave()) return next()

    this.to = to
    this.showConfirmCloseDialog = true
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formsConfigCurrent: {},
    isConfigurationValid: {},
    pageLoading: true,
    updating: false,
    rawFormsConfig: {},
    showConfirmCloseDialog: false,
    showStackFormsDiff: false,
    missingUsecaseErrors: [],
    to: null,
  }),
  computed: {
    ...mapState('organization/project', {
      environmentsConfig: (state) => state.environmentsConfig,
      environmentsConfigErrors: (state) => state.errors.environmentsConfig,
      formsErrors: (state) => state.errors.forms,
      pipelineErrors: (state) => state.errors.pipelines,
      projectErrors: (state) => state.errors.project,
      projectPipelines: (state) => state.pipelines,
    }),
    ...mapState('organization/project/configRepository', {
      configRepositoryErrors: (state) => state.errors.configRepository,
    }),
    ...mapState('organization/quota', {
      debouncingQuotas: (state) => state.debouncing,
    }),
    ...mapGetters('organization/project', [
      'project',
      'stackRef',
    ]),
    ...mapGetters('organization/quota', {
      isEnvQuotaValid: 'isEnvValid',
    }),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    canEdit () {
      return this.$cycloid.permissions.canDisplay('UpdateProject', this.project.canonical)
    },

    canSave () {
      const { envCanonical, isConfigurationValid, quotasEnabled } = this
      const areQuotasValid = !quotasEnabled || (this.isEnvQuotaValid(envCanonical) && !this.debouncingQuotas[envCanonical])

      return isConfigurationValid[envCanonical] && areQuotasValid
    },
    environmentUseCase () {
      const nonStartStopPipelines = extractStartStopPipelines(this.projectPipelines)?.regular
      const environmentsPipelines = Object.assign({},
        ..._.map(nonStartStopPipelines, ({ environment: { canonical }, use_case: useCase }) => ({ [canonical]: useCase })) || {},
      )
      return environmentsPipelines[this.envCanonical]
    },
    errors () {
      return [
        ...this.environmentsConfigErrors,
        ...this.missingUsecaseErrors,
        ...this.configRepositoryErrors,
        ...this.formsErrors,
        ...this.projectErrors,
        ...this.pipelineErrors,
      ]
    },
    formsConfig () {
      return _.$get(this.environmentsConfig, [this.envCanonical, 'forms'], {})
    },
    hasUnsavedChanges () {
      const { formsConfig, rawFormsConfig, environmentUseCase: useCase } = this

      if (_.isEmpty(rawFormsConfig)) return false
      let atLeastOneHasDiff = false

      const currentConfig = formsConfig?.use_cases?.find(({ name }) => name === useCase)
      if (_.isEmpty(currentConfig)) return false

      currentConfig.sections.forEach((section) => {
        section.groups.forEach((group) => {
          group.vars.forEach(({ key, default: defaultValue, current: storedValue, widget }) => {
            // Since conditional fields were introduced, it is possible to have
            // fields in currentConfig (the new values) that were not even present
            // in rawFormsConfig (the old values). This can cause a drift.
            const emittedValue = rawFormsConfig[section.name]?.[group.name]?.[key] ?? null
            const isInventoryWidget = widget === 'cy_inventory_resource'
            if (isInventoryWidget && _.isUndefined(storedValue)) storedValue = null

            const isNewInForms = storedValue === undefined

            // This is also why we trigger the following flags only if `emittedValue` is not null
            const existedAndHasDiff = !isNewInForms && (emittedValue !== null || isInventoryWidget) && !_.isEqual(emittedValue, storedValue)
            const isNewButDiffersFromDefault = isNewInForms && (emittedValue !== null || isInventoryWidget) && !_.isEqual(emittedValue, defaultValue)
            if (existedAndHasDiff || isNewButDiffersFromDefault) atLeastOneHasDiff = true
          })
        })
      })

      return atLeastOneHasDiff
    },
    isSaveBtnDisabled () {
      const { pageLoading, updating, canSave } = this
      return pageLoading || updating || !canSave
    },
    oldVariables () {
      const { formsConfigCurrent } = this
      const configWithoutNewVars = _.cloneDeep(this.$data.$originalData.rawFormsConfig || {})

      for (const section in configWithoutNewVars) {
        for (const group in configWithoutNewVars[section]) {
          for (const widget in configWithoutNewVars[section][group]) {
            const fullVariable = formsConfigCurrent.sections
              .find(({ name }) => name === section).groups
              .find(({ name }) => name === group).vars
              .find(({ key }) => key === widget)

            // mark new or missing variables with null in oldVariables
            if (fullVariable?.new) configWithoutNewVars[section][group][widget] = null
          }
        }
      }

      return configWithoutNewVars
    },
    quotasEnabled () {
      return this.organization.quotas && this.stack?.quota_enabled
    },
  },
  watch: {
    rawFormsConfig: {
      handler (config) {
        if (_.isEmpty(this.$data.$originalData.rawFormsConfig)) {
          this.$setOriginalData('rawFormsConfig')
        }
      },
      deep: true,
    },
    envCanonical: {
      async handler (env) {
        this.$toggle.pageLoading(true)
        if (!_.isEmpty(this.$data.$originalData.rawFormsConfig)) {
          this.rawFormsConfig = {}
          this.$setOriginalData('rawFormsConfig')
        }
        this.missingUsecaseErrors = []
        this.RESET_STACK_STATE()
        this.SET_STACK(this.project.service_catalog)
        await this.retrieveEnvFormsConfiguration()
        this.$toggle.pageLoading(false)
        this.to = null
      },
      immediate: true,
    },
  },
  destroyed () {
    this.RESET_STACK_STATE()
    this.STOP_FETCH('environmentsConfig')
  },
  methods: {
    ...mapActions('organization/project', [
      'CREATE_FORMS_CONFIG',
      'GET_PROJECT_ENVIRONMENT_CONFIG',
      'GET_PROJECT_PIPELINES',
      'UPDATE_ENV',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
      'STOP_FETCH',
    ]),
    ...mapMutations('organization/stack', [
      'SET_STACK',
      'RESET_STACK_STATE',
    ]),
    async createFormsConfigToPush () {
      const inputs = [{
        use_case: this.environmentUseCase,
        environment_canonical: this.envCanonical,
        vars: this.rawFormsConfig,
      }]
      const config = { service_catalog_ref: this.stackRef, inputs }
      await this.CREATE_FORMS_CONFIG(config)
    },
    exitRoute () {
      this.$router.push(this.to)
    },
    getUserConfigsWithDestination (config, array = []) {
      _.entries(config).forEach(([key, value]) => {
        if (!_.isPlainObject(value)) return

        const { content, destination: path } = value
        path
          ? array.push({ content: btoa(content), path })
          : this.getUserConfigsWithDestination(config[key], array)
      })

      return array
    },
    async resetConfig () {
      this.pageLoading = true
      this.setCurrentFormsConfig()
      this.pageLoading = false
    },
    async retrieveEnvFormsConfiguration () {
      // in case we are refreshing the page and pipelines are not set in the store
      if (_.isEmpty(this.projectPipelines)) await this.GET_PROJECT_PIPELINES()
      await this.GET_PROJECT_ENVIRONMENT_CONFIG(this.envCanonical)
      this.setCurrentFormsConfig()
    },
    setConfigurationValidity (env, isValid) {
      this.$set(this.isConfigurationValid, env, isValid)
    },
    setCurrentFormsConfig () {
      const { formsConfig, environmentUseCase: useCase } = this
      const configCurrent = _.cloneDeep(formsConfig.use_cases?.find(({ name }) => name === useCase))
      if (!configCurrent) {
        this.missingUsecaseErrors = [this.$t('missingUsecaseError', { useCase })]
        return
      }

      configCurrent?.sections.forEach((section) => {
        section.groups.forEach((group) => {
          group.vars = group.vars.map((widget) => {
            const isCurrentUndefined = widget.current === undefined

            return _.omit({
              ...widget,
              default: isCurrentUndefined ? widget.default : widget.current,
              new: isCurrentUndefined,
            }, 'current')
          })
        })
      })

      this.$set(this, 'formsConfigCurrent', configCurrent)
    },
    stayOnPage () {
      this.showConfirmCloseDialog = false
      this.to = null
    },
    getUpdatedEnvironment () {
      const environment = _.cloneDeep(_.find(this.project.environments, ['canonical', this.envCanonical]))
      const inputs = [{
        use_case: this.environmentUseCase,
        environment_canonical: this.envCanonical,
        vars: this.rawFormsConfig,
      }]

      return { ...environment, inputs }
    },
    async pushEnvironmentConfig () {
      this.updating = true
      this.CLEAR_PROJ_ERRORS('environmentsConfig')
      this.CLEAR_PROJ_ERRORS('forms')

      try {
        await this.createFormsConfigToPush()

        const environment = this.getUpdatedEnvironment()
        await this.UPDATE_ENV({
          environment,
          displaySuccessMessage: true,
          refreshProject: true,
          refreshConfig: true,
        })
        this.$setOriginalData('rawFormsConfig')
        this.setCurrentFormsConfig()
      } catch (error) {
        console.error(error)
      } finally {
        this.updating = false
        this.showStackFormsDiff = false
      }
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Environment configuration',
        confirmCloseDialog: {
          actionBtnText: 'Leave',
          cancelBtnText: 'Stay on StackForms',
          text: 'There are unsaved changes, are you sure you want to leave StackForms?',
          title: 'Unsaved changes detected',
        },
        missingUsecaseError: 'No configuration was found for the <b>{useCase}</b> usecase',
        stackFormsConfiguration: 'StackForms configuration',
        stackFormsDiffDialog: {
          actionBtnText: 'Apply changes',
          title: 'Edit StackForms configuration',
        },
        readOnlyNotification: {
          title: 'This is a Read Only view.',
          text: 'You do not have sufficient permissions to configure this environment.',
        },
      },
      es: {
        title: 'Configuración del entorno',
        confirmCloseDialog: {
          actionBtnText: 'Salir',
          cancelBtnText: 'Mantente en StackForms',
          text: 'Hay cambios sin guardar, ¿está seguro de que quiere abandonar StackForms?',
          title: 'Cambios no guardados detectados',
        },
        missingUsecaseError: 'No se encontró ninguna configuración para el caso de uso <b>{useCase}</b>',
        stackFormsConfiguration: 'Configuración de StackForms',
        stackFormsDiffDialog: {
          actionBtnText: 'Aplicar cambios',
          title: 'Editar la configuración de StackForms',
        },
        readOnlyNotification: {
          title: 'Esta es una vista de sólo lectura.',
          text: 'No tiene permisos suficientes para configurar este entorno.',
        },
      },
      fr: {
        title: `Configuration de l'environnement`,
        confirmCloseDialog: {
          actionBtnText: 'Laisser',
          cancelBtnText: 'Restez sur StackForms',
          text: 'Il y a des modifications non enregistrées, êtes-vous sûr de vouloir quitter StackForms ?',
          title: 'Modifications non enregistrées détectées',
        },
        missingUsecaseError: `Aucune configuration n'a été trouvée pour le cas d'utilisation <b>{useCase}</b>`,
        stackFormsConfiguration: 'Configuration de StackForms',
        stackFormsDiffDialog: {
          actionBtnText: 'Appliquer les modifications',
          title: 'Modifier la configuration de StackForms',
        },
        readOnlyNotification: {
          title: `Il s'agit d'une vue en lecture seule.`,
          text: 'Vous ne disposez pas des autorisations suffisantes pour configurer cet environnement.',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.environment-config {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;

  &__stackforms {
    &--margin-correction {
      display: flex;
      flex-grow: 1;
      width: auto;
      max-width: initial;
      margin: 0 -32px 37px;
      border-top: none;
      background-color: cy-get-color("white");
    }
  }

  &__actions {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -32px;
    width: calc(100% + 64px);
    margin-bottom: -32px;
    padding: 16px;
    border-top: solid 1px cy-get-color("grey", "light-2");
    background-color: cy-get-color("white");
  }
}

::v-deep .stack-forms__group {
  margin-left: 42px;

  > .v-expansion-panel-content__wrap {
    padding-right: 0;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>

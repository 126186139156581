<template>
  <div>
    <CyDataTableYdApi
      :fetch-available="{ keyPath: 'quotas' }"
      :filters="$static.filters"
      :groups="$static.groups"
      :headers="$static.headers"
      key-field="rowKey"
      :paginate="false"
      :parse-items-array="quotasParser"
      :searchable-fields="$static.searchableFields"
      :transform-items="(item) => ({ ...item, usage: getUsagePercentage(item) })">
      <template #group-header-title-append="{ props }">
        <v-icon
          v-if="_.some(props.items, ({ usage }) => usage >= 90)"
          color="error"
          size="18"
          class="ml-1">
          error
        </v-icon>
        <v-icon
          v-if="_.some(props.items, ({ usage }) => _.inRange(usage, 70, 90))"
          color="warning"
          size="18"
          class="ml-1">
          warning
        </v-icon>
      </template>
      <template #group-header-actions="{ props }">
        <CyButton
          v-if="_.head(props.groupBy).startsWith('team')"
          class="edit-quota__btn my-n1 mr-1"
          icon="edit"
          icon-only
          sm
          theme="primary"
          variant="tertiary"
          :to="{ name: 'quota', params: { quotaId: _.head(props.items).id } }"
          @click.stop/>
      </template>
      <template #table-cmp-body-row="{ props }">
        <td>
          {{ $t(`quotas.${props.item.type}`) }}
        </td>
        <td>
          {{ props.item.team }}
        </td>
        <td>
          {{ props.item.resource_pool }}
        </td>
        <td class="d-flex align-center">
          <v-progress-linear
            :value="props.item.usage"
            :color="getUsageBarColor(props.item)"
            background-color="grey lighten-2"
            height="8"
            rounded/>
          <div class="usage-percentage">
            {{ props.item.usage }}%
          </div>
          <div class="usedOf flex-grow-1">
            {{ getUsedOfText(props.item, true) }}
          </div>
          <CyMenu
            :items="getRowActionItems(props.item)"
            :bottom="true"
            :offset-y="true"
            :left="true"/>
        </td>
      </template>
      <template slot="table-cmp-no-data">
        <div class="no-data d-flex flex-column justify-center align-center">
          <h3 class="mb-2">
            {{ $t('noQuotasFound') }}
          </h3>
          <p>
            {{ $t('tryDifferentFilters') }}
          </p>
        </div>
      </template>
      <template slot="table-cmp-header-actions">
        <CyButton
          v-has-rights-to="'CreateQuota'"
          class="create-quota__btn"
          icon="add"
          theme="secondary"
          variant="primary"
          :to="{ name: 'newQuota' }">
          {{ $t('quotas.createQuota') }}
        </CyButton>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyDataTableYdApi from '@/components/CyDataTableYdApi.vue'
import { getUsedOfText, getUsagePercentage, getUsageBarColor } from '@/utils/helpers/quotas'

export default {
  name: 'CyQuotasListTable',
  components: {
    CyDataTableYdApi,
  },
  computed: {
    ...mapState('organization/quota', {
      quotas: (state) => state.overview,
    }),
    $static () {
      return {
        headers: [
          {
            text: this.$t('quotas.quotaItem'),
            value: 'type',
          },
          {
            text: this.$t('Team'),
            value: 'team',
          },
          {
            text: this.$t('ResourcePool'),
            value: 'resource_pool',
          },
          {
            text: this.$t('quotas.usage'),
            value: 'usage',
          },
        ],
        searchableFields: [
          {
            label: this.$t('quotas.quotaItem'),
            name: 'type',
          },
          {
            label: this.$t('Team'),
            name: 'team',
          },
          {
            label: this.$t('ResourcePool'),
            name: 'resource_pool',
          },
        ],
        groups: [
          {
            label: this.$t('quotas.quotaItem'),
            groupBy: 'type',
            hideColumn: false,
          },
          {
            label: this.$t('Team'),
            groupBy: 'team',
            hideColumn: false,
          },
          {
            label: this.$t('ResourcePool'),
            groupBy: 'resource_pool',
            hideColumn: false,
          },
        ],
        filters: [
          {
            label: this.$t('Team'),
            type: 'select',
            queryParams: ['team_canonical'],
            items: _.uniqBy(this.quotas, 'team.canonical').map(({ team: { canonical: teamCanonical } }) => ({
              title: teamCanonical,
              key: 'team_canonical',
              value: teamCanonical,
            })),
          },
          {
            label: this.$t('ResourcePool'),
            type: 'select',
            queryParams: ['resource_pool_canonical'],
            items: _.uniqBy(this.quotas, 'resource_pool.canonical').map(({ resource_pool: { canonical: resourcePoolCanonical } }) => ({
              title: resourcePoolCanonical,
              key: 'resource_pool_canonical',
              value: resourcePoolCanonical,
            })),
          },
        ],
      }
    },
  },
  methods: {
    getUsedOfText,
    getUsagePercentage,
    getUsageBarColor,
    getRowActionItems ({ id: quotaId }) {
      return [
        {
          icon: 'edit',
          label: this.$t('forms.btnEdit'),
          action: () => { this.$router.push({ name: 'quota', params: { quotaId } }) },
        },
      ]
    },
    quotasParser (items) {
      return items.reduce((parsedItems, item) => {
        const { id, team, resource_pool: resourcePool } = item
        const resourceQuotaItems = ['memory', 'storage', 'cpu'].map((type) => ({
          rowKey: `${id}-${type}`,
          id,
          team: team.canonical,
          resource_pool: resourcePool.canonical,
          type,
          used: item[`used_${type}`],
          allocated: item[type],
        }))
        return [...parsedItems, ...resourceQuotaItems]
      }, [])
    },
  },
  i18n: {
    messages: {
      en: {
        noQuotasFound: 'No quotas found',
        tryDifferentFilters: 'Try different filters',
      },
      es: {
        noQuotasFound: 'No se encontraron quotas',
        tryDifferentFilters: 'Prueba diferentes filtros',
      },
      fr: {
        noQuotasFound: 'Aucun quota trouvé',
        tryDifferentFilters: `Essayez d'autres filtres`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-progress-linear {
  width: 100px;
  margin-right: 16px;
}

.usage-percentage {
  width: 5em;
  margin-right: 16px;
}

.no-data {
  height: 240px;

  h3 {
    color: cy-get-color("primary", "light-2");
  }

  p {
    color: cy-get-color("primary", "light-3");
  }
}
</style>

<template>
  <div
    :class="['filters-sidebar space-y-4', {
      'filters-sidebar--collapsed': isSidebarCollapsed,
      'filters-sidebar--with-transition': hasSidebarTransition,
    }]">
    <CyCcmSidebarToggle @sidebar-collapse="isSidebarCollapsed = $event"/>
    <div
      v-show="!isSidebarCollapsed"
      class="space-y-4">
      <CyCcmSidebarDateRangeFilter @input="$emit('change')"/>
      <CyCcmSidebarGranularitySelector @input="$emit('change')"/>
      <CyCcmSidebarFilterList
        :filters="$static.filters"
        @input="$emit('change')"/>
      <CyCcmSidebarCurrencySwitcher @input="$emit('change')"/>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CyCcmSidebarCurrencySwitcher from '@/components/CyCcmSidebarCurrencySwitcher.vue'
import CyCcmSidebarDateRangeFilter from '@/components/CyCcmSidebarDateRangeFilter.vue'
import CyCcmSidebarFilterList from '@/components/CyCcmSidebarFilterList.vue'
import CyCcmSidebarGranularitySelector from '@/components/CyCcmSidebarGranularitySelector.vue'
import CyCcmSidebarToggle from '@/components/CyCcmSidebarToggle.vue'

export default {
  name: 'CyCcmDashboardFiltersSidebar',
  components: {
    CyCcmSidebarDateRangeFilter,
    CyCcmSidebarGranularitySelector,
    CyCcmSidebarFilterList,
    CyCcmSidebarCurrencySwitcher,
    CyCcmSidebarToggle,
  },
  data: () => ({
    hasSidebarTransition: false,
    isSidebarCollapsed: false,
  }),
  computed: {
    $static: () => ({
      filters: [
        'master_accounts',
        'linked_accounts',
        'projects',
      ],
    }),
  },
  created () {
    this.RESET_QUERY_BODY('dashboard')
  },
  mounted () {
    setTimeout(() => { this.hasSidebarTransition = true }, 1000)
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'RESET_QUERY_BODY',
    ]),
  },
}
</script>

<style lang="scss" scoped>
  .filters-sidebar {
    width: 328px;

    &--collapsed {
      width: 46px !important;
    }

    &--with-transition {
      transition: width 0.5s ease-in-out;
    }
  }
</style>

<template>
  <CyHeader
    v-if="catalogRepository"
    :loading="loading">
    <template #title>
      {{ catalogRepository.name }}
    </template>
    <template #actions>
      <portal-target name="header-actions"/>
    </template>
    <template #metadata>
      <CyHeaderMetadata
        v-if="catalogRepository.owner"
        :label="displayName(catalogRepository.owner)">
        <CyAvatar
          xs
          :item="catalogRepository.owner"/>
      </CyHeaderMetadata>
      <CyHeaderMetadata
        v-if="catalogRepository.created_at"
        :label="getLabel('created')"/>
      <CyHeaderMetadata
        v-if="catalogRepository.updated_at"
        :label="getLabel('updated')"/>
      <CyHeaderMetadata
        v-if="catalogRepository.refreshed_at"
        :label="getLabel('refreshed')"/>
    </template>
  </CyHeader>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CyHeader from '@/components/CyHeader.vue'
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import { displayName } from '@/utils/helpers'

export default {
  name: 'CyHeaderVariantCatalogRepository',
  components: {
    CyHeader,
    CyHeaderMetadata,
  },
  computed: {
    ...mapState('organization/catalogRepository', {
      loading: (state) => state.fetchInProgress.catalogRepository,
    }),
    ...mapGetters('organization/catalogRepository', [
      'catalogRepository',
    ]),
  },
  methods: {
    displayName,
    getLabel (type) {
      const { created_at, updated_at, refreshed_at } = this.catalogRepository
      const { format, $formatTimeAgo, $getTimestamp } = this.$date

      const labelTemplates = {
        created: this.$t('created', [format($getTimestamp(created_at), 'MMM d, yyyy')]),
        updated: this.$t('updated', [$formatTimeAgo(updated_at)]),
        refreshed: this.$t('refreshed', [$formatTimeAgo(refreshed_at)]),
      }

      return labelTemplates[type] || ''
    },
  },
  i18n: {
    messages: {
      en: {
        created: 'Created on {0}',
        updated: 'Updated {0}',
        refreshed: 'Refreshed {0}',
      },
      es: {
        created: 'Creado el {0}',
        updated: 'Actualizado {0}',
        refreshed: 'Refrescado {0}',
      },
      fr: {
        created: 'Créé le {0}',
        updated: 'Mis à jour {0}',
        refreshed: 'Rafraîchi {0}',
      },
    },
  },
}
</script>

<template>
  <div
    :class="['d-flex flex-nowrap space-x-6 align-end mx-n2 pa-2 rounded', {
      'primary-light-5': highlighted,
    }]"
    @click="$emit('highlight')">
    <div class="item__combo">
      <CyPoliciesCombobox
        ref="actions"
        v-model="manualAction.actions"
        chips
        :disabled="!isCycloidEmployee || disabled"
        :readonly="!isCycloidEmployee || disabled"
        :is-dropdown-enabled="false"
        :autofocus="highlighted"
        :error-messages="actionsErrors"
        :focusable-items="false"
        :placeholder="$t('actions')"
        @focus="onActionsFocus"
        @blur="onActionsBlur"/>
    </div>

    <div class="item__combo ml-6">
      <CyInputsCombobox
        ref="resources"
        v-model="manualAction.resources"
        chips
        :disabled="!isCycloidEmployee || disabled"
        :readonly="!isCycloidEmployee || disabled"
        :is-dropdown-enabled="false"
        :focusable-items="false"
        :placeholder="$t('Resources')"
        @focus="$emit('highlight')"/>
    </div>

    <div
      :class="['item__btn', {
        'ml-6': isCycloidEmployee,
        'ml-14': !isCycloidEmployee,
      }]">
      <CyButton
        v-if="isCycloidEmployee && !disabled"
        theme="primary"
        variant="tertiary"
        icon="delete"
        icon-only
        @click="$emit('remove')"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyInputsCombobox from '@/components/CyInputsCombobox.vue'
import CyPoliciesCombobox from '@/components/CyPoliciesCombobox.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyManualActionsBoxItem',
  components: {
    CyInputsCombobox,
    CyPoliciesCombobox,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  validations () {
    return {
      manualAction: {
        actions: {
          required,
        },
      },
    }
  },
  data: () => ({
    preventActionsError: true,
  }),
  computed: {
    ...mapGetters('user', ['isCycloidEmployee']),
    manualAction: {
      set (value) { this.$emit('input', value) },
      get () { return this.value || {} },
    },
    actionsErrors () {
      const errors = []
      const { required } = this.$v.manualAction.actions
      if (this.preventActionsError) return []
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  mounted () {
    this.preventActionsError = this.highlighted
  },
  methods: {
    onActionsFocus () {
      this.preventActionsError = true
      this.$emit('highlight')
    },
    onActionsBlur () {
      this.preventActionsError = false
    },
  },
  i18n: {
    messages: {
      en: {
        actions: 'Actions',
      },
      es: {
        actions: 'Acciones',
      },
      fr: {
        actions: 'Actions',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-wrap: nowrap;
  align-items: end;
  margin-right: -8px;
  margin-left: -8px;
  padding: 8px;
  border-radius: 8px;

  &--highlighted {
    background: cy-get-color("primary", "light-5");
  }

  &__combo {
    flex-grow: 1;
    width: 50%;
  }

  &__btn {
    flex-grow: 0;
    align-self: center;
  }
}

::v-deep {
  .cy-combobox {
    > .v-input {
      margin-top: 0;
      padding-top: 0;
    }

    .v-select__selections {
      min-height: 24px;
      padding-top: 0;
    }
  }
}
</style>

import { render, staticRenderFns } from "./CyPageUserWatchRule.vue?vue&type=template&id=b35bbbbc&scoped=true"
import script from "./CyPageUserWatchRule.vue?vue&type=script&lang=js"
export * from "./CyPageUserWatchRule.vue?vue&type=script&lang=js"
import style0 from "./CyPageUserWatchRule.vue?vue&type=style&index=0&id=b35bbbbc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b35bbbbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VContainer,VDivider,VIcon,VListItem,VListItemAvatar,VListItemIcon,VListItemTitle,VRow,VSelect,VSwitch,VTextField})

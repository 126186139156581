<template>
  <CyHeader
    v-if="stack"
    :loading="loading">
    <template #avatar>
      <CyStackAvatar
        :size="32"
        :stack="stack"
        class="mr-1"
        data-cy="stack-avatar"/>
    </template>
    <template #title>
      {{ stack.name }}
      <CyTooltip
        :max-width="250"
        right>
        <template #activator="{ on }">
          <CyTag
            class="ml-2"
            :variant="$static.visibilityTags[stackVisibility].variant"
            :icon-before="$static.visibilityTags[stackVisibility].icon"
            v-on="on">
            {{ $t(`stacks.visibility.${stackVisibility}`) }}
          </CyTag>
        </template>
        {{ $t(`stacks.visibility.${stackVisibility}${parentOrgText}Hint`) }}
      </CyTooltip>
    </template>
    <template #description>
      {{ stack.description }}
    </template>
    <template #actions>
      <portal-target name="header-actions"/>
    </template>
    <template #metadata>
      <CyHeaderMetadata
        v-if="!_.isEmpty(stack.team)"
        :to="stackBelongsToCurrentOrg ? { name: 'team', params: { teamCanonical: stack.team.canonical } } : null">
        <span class="d-flex align-center">
          <CyAvatar
            :item="stack.team"
            sm/>
          {{ stack.team.name }}
        </span>
      </CyHeaderMetadata>
      <CyHeaderMetadata
        icon="domain"
        :label="stack.organization_canonical"/>
      <CyHeaderMetadata
        v-if="stack.quota_enabled"
        icon="data_usage"
        :label="$t('quotas.requiresQuota')"/>
    </template>
  </CyHeader>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CyHeader from '@/components/CyHeader.vue'
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import { VISIBILITIES } from '@/utils/helpers/stacks'

export default {
  name: 'CyHeaderVariantStack',
  components: {
    CyHeader,
    CyHeaderMetadata,
    CyStackAvatar,
  },
  computed: {
    ...mapState('organization/stack', {
      loading: (state) => state.fetchInProgress.stack,
    }),
    ...mapGetters('organization/stack', [
      'stack',
      'stackBelongsToCurrentOrg',
    ]),
    $static: () => ({
      visibilityTags: VISIBILITIES,
    }),
    parentOrgText () {
      if (_.isEqual(this.stackVisibility, 'shared') && !this.stackBelongsToCurrentOrg) {
        return 'ByParentOrg'
      }
      return ''
    },
    stackVisibility () {
      return this.stack.visibility
    },
  },
  i18n: {
    messages: {
      en: {
        private: 'Private',
        public: 'Public',
      },
      es: {
        private: 'Privada',
        public: 'Pública',
      },
      fr: {
        private: 'Privée',
        public: 'Publique',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-avatar {
  border-radius: 4px !important;
}
</style>

<template>
  <div>
    <CyAlert
      theme="error"
      :content="credentialsErrors"/>

    <CyAlert
      v-if="showLegend"
      data-cy="what-is-cr"
      closeable
      :title="$t('whatIsCR')"
      @close="$toggle.showLegend">
      <span v-html="$t('legendTextBegin')"/>
      <router-link
        :to="{ name: 'credentials' }"
        class="cy-link">
        {{ $t('Credential') }}
      </router-link>
      <span v-html="$t('legendTextEnd')"/>
    </CyAlert>

    <CyDataTableYdApi
      id="cy-catalog-repositories-table"
      ref="cyDataTable"
      :fetch-available="{ keyPath: 'catalogRepositories' }"
      :headers="$static.headers"
      :link-builder="getLinkTarget"
      :bulk="hasBulkModeEnabled"
      :searchable-fields="$static.searchableFields"
      key-field="canonical">
      <template #table-cmp-header-actions="{ selected }">
        <a
          v-if="!showLegend"
          class="cy-link show-legend mr-4"
          data-cy="what-is-cr-button"
          @click="$toggle.showLegend">
          {{ $t('whatIsCR') }}
        </a>

        <template v-if="!_.isEmpty(selected)">
          <CyDevButton
            :wrap-dev-text="false"
            class="my-0 mr-2"
            @click.native="setMissingOwners(selected)">
            Set missing owner
          </CyDevButton>

          <CyButton
            theme="error"
            icon="delete"
            data-cy="delete-catalog-repo-btn"
            @click="openDeleteModal(selected)">
            {{ $tc('deleteCatalogReposBtn', selected.length, { nb: selected.length }) }}
          </CyButton>
        </template>

        <CyButton
          v-else
          v-has-rights-to="'CreateServiceCatalogSource'"
          data-cy="create-catalog-repo-btn"
          icon="add"
          :to="{ name: 'newCatalogRepository' }"
          @click="$gtm.trackEvent($static.gtmStacksEvents.stacksCatalogRepositoriesAddPrivateCatalog)">
          {{ $t('addCatalogRepository') }}
        </CyButton>

        <CyModal
          v-if="refreshedRepository"
          modal-type="success"
          :cancel-btn-text="$t('forms.btnClose')"
          :header-title="`${$t('repoRefreshSuccess', { repo: refreshedRepository })}`"
          :cancel-btn-func="() => refreshedRepository = null"
          action-btn-hidden>
          <div v-if="repositoryStackChanges.length">
            <div
              class="d-flex justify-space-between">
              <div
                v-for="[typeOfChange, stacks] in repositoryStackChanges"
                :key="typeOfChange">
                <span class="font-weight-bold ">
                  {{ `${typeOfChange} ${_.toLower($t('untranslated.stacks'))}` }}
                </span>
                <CyTagList
                  class="d-flex flex-column mt-2"
                  :tags="stacks">
                  <template #tag="{ tag }">
                    <CyTag
                      :variant="stackVariant(typeOfChange)">
                      {{ tag.canonical }}
                    </CyTag>
                  </template>
                </CyTagList>
              </div>
            </div>
          </div>
          <p v-else>
            {{ $t('noStacksChanged') }}
          </p>
        </CyModal>

        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmDeleteTitle', toDelete.length)"
          :action-btn-func="onDelete"
          :cancel-btn-func="closeDeleteModal"
          :action-btn-text="$tc('deleteCatalogReposBtn', toDelete.length, { nb: toDelete.length })"
          :loading="isDeleting"
          small
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length)"/>
          </p>
          <ul
            v-if="toDelete.length > 1"
            class="items-to-delete">
            <li
              v-for="{ canonical, name, url } of toDelete"
              :key="canonical">
              <div>
                <h3>{{ name }}</h3>
                <p class="url">
                  {{ url }}
                </p>
              </div>
            </li>
          </ul>
          <p>{{ $t('confirmDeleteRepository') }}</p>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>{{ item.name }}</td>

        <td>
          <CyButton
            v-if="item.credential_canonical"
            :key="item.canonical"
            theme="grey"
            variant="tertiary"
            class="credential-link"
            light
            sm
            :disabled="!canGoToCredential(item)"
            :readonly="!canGoToCredential(item)"
            :to="{ name: 'credential', params: { credentialCanonical: item.credential_canonical, backRouteTo: 'catalogRepositories' } }"
            @click.stop>
            <CyIconCredential
              v-if="getCredential(item.credential_canonical).type"
              :type="getCredential(item.credential_canonical).type"/>
            <span class="credential-link__name">
              {{ getCredential(item.credential_canonical).name }}
            </span>
          </CyButton>
        </td>

        <td>
          <CyFormsAssignOwner
            v-if="canUpdateOwner(item)"
            :errors="errors"
            :form-content="item"
            :action-btn-func="assignNewOwner"/>
          <CyButton
            v-else
            :to="{
              name: 'member',
              params: {
                id: item.owner.id,
                backRouteTo: 'catalogRepositories',
              },
            }"
            :disabled="!canGoToMember(item)"
            :readonly="!canGoToMember(item)"
            theme="grey"
            variant="tertiary"
            sm
            member-link
            @click.stop>
            <CyMember
              :member="item.owner"
              simple
              sm/>
          </CyButton>
        </td>

        <td>{{ item.branch }}</td>

        <td>
          <div>
            <CyCopyButton
              :copy-value="item.url"
              class="mr-2"
              small/>
            {{ item.url }}
          </div>
        </td>
        <td
          v-has-rights-to="['RefreshServiceCatalogSource', item.canonical]"
          class="refresh-column-cell"
          @click.stop>
          <CyTooltip
            left
            :disabled="refreshingCR[item.canonical]">
            <template #activator="{ on }">
              <CyButton
                icon="refresh"
                variant="tertiary"
                icon-only
                class="refresh-cr-btn"
                :disabled="refreshingCR[item.canonical]"
                :loading="refreshingCR[item.canonical]"
                @click.prevent.stop="refreshCR(item)"
                v-on="on"/>
            </template>
            <span>{{ $t('refreshCR') }}</span>
          </CyTooltip>
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CyCopyButton from '@/components/CyCopyButton.vue'
import CyDataTableYdApi from '@/components/CyDataTableYdApi.vue'
import CyTagList from '@/components/CyTagList.vue'
import { constructBreadcrumb, displayName, getMissingOwnerObject, hasNoOwner } from '@/utils/helpers'
import { gtmStacksEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyPageCatalogRepositories',
  components: {
    CyCopyButton,
    CyDataTableYdApi,
    CyTagList,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.catalogRepositories'), [
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.stacksSection'),
      description: {
        text: this.$t('routes.stacksSectionDescription'),
        link: this.$docLinks.stacks.overview,
      },
    }
  },
  data: () => ({
    showLegend: false,
    refreshingCR: {},
    showDeleteModal: false,
    toDelete: [],
    isDeleting: false,
    refreshedRepository: null,
    repositoryStackChanges: [],
  }),
  computed: {
    ...mapState('organization', {
      catalogRepositories: (state) => state.available.catalogRepositories,
      credentialsErrors: (state) => state.errors.credentials,
    }),
    ...mapState('organization/catalogRepository', {
      errors: (state) => state.errors.catalogRepository,
    }),
    ...mapGetters('organization', [
      'getAvailableByCanonical',
    ]),
    $static () {
      return {
        gtmStacksEvents,
        headers: [
          {
            text: this.$t('name'),
            value: 'name',
            align: 'left',
          },
          {
            text: this.$t('Credential'),
            value: 'credential_canonical',
            align: 'left',
            sort: (a, b) => {
              const aName = this.getCredential(a)?.name || ''
              const bName = this.getCredential(b)?.name || ''
              return aName.localeCompare(bName)
            },
          },
          {
            text: this.$t('owner'),
            align: 'left',
            value: 'owner',
            sort: (a, b) => displayName(a).localeCompare(displayName(b)),
          },
          {
            text: this.$t('forms.fieldBranch'),
            value: 'branch',
            align: 'left',
          },
          {
            text: this.$t('untranslated.URL'),
            value: 'url',
            align: 'left',
          },
          {
            text: '',
            value: 'refreshCR',
            align: 'right',
            sortable: false,
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'url',
            label: this.$t('untranslated.URL'),
          },
          {
            name: 'owner',
            label: this.$t('owner'),
            filterFunction: displayName,
          },
        ],
      }
    },
    stackVariant () {
      return (typeOfChange) => ({
        added: 'secondary',
        updated: 'primary',
        deleted: 'default',
      }[typeOfChange])
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('DeleteServiceCatalogSource')
      return canDelete && !_.isEmpty(this.catalogRepositories)
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'credentials' })
  },
  destroyed () {
    this.RESET_CR_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
      'BULK_DELETE',
    ]),
    ...mapActions('organization/catalogRepository', [
      'REFRESH_CATALOG_REPOSITORY',
      'UPDATE_CATALOG_REPOSITORY',
    ]),
    ...mapMutations('organization/catalogRepository', [
      'RESET_CR_STATE',
    ]),
    getLinkTarget ({ canonical: catalogRepositoryCanonical } = {}) {
      return {
        name: 'catalogRepository',
        params: { catalogRepositoryCanonical },
      }
    },
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.showDeleteModal(true)
    },
    closeDeleteModal () {
      this.$resetData('toDelete')
      this.$toggle.showDeleteModal(false)
    },
    async onDelete () {
      const { toDelete } = this
      this.$toggle.isDeleting(true)
      await this.BULK_DELETE({ keyPath: 'catalogRepositories', toDelete })
      this.$toggle.isDeleting(false)
      this.$toggle.showDeleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    async refreshCR ({ canonical, name }) {
      if (this.refreshingCR[canonical]) return

      this.$set(this.refreshingCR, canonical, true)
      const res = await this.REFRESH_CATALOG_REPOSITORY({ canonical, name })
      if (res) {
        this.refreshedRepository = canonical
        this.repositoryStackChanges = Object.entries(res).filter(([_, changes]) => changes.length)
      }
      this.$set(this.refreshingCR, canonical, false)
    },
    async assignNewOwner ({ formContent, owner }) {
      const catalogRepository = {
        ...formContent,
        owner: owner?.username,
      }
      const successMessage = this.$t('alerts.success.catalogRepository.reassigned', {
        catalogRepoName: formContent.name,
        owner: displayName(owner),
      })
      await this.UPDATE_CATALOG_REPOSITORY({ catalogRepository, successMessage })
      if (_.isEmpty(this.errors)) this.$refs.cyDataTable.retrieveItems()
    },
    canGoToCredential ({ canonical }) {
      return _.some([
        this.$cycloid.permissions.canDisplay('ListCredentials', canonical),
        this.$cycloid.permissions.canDisplay('GetCredential', canonical),
      ])
    },
    getCredential (canonical) {
      return this.getAvailableByCanonical('credentials', canonical) || {}
    },
    setMissingOwners (items) {
      for (const item of items) item.owner = getMissingOwnerObject()
    },
    canGoToMember ({ owner }) {
      return !hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('GetOrgMember', owner?.username)
    },
    canUpdateOwner ({ canonical, owner }) {
      return hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('UpdateServiceCatalogSource', canonical)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.catalogRepositories',
        addCatalogRepository: 'Add catalog repository',
        areYouSure: 'Are you really sure you want to delete <b>{item}</b>? | Are you really sure you want to delete the following catalog repositories?',
        confirmDeleteRepository: 'Please note that this does not delete the associated git repositories. If you want to do that as well, you\'ll have to do it manually afterwards.',
        confirmDeleteTitle: '@:forms.btnDelete catalog repository | @:forms.btnDelete catalog repositories',
        deleteCatalogReposBtn: '@:forms.btnDelete {nb} catalog repository | @:forms.btnDelete {nb} catalog repositories',
        legendTextBegin: 'A catalog repository is one of your git repositories, that we will use to create Stacks within.<br/>You can see Stacks as a template covering both infra, applicative and CI/CD layer. You can use Cycloid\'s official stacks, available in the public catalog repository or create your own, to reuse across your projects.<br/><br/>You can add public and private git repositories, however for private git repositories you will need to provide the corresponding',
        legendTextEnd: 'that will grant access to choose a @:forms.fieldBranch.',
        noStacksChanged: 'No stack changes were found',
        refreshCR: 'Refresh this repository',
        repoRefreshSuccess: '{repo} was refreshed successfully',
        whatIsCR: `What's a catalog repository?`,
      },
      es: {
        title: '@:routes.catalogRepositories',
        addCatalogRepository: 'Agregar repositorio de catálogo',
        areYouSure: '¿Está seguro de que quiere eliminar <b>{item}</b>? | ¿Está seguro de que quiere eliminar los siguientes repositorios de catálogo?',
        confirmDeleteRepository: 'Tenga en cuenta que esto no elimina los repositorios de git asociados. Si quiere hacer eso también, tendrá que hacerlo manualmente después.',
        confirmDeleteTitle: '@:forms.btnDelete repositorio de catálogo | @:forms.btnDelete repositorios de catálogo',
        deleteCatalogReposBtn: '@:forms.btnDelete {nb} repositorio de catálogo | @:forms.btnDelete {nb} repositorios de catálogo',
        legendTextBegin: 'Un repositorio de catálogo es uno de sus repositorios de git, que usaremos para crear Stacks dentro.<br/>Puede ver Stacks como una plantilla que cubre tanto la capa infra, aplicativa como CI / CD. Puede usar las stacks oficiales de Cycloid, disponibles en el catálogo público o crear las suyas, para reusarlas en sus projectos.<br/><br/>Todo lo que necesita para crear un repositorio de catálogos es un ',
        legendTextEnd: 'que otorgará acceso para elegir una @:forms.fieldBranch.',
        noStacksChanged: 'No se encontraron cambios en el stack',
        refreshCR: 'Actualizar este repositorio',
        repoRefreshSuccess: '{repo} se actualizó correctamente',
        whatIsCR: '¿Qué es un repositorio de catálogos?',
      },
      fr: {
        title: '@:routes.catalogRepositories',
        addCatalogRepository: 'Ajouter un référentiel de catalogue',
        areYouSure: 'Êtes-vous vraiment sûr de vouloir supprimer <b>{item}</b>? | Êtes-vous vraiment sûr de vouloir supprimer les référentiels de catalogue suivants?',
        confirmDeleteRepository: 'Veuillez noter que le dépôt git associé ne sera pas supprimé. Si vous souhaitez le faire également, vous devrez le supprimer manuellement par la suite.',
        confirmDeleteTitle: '@:forms.btnDelete un référentiel de catalogue | @:forms.btnDelete des référentiels de catalogue',
        deleteCatalogReposBtn: '@:forms.btnDelete {nb} référentiel de catalogue | @:forms.btnDelete {nb} référentiels de catalogue',
        legendTextBegin: `Un référentiel de catalogue est l'un de vos référentiels git, que nous utiliserons pour créer des piles à l'intérieur. <br/> Vous pouvez voir Stacks comme un modèle couvrant à la fois la couche infra, applicative et CI / CD.Vous pouvez utiliser les Stacks officielles de Cycloid, disponibles dans le catalogue publique ou bien créer les vôtres, pour les réutiliser dans vos projets. <br/> <br/> Tout ce dont vous avez besoin pour créer un référentiel de catalogue est un `,
        legendTextEnd: `qui accordera l'accès pour choisir une @:forms.fieldBranch.`,
        noStacksChanged: `Aucun changement de stack n'a été trouvé`,
        refreshCR: 'Mettre à jour les sources du catalogue',
        repoRefreshSuccess: '{repo} a été rafraîchie avec succès',
        whatIsCR: `Qu'est-ce qu'un référentiel de catalogue ?`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.show-legend {
  @include breakpoint("m") {
    max-width: 150px;
    margin-top: 12px;
    margin-right: 10px;
    text-align: center;
  }
}

.url {
  color: cy-get-color("grey", "dark-2");
}

::v-deep .refresh-cr-btn:not(:hover, :focus, :active) {
  .v-icon {
    color: cy-get-color("grey", "dark-1");
  }
}

.refresh-column-cell ::v-deep a {
  justify-content: end;
}

.credential-link {
  &__name {
    font-size: 14px;
    font-weight: $font-weight-default;
  }

  ::v-deep .credential-icon {
    margin-right: 8px !important;
  }
}
</style>

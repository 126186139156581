<template>
  <CyModal
    v-if="showModal"
    :header-title="$t('title')"
    :loading="updating"
    dialog-class="v-dialog--medium import-status__content"
    modal-type="info"
    large>
    <template slot="default">
      <CyAlert
        data-cy="infraimport-alert"
        v-bind="statusMessage"/>

      <CyCodeEditor
        ref="code-editor"
        :value="_.$isEmpty(parsedLog) ? importHasStartedMessage : parsedLog"
        :read-only="true"
        :class="['import-status__editor', { 'import-status__editor--with-error': editorWithError }]"
        :auto-scroll="autoScroll"
        @editor-scroll="handleScroll"
        @init="setCodeEditorHeight"/>
    </template>

    <div slot="modal-buttons">
      <CyButton
        v-show="hasCancelBtnVisible || !_.$isEmpty(errors)"
        icon="close"
        theme="primary"
        variant="secondary"
        data-cy="close-button"
        @click="SHOW_IMPORT_PROGRESS_MODAL(false)">
        {{ !_.$isEmpty(errors) ? $t('goBack') : $t('forms.btnClose') }}
      </CyButton>

      <CyButton
        v-show="hasActionBtnVisible"
        class="ml-4"
        :loading="updating"
        icon="check"
        data-cy="submit-button"
        @click="goTo">
        {{ isProjectImport ? $t('actionBtnProjectText') : $t('actionBtnStackText') }}
      </CyButton>

      <CyButton
        v-show="hasRetryBtnVisible"
        class="ml-4"
        :loading="updating"
        icon="refresh"
        data-cy="retry-button"
        @click="retryImport(canonicalOrRef)">
        {{ $t('retry') }}
      </CyButton>
    </div>
  </CyModal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CyCodeEditor from '@/components/CyCodeEditor.vue'
import { anyChecksPass } from '@/utils/helpers'

export const REFRESH_INTERVAL_TIMER = 800
export const CODE_EDITOR_HEIGHT = 300

export default {
  name: 'CyInfraImportProgressModal',
  components: {
    CyCodeEditor,
  },
  props: {
    canonicalOrRef: {
      type: String,
      required: true,
    },
    isProjectImport: {
      type: Boolean,
      default: false,
    },
    hasActionBtnVisible: {
      type: Boolean,
      default: false,
    },
    hasCancelBtnVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    autoScroll: true,
    updating: false,
    showModal: true,
    parsedLog: '',
  }),
  computed: {
    ...mapState('organization/infraImport', {
      infraImportStatus: (state) => state.infraImportStatus,
      errors: (state) => state.errors.infraImport,
    }),
    currentInfraImport () {
      const status = this.infraImportStatus[this.isProjectImport ? 'projects' : 'stacks']

      return status[this.canonicalOrRef] || { logs: '' }
    },
    hasRetryBtnVisible () {
      return this.currentInfraImport?.status === 'failed'
    },
    importHasStartedMessage () {
      return !_.$isEmpty(this.errors) ? '' : this.$t('gettingImportLogs')
    },
    statusMessage () {
      const { errors, currentInfraImport } = this

      return _.$isEmpty(errors)
        ? {
            failed: { theme: 'error', content: currentInfraImport.error },
            importing: { theme: 'info', content: this.$t('infraImportStarted') },
            succeeded: { theme: 'success', content: this.$t('infraImportFinished') },
          }[currentInfraImport.status || 'importing']
        : { theme: 'error', content: errors }
    },
    editorWithError () {
      const { currentInfraImport, parsedLog, autoScroll } = this

      return !_.$isEmpty(currentInfraImport.error) && !_.isEmpty(parsedLog) && autoScroll
    },
  },
  async mounted () {
    window.clearInterval(this.refreshIntervalID)
    this.refreshIntervalID = window.setInterval(() => this.getImportLogs(), REFRESH_INTERVAL_TIMER)
  },
  beforeDestroy () {
    window.clearInterval(this.refreshIntervalID)
  },
  methods: {
    ...mapMutations('organization/infraImport', [
      'SHOW_IMPORT_PROGRESS_MODAL',
      'RESET_STATE',
    ]),
    ...mapActions('organization/infraImport', [
      'GET_STACK_INFRA_IMPORT_STATUS',
      'GET_PROJECT_INFRA_IMPORT_STATUS',
      'RETRY_PROJECT_INFRA_IMPORT',
      'RETRY_STACK_INFRA_IMPORT',
    ]),
    goTo () {
      const page = this.isProjectImport ? 'projects' : 'stacks'
      this.SHOW_IMPORT_PROGRESS_MODAL(false)
      this.RESET_STATE()

      this.$router.push({
        name: page,
        query: {
          'infra-import': true,
          canonical: this.isProjectImport ? this.canonicalOrRef : this.canonicalOrRef.split(':')[1],
        },
      })
    },
    parseLog (logs = '') {
      const logArray = logs.match(/(.+\n)|(\r.+\n)|(\r.+)/g) || []

      return _.reduce(logArray, (parsedLogs, logLine, idx) => {
        if (anyChecksPass({
          hasNoCarriageReturn: !logLine.includes('\r'),
          hasNewLineOrCarriageReturn: logLine.includes('\r') && logLine.includes('\n'),
          isLastLogRow: !logArray[idx + 1],
        })) parsedLogs += logLine

        return parsedLogs
      }, '')
    },
    async getImportLogs () {
      this.isProjectImport
        ? await this.GET_PROJECT_INFRA_IMPORT_STATUS({ canonical: this.canonicalOrRef })
        : await this.GET_STACK_INFRA_IMPORT_STATUS({ stackRef: this.canonicalOrRef })

      if (['succeeded', 'failed'].includes(this.currentInfraImport.status)) window.clearInterval(this.refreshIntervalID)

      this.parsedLog = this.parseLog(this.currentInfraImport.logs)

      const { error = null } = this.currentInfraImport
      if (!_.$isEmpty(error) && !_.isEmpty(this.parsedLog)) this.parsedLog += `\n ${error}\n`
    },
    /**
    * The code editor height is set dynamically to ensure that the logic in handleScroll method works
    * even if the height is changed.
    */
    setCodeEditorHeight () {
      this.$refs['code-editor'].$el.style.setProperty('--code-editor-height', `${CODE_EDITOR_HEIGHT}px`)
    },
    handleScroll (scrollTop, editorContentHeight) {
      const hasMoreContentThanEditorHeight = editorContentHeight > CODE_EDITOR_HEIGHT
      const isNotScrolledToBottom = editorContentHeight - (scrollTop + CODE_EDITOR_HEIGHT) > 0

      if (hasMoreContentThanEditorHeight && isNotScrolledToBottom) {
        this.autoScroll = false
        return
      }

      this.autoScroll = true
    },
    async retryImport (canonicalOrRef) {
      this.updating = true
      this.parsedLog = ''
      this.isProjectImport
        ? await this.RETRY_PROJECT_INFRA_IMPORT({ canonical: canonicalOrRef })
        : await this.RETRY_STACK_INFRA_IMPORT({ stackRef: canonicalOrRef })

      await this.getImportLogs()
      this.updating = false
    },
  },
  i18n: {
    messages: {
      en: {
        actionBtnProjectText: 'Go to Projects page',
        actionBtnStackText: 'Go to Stacks page',
        gettingImportLogs: 'Getting the import logs...',
        goBack: 'Go back',
        infraImportFinished: 'The import was successful!',
        infraImportStarted: `The infrastructure import is in progress. It may take a while, depending on the amount of imported resources. You can close the modal while the import continues in the background. You will receive an email notification once it's finished.`,
        retry: 'Retry import',
        title: '@:InfraImport progress',
      },
      es: {
        actionBtnProjectText: 'Ir a la página de proyectos',
        actionBtnStackText: 'Ir a la página de Stacks',
        gettingImportLogs: 'Obteniendo los registros de importación...',
        goBack: 'Regresar',
        infraImportFinished: '¡La importación se ha realizado correctamente!',
        infraImportStarted: 'Importación de la infraestructura en curso. Esto puede llevar un tiempo dependiendo de la cantidad de recursos importados. Puede cerrar el modal mientras la importación continúa en segundo plano. Recibirá una notificación por correo electrónico una vez que haya terminado.',
        retry: 'Reintenta la importación',
        title: 'Progreso de @:InfraImport',
      },
      fr: {
        actionBtnProjectText: 'Aller à la page des projets',
        actionBtnStackText: 'Aller à la page Stacks',
        gettingImportLogs: `Obtention des logs d'importation...`,
        goBack: 'Retour',
        infraImportFinished: `L'importation à été un succès!`,
        infraImportStarted: `L'import de l'infrastructure est en cours. Cela peut prendre un certain temps, selon la quantité de ressources importées. Vous pouvez fermer le modal pendant que l'importation se poursuit en arrière-plan. Vous recevrez une notification par e-mail une fois l'opération terminée.`,
        retry: `Réessayez l'import`,
        title: `Progression d' @:InfraImport`,
      },
    },
  },
}
</script>

<style lang="scss"> /* FIXME: scope!! */
.import-status__content {
  .v-card__text {
    min-height: calc(var(--code-editor-height) + 92px) !important;
  }
}
</style>

<style lang="scss" scoped>
.import-status {
  &__editor {
    height: var(--code-editor-height);
    min-height: var(--code-editor-height);
  }

  &__editor--with-error {
    ::v-deep {
      .ace_line:nth-last-child(2)::before {
        content: "\25CF  Error:";
        color: cy-get-color("build", "failed-faded");
      }
    }
  }
}
</style>

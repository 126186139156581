import _ from '@/utils/lodash'
import en from './_en'
import es from './_es'
import fr from './_fr'
import untranslated from './untranslated'

const flatUntranslated = _.$flattenObject({ untranslated })

const locales = {
  en: {
    ...en,
    ...flatUntranslated,
  },
  es: {
    ...es,
    ...flatUntranslated,
  },
  fr: {
    ...fr,
    ...flatUntranslated,
  },
}

export default locales

<template>
  <div>
    <v-expansion-panels
      v-if="!runService"
      class="py-4"
      accordion
      :value="0">
      <v-expansion-panel v-if="hasForms">
        <v-expansion-panel-header class="panel-header">
          <div class="panel-header__text">
            <span class="panel-header__title mr-4">
              {{ $t('configurationSummary') }}
            </span>
            <span class="panel-header__subtitle">
              {{ $tc('environmentCount', environments.length) }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CyWizardFormsSummary :forms-config="rawFormsConfig"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="quotasEnabled">
        <v-expansion-panel-header class="panel-header">
          <div class="panel-header__text">
            <span class="panel-header__title mr-4">
              {{ $t('untranslated.quotas') }}
            </span>
            <span class="panel-header__subtitle">
              {{ getTotalsText }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="quotas-summary__content">
          <v-data-table
            class="elevation-1"
            hide-default-footer
            :items-per-page="parsedQuotas.length"
            :headers="$static.headers"
            :items="parsedQuotas">
            <template #[`item.environment`]="{ item }">
              <span class="font-weight-bold">
                {{ item.environment }}
              </span>
            </template>
            <template #[`item.resourcePoolName`]="{ item }">
              <CyTag variant="default">
                {{ item.resourcePoolName }}
              </CyTag>
            </template>
            <template #[`body.append`]>
              <tr class="quotas-summary__footer">
                <td>{{ $t('totalConsumption') }}</td>
                <td/>
                <td>{{ quotaTotals.cpu }}</td>
                <td>{{ quotaTotals.memory }}</td>
                <td>{{ quotaTotals.storage }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="hasForms">
        <v-expansion-panel-header class="panel-header">
          <div class="panel-header__text">
            <span class="panel-header__title mr-4">
              {{ $t('costOverview') }}
            </span>
            <span
              v-if="!_.some(estimatesLoading)"
              class="panel-header__subtitle mr-4">
              {{ monthlyCostEstimate }} / {{ $t('month') }}
            </span>
            <v-progress-circular
              v-else
              indeterminate
              color="secondary"
              :size="24"
              :width="4"/>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <CyTerracostPricingSummary
            :environments-usecase-key="environmentsUsecaseKey"
            :forms-config="rawFormsConfig"
            :stack-ref="service.catalog.ref"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="panel-header">
          <div class="panel-header__text">
            <span class="panel-header__title mr-4">
              {{ $t('service') }}
            </span>
            <span
              v-if="service"
              class="panel-header__subtitle">
              {{ service.catalog.name }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <CyWizardServiceCard
                v-if="service && service.catalog"
                :service="service.catalog"
                class="ma-4 pb-4"
                horizontal/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <v-row
        v-if="service.catalog && runService"
        class="ma-4">
        <v-col
          cols="12"
          sm="8"
          offset-sm="2">
          <v-card data-cy="run-service-card">
            <v-list subheader>
              <v-subheader>
                {{ $t('runServiceTitle', { service: service.catalog.canonical }) }}
              </v-subheader>
              <CyAlert
                v-if="errors"
                class="mb-4 mx-2 width-80"
                theme="error"
                :content="errors"/>
              <v-list-item v-if="updateProjectStep">
                <v-list-item-action>
                  <v-icon :color="getServiceStatusColor(updateProjectStep)">
                    {{ getServiceStatusIcon(updateProjectStep) }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $isCreationRoute ? $t('creatingProject') : $t('updatingProject') }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="!_.isEmpty(errors)">
                    {{ $t('failed') }}
                    {{ !_.isEmpty(runService.context) ? `- ${runService.context}` : '' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else-if="updateProjectStep.msg">
                    {{ updateProjectStep.msg }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="updateProjectStep.state === 'loading' && _.isEmpty(errors)">
                  <v-progress-circular
                    data-cy="progress-bar"
                    indeterminate
                    color="secondary"/>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="text-left">
      <CyButton
        v-if="!runService || runService.state === 'error'"
        data-cy="prev-step"
        @click="runService === null ? $emit('back') : runService = null">
        {{ $t('forms.back') }}
      </CyButton>
      <CyButton
        v-if="!runService"
        v-has-rights-to="$isCreationRoute ? 'CreateProject' : 'UpdateProject'"
        theme="success"
        :disabled="!configRepositoryCanonical"
        data-cy="run-button"
        @click="runApp">
        <v-icon>play_arrow</v-icon>
        {{ $t('run') }}
      </CyButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import CyTerracostPricingSummary from '@/components/CyTerracostPricingSummary.vue'
import CyWizardFormsSummary from '@/components/CyWizardFormsSummary.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import { formatBytes } from '@/utils/helpers/quotas'
import * as yaml from 'js-yaml'

export const EXCLUDED_PROPERTIES = ['name', 'description']

export default {
  name: 'CyWizardRunService',
  components: {
    CyWizardServiceCard,
    CyWizardFormsSummary,
    CyTerracostPricingSummary,
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    environments: {
      type: Array,
      default: null,
    },
    environmentsUsecaseKey: {
      type: Object,
      default: null,
    },
    rawFormsConfig: {
      type: Object,
      default: null,
    },
    hasForms: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      default: null,
    },
    pipelines: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    localErrors: [],
    runService: null,
    updateProjectStep: {},
  }),
  computed: {
    $static () {
      return {
        headers: [
          {
            text: this.$t('Environment'),
            value: 'environment',
          },
          {
            text: this.$t('ResourcePool'),
            value: 'resourcePoolName',
            sortable: false,
          },
          {
            text: this.$t('quotas.cpu'),
            value: 'cpu',
            sortable: false,
          },
          {
            text: this.$t('quotas.memory'),
            value: 'memory',
            sortable: false,
          },
          {
            text: this.$t('quotas.storage'),
            value: 'storage',
            sortable: false,
          },
        ],
      }
    },
    ...mapState({
      configRepositoryErrors: (state) => state.organization.project.configRepository.errors.configRepository,
      envsResourcePool: (state) => state.organization.quota.envsResourcePool,
      estimates: (state) => state.organization.terracost.estimates,
      estimatesLoading: (state) => state.organization.terracost.loading,
      formsErrors: (state) => state.organization.project.errors.forms,
      pipelineErrors: (state) => state.organization.project.pipeline.errors.pipeline,
      pipelinesErrors: (state) => state.organization.project.errors.pipelines,
      projectErrors: (state) => state.organization.project.errors.project,
      quotaRequirements: (state) => state.organization.quota.requirements,
      quotas: (state) => state.organization.available.quotas,
    }),
    ...mapGetters('organization/project', [
      'configRepositoryBranch',
      'configRepositoryCanonical',
      'project',
    ]),
    ...mapGetters('organization/quota', [
      'getTotalsText',
    ]),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    errors () {
      return _.find([
        this.localErrors,
        this.projectErrors,
        this.pipelinesErrors,
        this.pipelineErrors,
        this.configRepositoryErrors,
        this.formsErrors,
      ], (errors) => !_.isEmpty(errors)) || []
    },
    monthlyCostEstimate () {
      const getCostSum = (array, key) => _.sumBy(array, (item) => Number(item[key]))
      const monthlyTotal = _.reduce(this.estimates, (sum, modules) => sum + getCostSum(modules, 'planned_cost'), 0)
      return _.isNaN(monthlyTotal) || _.isNull(monthlyTotal) ? '--' : `$${_.round(monthlyTotal, 3)}`
    },
    formInputs () {
      return _.isEmpty(this.rawFormsConfig)
        ? {}
        : Object.entries(this.rawFormsConfig).map(([env, vars = {}]) => ({
          use_case: this.pipelines[env].useCase,
          environment_canonical: env,
          resource_pool_canonical: this.envsResourcePool[env],
          vars,
        })).filter(({ vars }) => !_.isEmpty(vars))
    },
    parsedQuotas () {
      return _.toPairs(this.envsResourcePool).map(([env, resourcePoolCanonical]) => {
        if (!this.quotaRequirements[env]) return undefined

        const resourcePool = _.find(this.quotas, ['resource_pool.canonical', resourcePoolCanonical])?.resource_pool

        const cpu = this.quotaRequirements[env].cpu
        const memory = this.quotaRequirements[env].memory
        const storage = this.quotaRequirements[env].storage

        return {
          environment: env,
          resourcePoolName: resourcePool.name,
          cpu: `${cpu} ${this.$t('quotas.cores').toLowerCase()}`,
          memory: formatBytes(memory),
          storage: formatBytes(storage),
        }
      }).filter((it) => it)
    },
    quotaTotals () {
      const allRequirements = _.values(this.quotaRequirements)

      const cpu = _.sumBy(allRequirements, 'cpu')
      const memory = _.sumBy(allRequirements, 'memory')
      const storage = _.sumBy(allRequirements, 'storage')

      return {
        cpu: `${cpu} ${this.$t('quotas.cores').toLowerCase()}`,
        memory: formatBytes(memory),
        storage: formatBytes(storage),
      }
    },
    quotasEnabled () {
      return this.organization.quotas && this.stack?.quota_enabled
    },
  },
  methods: {
    ...mapActions('organization/project', [
      'CREATE_PROJECT',
      'UPDATE_PROJECT',
    ]),
    ...mapActions('organization/project/pipeline', [
      'CREATE_PIPELINE',
      'UNPAUSE_PIPELINE',
    ]),
    ...mapActions('organization/project/configRepository', [
      'CREATE_CONFIG_REPOSITORY_FILES',
    ]),
    ...mapMutations('organization/project', [
      'SET_PROJECT',
      'CLEAR_PROJ_ERRORS',
    ]),
    ...mapMutations('organization/project/configRepository', [
      'CLEAR_CR_ERRORS',
    ]),
    ...mapMutations('organization/project/pipeline', [
      'CLEAR_PIPELINE_ERRORS',
    ]),
    getServiceStatusIcon ({ state }) {
      return {
        error: 'error',
      }[state] || 'check_circle'
    },
    getServiceStatusColor ({ state }) {
      return {
        done: 'green',
        loading: 'gray',
        error: 'red',
      }[state] || ''
    },
    async pushConfigRepositoryFiles () {
      const { configRepositoryCanonical } = this
      // Do not push config files if no CR has been defined
      if (!configRepositoryCanonical) return true

      const configObject = {
        configs: this.getUserConfigsWithDestination(this.pipelines),
        branch: this.configRepositoryBranch,
      }

      await this.CREATE_CONFIG_REPOSITORY_FILES({ configRepositoryCanonical, configObject })
      await this.$nextTick()

      if (!_.isEmpty(this.configRepositoryErrors)) this.setRunServiceState({ state: 'error', context: 'pushConfigRepositoryFiles' })
      return _.isEmpty(this.configRepositoryErrors)
    },
    getUserConfigsWithDestination (configs, array = []) {
      _.entries(configs).forEach(([key, value]) => {
        if (!_.isPlainObject(value)) return

        const { content, destination: path } = value
        path
          ? array.push({ content: btoa(unescape(encodeURIComponent(content))), path })
          : this.getUserConfigsWithDestination(configs[key], array)
      })

      return array
    },
    getNewProject () {
      const pipelines = this.getProjectPipelines()
      const project = _.cloneDeep(this.project)

      return _.isEmpty(pipelines)
        ? null
        : _.merge(project, {
          service_catalog_ref: this.service.catalog.ref,
          pipelines,
          ...(this.hasForms ? { inputs: this.formInputs } : {}),
        })
    },
    getUpdatedProject () {
      const newEnvironments = this.environments.map((env) => {
        const useCase = this.pipelines[env].useCase
        const cloudProvider = _.get(this.service.config[useCase], 'cloud_provider')
        return {
          canonical: env,
          ...(cloudProvider
            ? { cloud_provider_canonical: cloudProvider }
            : {}
          ),
        }
      })
      const environments = _.uniqBy([...this.project.environments, ...newEnvironments], 'canonical')
      const project = _.cloneDeep(this.project)

      return _.merge(project, {
        environments,
        ...(this.hasForms ? { inputs: this.formInputs } : {}),
      })
    },
    async updateProjectPipelines () {
      const pipelines = this.getProjectPipelines()
      this.SET_PROJECT({ ...this.project, pipelines })

      for (const pipeline of pipelines) {
        if (!await this.CREATE_PIPELINE(pipeline)) return false
        if (!await this.UNPAUSE_PIPELINE({ pipelineCanonical: pipeline.pipeline_name })) return false
      }
      return true
    },
    getProjectPipelines () {
      const pipelines = []

      for (const environment of this.environments) {
        try {
          const pipelineName = `${this.projectCanonical}-${environment}`
          const useCase = this.pipelines[environment].useCase
          const cloudProvider = _.get(this.service.config[useCase], 'cloud_provider')

          const pipelineConfig = !this.hasForms ? JSON.stringify(yaml.load(this.pipelines[environment].config.pipeline.pipeline.content)) : null
          const variables = !this.hasForms ? this.pipelines[environment].config.pipeline.variables.content : null

          pipelines.push({
            pipeline_name: pipelineName,
            use_case: useCase,
            environment: {
              canonical: environment,
              ...(cloudProvider
                ? { cloud_provider_canonical: cloudProvider }
                : {}
              ),
            },
            ...(!this.hasForms ? { passed_config: pipelineConfig, yaml_vars: variables } : {}),
          })
        } catch ({ code, name, message }) {
          this.localErrors.push({ code: code || name, message })
          return this.setRunServiceState({ state: 'error', context: 'parsePipelineConfig' })
        }
      }

      return pipelines
    },
    async runApp () {
      let callNextRequest = true

      this.CLEAR_PROJ_ERRORS()
      this.CLEAR_CR_ERRORS()
      this.CLEAR_PIPELINE_ERRORS()
      this.localErrors = []
      this.runService = {}
      this.updateProjectStep = {
        state: 'loading',
        msg: this.$t('pushingConfiguration'),
      }

      // Projects without forms, require to populate the creation of config repo first
      if (!this.hasForms) callNextRequest = await this.pushConfigRepositoryFiles()

      if (callNextRequest) {
        if (this.$isCreationRoute) {
          const project = this.getNewProject()
          if (!project) callNextRequest = false

          this.updateProjectStep.msg = this.$t('creatingProject')
          if (callNextRequest) callNextRequest = await this.CREATE_PROJECT(project)
        } else {
          const project = this.getUpdatedProject()
          if (!project) callNextRequest = false

          const envsLength = this.environments.length
          const envs = _.$getListFromArray(this.environments, { boldItems: true })
          const successMessage = this.$tc('alerts.success.project.env.created', envsLength, [envs])
          this.updateProjectStep.msg = this.$t('updatingProject')
          if (callNextRequest) callNextRequest = await this.UPDATE_PROJECT({ project, successMessage })
          if (!this.hasForms && callNextRequest) {
            callNextRequest = await this.updateProjectPipelines()
            !callNextRequest && this.setRunServiceState({ state: 'error', context: 'parsePipelineConfig' })
          }
        }
      }

      if (callNextRequest) {
        this.setRunServiceState({ state: 'done' })
        this.$emit('done')
      }
    },
    setRunServiceState ({ state = 'error', msg = '', context }) {
      this.$set(this, 'updateProjectStep', { state, msg })
      this.runService = {
        state,
        msg,
        context: !_.isEmpty(context) ? this.$t(`context.${context}`) : '',
      }
    },
  },
  i18n: {
    messages: {
      en: {
        configurationSummary: 'Configuration summary',
        configRepositoryHint: {
          create: 'Choose the Config Repository that will serve as storage for your config files.',
          update: 'The Config Repository that will serve as storage for your config files.',
        },
        context: {
          parsePipelineConfig: 'Parsing pipeline config',
          processStackFormsConfig: 'Processing StackForms configuration',
          pushConfigRepositoryFiles: 'Pushing Config Repository files',
        },
        costOverview: 'Cost overview',
        creatingProject: 'Creating project',
        envInstructions: 'Copy the code shown below and replace the files in your repository via the indicated path.',
        failed: 'Failed',
        month: 'month',
        pushingConfiguration: 'Pushing configuration to the repository',
        run: 'Run',
        runServiceTitle: 'Run service {service}',
        selectAnItem: 'Please select an item',
        service: '@:cloudCostManagement.groupBy.service',
        totalConsumption: 'Total consumption',
        updatingProject: 'Adding new environments',
      },
      es: {
        configRepositoryHint: {
          create: 'Elija el repositorio de configuración que servirá como almacenamiento para sus archivos de configuración.',
          update: 'El repositorio de configuración que servirá como almacenamiento para sus archivos de configuración.',
        },
        configurationSummary: 'Resumen de la configuración',
        context: {
          parsePipelineConfig: 'Configuración de la pipeline',
          processStackFormsConfig: 'Procesando la configuración de StackForms',
          pushConfigRepositoryFiles: 'Push de la configuración en el repositorio de configuración',
        },
        costOverview: 'Resumen de costos',
        creatingProject: 'Creando el proyecto',
        envInstructions: 'Copie el código abajo y reemplace los archivos en su repositorio en la ruta indicada.',
        failed: 'Fallado',
        invalidPipeline: 'La configuración de la pipeline no es válida',
        month: 'mes',
        pushingConfiguration: 'Empujando la configuración ar repositorio',
        run: 'Ejecutar',
        runServiceTitle: 'Creando servicio {service}',
        selectAnItem: 'Por favor seleccione un elemento',
        service: '@:cloudCostManagement.groupBy.service',
        totalConsumption: 'Consumo total',
        updatingProject: 'Añadindo los nuevos entornos',
      },
      fr: {
        configRepositoryHint: {
          create: 'Choisissez la source de configuration qui servira de stockage pour vos fichiers de configuration.',
          update: 'La source de configuration qui servira de stockage pour vos fichiers de configuration.',
        },
        configurationSummary: 'Résumé de la configuration',
        context: {
          parsePipelineConfig: 'Configuration du pipeline',
          processStackFormsConfig: 'Traitement de la configuration de StackForms',
          pushConfigRepositoryFiles: 'Push de la configuration dans le référentiel de configuration',
        },
        costOverview: 'Aperçu des coûts',
        creatingProject: 'Création du projet',
        envInstructions: 'Copiez le code ci-dessous et remplacez les fichiers dans votre repository dans le chemin indiqué.',
        failed: 'Échoué',
        month: 'mois',
        pushingConfiguration: 'Pousser la configuration vers le référentiel',
        run: 'Créer le service',
        runServiceTitle: 'Création du service {service}',
        selectAnItem: 'Veuillez sélectionner une source',
        service: '@:cloudCostManagement.groupBy.service',
        totalConsumption: 'Consommation totale',
        updatingProject: 'Ajout des nouveaux environnements',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-expansion-panels {
    .v-expansion-panel:first-child {
      border-top: 1px solid cy-get-color("black", $alpha: 0.15);
    }

    .v-expansion-panel:last-child {
      border-bottom: 1px solid cy-get-color("black", $alpha: 0.15);
    }
  }

  .panel-header {
    &__text {
      display: flex;
      align-items: center;
      height: 24px;
    }

    &__title {
      color: cy-get-color("primary", "light-2");
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;
    }

    &__subtitle {
      color: cy-get-color("primary", "light-3");
      font-size: $font-size-sm;
      font-weight: $font-weight-default;
    }

    ::v-deep .v-expansion-panel-header__icon {
      order: -1;
    }
  }

  ::v-deep {
    .quotas-summary {
      &__content {
        .v-expansion-panel-content__wrap {
          padding-right: 2px;
          padding-left: 2px;
        }
      }

      &__footer {
        background-color: cy-get-color("grey", "light-4");
        color: cy-get-color("primary");
        font-weight: $font-weight-bolder;
      }
    }
  }

  .v-select-list {
    ::v-deep {
      .v-list__tile__subtitle {
        color: cy-get-color("primary", "light-2");
      }
    }
  }

  .border-top {
    border-top: 1px dotted cy-get-color("grey");
  }

  .config-repository-select {
    max-width: 500px;
  }

  .section-title {
    text-transform: uppercase;
  }

  .pipeline {
    display: flex;
    align-items: center;

    &__name {
      margin-left: 16px;
      font-weight: $font-weight-bold;
    }
  }
</style>

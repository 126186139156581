<template>
  <div :class="{ 'cy-infra-import__zero-state': !hasImportWizardActive }">
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="secondary"/>

    <component
      v-else
      :is="component"/>

    <CyInfraImportProgressModal
      v-if="showImportProgressModal"
      data-cy="progress-modal"
      has-action-btn-visible
      :canonical-or-ref="projectCanonicalOrStackRef"
      :is-project-import="!createStackOnly"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageInfraImport',
  components: {
    CyInfraImportProgressModal: () => import('@/components/CyInfraImportProgressModal.vue'),
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.infraImport'), [
      {
        label: this.$t('routes.infraTools'),
        name: 'infraTools',
      },
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.infraImport'),
      description: {
        text: this.$t('routes.infraImportDescription'),
        link: this.$docLinks.infraImport.index,
      },
    }
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters('organization', [
      'hasAvailable',
    ]),
    ...mapState('organization/infraImport', {
      hasImportWizardActive: (state) => state.wizardConfig.active,
      showImportProgressModal: (state) => state.showImportProgressModal,
      createStackOnly: (state) => state.wizardConfig.createStackOnly,
      stack: (state) => state.stack,
      project: (state) => state.project,
    }),
    component () {
      return this.hasImportWizardActive
        ? () => import('@/components/CyInfraImportWizard.vue')
        : () => import('@/components/CyInfraImportZeroState.vue')
    },
    projectCanonicalOrStackRef () {
      return this.createStackOnly
        ? `${this.organization?.canonical}:${this.stack?.canonical}`
        : this.project?.canonical
    },
  },
  async mounted () {
    await this.RESET_STATE()
    await this.fetchDependencies()
    this.loading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('organization/infraImport', [
      'RESET_STATE',
    ]),
    async fetchDependencies () {
      const { hasAvailable } = this

      await Promise.allSettled([
        ...(!hasAvailable('credentials') ? [this.FETCH_AVAILABLE({ keyPath: 'credentials' })] : []),
        ...(!hasAvailable('catalogRepositories') ? [this.FETCH_AVAILABLE({ keyPath: 'catalogRepositories' })] : []),
        ...(!hasAvailable('configRepositories') ? [this.FETCH_AVAILABLE({ keyPath: 'configRepositories' })] : []),
      ])
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:InfraImport integration',
      },
      es: {
        title: 'Integración @:InfraImport',
      },
      fr: {
        title: 'Intégration @:InfraImport',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-infra-import {
  &__zero-state {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
}
</style>

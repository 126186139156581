import { render, staticRenderFns } from "./CyCcmProviderDataTable.vue?vue&type=template&id=f3ab6824&scoped=true"
import script from "./CyCcmProviderDataTable.vue?vue&type=script&lang=js"
export * from "./CyCcmProviderDataTable.vue?vue&type=script&lang=js"
import style0 from "./CyCcmProviderDataTable.vue?vue&type=style&index=0&id=f3ab6824&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3ab6824",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VDataTable,VExpansionPanel,VExpansionPanelContent,VExpansionPanels,VIcon})

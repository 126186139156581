<template>
  <CyKpiWidgetsWidget
    v-bind="$attrs"
    :kpi="kpi"
    show-job
    v-on="$listeners">
    <div
      v-if="!_.isEmpty(kpi.data_set)"
      class="success-failure">
      <ECharts
        class="success-failure__chart"
        theme="cycloid"
        autoresize
        :option="options"/>

      <ul class="success-failure__aside list-style-none">
        <li class="success-failure__item">
          <table>
            <tbody>
              <tr
                v-for="status in ['succeeded', 'failed', 'errored', 'aborted']"
                :key="status">
                <td class="align-right pr-1">
                  {{ aggregate[status] }}
                </td>
                <td><span :class="['success-failure__status', `success-failure__status--${status}`]">{{ status }}</span></td>
              </tr>
            </tbody>
          </table>
        </li>
        <li class="success-failure__item">
          <CyKpiWidgetsNumber
            :value="aggregate.total"
            :label="$t('totalBuilds')"/>
        </li>
      </ul>
    </div>
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot"/>
      </template>
    </template>
  </CyKpiWidgetsWidget>
</template>

<script>
import CyKpiWidgetsNumber from '@/components/CyKpiWidgetsNumber.vue'
import CyKpiWidgetsWidget from '@/components/CyKpiWidgetsWidget.vue'

export default {
  name: 'CyKpiWidgetsSuccessFailure',
  components: {
    CyKpiWidgetsWidget,
    CyKpiWidgetsNumber,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    aggregate: {
      succeeded: null,
      failed: null,
      errored: null,
      aborted: null,
      total: null,
    },
  }),
  computed: {
    chartData () {
      return [{
        name: 'succeeded',
        value: this.aggregate.succeeded,
      }, {
        name: 'failed',
        value: this.aggregate.failed,
      }, {
        name: 'errored',
        value: this.aggregate.errored,
      },
      {
        name: 'aborted',
        value: this.aggregate.aborted,
      }]
    },
    options () {
      return {
        color: ['#51AF45', '#E6213C', '#E67D22', '#8F4A2D'],
        series: [
          {
            data: this.chartData,
            type: 'pie',
            silent: true,
            top: 32,
            right: 32,
            bottom: 32,
            left: 32,
            radius: '100%',
            label: {
              position: 'inside',
              fontSize: 14,
              fontStyle: 'bold',
              formatter: this.formatLabel,
            },
          },
        ],
      }
    },
  },
  watch: {
    'kpi.data_set': {
      immediate: true,
      handler (dataSet) {
        if (_.isEmpty(dataSet)) return
        this.setDataFromSource(dataSet)
      },
    },
  },
  methods: {
    setDataFromSource ([keys, ...rows]) {
      this.aggregate = _.reduce(
        rows,
        (aggregate, row) => _.mapValues(aggregate, (value, key) => value + row[keys.indexOf(key)])
        , {
          succeeded: 0,
          failed: 0,
          errored: 0,
          aborted: 0,
          total: 0,
        })
    },
    formatLabel ({ percent }) {
      return percent === 0 ? '' : `${percent}%`
    },
  },
  i18n: {
    messages: {
      en: {
        failedBuilds: 'Failed builds',
        successfulBuilds: 'Successful builds',
        totalBuilds: 'Total builds',
      },
      es: {
        failedBuilds: 'Compilaciones fallidas',
        successfulBuilds: 'Compilaciones exitosas',
        totalBuilds: 'Compilaciones totales',
      },
      fr: {
        failedBuilds: 'Builds échoués',
        successfulBuilds: 'Builds réussis',
        totalBuilds: 'Total des builds',
      },
    },
  },

}
</script>

<style lang="scss" scoped>
.success-failure {
  display: flex;

  &__chart {
    width: 244px;
    height: 244px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-left: 24px;
  }

  &__item {
    & + & {
      margin-top: 4px;
    }
  }

  &__icon {
    margin-right: 4px;
    margin-bottom: 3px;

    &--success {
      color: cy-get-color("success");
    }

    &--error {
      color: cy-get-color("error");
    }
  }

  &__status {
    padding: 0 4px;
    border-radius: 3px;
    background-color:
      hsl(
        var(--build-color-h)
        var(--build-color-s)
        clamp(70%, calc(var(--build-color-l) + 25%), 90%)
      );
    color:
      hsl(
        var(--build-color-h)
        calc(var(--build-color-s) + 10%)
        min(calc(var(--build-color-l) - 35%), 20%)
      );
    font-family: $font-family-code;

    @each $status in ("succeeded", "failed", "errored", "aborted") {
      &--#{$status} {
        $color: cy-get-color("build", $status);

        --build-color-h: #{color.hue($color)};
        --build-color-s: #{color.saturation($color)};
        --build-color-l: #{color.lightness($color)};
      }
    }
  }
}

.align-right {
  text-align: right;
}
</style>

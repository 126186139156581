<template>
  <div class="layout__container d-flex mb-n6 mr-n8 ml-n8">
    <div class="layout__content pl-8">
      <div class="d-flex flex-column flex-grow-1">
        <CyCcmGraphsSwitcher
          :loading="providerDataLoading"
          :series="series"
          :totals="seriesTotals"
          context="providerDetail">
          <template
            slot="header">
            <div
              class="d-flex flex-grow-1">
              <div class="d-flex align-center">
                {{ providerDateLabel }}
              </div>
            </div>
          </template>

          <template slot="footer">
            <CyCcmProviderDataTable
              ref="dataTable"
              class="layout__table elevation-0"
              :loading="providerDataLoading"
              :item-is-active="tableSidebarActive"
              :provider-canonical="providerCanonical"
              :totals="seriesTotals"
              :top-items="_.keys(getTrimmedSeries(series, graphOrderingParam))"
              @selected="setSidebarData"/>
          </template>
        </CyCcmGraphsSwitcher>

        <v-slide-x-reverse-transition>
          <CyCcmProviderDetailTableSidebar
            v-if="tableSidebarActive"
            v-click-outside="{
              handler: closeSidebar,
              include: getClickOutsideExceptions,
            }"
            :table-data="tableSidebarData"
            @close="closeSidebar"/>
        </v-slide-x-reverse-transition>
      </div>

      <CyCcmProviderDetailSummaryBanner
        :loading="providerDataLoading"
        class="mt-6"/>
    </div>

    <div class="layout__sidebar pb-6">
      <CyCcmProviderDetailFiltersSidebar @change="fetchProviderData"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import CyCcmGraphsSwitcher from '@/components/CyCcmGraphsSwitcher.vue'
import CyCcmProviderDataTable from '@/components/CyCcmProviderDataTable.vue'
import CyCcmProviderDetailFiltersSidebar from '@/components/CyCcmProviderDetailFiltersSidebar.vue'
import CyCcmProviderDetailSummaryBanner from '@/components/CyCcmProviderDetailSummaryBanner.vue'
import CyCcmProviderDetailTableSidebar from '@/components/CyCcmProviderDetailTableSidebar.vue'
import { parseToGraphFormat } from '@/utils/api/parsers/cloud-cost-management/providerHistograms'
import { constructBreadcrumb } from '@/utils/helpers'
import { getTrimmedSeries } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyPageCloudCostManagementProviderDetail',
  components: {
    CyCcmProviderDetailSummaryBanner,
    CyCcmProviderDetailFiltersSidebar,
    CyCcmProviderDataTable,
    CyCcmGraphsSwitcher,
    CyCcmProviderDetailTableSidebar,
  },
  breadcrumb () {
    const { canonicalDisplayName } = this
    const routeTitle = this.$t('routes.cloudCostManagementProviderDetail')
    const header = canonicalDisplayName
      ? `${canonicalDisplayName} ${routeTitle.toLowerCase()}`
      : routeTitle

    return constructBreadcrumb(this.$options.name, header, [
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagement'),
      },
    ])
  },
  header () {
    return {
      title: this.canonicalDisplayName,
      avatar: {
        type: 'providerIcon',
        item: this.providerCanonical,
      },
    }
  },
  data: () => ({
    providerDataLoading: false,
    tableSidebarActive: false,
    tableSidebarData: {},
  }),
  computed: {
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfo',
    ]),
    ...mapState('organization/cloudCostManagement', {
      graphOrderingParam: (state) => state.graphOrderingParam,
      providerData: (state) => state.providerData,
      histogramDates: (state) => state.histogramDates,
      queryBody: (state) => state.queryBody,
    }),
    canonicalDisplayName () {
      return _.find(this.getProviderExtraInfo, ['canonical', this.providerCanonical])?.displayName
    },
    providerCanonical () {
      return this.$route.params.providerCanonical
    },
    providerDateLabel () {
      const { begin = Date.now(), end = Date.now() } = this.queryBody
      const startDate = $date.format(new Date(begin), 'MMM dd, yyyy')
      const endDate = $date.format(new Date(end), 'MMM dd, yyyy')

      return `${startDate} - ${endDate}`
    },
    series () {
      return this.parseToGraphFormat(this.providerData, this.histogramDates)
    },
    seriesTotals () {
      return this.histogramDates.map((date) => {
        const cost = _.sumBy(_.values(this.series), (amounts) => _.find(amounts, ['date', date])?.cost)
        const co2e = _.sumBy(_.values(this.series), (amounts) => _.find(amounts, ['date', date])?.co2e)
        const kwh = _.sumBy(_.values(this.series), (amounts) => _.find(amounts, ['date', date])?.kwh)
        return { date, cost, co2e, kwh }
      })
    },
  },
  created () {
    const urlQueryParams = new URLSearchParams(window.location.search)
    if (urlQueryParams.get('filters')) {
      this.READ_URL_QUERY()
    } else {
      this.UPDATE_URL_QUERY(this.$router)
    }
  },
  methods: {
    ...mapActions('organization/cloudCostManagement', [
      'FETCH_PROVIDER_DATA',
    ]),
    ...mapMutations('organization/cloudCostManagement', [
      'READ_URL_QUERY',
      'UPDATE_URL_QUERY',
    ]),
    async fetchProviderData () {
      this.$toggle.providerDataLoading(true)
      const { providerCanonical } = this
      await this.FETCH_PROVIDER_DATA({ providerCanonical, router: this.$router })
      this.$toggle.providerDataLoading(false)
      this.$refs.dataTable?.scrollTableValues()
    },
    setSidebarData (data) {
      this.tableSidebarData = data
      this.$toggle.tableSidebarActive(true)
    },
    closeSidebar () {
      this.$toggle.tableSidebarActive(false)
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.v-data-table__wrapper',
        '.cy-btn-toggle',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    getTrimmedSeries,
    parseToGraphFormat,
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.cloudCostManagementProviderDetail',
      },
      es: {
        title: '@:routes.cloudCostManagementProviderDetail',
      },
      fr: {
        title: '@:routes.cloudCostManagementProviderDetail',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  &__container {
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    padding-right: 16px;

    &::after {
      content: "";
      display: block;
      padding-bottom: 24px;
    }
  }

  &__table {
    position: relative;
  }

  &__sidebar {
    flex-shrink: 0;
    padding-right: 16px;
    padding-left: 16px;

    > * {
      width: 328px;
    }
  }
}
</style>

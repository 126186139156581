<template>
  <div class="group-by">
    <div class="group-by__header pa-4">
      <div class="group-by__title">
        {{ $t('groupBy') }}
      </div>
    </div>
    <div class="group-by__content">
      <v-expansion-panels
        v-model="openPanel"
        v-click-outside="closePanel"
        active-class="elevation-4">
        <v-expansion-panel
          v-for="(_, index) in selected[0] ? 2 : 1"
          :key="index">
          <v-expansion-panel-header class="px-4">
            <div
              v-if="index"
              class="operator mb-2">
              {{ $t('and') }}
            </div>
            <CyTag
              :variant="selected[index] ? 'secondary' : 'default'"
              :icon-after="selected[index] ? 'close' : null"
              class="flex-grow-0"
              @click-icon-after="changeAtIndex(null, index)">
              {{ displayLabel(selected[index]) }}
            </CyTag>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="elevation-4">
            <CyRandomTip
              v-if="$route.params.providerCanonical === 'gcp'"
              class="cy-alert--naming-convention"
              :ls-path="lsPath"
              :tips="[{
                title: $t('cloudCostManagement.namingConventions.title'),
                content: $t('cloudCostManagement.namingConventions.text'),
              }]"/>
            <div class="d-flex px-4 mb-2 mt-2">
              <v-text-field
                v-model="search[index]"
                append-icon="search"
                class="pt-0"
                clearable
                :placeholder="$t('forms.fieldSearch')"
                hide-details/>
            </div>
            <v-list class="group-by__options cy-scrollbars py-0">
              <v-list-item-group
                mandatory
                :value="selected[index]"
                @change="changeAtIndex($event, index)">
                <v-list-item
                  v-show="isSearchedFor(label, index)"
                  v-for="{ label, value } in getAvailableOptions(index)"
                  :key="value"
                  :value="value">
                  <v-list-item-title>
                    {{ label }}
                  </v-list-item-title>
                  <v-list-item-icon v-if="selected[index] === value">
                    <v-icon>
                      check
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div class="group-by__actions d-flex justify-end pa-2">
              <CyButton
                variant="tertiary"
                theme="grey"
                sm
                @click="changeAtIndex(0, index)">
                {{ $t('forms.btnClear') }}
              </CyButton>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CyRandomTip from '@/components/CyRandomTip.vue'
import { groupingOptions } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCcmSidebarGroupByFilter',
  components: {
    CyRandomTip,
  },
  data: () => ({
    openPanel: null,
    search: ['', ''],
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      queryBody: (state) => state.queryBody,
    }),
    $static: () => ({
      groupingOptions,
    }),
    selected: {
      get () {
        return _.get(this.queryBody, 'group_by')
      },
      set (value) {
        this.SET_QUERY_FILTER({ key: 'group_by', value })
        this.$emit('input')
      },
    },
    lsPath () {
      return LSK.CCM_GROUP_BY_NOTIFICATIONS
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
    changeAtIndex (value, index) {
      const newArray = [...this.selected]
      newArray[index] = value || null
      if (!newArray[0] && newArray[1]) _.reverse(newArray)
      this.selected = newArray
      this.closePanel()
    },
    closePanel () {
      this.openPanel = null
    },
    displayLabel (value) {
      const option = _.find(this.$static.groupingOptions, ['value', value])
      return _.get(option, 'label', this.$t('cloudCostManagement.groupBy.noGrouping'))
    },
    getAvailableOptions (index) {
      return _.filter(this.$static.groupingOptions, ({ value }) => !_.includes(this.selected.slice(0, index), value))
    },
    isSearchedFor (label, index) {
      const search = this.search[index]
      return !search || label?.toLowerCase().includes(search.toLowerCase())
    },
  },
  i18n: {
    messages: {
      en: {
        groupBy: 'Group by',
      },
      es: {
        groupBy: 'Agrupar por',
      },
      fr: {
        groupBy: 'Regrouper par',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.group-by {
  border: 1px solid cy-get-color("grey");
  border-radius: 4px;
  background-color: cy-get-color("white");

  &__title {
    color: cy-get-color("grey", "dark-2");
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    text-transform: uppercase;
  }

  &__header {
    border-bottom: 1px solid cy-get-color("grey");
  }

  &__options {
    max-height: 260px;
    border-top: 1px solid cy-get-color("grey");
    border-bottom: 1px solid cy-get-color("grey");
  }

  ::v-deep .v-expansion-panels {
    z-index: 4;
  }

  ::v-deep .v-expansion-panel {
    margin-top: 0;

    &-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .operator {
        width: 100%;
        color: cy-get-color("primary");
        font-size: $font-size-default;
        text-transform: uppercase;
      }

      &__icon {
        .v-icon {
          margin-right: -4px;
          font-size: 30px;
        }
      }

      &--active {
        min-height: 0;

        &::after {
          content: "";
          display: block;
          position: absolute;
          z-index: 6;
          right: 0;
          bottom: 0;
          left: 0;
          height: 6px;
          background: cy-get-color("white");
        }
      }
    }

    &-content {
      position: absolute;
      z-index: 5;
      width: 100%;
      border-radius: 0 0 #{$menu-border-radius} #{$menu-border-radius};
      background-color: cy-get-color("white");

      &.expand-transition-leave-active {
        z-index: 1;
      }
    }

    &-content__wrap {
      padding: 0;
    }

    &:last-of-type {
      border-radius: 4px;
    }
  }

  ::v-deep .v-list-item {
    color: cy-get-color("primary");

    &--active {
      background-color: white;
      font-weight: $font-weight-bolder;

      &::before {
        opacity: 0;
      }
    }
  }
}
</style>

<template>
  <div>
    <section>
      <h2 class="h4 mt-1">
        {{ $t('general') }}
      </h2>
      <dl class="mt-5">
        <template v-for="(attr, index) in displayedGeneralAttributes">
          <dt
            v-if="loading || !_.isEmpty(attr.value)"
            :key="`${attr.key}-label`"
            :ref="`${attr.key}-label`"
            :class="[
              'h6 line-height-md',
              { 'mt-3': index },
            ]">
            {{ attr.label }}
          </dt>
          <v-skeleton-loader
            v-if="loading"
            :key="`${attr.key}-loader`"
            :ref="`${attr.key}-loader`"
            :loading="loading"
            type="text"
            width="200"
            class="attribute-value-loader"/>
          <dd
            v-else-if="!_.isEmpty(attr.value)"
            :key="`${attr.key}-value`"
            :ref="`${attr.key}-value`"
            :class="{
              'text-truncate ellipsis': attr.key === 'link',
            }">
            <CyTagList
              v-if="attr.key === 'tags'"
              :tags="attr.value"
              variant="default"
              small
              class="tag-list"
              data-cy="tag-list"/>
            <router-link
              v-else-if="attr.key === 'project_canonical'"
              :to="{
                name: 'project',
                params: { orgCanonical, projectCanonical: attr.value },
              }"
              class="cy-link"
              data-cy="project-link">
              {{ attr.value }}
            </router-link>
            <router-link
              v-else-if="attr.key === 'environment_canonical'"
              :to="{
                name: 'projectEnvironments',
                params: { orgCanonical, projectCanonical: resource.project_canonical },
              }"
              class="cy-link"
              data-cy="environment-link">
              {{ attr.value }}
            </router-link>
            <template v-else>
              {{ attr.value }}
            </template>
          </dd>
        </template>
      </dl>
    </section>
    <section class="mt-8 mb-4">
      <header class="attributes-header">
        <v-row align="baseline">
          <v-col>
            <h2>{{ $t('attributes') }}</h2>
          </v-col>
          <v-col class="px-0">
            <CyDevButton
              class="mr-1"
              @click.native="toggleAllAttributesExpanded">
              {{ isAllAttributesExpanded ? 'Collapse all' : 'Expand all' }}
            </CyDevButton>
          </v-col>
          <v-col cols="7">
            <CySearchBox
              v-model="attrSearchText"
              :label="$t('filterAttributes')"
              clearable
              hide-details>
              <v-icon slot="append">
                search
              </v-icon>
            </CySearchBox>
          </v-col>
        </v-row>
      </header>
      <div
        v-if="loading"
        class="mt-4">
        <v-skeleton-loader
          v-for="(index) in 10"
          :key="`attr-loader-${index}`"
          :ref="`attr-tree-loader`"
          :loading="loading"
          type="text"
          class="attribute-tree-loader"/>
      </div>
      <tree
        v-else-if="!_.isEmpty(resource)"
        ref="tree"
        :key="resource.id"
        :data="treeData"
        :filter="attrSearchText"
        :options="{
          filter: {
            emptyText: '',
            matcher: attrSearchMatcher,
          },
          multiple: false,
          propertyNames: {
            text: 'text',
            children: 'children',
            type: 'type',
          },
        }"
        class="cy-tree mt-4"
        data-cy="tree"
        @tree:filtered="onTreeFiltered">
        <template #default="{ node }">
          <div class="width-100">
            <v-hover #default="{ hover }">
              <div :class="`cy-tree__text cy-tree__text--type-${_.camelCase(node.data.type)}`">
                <div>
                  <span
                    ref="tree-node-key"
                    class="cy-tree__key mr-2"
                    v-html="highlight(node.text)"/>
                  <span
                    v-if="shouldDisplayAttributeValue(node)"
                    ref="tree-node-value"
                    class="cy-tree__value"
                    v-html="highlight(node.data.value)"/>
                  <span
                    v-if="shouldDisplayAttributeType(node)"
                    ref="tree-node-type"
                    class="cy-tree__type"
                    v-text="getNodeType(node)"/>
                </div>
                <div class="cy-tree__copy-btn">
                  <div v-show="hover">
                    <CyCopyButton
                      ref="tree-node-copy-btn"
                      :copy-value="getValueForCopy(node)"
                      :copy-hint="getValueForCopy(node)"
                      small
                      left/>
                  </div>
                </div>
              </div>
            </v-hover>
          </div>
        </template>
      </tree>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyCopyButton from '@/components/CyCopyButton.vue'
import CySearchBox from '@/components/CySearchBox.vue'
import CyTagList from '@/components/CyTagList.vue'
import LiquorTree from 'liquor-tree'

export const generalAttributeKeys = [
  'id',
  'category',
  'tags',
  'project_canonical',
  'environment_canonical',
]

export default {
  name: 'CyInventoryResourceDetails',
  components: {
    CyCopyButton,
    CySearchBox,
    CyTagList,
    [LiquorTree.name]: LiquorTree,
  },
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    attrSearchText: null,
    isAllAttributesExpanded: false,
  }),
  computed: {
    ...mapGetters('organization/inventory', [
      'getAttributes',
      'getId',
      'isCustomResource',
    ]),
    attributes () {
      return this.getAttributes(this.resource)
    },
    displayedGeneralAttributes () {
      return _(generalAttributeKeys)
        .map((key) => ({
          key,
          value: this.parseAttributeValue(key),
          label: this.$t(`inventory.values.attribute.${key}`),
        }))
        .value()
    },
    treeData () {
      return this.generateTreeData(this.attributes)
    },
  },
  watch: {
    resource: {
      handler () {
        this.$resetData('attrSearchText')
      },
    },
  },
  methods: {
    castString (value) {
      return value ? String(value) : value
    },
    parseAttributeValue (key) {
      switch (key) {
        case 'id':
          return this.castString(this.getId(this.resource))
        case 'tags':
          return this.isCustomResource(this.resource)
            ? null
            : this.parseTags(this.attributes?.tags)
        default:
          return this.castString(this.resource[key])
      }
    },
    parseTags (tags) {
      return _(tags)
        .$sortObjKeys()
        .$flattenObject()
        .map((tagValue, tagKey) => ({
          label: tagKey,
          content: tagValue,
        }))
        .value()
    },
    generateTreeData (attributes, prevPath) {
      // This used to be _.map but it caused a very unique bug with lodash
      // where if the attributes contain a number property named "length"
      // this would confuse the loop and return an empty array of that "length"
      return _.transform(attributes, (result, value, key) => {
        const getPath = () => {
          const currentPath = _.isNumber(key) ? `[${key}]` : key
          const separator = prevPath && !_.isNumber(key) ? '.' : ''
          return [prevPath, currentPath].join(separator)
        }
        const hasChildren = _.overSome([_.isPlainObject, _.isArray])
        const path = getPath()
        const type = Object.prototype.toString.call(value).slice(8, -1)

        result.push({
          text: `${key}`,
          data: {
            path,
            value,
            type,
          },
          ...(hasChildren(value) && { children: this.generateTreeData(value, path) }),
        })
      }, [])
    },
    attrSearchMatcher (query, node) {
      const isMatch = (content) => {
        try {
          return JSON.stringify(content).includes(query)
        } catch (error) {
          return false
        }
      }
      return _.some([node.data.value, node.text], isMatch)
    },
    shouldDisplayAttributeType (node) {
      return [
        'Object',
        'Array',
      ].includes(node.data.type)
    },
    shouldDisplayAttributeValue (node) {
      return ![
        'Object',
        'Array',
      ].includes(node.data.type)
    },
    shouldDisplayAttributeLength (node) {
      return [
        'Array',
      ].includes(node.data.type)
    },
    getNodeType (node) {
      const type = node.data.type
      return this.shouldDisplayAttributeLength(node)
        ? `${type}[${node.children?.length}]`
        : `${type}`
    },
    getValueForCopy (node) {
      try {
        return JSON.stringify(node.data.value) || ''
      } catch (error) {
        return ''
      }
    },
    highlight (content) {
      const serializedContent = `${content}`

      if (this.attrSearchText) {
        const regex = new RegExp(this.attrSearchText, 'gi')
        return this.$sanitizeHtml(serializedContent)
          .replace(regex, (match) => `<span class="highlight">${match}</span>`)
      }
      return serializedContent
    },
    toggleAllAttributesExpanded () {
      if (this.isAllAttributesExpanded) this.$refs.tree.tree.collapseAll()
      else this.$refs.tree.tree.expandAll()
      this.$toggle.isAllAttributesExpanded()
    },
    onTreeFiltered (matches) {
      matches.forEach((node) => {
        node.parent?.expand()
      })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.inventoryResourceDetails',
        attributes: 'Attributes',
        filterAttributes: 'Filter attributes',
      },
      es: {
        title: '@:routes.inventoryResourceDetails',
        attributes: 'Atributos',
        filterAttributes: 'Filtrar atributos',
      },
      fr: {
        title: '@:routes.inventoryResourceDetails',
        attributes: 'Attributs',
        filterAttributes: 'Attributs de filtre',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$tree-indentation: 24px;
$tree-line-height: 20px;

.attribute-value-loader,
.attribute-tree-loader {
  ::v-deep .v-skeleton-loader__text {
    height: $font-size-default;
    margin-top: 3px;
    margin-bottom: 4px;
  }
}

.tag-list {
  margin-left: -2px;
}

.attributes-header {
  position: sticky;
  z-index: 1;
  top: -#{$spacer-4};
  background-color: cy-get-color("white");
}

.cy-tree {
  padding-top: 1px;
  padding-bottom: 1px;
  overflow: hidden;

  ::v-deep {
    > .tree-root {
      padding: 0;
    }

    .tree-content {
      padding: 0;
      border-radius: 4px;
      word-break: break-word;
      white-space: normal;
    }

    .tree-children {
      padding-left: $tree-indentation;
    }

    .tree-arrow {
      align-self: start;
      width: $tree-indentation;
      height: $tree-line-height;
      margin-left: 0;

      &::before,
      &.has-child::before {
        top: 2px;
      }
    }

    .tree-anchor {
      margin-left: 0;
      padding: 0;
      line-height: $tree-line-height;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__key::after {
    content: ":";
  }

  &__text--type-boolean &__value {
    color: cy-get-color("secondary");
  }

  &__text--type-string &__value {
    &::before,
    &::after {
      content: '"';
    }
  }

  &__type {
    color: cy-get-color("primary", "light-3");
  }

  &__copy-btn {
    min-width: 24px;
    padding-right: $spacer;
    padding-left: $spacer;
  }
}

::v-deep .highlight {
  border: 1px solid cy-get-color("warning");
  background-color: color.change(cy-get-color("warning"), $alpha: 0.2);
}
</style>

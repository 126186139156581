import { locales, getLocalesList } from '@/locales/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  localesList: () => getLocalesList(i18n.locale),
}

export const actions = {}

export const mutations = {
  CHANGE_LOCALE (_context, locale) {
    if (!locales.includes(locale)) return console.warn(`[vuex CHANGE_LOCALE] was passed an invalid locale: "${locale}"`)
    if (i18n.locale === locale) return
    i18n.locale = locale
  },
}

export {
  STATE as state,
  GETTERS as getters,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}

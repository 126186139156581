<template>
  <div class="provider-breakdown-widget">
    <div
      v-if="!loading"
      data-cy="content">
      <h4 class="mb-6">
        {{ title || $t('untranslated.finops') }}
      </h4>
      <div class="subtitle">
        {{ $t('cloudCostManagement.cost') }} ({{ getCurrencySymbol(currency) }})
      </div>
      <div class="d-flex align-center mb-3 mt-1">
        <CyTooltip right>
          <template #activator="{ on }">
            <div
              data-cy="total-cost"
              class="total__cost mr-1"
              v-on="on">
              <span class="total__cost--number">
                {{ formatAmount(totals.cost) }}
              </span>
              <span class="total__cost--currency">
                {{ getCurrencySymbol(currency) }}
              </span>
            </div>
          </template>
          {{ $t('excludesTax') }}
        </CyTooltip>
        <CyCcmTrendChip
          data-cy="trend"
          :value="[totals.oldestCost, totals.latestCost]"/>
        <span
          data-cy="absolute-change"
          class="total__difference subtitle ml-1">
          {{ absoluteChange }}
        </span>
      </div>
      <ECharts
        v-if="providerDatas.length > 1"
        data-cy="chart"
        class="pie-chart"
        theme="cycloid"
        autoresize
        :option="options.pieChart"/>
      <router-link
        class="ccm-link cy-headline unstyled-link"
        :to="{ name: 'cloudCostManagementDashboard' }">
        {{ $t('viewCloudCost') }}
      </router-link>
    </div>
    <div
      v-else
      data-cy="loader"
      class="sk-template d-flex align-stretch flex-wrap mb-n4">
      <div class="flex-column flex-grow-1">
        <div class="sk-block sk-title sk-w-30 sk-h-6 mb-6"/>
        <div class="sk-block sk-title sk-w-10 sk-h-4"/>
        <div class="d-flex align-center mt-1 mb-3">
          <div class="sk-block sk-title sk-w-28 sk-h-7 sk-dark"/>
          <div class="sk-block sk-title sk-w-10 sk-h-4 ml-1"/>
          <div class="sk-block sk-title sk-w-16 sk-h-4 ml-1"/>
        </div>
        <div class="d-flex align-stretch mb-6 pt-2">
          <div class="sk-block sk-avatar sk-w-16 sk-h-16 sk-dark"/>
          <div class="d-flex flex-column justify-center">
            <div class="sk-block sk-title sk-w-20 sk-h-4 ml-2 ml-4 mb-2"/>
            <div class="sk-block sk-title sk-w-20 sk-h-4 ml-2 ml-4 mb-2"/>
          </div>
        </div>
        <div class="ccm-link">
          <div class="sk-block sk-title sk-w-30 sk-h-4"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CyCcmTrendChip from '@/components/CyCcmTrendChip.vue'
import { getCurrencySymbol, formatAmount } from '@/utils/helpers'
import { getTotalsFromAggregateData, chartLegendColors, formatPercentage } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCcmWidgetsProviderBreakdown',
  components: {
    CyCcmTrendChip,
  },
  props: {
    currency: {
      type: String,
      default: 'EUR',
    },
    title: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      aggregateData: (state) => state.aggregateData,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCanonical',
    ]),
    totals () {
      return getTotalsFromAggregateData(this.aggregateData)
    },
    providerDatas () {
      return _.get(this.aggregateData, 'providerDatas', [])
    },
    absoluteChange () {
      const { currency } = this
      return this.formatAmount(this.totals.difference, { currency, signDisplay: 'always' }).replace(/^(.)/, '$1 ')
    },
    options () {
      return {
        pieChart: {
          color: chartLegendColors,
          legend: {
            align: 'left',
            icon: 'circle',
            formatter: this.formatPieLegend,
            orient: 'vertical',
            right: '0',
            selectedMode: false,
            itemWidth: 6,
            textStyle: {
              rich: {
                provider: {
                  color: '#253449',
                  fontFamily: 'Roboto',
                  fontSize: 14,
                },
                percentage: {
                  color: '#576E8E',
                  fontFamily: 'Roboto',
                  fontSize: 14,
                },
              },
            },
            top: 'middle',
          },
          series: [
            {
              avoidLabelOverlap: false,
              center: ['20%', '50%'],
              data: _.map(this.providerDatas, ({ canonical, cost }) => ({ name: canonical, value: cost })),
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
              },
              label: {
                show: false,
                position: 'center',
              },
              labelLine: {
                show: false,
              },
              emphasis: {
                scale: false,
              },
              radius: ['40%', '70%'],
              type: 'pie',
            },
          ],
          tooltip: {
            confine: false,
            formatter: this.formatPieChartTooltip,
            position: ['0%', '100%'],
          },
        },
      }
    },
  },
  methods: {
    getCurrencySymbol,
    formatAmount,
    getTooltipListItem (marker, provider, cost, percent) {
      const percentage = percent ? `(${formatPercentage(percent)})` : ''
      const { currency } = this
      return `
        <span class="marker-slot">${marker || ''}</span>
        <span class="provider-canonical">${provider}:</span>
        <strong class="cost mr-2">${formatAmount(cost, { currency })}</strong>
        <span class="percentage">${percentage}</span>`
    },
    formatPieChartTooltip () {
      const providerDatasMarkup = this.providerDatas.map(({ canonical, cost }, index) => {
        const marker = this.providerDatas.length > 1
          ? `<span class="marker" style="background-color:${chartLegendColors[index]}"></span>`
          : ''
        return this.getTooltipListItem(
          marker,
          this.getProviderExtraInfoByCanonical[canonical].displayName,
          cost,
          this.getProviderPercentage(canonical),
        )
      })
      return `
        <div class="chart-tooltip__list">
          ${providerDatasMarkup.join('')}
        </div>`
    },
    formatPieLegend (label) {
      return `{provider|${this.getProviderExtraInfoByCanonical[label].displayName}} {percentage|${formatPercentage(this.getProviderPercentage(label))}}`
    },
    getProviderPercentage (providerCanonical) {
      const providerCost = _.find(this.providerDatas, ({ canonical }) => canonical === providerCanonical)?.cost || 0
      return providerCost * 100 / this.totals.cost
    },
  },
  i18n: {
    messages: {
      en: {
        excludesTax: 'This cost excludes tax',
        viewCloudCost: 'View cloud cost',
      },
      es: {
        excludesTax: 'Este costo no incluye impuestos',
        viewCloudCost: 'Ver el costo del cloud',
      },
      fr: {
        excludesTax: 'Ce coût est hors taxe',
        viewCloudCost: 'Voir les coûts du cloud',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.provider-breakdown-widget {
  position: relative;
  height: 100%;
  min-height: 255px;
  padding: 16px;
  overflow: hidden;
  transition: border-color 0.3s ease;
  border: 1px solid cy-get-color("primary", "light-4");
  border-radius: 8px;
  background: cy-get-color("white");

  &:hover {
    border-color: cy-get-color("primary", "light-2");
  }

  .subtitle {
    color: cy-get-color("primary", "light-3");
    font-size: $font-size-sm;
  }

  .total {
    &__cost {
      font-family: $font-family-condensed;

      &--number {
        color: cy-get-color("primary", "light-1");
        font-size: $font-size-h3;
        font-weight: $font-weight-bolder;
        line-height: 100%;
      }

      &--currency {
        color: cy-get-color("primary", "light-2");
        font-size: $font-size-lg;
        font-weight: $font-weight-bolder;
      }
    }

    &__difference {
      opacity: 0;
      font-size: $font-size-default;
    }
  }

  .trend-chip {
    font-size: $font-size-sm;
  }

  .pie-chart {
    position: relative;
    top: -10px;
    width: 200px;
    height: 92px;
    margin-left: -8px;

    ::v-deep {
      > div {
        cursor: pointer !important;
      }

      .marker {
        display: inline-block;
        position: relative;
        top: 1px;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border: solid 1px white;
        border-radius: 10px;
      }

      .chart-tooltip__list {
        display: grid;
        grid-template-columns: 20px auto 1fr auto;
        gap: 2px;
        list-style-type: none;

        .provider-canonical {
          margin-right: 10px;
          margin-left: -5px;
        }

        .cost {
          text-align: right;
        }

        .percentage {
          color: cy-get-color("white");
        }
      }

      .date {
        margin-bottom: 2px;
      }
    }
  }

  .ccm-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    border-top: 1px solid cy-get-color("primary", "light-4");
    color: cy-get-color("primary", "light-3");

    &:hover {
      color: cy-get-color("accent");
    }
  }
}
</style>

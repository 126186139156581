<template>
  <div>
    <CyDetails
      :item-id="credentialCanonical"
      :on-save="onSave"
      :on-cancel="onCancel"
      :on-delete="$toggle.show.deleteDialog"
      :can-cancel="$hasDataChanged('formContent')"
      :hide-delete="isCycloidCredential"
      :can-save="canSave"
      :loading="status.loading"
      :saving="status.saving"
      :deleting="status.deleting"
      :is-owner="isOwner">
      <template slot="details_form">
        <CyAlert
          v-if="isCycloidCredential"
          :title="cycloidCredentialNotification"/>

        <CyAlert
          theme="error"
          :content="credentialErrors"/>

        <v-text-field
          ref="nameField"
          v-model="$v.formContent.name.$model"
          class="required-field"
          :label="$t('forms.fieldName')"
          :error-messages="errors.name"
          required
          :readonly="isReadonlyField('name')"
          :disabled="isReadonlyField('name')"
          data-cy="name-input"
          @blur="$v.formContent.name.$touch()"/>

        <v-textarea
          v-model="$v.formContent.description.$model"
          rows="4"
          :readonly="isReadonlyField('description')"
          :disabled="isReadonlyField('description')"
          :label="$t('forms.fieldDescription')"/>

        <CySelectOwner
          v-model="$v.formContent.owner.$model"
          :readonly="isReadonlyField('owner')"
          :disabled="isReadonlyField('owner')"
          :current-owner="formContent.owner"/>

        <v-text-field
          v-model="$v.formContent.path.$model"
          :hint="hints.path"
          :error-messages="errors.path"
          :readonly="isReadonlyField('path') || !isPathCustom"
          :disabled="isReadonlyField('path') || !isPathCustom"
          :placeholder="generatedPath"
          persistent-hint
          required
          data-cy="path-field"
          class="required-field credential__path"
          @blur="$v.formContent.path.$touch()">
          <v-icon
            v-if="!isReadonlyField('path')"
            slot="append-outer"
            color="darkgrey"
            @click="setIsPathCustom(!isPathCustom)">
            {{ isPathCustom ? 'lock_open' : 'lock' }}
          </v-icon>
          <span slot="label">{{ pathLabel }}</span>
          <CyCopyButton
            v-if="formContent.path"
            slot="append"
            :copy-value="formContent.path"/>
        </v-text-field>

        <v-select
          ref="typeField"
          :key="formContent.type"
          v-model="$v.formContent.type.$model"
          :label="$t('forms.type')"
          :hint="hints.raw"
          :error-messages="errors.type"
          :readonly="isReadonlyField('type')"
          :disabled="isReadonlyField('type')"
          :items="$static.credentialTypeOptions"
          :persistent-hint="typeIs.custom"
          :menu-props="{ maxHeight: 521 }"
          item-value="canonical"
          item-text="name"
          data-cy="type-dropdown"
          required
          class="required-field credential__type"
          @blur="$v.formContent.type.$touch()">
          <template #selection="{ item }">
            <CyIconCredential
              :key="item"
              :type="item"
              size="24"
              disabled/>
            <div :class="[{ 'black--text': !isReadonlyField('type') || $isCreationRoute }]">
              {{ item }}
            </div>
          </template>
          <template #item="{ item, on, attrs }">
            <v-list-item
              v-hover-siblings
              v-bind="attrs"
              data-cy="credential-item"
              class="credential--item"
              v-on="on">
              <CyIconCredential :type="item"/>
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>

        <template v-if="!!_.$get(formContent, 'type')">
          <template v-if="typeIs.ssh">
            <component
              v-model="$v.formContent.raw.ssh_key.$model"
              :error-messages="errors.raw.ssh_key"
              :append-icon="hideAsPassword.ssh.sshKey ? 'visibility' : 'visibility_off'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="ssh-key"
              :type="hideAsPassword.ssh.sshKey ? 'password' : 'text'"
              autocomplete="off"
              auto-grow
              @click:append="hideAsPassword.ssh.sshKey = !hideAsPassword.ssh.sshKey"
              @blur="$v.formContent.raw.ssh_key.$touch()"
              :is="hideAsPassword.ssh.sshKey ? 'v-text-field' : 'v-textarea'">
              <template slot="label">
                <span>ssh_key</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.ssh_key`"
                  :copy-value="`${formContent.path}.ssh_key`"/>
              </template>
            </component>
          </template>

          <template v-if="typeIs.aws">
            <v-text-field
              v-model="$v.formContent.raw.access_key.$model"
              :error-messages="errors.raw.access_key"
              :append-icon="hideAsPassword.aws.accessKey ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.aws.accessKey ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="aws-access-key"
              @click:append="hideAsPassword.aws.accessKey = !hideAsPassword.aws.accessKey"
              @blur="$v.formContent.raw.access_key.$touch()">
              <template slot="label">
                <span>access_key</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.access_key`"
                  :copy-value="`${formContent.path}.access_key`"/>
              </template>
            </v-text-field>
            <v-text-field
              v-model="$v.formContent.raw.secret_key.$model"
              :error-messages="errors.raw.secret_key"
              :append-icon="hideAsPassword.aws.secretKey ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.aws.secretKey ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="aws-secret-key"
              @click:append="hideAsPassword.aws.secretKey = !hideAsPassword.aws.secretKey"
              @blur="$v.formContent.raw.secret_key.$touch()">
              <template slot="label">
                <span>secret_key</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.secret_key`"
                  :copy-value="`${formContent.path}.secret_key`"/>
              </template>
            </v-text-field>
          </template>

          <template v-if="typeIs.gcp">
            <component
              v-model="$v.formContent.raw.json_key.$model"
              :error-messages="errors.raw.json_key"
              :append-icon="hideAsPassword.gcp.jsonKey ? 'visibility' : 'visibility_off'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              data-cy="gcp-json-key"
              class="required-field has-copy-btn"
              :type="hideAsPassword.gcp.jsonKey ? 'password' : 'text'"
              autocomplete="off"
              @click:append="hideAsPassword.gcp.jsonKey = !hideAsPassword.gcp.jsonKey"
              @blur="$v.formContent.raw.json_key.$touch()"
              :is="hideAsPassword.gcp.jsonKey ? 'v-text-field' : 'v-textarea'">
              <template slot="label">
                <span>json_key</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.json_key`"
                  :copy-value="`${formContent.path}.json_key`"/>
              </template>
            </component>
          </template>

          <template v-if="typeIs.azure">
            <v-text-field
              v-model="$v.formContent.raw.client_id.$model"
              :error-messages="errors.raw.client_id"
              :append-icon="hideAsPassword.azure.clientId ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.azure.clientId ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              data-cy="azure-client-id"
              class="required-field has-copy-btn"
              @click:append="hideAsPassword.azure.clientId = !hideAsPassword.azure.clientId"
              @blur="$v.formContent.raw.client_id.$touch()">
              <template slot="label">
                <span>client_id</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.client_id`"
                  :copy-value="`${formContent.path}.client_id`"/>
              </template>
            </v-text-field>
            <v-text-field
              v-model="$v.formContent.raw.client_secret.$model"
              :error-messages="errors.raw.client_secret"
              :append-icon="hideAsPassword.azure.clientSecret ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.azure.clientSecret ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="azure-client-secret"
              @click:append="hideAsPassword.azure.clientSecret = !hideAsPassword.azure.clientSecret"
              @blur="$v.formContent.raw.client_secret.$touch()">
              <template slot="label">
                <span>client_secret</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.client_secret`"
                  :copy-value="`${formContent.path}.client_secret`"/>
              </template>
            </v-text-field>
            <v-text-field
              v-model="$v.formContent.raw.subscription_id.$model"
              :error-messages="errors.raw.subscription_id"
              :append-icon="hideAsPassword.azure.subscriptionId ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.azure.subscriptionId ? 'password' : 'text'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="azure-subscription-id"
              autocomplete="off"
              @click:append="hideAsPassword.azure.subscriptionId = !hideAsPassword.azure.subscriptionId"
              @blur="$v.formContent.raw.subscription_id.$touch()">
              <template slot="label">
                <span>subscription_id</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.subscription_id`"
                  :copy-value="`${formContent.path}.subscription_id`"/>
              </template>
            </v-text-field>
            <v-text-field
              v-model="$v.formContent.raw.tenant_id.$model"
              :error-messages="errors.raw.tenant_id"
              :append-icon="hideAsPassword.azure.tenantId ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.azure.tenantId ? 'password' : 'text'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="azure-tenant-id"
              autocomplete="off"
              @click:append="hideAsPassword.azure.tenantId = !hideAsPassword.azure.tenantId"
              @blur="$v.formContent.raw.tenant_id.$touch()">
              <template slot="label">
                <span>tenant_id</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.tenant_id`"
                  :copy-value="`${formContent.path}.tenant_id`"/>
              </template>
            </v-text-field>
          </template>

          <template v-if="typeIs.azure_storage">
            <v-text-field
              v-model="$v.formContent.raw.account_name.$model"
              :error-messages="errors.raw.account_name"
              :append-icon="hideAsPassword.azureStorage.accountName ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.azureStorage.accountName ? 'password' : 'text'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="azure-storage-account-name"
              autocomplete="off"
              @click:append="hideAsPassword.azureStorage.accountName = !hideAsPassword.azureStorage.accountName"
              @blur="$v.formContent.raw.account_name.$touch()">
              <template slot="label">
                <span>account_name</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.account_name`"
                  :copy-value="`${formContent.path}.account_name`"/>
              </template>
            </v-text-field>
            <component
              v-model="$v.formContent.raw.access_key.$model"
              :error-messages="errors.raw.access_key"
              :append-icon="hideAsPassword.azureStorage.accessKey ? 'visibility' : 'visibility_off'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              auto-grow
              required
              class="required-field has-copy-btn"
              data-cy="azure-storage-access-key"
              :type="hideAsPassword.azureStorage.accessKey ? 'password' : 'text'"
              autocomplete="off"
              @click:append="hideAsPassword.azureStorage.accessKey = !hideAsPassword.azureStorage.accessKey"
              @blur="$v.formContent.raw.access_key.$touch()"
              :is="hideAsPassword.azureStorage.accessKey ? 'v-text-field' : 'v-textarea'">
              <template slot="label">
                <span>access_key</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.access_key`"
                  :copy-value="`${formContent.path}.access_key`"/>
              </template>
            </component>
          </template>

          <template v-if="typeIs.elasticsearch">
            <CyAlert
              v-if="!isReadOnly"
              class="es-validations-explanation">
              <span class="es-validations-explanation__examples">
                <span>{{ $t('elasticSearchValidationsExplanation') }}</span>
                <span class="for-example"> {{ $t('forExample') }}</span>
                <ul class="examples">
                  <li class="example">
                    <v-icon class="example__icon">check</v-icon>
                    <pre>ca_cert</pre>
                  </li>
                  <li class="example">
                    <v-icon class="example__icon">check</v-icon>
                    <pre>username + password</pre>
                  </li>
                  <li class="example">
                    <v-icon class="example__icon">check</v-icon>
                    <pre>username + password + ca_cert</pre>
                  </li>
                </ul>
              </span>
            </CyAlert>

            <v-text-field
              v-model="$v.formContent.raw.username.$model"
              :error-messages="errors.raw.username"
              :append-icon="hideAsPassword.elasticsearch.username ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.elasticsearch.username ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              :required="isFieldRequired.username"
              :class="['has-copy-btn', { 'required-field': isFieldRequired.username }]"
              data-cy="elasticsearch-username"
              @click:append="hideAsPassword.elasticsearch.username = !hideAsPassword.elasticsearch.username"
              @blur="$v.formContent.raw.username.$touch()">
              <template slot="label">
                <span>username</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.username`"
                  :copy-value="`${formContent.path}.username`"/>
              </template>
            </v-text-field>

            <component
              v-model="$v.formContent.raw.password.$model"
              :error-messages="errors.raw.password"
              :append-icon="hideAsPassword.elasticsearch.password ? 'visibility' : 'visibility_off'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              :required="isFieldRequired.password"
              :class="['has-copy-btn', { 'required-field': isFieldRequired.password }]"
              data-cy="elasticsearch-password"
              :type="hideAsPassword.elasticsearch.password ? 'password' : 'text'"
              autocomplete="off"
              @click:append="hideAsPassword.elasticsearch.password = !hideAsPassword.elasticsearch.password"
              @blur="$v.formContent.raw.password.$touch()"
              :is="hideAsPassword.elasticsearch.password ? 'v-text-field' : 'v-textarea'">
              <template slot="label">
                <span>password</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.password`"
                  :copy-value="`${formContent.path}.password`"/>
              </template>
            </component>

            <component
              v-model="$v.formContent.raw.ca_cert.$model"
              :error-messages="errors.raw.ca_cert"
              :append-icon="hideAsPassword.elasticsearch.cacert ? 'visibility' : 'visibility_off'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              :required="isFieldRequired.ca_cert"
              data-cy="elasticsearch-ca_cert"
              :class="['has-copy-btn', { 'required-field': isFieldRequired.ca_cert }]"
              :type="hideAsPassword.elasticsearch.cacert ? 'password' : 'text'"
              autocomplete="off"
              @click:append="hideAsPassword.elasticsearch.cacert = !hideAsPassword.elasticsearch.cacert"
              @blur="$v.formContent.raw.ca_cert.$touch()"
              :is="hideAsPassword.elasticsearch.cacert ? 'v-text-field' : 'v-textarea'">
              <template slot="label">
                <span>ca_cert</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.ca_cert`"
                  :copy-value="`${formContent.path}.ca_cert`"/>
              </template>
            </component>
          </template>

          <template v-if="typeIs.swift">
            <v-text-field
              v-model="$v.formContent.raw.username.$model"
              :error-messages="errors.raw.username"
              :append-icon="hideAsPassword.swift.username ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.swift.username ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="swift-username"
              @click:append="hideAsPassword.swift.username = !hideAsPassword.swift.username"
              @blur="$v.formContent.raw.username.$touch()">
              <template slot="label">
                <span>username</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.username`"
                  :copy-value="`${formContent.path}.username`"/>
              </template>
            </v-text-field>

            <component
              v-model="$v.formContent.raw.password.$model"
              :error-messages="errors.raw.password"
              :append-icon="hideAsPassword.swift.password ? 'visibility' : 'visibility_off'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="swift-password"
              :type="hideAsPassword.swift.password ? 'password' : 'text'"
              autocomplete="off"
              @click:append="hideAsPassword.swift.password = !hideAsPassword.swift.password"
              @blur="$v.formContent.raw.password.$touch()"
              :is="hideAsPassword.swift.password ? 'v-text-field' : 'v-textarea'">
              <template slot="label">
                <span>password</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.password`"
                  :copy-value="`${formContent.path}.password`"/>
              </template>
            </component>

            <v-text-field
              v-model="$v.formContent.raw.tenant_id.$model"
              :error-messages="errors.raw.tenant_id"
              :append-icon="hideAsPassword.swift.tenantId ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.swift.tenantId ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="swift-tenant_id"
              @click:append="hideAsPassword.swift.tenantId = !hideAsPassword.swift.tenantId"
              @blur="$v.formContent.raw.tenant_id.$touch()">
              <template slot="label">
                <span>tenant_id</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.tenant_id`"
                  :copy-value="`${formContent.path}.tenant_id`"/>
              </template>
            </v-text-field>

            <v-text-field
              v-model="$v.formContent.raw.domain_id.$model"
              :error-messages="errors.raw.domain_id"
              :append-icon="hideAsPassword.swift.domainId ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.swift.domainId ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="swift-domain_id"
              @click:append="hideAsPassword.swift.domainId = !hideAsPassword.swift.domainId"
              @blur="$v.formContent.raw.domain_id.$touch()">
              <template slot="label">
                <span>domain_id</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.domain_id`"
                  :copy-value="`${formContent.path}.domain_id`"/>
              </template>
            </v-text-field>

            <component
              v-model="$v.formContent.raw.auth_url.$model"
              :error-messages="errors.raw.auth_url"
              :append-icon="hideAsPassword.swift.authUrl ? 'visibility' : 'visibility_off'"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="swift-auth_url"
              :type="hideAsPassword.swift.authUrl ? 'password' : 'text'"
              autocomplete="off"
              @click:append="hideAsPassword.swift.authUrl = !hideAsPassword.swift.authUrl"
              @blur="$v.formContent.raw.auth_url.$touch()"
              :is="hideAsPassword.swift.authUrl ? 'v-text-field' : 'v-textarea'">
              <template slot="label">
                <span>auth_url</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.auth_url`"
                  :copy-value="`${formContent.path}.auth_url`"/>
              </template>
            </component>
          </template>

          <template v-if="typeIs.basic_auth">
            <v-text-field
              v-model="$v.formContent.raw.username.$model"
              :error-messages="errors.raw.username"
              :append-icon="hideAsPassword.basicAuth.username ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.basicAuth.username ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="basic_auth-username"
              @click:append="hideAsPassword.basicAuth.username = !hideAsPassword.basicAuth.username"
              @blur="$v.formContent.raw.username.$touch()">
              <template slot="label">
                <span>username</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.username`"
                  :copy-value="`${formContent.path}.username`"/>
              </template>
            </v-text-field>
            <v-text-field
              v-model="$v.formContent.raw.password.$model"
              :error-messages="errors.raw.password"
              :append-icon="hideAsPassword.basicAuth.password ? 'visibility' : 'visibility_off'"
              :type="hideAsPassword.basicAuth.password ? 'password' : 'text'"
              autocomplete="off"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              required
              class="required-field has-copy-btn"
              data-cy="basic_auth-password"
              @click:append="hideAsPassword.basicAuth.password = !hideAsPassword.basicAuth.password"
              @blur="$v.formContent.raw.password.$touch()">
              <template slot="label">
                <span>password</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.password`"
                  :copy-value="`${formContent.path}.password`"/>
              </template>
            </v-text-field>
          </template>

          <template v-if="typeIs.vmware">
            <v-text-field
              v-model="$v.formContent.raw.username.$model"
              v-bind="readOnlyAttrs"
              data-cy="vmware-username"
              spellcheck="false"
              rows="3"
              :append-icon="hideAsPassword.vmware.username ? 'visibility' : 'visibility_off'"
              :class="['has-copy-btn', { 'required-field': isFieldRequired.username }]"
              :error-messages="errors.raw.username"
              :required="isFieldRequired.username"
              :type="hideAsPassword.vmware.username ? 'password' : 'text'"
              @click:append="hideAsPassword.vmware.username = !hideAsPassword.vmware.username"
              @blur="$v.formContent.raw.username.$touch()">
              <template slot="label">
                <span>username</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.username`"
                  :copy-value="`${formContent.path}.username`"/>
              </template>
            </v-text-field>
            <v-text-field
              v-model="$v.formContent.raw.password.$model"
              data-cy="vmware-password"
              v-bind="readOnlyAttrs"
              spellcheck="false"
              rows="3"
              :append-icon="hideAsPassword.vmware.password ? 'visibility' : 'visibility_off'"
              :class="['has-copy-btn', { 'required-field': isFieldRequired.password }]"
              :error-messages="errors.raw.password"
              :required="isFieldRequired.password"
              :type="hideAsPassword.vmware.password ? 'password' : 'text'"
              @click:append="hideAsPassword.vmware.password = !hideAsPassword.vmware.password"
              @blur="$v.formContent.raw.password.$touch()">
              <template slot="label">
                <span>password</span>
                <CyCopyButton
                  v-if="formContent.path"
                  :copy-hint="`${formContent.path}.password`"
                  :copy-value="`${formContent.path}.password`"/>
              </template>
            </v-text-field>
          </template>
        </template>
      </template>

      <div
        v-if="typeIs.custom"
        slot="details_formFullWidth"
        class="mt-6 mb-2 py-2">
        <CyCredentialCustomKeyList
          :custom-list="formContent.raw"
          :errors="errors.raw"
          :path="formContent.path"
          :credential-canonical="credentialCanonical"
          v-bind="{ readOnlyAttrs }"
          data-cy="custom-credentials-list"
          class="cy-pair-key-value-list"
          @update-list="updateRaw"
          @update-validations="updateRawValidations"/>
      </div>
    </CyDetails>

    <CyModal
      v-if="show.deleteDialog"
      :header-title="$t('confirmDeleteHeader')"
      :loading="status.deleting"
      :action-btn-func="onDeleteConfirm"
      :action-btn-disabled="status.deleting"
      :cancel-btn-func="() => $toggle.show.deleteDialog(false)"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
      <h3>{{ formContent.name }}</h3>
      <p class="url">
        {{ formContent.path }}
      </p>
    </CyModal>
  </div>
</template>

<script>
/**
 * Credential page for CRUD actions to credential
 * There are several types of credential and they all have different .raw values
 * The credential type is formContent.type
 *
 * ssh.raw:             { ssh_key }
 * aws.raw:             { access_key, secret_key }
 * gcp.raw:             { json_key }
 * azure.raw:           { client_id, client_secret, subscription_id, tenant_id }
 * azure_storage.raw:   { account_name, access_key }
 * basic_auth.raw:      { username, password }
 * swift.raw:           { username, password, tenant_id, auth_url, domain_id }
 * custom.raw:          [{ key, value }, { key, value }, { key, value }]
 *
 */

import { mapState, mapActions, mapMutations } from 'vuex'
import CyCopyButton from '@/components/CyCopyButton.vue'
import CyCredentialCustomKeyList from '@/components/CyCredentialCustomKeyList.vue'
import CyDetails from '@/components/CyDetails.vue'
import CySelectOwner from '@/components/CySelectOwner.vue'
import { arrayToObject, constructBreadcrumb, checksPass, displayName, getOwnerUsername } from '@/utils/helpers'
import { pathIsValid, generalCredentials, vendorCredentials, CREDENTIAL_DEFAULTS } from '@/utils/helpers/credentials'
import { findDuplicates } from '@/utils/helpers/findDuplicates'
import { required, requiredIf } from 'vuelidate/lib/validators'

export const CREDENTIAL_TYPES = [...generalCredentials, ...vendorCredentials]
export const placeholder = '******'

export default {
  name: 'CyPageCredential',
  components: {
    CyCopyButton,
    CyCredentialCustomKeyList,
    CyDetails,
    CySelectOwner,
  },
  breadcrumb () {
    const { $isCreationRoute, storeCredential } = this
    const header = $isCreationRoute
      ? this.$t('createCredential')
      : storeCredential?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.credentials'),
        name: 'credentials',
      },
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    const { $isCreationRoute, loading } = this
    const { format, $formatTimeAgo, $getTimestamp } = this.$date
    const { name, owner, type, created_at, updated_at } = this.storeCredential || {}

    if (this.loading) return {}

    if ($isCreationRoute) return { title: this.$t('createCredential') }

    if (this.storeCredential) {
      return {
        title: name,
        owner,
        credential: type,
        metas: [
          this.$t('created', [format($getTimestamp(created_at), 'MMM d, yyyy')]),
          this.$t('updated', [$formatTimeAgo(updated_at)]),
        ],
        loading,
      }
    }
  },
  props: {
    credentialCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formContent: {
      name: '',
      path: '',
      type: '',
      raw: undefined,
      owner: null,
    },
    show: {
      deleteDialog: false,
    },
    hideAsPassword: {
      aws: {
        accessKey: true,
        secretKey: true,
      },
      azure: {
        clientId: true,
        clientSecret: true,
        subscriptionId: true,
        tenantId: true,
      },
      azureStorage: {
        accountName: true,
        accessKey: true,
      },
      basicAuth: {
        username: true,
        password: true,
      },
      elasticsearch: {
        username: true,
        password: true,
        cacert: true,
      },
      gcp: {
        jsonKey: true,
      },
      ssh: {
        sshKey: true,
      },
      vmware: {
        username: true,
        password: true,
      },
      swift: {
        username: true,
        password: true,
        tenantId: true,
        authUrl: true,
        domainId: true,
      },
    },
    status: {
      loading: false,
      deleting: false,
      saving: false,
    },
    isPathCustom: false,
  }),
  validations () {
    return {
      formContent: {
        name: { required },
        path: {
          validPath: (path) => pathIsValid(path),
          required,
        },
        type: { required },
        raw: { ...this.rawValidations },
        owner: {},
        description: {},
      },
    }
  },
  computed: {
    ...mapState('organization/credential', {
      credentialErrors: (state) => state.errors,
      storeCredential: (state) => state.detail,
    }),
    $static: () => ({
      CREDENTIAL_TYPES,
      credentialTypeOptions: [
        ...generalCredentials,
        { divider: true },
        ...vendorCredentials,
      ],
    }),
    hasOwnerChanged () {
      if (this.$isCreationRoute || _.isEmpty(this.credential?.owner)) return false
      return !_.isEqual(
        getOwnerUsername(this.credential?.owner),
        getOwnerUsername(this.storeCredential?.owner),
      )
    },
    rawValidations () {
      const { username, password } = this.formContent.raw || {}

      const rawValidations = {
        ssh: {
          ssh_key: { required },
        },
        aws: {
          access_key: { required },
          secret_key: { required },
        },
        gcp: {
          json_key: { required },
        },
        azure: {
          client_id: { required },
          client_secret: { required },
          subscription_id: { required },
          tenant_id: { required },
        },
        azure_storage: {
          account_name: { required },
          access_key: { required },
        },
        elasticsearch: {
          username: { required: requiredIf(() => _.some([username, password])) },
          password: { required: requiredIf(() => _.some([username, password])) },
          ca_cert: { required: requiredIf(() => _.every([username, password], _.isEmpty)) },
        },
        basic_auth: {
          username: { required },
          password: { required },
        },
        swift: {
          username: { required },
          password: { required },
          tenant_id: { required },
          auth_url: { required },
          domain_id: { required },
        },
        vmware: {
          username: { required },
          password: { required },
        },
        custom: {
          $each: {
            key: {
              required,
              isUnique: (key) => !this.duplicateKeys.includes(key),
            },
            value: {
              required,
            },
          },
        },
      }
      return rawValidations[_.$get(this.formContent, 'type')]
    },
    isFieldRequired () {
      const { username, password, ca_cert: caCert } = this.$v.formContent.raw
      return this.formContent.type === 'elasticsearch'
        ? {
            username: username.$params.required.prop(),
            password: password.$params.required.prop(),
            ca_cert: caCert.$params.required.prop(),
          }
        : {}
    },
    typeIs () {
      return { [this.formContent.type]: true }
    },
    duplicateKeys () {
      return this.typeIs.custom
        ? findDuplicates(_.map(this.formContent.raw, 'key'))
        : []
    },
    generatedPath () {
      const { name = '', canonical } = this.formContent
      return this.$isCreationRoute
        ? this.$getSlug(name)
        : canonical
    },
    credential () {
      const { created_at, owner, updated_at, name, ...credential } = this.formContent
      const canonical = this.$isCreationRoute ? this.$getSlug(name) : this.credentialCanonical
      return this.typeIs.custom
        ? { ...credential, name, owner, canonical, raw: { raw: arrayToObject(credential.raw, { key: 'key', value: 'value' }) } }
        : { ...credential, name, owner, canonical }
    },
    isRawLengthSatisfied () {
      return this.typeIs.custom
        ? !_.isEmpty(this.formContent.raw)
        : true
    },
    pathLabel () {
      return this.isPathCustom
        ? this.$t('credentials.customPath')
        : this.$t('forms.fieldPath')
    },
    hints () {
      return {
        path: this.isPathCustom
          ? this.$t('credentials.fieldPathHint')
          : this.$t('credentials.fieldPathReadonly'),
        raw: this.typeIs.custom && _.isEmpty(this.formContent.raw)
          ? this.$t('credentials.fieldTypeRawHint')
          : '',
      }
    },
    errors () {
      return {
        name: (() => {
          const errors = []
          const { $dirty, required } = this.$v.formContent.name
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          return errors
        })(),
        path: (() => {
          const errors = []
          const { $dirty, required, validPath } = this.$v.formContent.path
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          if (required && !validPath) errors.push(this.$t('credentials.pathInvalid'))
          return errors
        })(),
        type: (() => {
          const errors = []
          const { $dirty, required } = this.$v.formContent.type
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          return errors
        })(),
        raw: (() => {
          if (_.$isEmpty(this.formContent.type)) return []
          if (this.typeIs.aws) {
            return {
              secret_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.secret_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              access_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.access_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.gcp) {
            return {
              json_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.json_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.azure) {
            return {
              client_id: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.client_id
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              client_secret: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.client_secret
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              subscription_id: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.subscription_id
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              tenant_id: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.tenant_id
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.azure_storage) {
            return {
              account_name: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.account_name
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              access_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.access_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.elasticsearch) {
            return {
              username: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.username
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              password: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.password
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              ca_cert: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.ca_cert
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.basic_auth) {
            return {
              username: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.username
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              password: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.password
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.ssh) {
            return {
              ssh_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.ssh_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.swift) {
            return {
              username: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.username
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              password: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.password
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              tenant_id: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.tenant_id
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              auth_url: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.auth_url
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              domain_id: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.domain_id
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.vmware) {
            return {
              username: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.username
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              password: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formContent.raw.password
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.custom) {
            return (() => {
              const errors = {}
              const isRawContentArray = !Array.isArray(this.formContent?.raw)
              if (isRawContentArray) _.set(this.formContent, 'raw', [])
              const customKeyPairs = _.$get(this.formContent, 'raw', [])
              customKeyPairs.forEach(({ key, value }, index) => {
                errors[index] = {
                  key: (() => {
                    const errors = []
                    const { $dirty, required, isUnique } = this.$v.formContent.raw.$each[index].key
                    if (!$dirty) return errors
                    if (!required) errors.push(this.$t('forms.fieldRequired'))
                    if (!isUnique) errors.push(this.$t('forms.fieldMustBeUnique'))
                    return errors
                  })(),
                  value: (() => {
                    const errors = []
                    const { $dirty, required } = this.$v.formContent.raw.$each[index].value
                    if (!$dirty) return errors
                    if (!required) errors.push(this.$t('forms.fieldRequired'))
                    return errors
                  })(),
                }
              })
              return errors
            })()
          }
        })(),
      }
    },
    isCycloidCredential () {
      return ['vault', 'cycloid-worker-keys'].includes(this.credentialCanonical)
    },
    cycloidCredentialNotification () {
      if (!this.isCycloidCredential) return ''
      return {
        'cycloid-worker-keys': this.$t('defaultCredentialAlert.someEdit'),
      }[this.credentialCanonical] || this.$t('defaultCredentialAlert.noEdit')
    },
    isReadOnly () {
      return !_.some([
        this.$isCreationRoute,
        this.$cycloid.permissions.canDisplay('UpdateCredential', this.credentialCanonical),
      ])
    },
    isOwner () {
      return this.isEntityOwner(this.formContent)
    },
    readOnlyAttrs () {
      const canRead = this.$cycloid.permissions.canDisplay('GetCredential', this.credentialCanonical)
      const canUpdate = !this.isReadOnly

      const fullyHiddenAttrs = {
        readonly: true,
        disabled: true,
        placeholder,
        persistentPlaceholder: true,
        class: 'cy-input--no-ripple',
      }
      const partiallyHiddenAttrs = { readonly: true, class: 'v-input--is-disabled cy-input--no-ripple' }

      if (this.isCycloidCredential) return canRead ? partiallyHiddenAttrs : fullyHiddenAttrs
      if (canUpdate) return {}
      if (canRead) return partiallyHiddenAttrs
      return fullyHiddenAttrs
    },
    canSave () {
      return checksPass({
        isRawLengthSatisfied: this.isRawLengthSatisfied,
        noDuplicateKeys: !this.duplicateKeys.length,
        noErrors: !this.$v.$anyError,
        allValid: !this.$v.$invalid,
        anyDirty: this.$hasDataChanged('formContent'),
      })
    },
  },
  watch: {
    isPathCustom (newVal) {
      if (_.isNull(newVal)) return
      if (!newVal) this.setPathToGenerated()
    },
    'formContent.name' (newVal, oldVal) {
      if (!this.$hasDataChanged('formContent')) return
      if (!this.isPathCustom) this.setPathToGenerated()
      this.$v.formContent.name.$touch()
    },
    'formContent.path' (newVal) {
      if (!this.$hasDataChanged('formContent')) return
      this.$v.formContent.path.$touch()
    },
    'formContent.type' (newVal, oldVal) {
      if (!this.$hasDataChanged('formContent') || !this.$isCreationRoute) return
      if (!this.isPathCustom) this.setPathToGenerated()
      this.setRaw(newVal)
      this.$v.formContent.type.$touch()
    },
    'formContent.raw': {
      handler (newVal, oldVal) {
        if (!this.$hasDataChanged('formContent')) return
        const addingItem = newVal && oldVal ? newVal.length === oldVal.length + 1 : false
        const noChange = newVal === oldVal
        const isReset = newVal === undefined

        if (noChange) return
        if (this.typeIs.custom) {
          if (addingItem) return this.$v.formContent.raw.$each[oldVal.length].$reset()
          if (isReset) this.$v.formContent.raw.$reset()
        }
      },
      deep: true,
    },
    formContent (newVal, oldVal) {
      if (!this.$hasDataChanged('formContent')) {
        this.setIsPathCustom(this.formContent.path !== this.generatedPath)
        this.$v.$reset()
      }
    },
  },
  created () {
    if (this.$isCreationRoute) {
      for (const credType in this.hideAsPassword) {
        for (const field in this.hideAsPassword[credType]) {
          this.hideAsPassword[credType][field] = false
        }
      }
    }
  },
  async mounted () {
    if (!this.$isCreationRoute) await this.getCredential()
    else this.setPathToGenerated()
  },
  beforeDestroy () {
    this.RESET_CREDENTIAL_STATE()
  },
  methods: {
    ...mapActions('organization/credential', [
      'CREATE_CREDENTIAL',
      'DELETE_CREDENTIAL',
      'GET_CREDENTIAL',
      'UPDATE_CREDENTIAL',
    ]),
    ...mapMutations('organization/credential', [
      'RESET_CREDENTIAL_STATE',
    ]),
    async getCredential () {
      const { credentialCanonical } = this

      this.$toggle.status.loading(true)
      await this.GET_CREDENTIAL({ credentialCanonical })
      this.$toggle.status.loading(false)

      if (_.isEmpty(this.credentialErrors)) {
        const credential = this.storeCredential?.type === 'custom'
          ? { ...this.storeCredential, raw: [...this.getRawList(this.storeCredential)] }
          : this.storeCredential
        this.setFormContent(credential)
      }
    },
    async onSave () {
      const { $router, credential, hasOwnerChanged } = this

      const successMessage = hasOwnerChanged && this.$t('alerts.success.credential.reassigned', {
        credentialName: credential.name,
        owner: displayName(credential.owner),
      })

      this.$toggle.status.saving(true)
      this.$isCreationRoute
        ? await this.CREATE_CREDENTIAL({ credential, $router })
        : await this.UPDATE_CREDENTIAL({ credential, successMessage })
      this.$toggle.status.saving(false)

      if (!_.isEmpty(this.credentialErrors)) return

      this.$setOriginalData('formContent')
      this.$v.formContent.$reset()
    },
    async onDeleteConfirm () {
      const { credential, $router } = this

      this.$toggle.status.deleting(true)
      await this.DELETE_CREDENTIAL({ credential, $router })
      this.$toggle.status.deleting(false)

      this.$toggle.show.deleteDialog(false)
    },
    setIsPathCustom (value = true) {
      this.isPathCustom = Boolean(value)
    },
    setPathToGenerated () {
      this.formContent.path = this.generatedPath
    },
    updateRaw (rawList) {
      this.$set(this.formContent, 'raw', _.cloneDeep(rawList))
      this.$forceUpdate()
    },
    updateRawValidations (index, fieldToUpdate) {
      this.$v.formContent.raw.$each[index][fieldToUpdate].$touch()
    },
    setRaw (type) {
      this.$set(this.formContent, 'raw', _.cloneDeep(CREDENTIAL_DEFAULTS[type]))
    },
    getRawList (credential) {
      const rawValues = credential?.raw?.raw
      return rawValues
        ? _.map(rawValues, (value, key) => ({ key, value }))
        : _.map(credential.keys, (key) => ({ key, value: placeholder }))
    },
    setFormContent (formContent) {
      this.formContent = { ...this.formContent, ..._.cloneDeep(formContent) }
      this.setIsPathCustom(this.formContent.path !== this.generatedPath)
      this.$setOriginalData()
      this.$v.$reset()
    },
    onCancel () {
      this.$resetData('formContent')
      this.RESET_CREDENTIAL_STATE('errors')
      this.$v.$reset()
    },
    isReadonlyField (field) {
      if (this.$isCreationRoute) return false
      if (this.isCycloidCredential) return ['name', 'path', 'type'].includes(field) || this.isReadOnly

      return this.isReadOnly || {
        owner: !this.$cycloid.permissions.canDisplay('UpdateCredential', this.credentialCanonical),
      }[field] || false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.credential',
        created: 'Created on {0}',
        updated: 'Updated {0}',
        confirmDeleteHeader: 'Delete @:credential?',
        confirmDeleteSentence: 'Are you sure that you want to delete this @:credential?',
        createCredential: 'Create @:credential',
        defaultCredentialAlert: {
          noEdit: 'This is a default @:credential and cannot be modified.',
          someEdit: 'This is a default @:credential, so most fields cannot be modified.',
        },
        elasticSearchValidationsExplanation: 'The fields below become required or optional depending upon the fields you provide values for.',
        forExample: 'For Example...',
      },
      es: {
        title: '@:routes.credential',
        created: 'Creado el {0}',
        updated: 'Actualizado {0}',
        confirmDeleteHeader: 'Borrar @:credential?',
        confirmDeleteSentence: 'Estás seguro de querer borrar esta @:credential ?',
        createCredential: 'Crear @:credential',
        defaultCredentialAlert: {
          noEdit: 'Esta es una @:credential predeterminada y no se puede modificar.',
          someEdit: 'Esta es una @:credential predeterminada, por lo que la mayoría de los campos no se pueden modificar.',
        },
        elasticSearchValidationsExplanation: 'The fields below become required or optional depending upon the fields you provide values for.',
        forExample: 'For Example...',
      },
      fr: {
        title: '@:routes.credential',
        created: 'Créé le {0}',
        updated: 'Mis à jour {0}',
        confirmDeleteHeader: `Supprimer l'@:credential ?`,
        confirmDeleteSentence: `Êtes-vous sûr de vouloir supprimer cette @:credential ?`,
        createCredential: `Nouvelle @:credential`,
        defaultCredentialAlert: {
          noEdit: `Il s'agit d'une @:credential par défaut et ne peut pas être modifié.`,
          someEdit: `Il s'agit d'une @:credential par défaut, la plupart des champs ne peuvent donc pas être modifiés.`,
        },
        elasticSearchValidationsExplanation: 'The fields below become required or optional depending upon the fields you provide values for.',
        fieldGitHint: 'Ce champ doit contenir une URL Git valide',
        forExample: 'For Example...',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .has-copy-btn {
    ::v-deep {
      .v-label {
        height: auto;
        cursor: pointer;
        pointer-events: auto;

        .cy-copy-btn {
          margin-left: 0.5em;
        }
      }

      input {
        margin-top: 2px;
      }

      textarea {
        margin-top: 8px;
        padding-top: 0;
      }
    }
  }

  .v-card__title {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }

  .url {
    color: cy-get-color("black", $alpha: 0.5);
  }

  .refresh-btn {
    margin: 0 0 48px;
  }

  .cy-pair-key-value-list {
    overflow-x: hidden;
  }

  .credential__type {
    margin-top: 1.5em;
  }

  .es-validations-explanation {
    margin-top: 1em;

    &__examples {
      width: 100%;
      padding-top: 1em;
      font-size: 12px;

      .for-example {
        padding-bottom: 1em;
        font-style: italic;
      }

      .examples {
        padding-left: 0;
      }

      .example {
        display: flex;
        align-items: center;
        color: cy-get-color("grey", "dark-2");

        &__icon {
          margin-right: 0.25em;
          color: cy-get-color("success", "dark-2");
          font-size: 16px;
        }
      }
    }
  }

  .credential--item {
    display: flex;
    align-items: center;
  }

  .cy-input--no-ripple,
  ::v-deep .cy-input--no-ripple {
    .v-input__control > .v-input__slot::after {
      transform: scaleX(0);
    }
  }
</style>

/** Find the duplicates within an array
 * ! Best used with primitives, as arrays and objects will not be parsed
 *
 * @param   {array} [array]   - any array (ideally of primitives)
 * @returns [array]           - an array of unique values, that appear more than once in the original array
 */
export function findDuplicates (array) {
  if (!Array.isArray(array)) {
    console.error(`[findDuplicates]: was not passed an array`, array)
    return []
  }
  const keyCount = _.countBy(array)
  if (Object.keys(keyCount).includes('[object Object]')) {
    console.warn(`[findDuplicates]: was passed an array containing an Object, of which is not parsed`, array)
  }
  return Object.keys(keyCount).filter((key) => keyCount[key] > 1)
}

export default {
  en: {
    edit: 'Edit watch rule',
    lastTriggered: 'Last triggered',
    mute: 'Mute',
    muted: 'Muted',
    mutedAlertContent: 'Muted watch rules do not emit notifications.',
    mutedAlertTitle: 'This watch rule is muted.',
    unmute: 'Unmute',
    watchRuleType: 'Watch rule type',
    watchRuleTypes: {
      project: {
        name: 'Project activity',
        description: 'Get notified when changes are made within a project.',
      },
      event: {
        name: 'Event tracking',
        description: 'Get notified for specific events within an organization.',
      },
    },
  },
  es: {
    edit: 'Editar regla',
    lastTriggered: 'Último activado',
    mute: 'Silenciar',
    muted: 'Silenciado',
    mutedAlertContent: 'Las reglas silenciadas no emiten notificaciones.',
    mutedAlertTitle: 'Esta regla está silenciada.',
    unmute: 'Activar',
    watchRuleType: 'Tipo de regla de monitoreo',
    watchRuleTypes: {
      project: {
        name: 'Actividad del proyecto',
        description: 'Recibe notificaciones cuando se realizan cambios dentro de un proyecto.',
      },
      event: {
        name: 'Seguimiento de eventos',
        description: 'Recibe notificaciones para eventos específicos dentro de una organización.',
      },
    },
  },
  fr: {
    edit: 'Modifier la règle',
    lastTriggered: 'Dernier déclenchement',
    mute: 'Désactiver',
    muted: 'Désactivée',
    mutedAlertContent: `Les règles désactivées n'émettent pas de notifications.`,
    mutedAlertTitle: 'Cette règle est désactivée.',
    unmute: 'Activer',
    watchRuleType: 'Type de règle de notification',
    watchRuleTypes: {
      project: {
        name: 'Activité du projet',
        description: `Recevez des notifications lorsque des changements sont apportés à un projet.`,
      },
      event: {
        name: 'Suivi des événements',
        description: `Recevez des notifications pour des événements spécifiques au sein d'une organisation.`,
      },
    },
  },
}

<template>
  <v-data-table
    :items="!loading ? topProjects : []"
    :items-per-page="numOfVisibleItems"
    :headers="$static.headers"
    :custom-sort="sortItems"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :class="{ 'v-data-table--loading': loading }"
    :loading="loading"
    :loader-height="0"
    :hide-default-header="loading"
    hide-default-footer
    @update:sort-by="expanded = []"
    @update:sort-desc="expanded = []">
    <template #item="{ item, index }">
      <tr
        class="project-row clickable"
        :data-cy="item.providers.length < 2 ? 'link-listed-projects' : ''"
        @click="handleRowExpand(item, index)">
        <td>
          <v-icon v-if="item.providers.length > 1">
            {{ _.includes(expanded, index) ? 'expand_less' : 'expand_more' }}
          </v-icon>
        </td>
        <td class="provider-canonical">
          <v-badge
            class="provider-canonical__badge"
            :color="getItemColor(item.canonical)"
            inline
            left/>
          <CyTooltip
            open-delay="250"
            top>
            <template #activator="{ on }">
              <span
                class="provider-canonical__text"
                v-on="on">
                {{ getDisplayName(item.canonical, 'project') }}
              </span>
            </template>
            {{ getDisplayName(item.canonical, 'project') }}
          </CyTooltip>
        </td>
        <td class="pr-0">
          <CyCondensedAvatar
            :images="getProviderImages(item.providers)"
            class="clickable"
            greyscale/>
        </td>
        <!-- TODO: FE#6429 restore this column when resources can be returned by BE -->
        <td v-if="false">
          <CyTag variant="default">
            {{ item.resource_count }}
          </CyTag>
        </td>
        <!-- TODO: FE#6429 restore this column when resources can be returned by BE -->
        <td
          v-if="false"
          class="text-right pr-8">
          <CyTag variant="default">
            {{ item.new_resources_count }}
          </CyTag>
        </td>
        <template v-for="dataType in ['co2e', 'kwh', 'cost']">
          <td
            :key="`${dataType}Total`"
            class="total">
            {{ formatAmount(item[dataType].total) }}
          </td>
          <td
            :key="`${dataType}Trend`"
            class="trend">
            <CyCcmTrendChip
              :value="[item[dataType].first, item[dataType].last]"/>
          </td>
        </template>
      </tr>
      <tr
        v-if="item.providers.length > 1"
        class="providers-row">
        <td
          colspan="100%"
          class="pa-0 panels-wrapper">
          <v-expansion-panels :value="_.includes(expanded, index) ? 0 : null">
            <v-expansion-panel>
              <v-expansion-panel-content>
                <table class="provider-details">
                  <tr
                    v-for="provider in item.providers"
                    :key="`${item.canonical}__${provider.canonical}`"
                    class="provider-detail clickable"
                    data-cy="link-listed-projects"
                    eager
                    @click="gotoProviderDetail(provider.canonical, item.canonical)">
                    <td/>
                    <td class="provider-share">
                      <component
                        :key="getProviderImages([provider])[0].title"
                        :width="24"
                        :height="24"
                        class="provider-share__logo mr-8"
                        :is="getProviderImages([provider])[0].src"/>
                      <span class="provider-share__value mr-2">
                        {{ formatPercentage(getProviderShare(item.cost.total, provider.cost)) }}
                      </span>
                      <v-progress-linear
                        :value="getProviderShare(item.cost.total, provider.cost)"
                        width="80"
                        height="2"
                        rounded
                        color="secondary"
                        background-color="default"
                        class="provider-share__progress"/>
                    </td>
                    <!-- TODO: FE#6429 restore this column when resources can be returned by BE -->
                    <CyTag
                      v-if="false"
                      variant="default">
                      {{ provider.resource_count }}
                    </CyTag>
                    <!-- TODO: FE#6429 restore this column when resources can be returned by BE -->
                    <span
                      v-if="false"
                      class="text-right pr-7">
                      <CyTag variant="default">
                        {{ provider.new_resources_count }}
                      </CyTag>
                    </span>
                    <template v-for="dataType in ['co2e', 'kwh', 'cost']">
                      <td
                        :key="`${dataType}Total`"
                        class="total">
                        {{ formatAmount(provider[dataType]) }}
                      </td>
                      <!-- TODO: FE#6429 Restore this trend display once BE returns enough data for it -->
                      <CyCcmTrendChip
                        v-if="false"
                        :key="`${dataType}Trend`"
                        :value="[provider.previous_total, provider.current_total]"/>
                      <td
                        v-else
                        :key="`${dataType}Trend`"/>
                    </template>
                  </tr>
                </table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </td>
      </tr>
    </template>
    <template slot="body.append">
      <tr v-if="!loading && !areItemsLoaded">
        <td colspan="8">
          <CyInfiniteScrolling
            :stop-loading="areItemsLoaded"
            class="sk-template d-flex flex-column px-3 pb-3"
            @load-more="numOfVisibleItems += $static.infiniteScrollItemsStep">
            <div
              v-for="i in 3"
              :key="`skeleton-row-${i}`"
              class="d-flex justify-space-between py-3">
              <div class="d-flex ml-12">
                <div class="sk-block sk-avatar sk-w-5 sk-h-5 mr-4"/>
                <div class="sk-block sk-title sk-dark sk-w-28"/>
              </div>
              <div class="d-flex">
                <div class="sk-block sk-title sk-w-8 mr-12"/>
                <div class="sk-block sk-title sk-w-18 mr-8 ml-8"/>
                <div class="sk-block sk-title sk-w-20 ml-2"/>
              </div>
            </div>
          </CyInfiniteScrolling>
        </td>
      </tr>
    </template>
    <template #foot>
      <td
        class="footer-cell"
        colspan="3">
        <div class="cell-content equivalence">
          <CyCcmEmissionEquivalence
            icon-size="37"
            :value="_.sumBy(topProjects, 'co2e.total')"/>
        </div>
      </td>
      <td class="footer-cell">
        <div class="cell-content total-cell">
          <span>
            KgCO2e
          </span>
          <span>
            {{ formatAmount(_.sumBy(topProjects, 'co2e.total')) }}
          </span>
        </div>
      </td>
      <td class="footer-cell trend-cell">
        <div class="cell-content trend-cell">
          <span/>
          <span>
            <CyCcmTrendChip :value="geTotalTrendByType('co2e')"/>
          </span>
        </div>
      </td>
      <td class="footer-cell">
        <div class="cell-content total-cell">
          <span>
            kWh
          </span>
          <span>
            {{ formatAmount(_.sumBy(topProjects, 'kwh.total')) }}
          </span>
        </div>
      </td>
      <td class="footer-cell trend-cell">
        <div class="cell-content trend-cell">
          <span/>
          <span>
            <CyCcmTrendChip :value="geTotalTrendByType('kwh')"/>
          </span>
        </div>
      </td>
      <td class="footer-cell">
        <div class="cell-content total-cell">
          <span>
            {{ $t('untranslated.total') }} {{ $t('cloudCostManagement.cost') }} ({{ getCurrencySymbol(currency) }})
          </span>
          <span>
            {{ formatAmount(_.sumBy(topProjects, 'cost.total')) }}
          </span>
        </div>
      </td>
      <td class="footer-cell trend-cell">
        <div class="cell-content trend-cell">
          <span/>
          <span>
            <CyCcmTrendChip :value="geTotalTrendByType('cost')"/>
          </span>
        </div>
      </td>
    </template>
    <template #loading>
      <div class="sk-template d-flex flex-column py-3">
        <div
          v-for="i in 3"
          :key="`skeleton-row-${i}`"
          class="d-flex justify-space-between pa-3">
          <div class="d-flex align-center">
            <div class="sk-block sk-avatar sk-w-5 sk-h-5 mr-4"/>
            <div class="sk-block sk-title sk-dark sk-w-28"/>
          </div>
          <div class="d-flex align-center">
            <div class="sk-block sk-title sk-w-6 mr-8"/>
            <div class="sk-block sk-title sk-w-16 mr-8"/>
            <div class="sk-block sk-title sk-w-10 mr-8"/>
            <div class="sk-block sk-title sk-w-20"/>
          </div>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import CyCcmEmissionEquivalence from '@/components/CyCcmEmissionEquivalence.vue'
import CyCcmTrendChip from '@/components/CyCcmTrendChip.vue'
import CyCondensedAvatar from '@/components/CyCondensedAvatar.vue'
import CyInfiniteScrolling from '@/components/CyInfiniteScrolling.vue'
import { formatAmount, getCurrencySymbol } from '@/utils/helpers'
import { formatPercentage, getDisplayName, graphColors, sortItems } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCcmTopProjectsTable',
  components: {
    CyCondensedAvatar,
    CyCcmTrendChip,
    CyCcmEmissionEquivalence,
    CyInfiniteScrolling,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    topItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    expanded: [],
    sortBy: 'cost.total',
    sortDesc: true,
    numOfVisibleItems: 10,
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      currency: (state) => state.queryBody.currency,
      topProjects: (state) => state.topProjects.resources,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfo',
    ]),
    $static () {
      return {
        headers: [
          {
            text: '',
            value: 'data-table-expand',
            width: '24px',
          },
          {
            text: this.$t('project'),
            align: 'left',
            value: 'canonical',
            width: '240px',
          },
          {
            text: this.$t('providers'),
            align: 'left',
            value: 'providers',
            width: '112px',
          },
          // TODO: FE#6429 restore these columns when resources can be returned by BE
          // {
          //   text: this.$t('resources'),
          //   align: 'left',
          //   value: 'resource_count',
          //   width: '60px',
          // },
          // {
          //   text: this.$t('newResources'),
          //   align: 'right',
          //   value: 'new_resources_count',
          //   width: '90px',
          // },
          {
            text: 'KgC02e',
            align: 'right',
            value: 'co2e.total',
          },
          {
            text: '',
            align: 'right',
            value: 'co2e.first',
            width: '90px',
            class: 'header__trend',
            sortable: false,
          },
          {
            text: 'kWh',
            align: 'right',
            value: 'kwh.total',
          },
          {
            text: '',
            align: 'right',
            value: 'kwh.first',
            width: '90px',
            class: 'header__trend',
            sortable: false,
          },
          {
            text: `${this.$t('cloudCostManagement.cost')} (${this.getCurrencySymbol(this.currency)})`,
            align: 'right',
            value: 'cost.total',
          },
          {
            text: '',
            align: 'right',
            value: 'previous_total',
            width: '90px',
            class: 'header__trend',
            sortable: false,
          },
        ],
        infiniteScrollItemsStep: 15,
      }
    },
    areItemsLoaded () {
      return this.numOfVisibleItems >= this.topProjects.length
    },
  },
  watch: {
    sortBy (sortParam) {
      const value = _.head(sortParam?.split('.'))
      if (!['cost', 'kwh', 'co2e'].includes(value)) {
        this.SET_GRAPH_ORDERING_PARAM('cost')
        return
      }
      this.SET_GRAPH_ORDERING_PARAM(value)
    },
  },
  destroyed () {
    this.SET_GRAPH_ORDERING_PARAM('cost')
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_GRAPH_ORDERING_PARAM',
    ]),
    formatAmount,
    formatPercentage,
    getCurrencySymbol,
    getDisplayName,
    sortItems,
    handleRowExpand (item, index) {
      if (_.isEmpty(item.providers)) return
      if (item.providers.length < 2) {
        this.gotoProviderDetail(_.head(item.providers).canonical, item.canonical)
        return
      }
      this.expanded = _.xor(this.expanded, [index])
    },
    getItemColor (itemCanonical) {
      const canonical = itemCanonical || 'undefined'
      const itemIndex = _.indexOf(this.topItems, canonical)
      const othersIndex = _.indexOf(this.topItems, this.$t('others'))
      const colorIndex = itemIndex === -1 ? othersIndex : itemIndex
      return graphColors[colorIndex]
    },
    getProviderImages (providers) {
      return _.map(providers, ({ canonical }) => ({
        src: _.find(this.getProviderExtraInfo, ['canonical', canonical]).logo,
        title: _.find(this.getProviderExtraInfo, ['canonical', canonical]).displayName,
      }))
    },
    getProviderShare (projectTotal, providerTotal) {
      if (!projectTotal) return 0
      return providerTotal / projectTotal * 100
    },
    gotoProviderDetail (providerCanonical, projectCanonical) {
      const params = {
        defaultQueryFilters: [
          { key: 'group_by', value: ['service', null] },
          { key: 'projects', value: [projectCanonical] },
        ],
        providerCanonical,
      }
      this.$router.push({ name: 'cloudCostManagementProviderDetail', params })
    },
    geTotalTrendByType (dataType) {
      return [_.sumBy(this.topProjects, `${dataType}.first`), _.sumBy(this.topProjects, `${dataType}.last`)]
    },
  },
  i18n: {
    messages: {
      en: {
        aws: '@:untranslated.aws.alias',
        azure: '@:untranslated.azure',
        flexeng: '@:untranslated.flexeng',
        gcp: '@:untranslated.gcp.alias',
        newResources: 'New resources',
        project: '@:Project',
        providers: 'Providers',
        resources: '@:Resources',
      },
      es: {
        aws: '@:untranslated.aws.alias',
        azure: '@:untranslated.azure',
        flexeng: '@:untranslated.flexeng',
        gcp: '@:untranslated.gcp.alias',
        newResources: 'Nuevos recursos',
        project: '@:Project',
        providers: 'Proveedor',
        resources: '@:Resources',
      },
      fr: {
        aws: '@:untranslated.aws.alias',
        azure: '@:untranslated.azure',
        flexeng: '@:untranslated.flexeng',
        gcp: '@:untranslated.gcp.alias',
        newResources: 'Nouvelles resources',
        project: '@:Project',
        providers: 'Fournisseur',
        resources: '@:Resources',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep.v-data-table {
    position: relative;
    overflow: hidden;
    border-top: 1px solid cy-get-color("grey", "light-1");

    &--loading {
      border-top: none;
    }

    .v-data-table-header th {
      font-size: $font-size-default;

      &:not(.header__trend) {
        color: cy-get-color("grey", "dark-3");
      }
    }

    .provider-canonical {
      display: flex;
      align-items: center;
      min-width: 150px;
      max-width: 240px;

      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .v-badge {
      &__wrapper {
        display: flex;
        margin-right: 17px;
        margin-left: 0;
      }

      &__badge {
        width: 6px;
        min-width: 0;
        height: 6px;
        padding: 0;
      }
    }

    .panels-wrapper {
      height: auto;
      border-bottom: 0 !important;
    }

    .header__trend {
      color: cy-get-color("grey", "dark-1") !important;
    }

    .trend-chip {
      font-size: $font-size-sm;
    }

    .trend {
      padding-right: 16px;
      padding-left: 0;
    }

    .total,
    .trend {
      text-align: right;
      white-space: nowrap;
    }

    .total {
      color: cy-get-color("primary");
      font-weight: $font-weight-bolder;
    }

    .project-row {
      .total {
        min-width: 100px;
      }
    }

    .footer-cell {
      padding: 8px 16px 12px;
      border-top: thin solid cy-get-color("black", $alpha: 0.12);
      background-color: cy-get-color("grey", "light-4");
      vertical-align: middle;

      .cell-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        min-height: 100%;

        > span:first-of-type {
          margin-bottom: 20px;
        }

        &.total-cell {
          min-width: 90px;
          color: cy-get-color("primary");
          font-weight: $font-weight-bolder;
        }

        &.trend-cell {
          min-width: 70px;
          height: 62px;
          color: cy-get-color("grey", "dark-1");
          font-weight: $font-weight-bolder;
        }

        .emission-equivalence {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }

  ::v-deep .v-data-table__wrapper {
    @extend %cy-scrollbars;
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .provider-details {
    display: grid;
    grid-template-columns: 56px 352px repeat(3, 1fr 102px);
    flex: 1;
    border-collapse: collapse;
    background-color: cy-get-color("grey", "light-4");

    tr {
      display: contents;

      td {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 8px 16px;

        &.total {
          justify-content: flex-end;
        }
      }

      &:hover td {
        transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      }

      background-color: cy-get-color("grey", "light-2");
    }

    &:last-child {
      border-bottom: thin solid cy-get-color("black", $alpha: 0.12) !important;
    }

    .provider-share {
      display: flex;
      align-items: center;

      &__value {
        width: 60px;
      }

      &__logo {
        filter: grayscale(1);
      }

      &__progress {
        width: 80px;
        background-color: cy-get-color("grey");
      }
    }
  }
</style>

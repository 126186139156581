<template>
  <div class="layout__container d-flex">
    <div
      v-if="!tagMapping || loading"
      class="layout__content--loading">
      <v-progress-circular
        class="loading-spinner"
        indeterminate
        size="100"
        color="secondary"/>
    </div>
    <div
      v-else
      class="layout__content">
      <div class="layout__main-content">
        <div class="layout__main-column">
          <CyCcmTagMapping
            :tag-mapping="formData.tagMapping"
            :tag-mapping-tags="tagMappingTags"
            :environment-selection-mode="formData.tagMappingMode.environments"
            :project-selection-mode="formData.tagMappingMode.projects"
            :disabled="saving || loading || !$cycloid.permissions.canDisplay('PutCloudCostManagementTagMapping')"
            @change="$set(formData.tagMapping, $event.key, $event.data)"
            @change-environments-selection-mode="formData.tagMappingMode.environments = $event"
            @change-projects-selection-mode="formData.tagMappingMode.projects = $event"
            @is-valid="validTagMapping = $event"/>
        </div>
      </div>
      <div v-has-rights-to="'PutCloudCostManagementTagMapping'">
        <v-divider class="actions-border mt-8 mx-n8"/>
        <div class="layout__actions py-4 mx-n8 mb-n8 white">
          <CyButton
            v-if="$hasDataChanged('formData')"
            variant="secondary"
            theme="primary"
            icon="close"
            :disabled="saving"
            @click="setDefaults">
            {{ $t('forms.btnCancel') }}
          </CyButton>
          <CyButton
            class="ml-5"
            :disabled="!validTagMapping || !$hasDataChanged('formData')"
            :loading="saving"
            theme="success"
            icon="check"
            @click="saveChanges">
            {{ $t('forms.btnSave') }}
          </CyButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyCcmTagMapping from '@/components/CyCcmTagMapping.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageCloudCostManagementTagMapping',
  components: {
    CyCcmTagMapping,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.cloudCostManagementTagMapping'), [
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagementSection'),
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.cloudCostManagementTitle'),
      description: {
        text: this.$t('routes.cloudCostManagementSectionDescription'),
      },
    }
  },
  data: () => ({
    saving: false,
    loading: true,
    validTagMapping: false,
    formData: {
      tagMapping: {},
      tagMappingMode: {
        projects: 'tagSelection',
        environments: 'advanced',
      },
    },
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      tagMapping: (state) => state.tagMapping,
      tagMappingTags: (state) => state.tagMappingTags,
    }),
  },
  mounted () {
    this.setDefaults()
  },
  methods: {
    ...mapActions('organization/cloudCostManagement', [
      'GET_TAG_MAPPING',
      'UPDATE_TAG_MAPPING',
    ]),
    async setDefaults () {
      await this.GET_TAG_MAPPING()
      this.formData.tagMapping = _.cloneDeep(this.tagMapping)
      this.setDefaultTagMappingModes()
      this.$toggle.loading(false)
      this.$setOriginalData()
    },
    async saveChanges () {
      this.$toggle.saving(true)
      const tagMapping = _.omit(this.formData.tagMapping, [
        this.formData.tagMappingMode.environments === 'advanced' ? 'environment_tags' : 'environment_regex',
        this.formData.tagMappingMode.projects === 'advanced' ? 'project_tags' : 'project_regex',
      ])
      await this.UPDATE_TAG_MAPPING({ tagMapping })
      this.$setOriginalData()
      this.$toggle.saving(false)
    },
    setDefaultTagMappingModes () {
      this.$set(this.formData, 'tagMappingMode', {
        projects: _.$isEmpty(this.tagMapping?.project_regex) ? 'tagSelection' : 'advanced',
        environments: _.$isEmpty(this.tagMapping?.environment_regex) ? 'tagSelection' : 'advanced',
      })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.cloudCostManagementTagMapping',
      },
      es: {
        title: '@:routes.cloudCostManagementTagMapping',
      },
      fr: {
        title: '@:routes.cloudCostManagementTagMapping',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 4px;
  }

  &__content {
    flex-direction: column;
  }

  &__content,
  &__main-content {
    display: flex;
    flex-grow: 1;

    &--loading {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
  }

  &__main-column {
    flex-grow: 1;
    max-width: 1219px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
  }
}

.actions-border {
  max-width: unset;
}
</style>

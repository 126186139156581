var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"id":"cy-credentials-table","fetch-available":{ keyPath: 'credentials' },"headers":_vm.$static.headers,"link-builder":_vm.getLinkTarget,"searchable-fields":_vm.$static.searchableFields,"bulk":_vm.hasBulkModeEnabled,"key-field":"canonical"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
var selected = ref.selected;
return [(!_vm._.isEmpty(selected))?[_c('CyDevButton',{staticClass:"my-0 mr-2",attrs:{"wrap-dev-text":false},nativeOn:{"click":function($event){return _vm.setMissingOwners(selected)}}},[_vm._v(" Set missing owner ")]),_c('CyButton',{attrs:{"theme":"error","icon":"delete","data-cy":"delete-credential-button"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm.$tc('deleteCredentialBtn', selected.length, { nb: selected.length }))+" ")])]:_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateCredential'),expression:"'CreateCredential'"}],attrs:{"icon":"add","data-cy":"add-credential-button","to":{ name: 'newCredential' }}},[_vm._v(" "+_vm._s(_vm.$t('addCredentialBtn'))+" ")]),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$tc('confirmDeleteTitle', _vm.toDelete.length),"action-btn-func":_vm.onDelete,"cancel-btn-func":_vm.closeDeleteModal,"action-btn-text":_vm.$tc('deleteCredentialBtn', _vm.toDelete.length, { nb: _vm.toDelete.length }),"loading":_vm.deleting,"small":"","modal-type":"delete"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('areYouSure', _vm.toDelete.length, { item: _vm.toDelete[0].name }))}})]),(_vm.toDelete.length > 1)?_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.toDelete),function(ref){
var canonical = ref.canonical;
var name = ref.name;
return _c('li',{key:canonical},[_c('b',[_vm._v(_vm._s(name))])])}),0):_vm._e()]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var item = ref.props.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.name))]),(_vm.$static.cycloidCredentials.includes(item.canonical))?_c('CyTag',{staticClass:"ml-2",attrs:{"variant":"primary","small":""}},[_vm._v(" "+_vm._s(_vm._.toLower(_vm.$t('untranslated.default')))+" ")]):_vm._e()],1),_c('td',[_c('CyTooltip',{attrs:{"disabled":_vm._.get(item, 'description.length', 0) <= 40,"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._.truncate(item.description, { length: 40 })))])]}}],null,true)},[_vm._v(" "+_vm._s(item.description)+" ")])],1),_c('td',[(_vm.canUpdateOwner(item))?_c('CyFormsAssignOwner',{attrs:{"errors":_vm.errors,"form-content":item,"action-btn-func":_vm.assignNewOwner,"sm":""}}):_c('CyButton',{attrs:{"to":{
            name: 'member',
            params: {
              id: item.owner.id,
              backRouteTo: 'credentials',
            },
          },"disabled":!_vm.canGoToMember(item),"readonly":!_vm.canGoToMember(item),"theme":"grey","variant":"tertiary","sm":"","member-link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('CyMember',{attrs:{"member":item.owner,"simple":"","sm":""}})],1)],1),_c('td',{key:item.canonical},[_c('v-row',{staticClass:"credential flex-nowrap",attrs:{"no-gutters":""}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30","rounded":"0"}},[_c('CyIconCredential',{attrs:{"colour-icon":"","hide-tooltip":"","no-margin":"","type":item.type}})],1),_c('span',[_vm._v(_vm._s(_vm.formatType(item.type)))])],1)],1),_c('td',[_c('div',[_c('CyCopyButton',{staticClass:"mr-2",attrs:{"copy-value":item.path,"small":""}}),_vm._v(" "+_vm._s(item.path)+" ")],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
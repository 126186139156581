<template>
  <div class="pricing-panel">
    <CyModal
      v-if="detailsIsOpen"
      action-btn-hidden
      large
      :header-title="$t('costEstimationDetails')"
      :cancel-btn-func="() => $toggle.detailsIsOpen(false)">
      <CyTerracostPricingTable :pricing-data="envEstimate"/>
    </CyModal>
    <div class="pricing-panel__header">
      <div
        v-if="envEstimate"
        class="my-1 font-size-sm">
        <CyTag
          v-if="isEstimateUpToDate && !isEnvLoading"
          small
          data-cy="up-to-date">
          {{ $t('status.upToDate') }}
        </CyTag>
        <CyTag
          v-else
          small
          data-cy="outdated"
          variant="warning">
          {{ $t('status.outdated') }}
        </CyTag>
      </div>

      <template v-if="!envEstimate">
        <div class="pricing-panel__intro-title">
          {{ $t('intro.title') }}
        </div>
        <div class="pricing-panel__intro-summary">
          {{ $t('intro.summary') }}
        </div>
      </template>

      <template v-else>
        <CyAlert
          v-if="envEstimate.info"
          class="cost-hero"
          :title="$t('estimateInfo')"
          :content="_.upperFirst(envEstimate.info)"/>
        <div class="cost-hero">
          <div class="cost-hero__title">
            {{ $t('price.monthly') }} ({{ currency }})*
          </div>
          <div class="cost-hero__cost">
            {{ monthlyCostEstimate }}
          </div>
        </div>
        <div class="cost-hero cost-hero--annual">
          <div class="cost-hero__title">
            {{ $t('price.yearly') }} ({{ currency }})*
          </div>
          <div class="cost-hero__cost">
            {{ yearlyCostEstimate }}
          </div>
        </div>
      </template>

      <CyButton
        v-if="!envEstimate"
        class="mt-5"
        data-cy="estimate-cost"
        variant="secondary"
        :disabled="!requiredFieldsFilled || !canEditProject"
        :loading="isEnvLoading"
        @click="fetchEstimate()">
        {{ $t('button.estimate') }}
      </CyButton>

      <CyButton
        v-else-if="!haveStackFormsChanged && !isEnvLoading"
        data-cy="cost-details"
        variant="secondary"
        theme="primary"
        @click="$toggle.detailsIsOpen(true)">
        {{ $t('button.details') }}
      </CyButton>

      <CyButton
        v-else
        :loading="isEnvLoading"
        :disabled="!requiredFieldsFilled"
        icon="autorenew"
        data-cy="estimate-cost"
        variant="primary"
        @click="fetchEstimate()">
        {{ $t('button.update') }}
      </CyButton>

      <div
        v-if="!requiredFieldsFilled"
        data-cy="required-fields-missing"
        class="error--text font-size-sm mt-2">
        {{ $t('message.missingRequiredFields') }}
      </div>
      <CyAlert
        v-if="_.get(errors, env)"
        class="mt-4"
        data-cy="errors"
        theme="error"
        :content="_.get(errors, env)"/>
    </div>

    <v-spacer/>

    <div class="pricing-panel__footer">
      <p v-if="envEstimate">
        {{ $t('message.estimationNote') }}
      </p>
      <div>
        {{ $t('poweredBy') }}
        <a
          class="cy-link"
          href="https://github.com/cycloidio/terracost"
          target="_blank">
          TerraCost
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import CyTerracostPricingTable from '@/components/CyTerracostPricingTable.vue'

export default {
  name: 'CyTerracostPricingPanel',
  components: {
    CyTerracostPricingTable,
  },
  props: {
    stackFormsInput: {
      type: Object,
      validator: (item) => item.inputs?.length === 1,
      required: true,
    },
    requiredFieldsFilled: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    detailsIsOpen: false,
    envEstimate: null,
  }),
  computed: {
    ...mapState('organization/terracost', [
      'estimates',
      'stackFormsValues',
      'currency',
      'loading',
      'errors',
    ]),
    ...mapGetters('organization/terracost', [
      'getAmountWithCurrency',
    ]),
    ...mapGetters('organization/project', [
      'project',
    ]),
    estimate () {
      return this.estimates[this.env]
    },
    isEnvLoading () {
      return this.loading[this.env] || false
    },
    env () {
      return _.get(this.stackFormsInput, 'inputs[0].environment_canonical', null)
    },
    haveStackFormsChanged () {
      return !_.isEqual(this.stackFormsValues[this.env]?.inputs, this.stackFormsInput?.inputs)
    },
    isEstimateUpToDate () {
      return Boolean(!this.haveStackFormsChanged && this.estimate)
    },
    canEditProject () {
      return this.$cycloid.permissions.canDisplay('UpdateProject', this.project.canonical)
    },
    monthlyCostEstimate () {
      return this.getAmountWithCurrency(_.sumBy(this.envEstimate, ({ planned_cost }) => Number(planned_cost)))
    },
    yearlyCostEstimate () {
      return this.getAmountWithCurrency(_.sumBy(this.envEstimate, ({ planned_yearly_cost }) => Number(planned_yearly_cost)))
    },
  },
  watch: {
    estimate (newVal) {
      if (this.isEnvLoading) return
      if (!newVal) this.envEstimate = null
      this.envEstimate = newVal
    },
  },
  destroyed () {
    this.RESET_STATE()
  },
  methods: {
    ...mapActions('organization/terracost', [
      'GET_ESTIMATE',
    ]),
    ...mapMutations('organization/terracost', [
      'RESET_STATE',
    ]),
    async fetchEstimate () {
      await this.GET_ESTIMATE(this.stackFormsInput)
    },
  },
  i18n: {
    messages: {
      en: {
        button: {
          details: 'View cost details',
          estimate: 'Estimate cost',
          update: 'Update cost estimate',
        },
        costEstimationDetails: 'Cost estimation details',
        estimateInfo: 'Estimate information.',
        intro: {
          summary: 'Get a clear idea of your future costs by estimating the environment infrastructure.',
          title: 'Estimate the cost of your environment',
        },
        message: {
          estimationNote: '* Please note that this is an estimation: the actual price may differ, based upon reductions, not estimated resources and other parameters.',
          missingRequiredFields: 'Fill in the required fields before you can update the cost estimation.',
        },
        poweredBy: 'Powered by',
        price: {
          monthly: 'Monthly cost estimation',
          yearly: 'Annual cost estimation',
        },
        status: {
          outdated: 'Estimation outdated',
          upToDate: 'Estimation up-to-date',
        },
      },
      es: {
        button: {
          details: 'Ver detalles de coste',
          estimate: 'Estimación de costes',
          update: 'Actualizar la estimación de costes',
        },
        costEstimationDetails: 'Detalles de la estimación de costos',
        estimateInfo: 'Información de estimación.',
        intro: {
          summary: 'Obtenga una idea clara de sus costes futuros estimando la infraestructura del entorno.',
          title: 'Estime el coste de su entorno',
        },
        message: {
          estimationNote: '* Tenga en cuenta que esta es una estimación: el precio real puede diferir, basándose eso en reducciones, recursos no estimados y otros parámetros',
          missingRequiredFields: 'Rellene los campos obligatorios antes de poder actualizar la estimación de costes.',
        },
        poweredBy: 'Desarrollado por',
        price: {
          monthly: 'Estimación de costes mensual',
          yearly: 'Estimación de costes anuales',
        },
        status: {
          outdated: 'Estimación obsoleta',
          upToDate: 'Estimación actualizada',
        },
      },
      fr: {
        button: {
          details: 'Afficher les détails du coût',
          estimate: 'Estimer le coût',
          update: `Mettre à jour l'estimation des coûts`,
        },
        costEstimationDetails: `Détail de l'estimation des tarifs`,
        estimateInfo: `Information sur l'estimation.`,
        intro: {
          summary: `Ayez une idée claire de vos coûts futurs en estimant l'infrastructure de votre environnement.`,
          title: 'Estimez le coût de votre environnement',
        },
        message: {
          estimationNote: `* Veuillez noter qu'il s'agit d'une estimation: le prix réel peut différer, en fonction des réductions, des ressources non estimées et d'autres paramètres.`,
          missingRequiredFields: `Remplissez les champs obligatoires avant de pouvoir mettre à jour l'estimation des coûts.`,
        },
        poweredBy: 'Propulsé par',
        price: {
          monthly: 'Estimation mensuelle des coûts',
          yearly: 'Estimation annuelle des coûts',
        },
        status: {
          outdated: 'Estimation obsolète',
          upToDate: 'Estimation à jour',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cost-hero {
  margin: 16px 0;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: cy-get-color("secondary", "light-4");
  color: cy-get-color("secondary", "main");

  &__title {
    font-weight: $font-weight-bolder;
  }

  &__cost {
    color: cy-get-color("primary", "dark-1");
    font-size: $font-size-h3;
    font-weight: $font-weight-bolder;
    line-height: $line-height-sm;
  }

  &--annual {
    background-color: cy-get-color("primary", "light-5");
    color: cy-get-color("primary", "light-2");
  }
}

.pricing-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__intro-title {
    margin-top: 16px;
    color: cy-get-color("primary", "light-2");
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
  }

  &__intro-summary {
    margin-top: 8px;
    margin-bottom: 16px;
    color: cy-get-color("primary", "light-3");
  }

  &__footer {
    margin-top: 42px;
    font-size: $font-size-sm;

    > p {
      color: cy-get-color("primary", "light-2");
    }

    > a {
      font-weight: $font-weight-bolder;
    }
  }
}

</style>
